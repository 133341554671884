import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { IonSelect, IonSelectOption, IonLoading } from '@ionic/react'

import { getLoan, getLoans } from 'api/pos'
import { useAuth } from 'store/Auth'

import Label from 'components/Label'
import Button from './components/Button'
import Layout from './components/Layout'
import { useOnboarding } from 'store/Onboarding'

const StyledSelect = styled(IonSelect)`
  padding: 1rem 0.5rem;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;

  background: #fff;

  color: var(--color-gray);
  border: none;
  border-radius: 0.5rem;

  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
`

const SelectLoanPage = ({ history }) => {
  const { loan, setLoan, isPos } = useAuth()

  const { redirect } = useOnboarding()

  const [state, setState] = useState({
    loading: true,
    skip: false,
    loans: [],
    missingLoan: false,
  })

  const setLoanId = id => getLoan(id).then(setLoan)

  const setSkip = skip => setState(state => ({ ...state, skip }))
  const setLoans = loans => setState(state => ({ ...state, loans }))
  const setLoading = loading => setState(state => ({ ...state, loading }))
  const setMissingLoan = () => setState(state => ({ ...state, missingLoan: true }))

  useEffect(() => {
    if (!isPos) {
      console.log('Consumer does not have a POS account. Skipping loan selection.')

      return setSkip(true)
    }

    getLoans().then(loans => {
      if (loans.length === 0) {
        setMissingLoan()

        return
      }

      if (loans.length === 1) {
        console.log('Single loan found', loans[0])

        setLoanId(loans[0].id).then(() => setSkip(true))

        return
      }

      console.log('Showing loan list.', loans)

      setLoans(loans)
      setLoading(false)
    })
  }, [])

  const nextStep = redirect || '/home'

  if (state.missingLoan) {
    return <Layout title={`Error!`} subtitle="Loan Not Found. Please contact support." />
  }

  if (state.skip) {
    return <Redirect to={nextStep} />
  }

  if (state.loading) {
    return <IonLoading isOpen={state.loading} message="Loading..." />
  }

  return (
    <Layout title={`Almost there!`} subtitle="Please select your loan...">
      <Label secondary color="gray" size="sm">
        Loan
      </Label>

      <StyledSelect
        placeholder="Select your loan"
        interface="action-sheet"
        value={state.loans[0].id}
        onIonChange={event => setLoanId(Number(event.detail.value))}
      >
        {state.loans.map((loan, index) => (
          <IonSelectOption key={`loan-${index}`} value={loan.id}>
            {loan.id}
          </IonSelectOption>
        ))}
      </StyledSelect>

      <Button routerLink={nextStep} disabled={Boolean(loan)}>
        Next
      </Button>
    </Layout>
  )
}

export default SelectLoanPage
