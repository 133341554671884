import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

import List from 'components/List'
import { IonCard, IonCol, IonGrid, IonRow } from '@ionic/react'
import { ReactComponent as IconPlusPartners } from 'legacy-icons/plus-partners.svg'
import Label from '../../../components/Label'

interface LoanOfficerListItemProps {
  officer: ILoanOfficer
}

interface ILoanOfficer {
  email: string
  facebook_url: string | null
  first_name: string
  last_name: string
  id: number
  instagram_url: string | null
  license: null | string
  linkedin_url: string | null
  nmls: null | string | number
  partner: ILoanOfficerPartner | Record<string, never>
  phone: string
  photo_url: string | null
  pinterest_url: string | null
  title: string | null
  twitter_url: string | null
  youtube_url: string | null
  zipcode: string
}

interface ILoanOfficerPartner {
  first_name: string
  last_name: string
  email: string
}

const LoanOfficerList = ({ officers, onSelect }) => {
  return (
    <StyledList>
      {
        officers.map((officer, i) => (
          <LoanOfficerListItem onClick={() => onSelect(officer)} key={`officer-${i}`} officer={officer} />
        )) as ReactNode[]
      }
    </StyledList>
  )
}

const LoanOfficerListItem: React.FC<LoanOfficerListItemProps> = props => {
  const { officer } = props
  const [partner, setPartner] = useState<ILoanOfficerPartner | Record<String, never>>({})
  const [hasPartner, setHasPartner] = useState<boolean>(false)

  useEffect(() => {
    if (officer.partner) {
      setPartner(officer.partner)
    }
  }, [officer])

  useEffect(() => {
    setHasPartner(Object.keys(partner).length > 0)
  }, [partner])

  return (
    <LoanOfficerItem {...props}>
      <IonGrid>
        <StyledIonRow className={hasPartner ? 'ion-justify-content-center' : ''}>
          <StyledIonCol
            size={hasPartner ? 6 : '12'}
            partner={hasPartner}
            padding={hasPartner ? '5px 5px 5px 25px' : '5px'}
            align={hasPartner ? 'right' : 'left'}
            style={{ order: hasPartner ? 3 : 1 }}
          >
            <Label size="lg" color="primary" block isWrapped={true}>
              {officer.first_name} {officer.last_name}
            </Label>
          </StyledIonCol>

          {hasPartner && (
            <StyledPlusWrapper>
              <IconPlusPartners />
            </StyledPlusWrapper>
          )}

          {hasPartner && (
            <StyledIonCol size={6} partner={hasPartner}>
              <Label size="lg" color="primary" block isWrapped={true}>
                {partner.first_name} {partner.last_name}
              </Label>
            </StyledIonCol>
          )}
        </StyledIonRow>
      </IonGrid>
    </LoanOfficerItem>
  )
}

const StyledList = styled(List)`
  padding-top: 1rem;
`

const LoanOfficerItem = styled(IonCard)`
  min-height: 80px;
  display: flex;

  ion-grid {
    flex: 1 1 100%;
    align-items: center;
    display: flex;
  }
`

const StyledIonRow = styled(IonRow)`
  flex: 0 0 100%;
`

const StyledIonCol = styled(IonCol)`
  display: flex;
  align-items: center;
  padding: ${props => (props.padding ? props.padding : '5px')};
  text-align: ${props => (props.align ? props.align : 'inherit')};
`

const StyledPlusWrapper = styled.div`
  position: absolute;
  top: calc(50% - 19px);
  bottom: 0;

  text-align: center;

  svg {
    circle {
      fill: var(--color-primary-lighter);
    }

    path {
      fill: var(--color-primary);
    }
  }
`

export default LoanOfficerList
