import React from 'react'
import styled from 'styled-components'

import { Milestone } from 'types/Pos'

import Label from 'components/Label'
import MilestoneImage from './MilestoneImage'
import ModalContent from 'components/ModalContent'

interface Props {
  milestone: Milestone
}

const MilestoneModal: React.FC<Props> = ({ milestone }) => (
  <ModalContent>
    <Wrapper>
      <div className="content">
        <MilestoneImage milestone={milestone} style={{ marginBottom: '10px' }} />

        <Label block color="gray-light" className="title">
          {milestone.name}
        </Label>

        <Label secondary size="xs" color="gray-light" isWrapped>
          {milestone.explanation_1}
        </Label>
      </div>
    </Wrapper>
  </ModalContent>
)

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    padding: 1rem;
    text-align: center;
  }

  .title {
    margin-bottom: 10px;
  }
`

export default MilestoneModal
