import React from 'react'
import { IonCard } from '@ionic/react'
import Label from './Label'
import styled from 'styled-components'
import Button from './Button'

const StyledCard = styled(IonCard)`
  margin-top: 0;
  margin-bottom: 0.375rem;
  padding: 2.25rem 1.25rem 2rem;
`

const ProfileInfoAlt = ({ owner, nmls, license, color = 'primary' }) => (
  <StyledCard className="ion-no-margin">
    <Label block color={color} weight={500} center isWrapped>
      {owner.first_name} {owner.last_name}
    </Label>

    <Label block secondary color="gray-light" weight={600} size="xsplus" center style={{ marginTop: '4px' }} isWrapped>
      {owner.title}
    </Label>

    {(nmls || license) && (
      <Label
        block
        secondary
        color="gray-lighter-alt"
        weight={600}
        size="xs"
        center
        style={{ marginTop: '2px' }}
        isWrapped
      >
        {license ? `License: #${license}` : nmls ? `NMLS: #${nmls}` : ''}
      </Label>
    )}
    <Button center color="secondary" size="sm" style={{ marginTop: '1rem' }} routerLink="/home/profile">
      Contact Me
    </Button>
  </StyledCard>
)

export default ProfileInfoAlt
