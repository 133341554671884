import React from 'react'

import PosDocumentItem from './PosDocumentItem'
import DocumentList from 'pages/documents/components/DocumentList'

const PosDocumentList = ({ documents, ...props }) => (
  <DocumentList>
    {documents.owed.map((document, index) => (
      <PosDocumentItem key={`owed-${document.id || index}`} document={document} {...props} />
    ))}

    {documents.submitted.map((document, index) => (
      <PosDocumentItem key={`submitted-${index}`} document={document} {...props} />
    ))}

    {documents.rejected.map((document, index) => (
      <PosDocumentItem key={`rejected-${index}`} document={document} {...props} />
    ))}

    {documents.accepted.map((document, index) => (
      <PosDocumentItem key={`accepted-${index}`} document={document} {...props} />
    ))}
  </DocumentList>
)

export default PosDocumentList
