import React, { useState, useEffect } from 'react'
import { IonSelect, IonSelectOption } from '@ionic/react'
import styled from 'styled-components'

import { useAuth } from 'store/Auth'

import Label from 'components/Label'
import Layout from './components/Layout'
import Button from './components/Button'

const StyledSelect = styled(IonSelect)`
  padding: 1rem 0.5rem;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;

  background: #fff;

  color: var(--color-gray);
  border: none;
  border-radius: 0.5rem;

  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
`

const SelectRolePage = ({ history }) => {
  const { user, setRole } = useAuth()
  const [selectedRole, setSelectedRole] = useState(null)

  useEffect(() => {
    if (user.roles.length === 1) {
      setRole(user.roles[0])
    }

    history.push(`/auth/select-loan-officer`)
  }, [])

  return (
    <Layout title={`Welcome, ${user.first_name}`} subtitle="Please select your sign-in role...">
      <Label secondary color="gray" size="sm">
        Role
      </Label>

      <StyledSelect
        interface="action-sheet"
        value={selectedRole}
        onIonChange={event => setSelectedRole(event.detail.value)}
      >
        {user.roles.map(role => (
          <IonSelectOption key={role} value={role}>
            {role}
          </IonSelectOption>
        ))}
      </StyledSelect>

      <Button onClick={() => setRole(selectedRole)}>Next</Button>
    </Layout>
  )
}

export default SelectRolePage
