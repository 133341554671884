import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { IonContent, IonLoading, IonPage } from '@ionic/react'

import List from 'components/List'
import Header from 'components/Header'
import HistoryListItem from './components/HistoryListItem'

import { getHistory } from 'api/documents'
import { HistoryItem } from 'types/Documents'

interface Params {
  document_id: string
}

interface State {
  loading: boolean
  history: HistoryItem[]
}

const DocumentHistoryPage: React.FC = () => {
  const { document_id } = useParams<Params>()

  const [state, setState] = useState<State>({
    loading: true,
    history: [],
  })

  useEffect(() => {
    const setHistory = (history: HistoryItem[]) =>
      setState(state => ({
        ...state,
        history,
        loading: false,
      }))

    getHistory(Number(document_id)).then(setHistory)
  }, [])

  return (
    <IonPage>
      <Header title="Document History" defaultHref="/documents" />

      <IonContent>
        <IonLoading isOpen={state.loading} message="Loading..." />

        <List>
          {state.history.map(historyItem => (
            <HistoryListItem key={historyItem.id} item={historyItem} />
          ))}
        </List>
      </IonContent>
    </IonPage>
  )
}

export default DocumentHistoryPage
