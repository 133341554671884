import React, { ReactNode } from 'react'
import { IonCard, IonCardContent } from '@ionic/react'
import styled from 'styled-components'

interface Props extends React.ComponentProps<typeof IonCard> {
  left?: ReactNode
  right?: ReactNode
}

const BannerCard: React.FC<Props> = ({ children, left, right, ...card }) => (
  <IonCard {...card} className={`ion-no-margin ${card.className}`}>
    <CardContent>
      <div>{children || left}</div>

      {right}
    </CardContent>
  </IonCard>
)

const CardContent = styled(IonCardContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`

export default BannerCard
