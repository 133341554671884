import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useAuth } from 'store/Auth'
import { useApplication } from 'store/Application'

type BorrowerRouteProps = {
  [key: string]: any
  location?: any
  component?: any
}

const BorrowerRoute = ({ location, component, ...props }: BorrowerRouteProps) => {
  const { user, consumerService } = useAuth()
  const { isPreview } = useApplication()

  const renderPrivateComponent = () => {
    if (isPreview) {
      return <Route component={component} {...props} />
    }

    if (!user) {
      return <Redirect to={{ pathname: '/auth', state: { from: location } }} />
    }

    if (user.is_consumer && !consumerService) {
      return <Redirect to={{ pathname: '/auth/select-loan-officer', state: { from: location } }} />
    }

    if (!user.is_consumer) {
      return <Redirect to={{ pathname: '/home', state: { from: location } }} />
    }

    return <Route component={component} {...props} />
  }

  return <Route {...props} render={renderPrivateComponent} />
}

export default BorrowerRoute
