import React from 'react'
import BorrowerRoute from '../layouts/auth/BorrowerRoute'
import PosTasksPage from '../pages/home/PosTasksPage'
import PosMilestonesPage from '../pages/home/PosMilestonesPage'
import PosLoanTeamPage from '../pages/home/PosLoanTeamPage'
import BorrowerCreditReport from '../pages/home/BorrowerCreditReport'

const ConsumerTabRoutes = () => {
  return (
    <>
      <BorrowerRoute exact path="/consumer/tasks" component={PosTasksPage} />
      <BorrowerRoute exact path="/consumer/milestones" component={PosMilestonesPage} />
      <BorrowerRoute exact path="/consumer/team" component={PosLoanTeamPage} />
      <BorrowerRoute exact path="/consumer/credit" component={BorrowerCreditReport} />
    </>
  )
}

export default ConsumerTabRoutes
