import * as React from 'react';
import styled from 'styled-components/macro';

import { Loan, LoanMilestone } from 'types/Pos';

import Label from 'components/Label';
import useModal from 'hooks/useModal';

import MilestoneModal from './MilestoneModal';
import MilestoneActionButton from './MilestoneActionButton';
import MilestoneImageOrStatus from './MilestoneImageOrStatus';
import { format, parseISO } from 'date-fns';
import { useAuth } from '../../../store/Auth';
import MilestoneDateTimePicker from './MilestoneDateTimePicker';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  loan: Loan;
  loanMilestone: LoanMilestone;
  isLast?: boolean | undefined;
  reload?: Function;
}

const MilestoneItem: React.FC<Props> = ({ loan, loanMilestone, isLast, reload }) => {
  const { isLoanOfficer, isPartner, isUser } = useAuth();

  const [actionDate, setActionDate] = useState('');
  const [dense, setDense] = useState(false);
  const [currentMilestone, setCurrentMilestone] = useState<LoanMilestone | Record<string, any>>({
    milestone: {},
    completed_at: null,
    is_current: false,
    in_progress: false,
  });

  const [present] = useModal(MilestoneModal, {
    milestone: currentMilestone.milestone,
  });

  const showModal = () => loanMilestone.milestone.explanation_1 && !isLoanOfficer && present();

  const onDateTimeChanged = dateTime => {
    if (dateTime) {
      setCurrentMilestone(prevState => ({
        ...prevState,
        completed_at: format(new Date(dateTime), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        completed_at_date: dateTime,
      }));
    }
  };

  const handleMilestoneClickEvent = useCallback(() => {
    if (!currentMilestone.completed_at && !currentMilestone.is_current && !currentMilestone.in_progress && isUser) {
      showModal();
    }
  }, [currentMilestone]);

  useEffect(() => {
    let mounted = true;

    if (mounted && loanMilestone) {
      setCurrentMilestone(loanMilestone);
    }

    return () => {
      mounted = false;
    };
  }, [loanMilestone]);

  useEffect(() => {
    let mounted = true;

    if (mounted && currentMilestone) {
      if (currentMilestone.completed_at) {
        setActionDate(format(parseISO(currentMilestone.completed_at), 'P'));
      }
    }

    return () => {
      mounted = false;
    };
  }, [currentMilestone, setActionDate]);

  useEffect(() => {
    setDense(isLoanOfficer || isPartner);
  }, [setDense]);

  return (
    <Wrapper
      dense={dense}
      halfOpacity={!currentMilestone.is_current && !currentMilestone.in_progress && !currentMilestone.completed_at}
      onClick={handleMilestoneClickEvent}
    >
      <MilestoneImageOrStatus loanMilestone={currentMilestone} showModal={showModal} />

      {!dense && <HorizontalSeparator />}

      {!isLast && !dense && <VerticalSeparator />}

      <MilestoneInformationWrapper dense={dense}>
        {!dense && (
          <MilestoneStatusTextWrapper>
            {(currentMilestone.is_current || currentMilestone.in_progress === true) &&
            !currentMilestone.completed_at &&
            !currentMilestone.completed_at_date ? (
              <Label size="xs" weight={700} color="accent">
                IN PROGRESS
              </Label>
            ) : currentMilestone.completed_at || currentMilestone.completed_at ? (
              <Label size="xs" weight={700} color="accent">
                COMPLETED
              </Label>
            ) : (
              <Label size="xs" weight={700} color="gray-lighter">
                NOT STARTED
              </Label>
            )}
            <MilestoneDateWrapper dense={dense}>{actionDate}</MilestoneDateWrapper>
          </MilestoneStatusTextWrapper>
        )}

        <MilestoneActionButtonWrapper dense={dense}>
          <MilestoneActionButton
            loanMilestone={currentMilestone}
            onMoreDetails={showModal}
            render={handle => (
              <Label
                block
                secondary
                weight={dense ? 700 : 400}
                size={dense ? 'xs' : 'normal'}
                color="gray"
                onClick={dense ? null : handle}
                isWrapped={true}
              >
                {currentMilestone.milestone.name}
              </Label>
            )}
          />
        </MilestoneActionButtonWrapper>

        {dense && actionDate && !isLoanOfficer && (
          <MilestoneDateWrapper dense={dense}>{actionDate}</MilestoneDateWrapper>
        )}

        {isLoanOfficer && (
          <MilestoneDateWrapper dense={dense}>
            <MilestoneDateTimePicker
              milestone={currentMilestone}
              loan={loan}
              onChange={onDateTimeChanged}
              reload={reload}
            />
          </MilestoneDateWrapper>
        )}
      </MilestoneInformationWrapper>
    </Wrapper>
  );
};

export const Wrapper = styled.div<{ dense: boolean; halfOpacity: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${props => (props.dense ? '1px' : '0px')};
  margin-bottom: ${props => (props.dense ? '0px' : '33px')};
  height: ${props => (props.dense ? '48px' : 'auto')};
  &:nth-of-type(odd) {
    background-color: ${props => (props.dense ? '#F5F6F7' : 'transparent')};
  }
  opacity: ${props => (props.halfOpacity ? '0.5' : '1')};
`;

const HorizontalSeparator = styled.div`
  width: 19px;
  height: 2px;
  margin: 0 10px;
  background-color: var(--color-gray-lighter);
`;

const VerticalSeparator = styled.div`
  position: absolute;
  top: 50px;
  left: 90px;
  width: 2px;
  height: 51px;
  background-color: var(--color-gray-lighter);
`;

const MilestoneDateWrapper = styled.div<{ dense: boolean }>`
  font-style: italic;
  font-size: 10px;
  font-weight: 400;
  margin-left: auto;
  color: var(--color-gray-light);
`;

const MilestoneStatusTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MilestoneInformationWrapper = styled.div<{ dense: boolean }>`
  width: 100%;
  display: ${props => (props.dense ? 'flex' : 'block')};
  flex-direction: row;
  padding-right: ${props => (props.dense ? '.675rem' : '0')};
`;

const MilestoneActionButtonWrapper = styled.div<{ dense: boolean }>`
  ${props =>
    props.dense
      ? {
          display: 'flex',
          'align-items': 'center',
        }
      : {}}
`;

export default MilestoneItem;
