import React from 'react'

import TrialWelcomePage from '../pages/help/TrialWelcome'
import { Route } from 'react-router-dom'

const HelpRoutes = () => (
  <>
    <Route exact path="/help/trial-welcome" component={TrialWelcomePage} />
  </>
)

export default HelpRoutes
