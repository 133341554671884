import { useQuery } from 'react-query'

import { Loan } from 'types/Pos'
import { getStatistics } from 'api/lhp-forms'

const useLoanCompletedFields = (loan?: Loan) => {
  return useQuery(
    'completed-fields',
    async () => {
      if (!loan) return

      return await getStatistics(loan.submission_id)
    },
    {
      staleTime: 1000 * 60 * 1, // 1 minute
    }
  )
}

export default useLoanCompletedFields
