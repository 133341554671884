import React, { ReactElement, useCallback } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { LoanMilestone, TimelineItem } from 'types/Pos';

import Label from 'components/Label';
import Button from 'components/Button';

interface Props {
  loanMilestone: LoanMilestone | TimelineItem;
  onMoreDetails?: () => void;
  render?: (handle: () => void) => ReactElement;
}

const MilestoneActionButton: React.FC<Props> = ({ render, loanMilestone, onMoreDetails = () => undefined }) => {
  const history = useHistory();

  const handle = useCallback(() => {
    if (!loanMilestone.milestone.cta) {
      return;
    }

    if (loanMilestone.milestone.cta === 'DOCUMENTS') {
      return history.push('/documents');
    }

    if (loanMilestone.milestone.cta === 'ACCOUNT') {
      return history.push('/home');
    }

    if (loanMilestone.milestone.cta === 'APPLICATION') {
      return history.push('/application/form');
    }

    if (!loanMilestone.is_current) {
      return onMoreDetails();
    }
  }, [loanMilestone]);

  if (render) {
    return render(handle);
  }

  return (
    <>
      {loanMilestone.is_current && (
        <StyledButton size="sm" onClick={handle}>
          Access
        </StyledButton>
      )}

      {loanMilestone.is_current && loanMilestone.milestone.explanation_1 && (
        <Label style={{ marginTop: '5px' }} size="xs" fontStyle="italic" color="gray-light" onClick={onMoreDetails}>
          {' '}
          Tap for more details
        </Label>
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

export default MilestoneActionButton;
