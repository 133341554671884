import React from 'react'

import CalculatorResultPage from 'containers/CalculatorResultPage'

import ResultContent from './components/ResultContent'

const PrincipalResultPage = () => {
  return (
    <CalculatorResultPage
      calculator="principal"
      calculate={window.lhpCalculator.principal}
      renderContent={result => <ResultContent result={result} />}
    />
  )
}

export default PrincipalResultPage
