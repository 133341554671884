import * as React from 'react'
import { Loan } from 'types/Pos'
import styled from 'styled-components'
import { useAuth } from '../../../store/Auth'

type MilestoneProps = {
  dense?: boolean
  loan: Loan
  isPartner: boolean
  isUser: boolean
}

const Milestones: React.FC<MilestoneProps> = ({ children }) => {
  const { isLoanOfficer } = useAuth()

  return <MilestoneContainer dense={isLoanOfficer}>{children}</MilestoneContainer>
}

const MilestoneContainer = styled.div``

export default Milestones
