import React from 'react';
import { IonSlide } from '@ionic/react';
import styled from 'styled-components/macro';

import Layout from '../components/Layout';
import Label from 'components/Label';

import PhoneImageUrl from 'legacy-icons/onboarding/iphone-home-unedited.png';
import { ReactComponent as ChevronRightIcon } from 'legacy-icons/chevron-right.svg';
import DescriptionLabel from '../components/DescriptionLabel';

interface Props {
  progress: number;
  max: number;
}

const Introduction: React.FC<Props> = () => (
  <IonSlide>
    <Layout>
      <Wrapper>
        <Content>
          <Label style={{ marginTop: '2rem' }} block color="primary" size="2xl" isWrapped>
            Congrats, your trial has started!
          </Label>

          <DescriptionLabel style={{ marginTop: '1rem' }}>Now, let&apos;s quickly customize your app</DescriptionLabel>

          <Label style={{ marginTop: '2.5rem' }} secondary block color="gray-light" fontStyle="italic">
            swipe left to begin
          </Label>
        </Content>

        <PhoneImageContainer>
          <img src={PhoneImageUrl} alt="Phone" />
        </PhoneImageContainer>
      </Wrapper>

      <ChevronRightWrapper>
        <ChevronRightIcon />
      </ChevronRightWrapper>
    </Layout>
  </IonSlide>
);

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  padding-bottom: 2.5rem;
`;

const PhoneImageContainer = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;

  img {
    margin: 0 auto;
    width: 240px;
    min-height: 403px;
  }
`;

const ChevronRightWrapper = styled.div`
  right: 15px;
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  svg {
    color: var(--color-gray-light);
  }
`;

export default Introduction;
