import React from 'react'
import styled from 'styled-components'
import { IonCard, IonCardContent } from '@ionic/react'

const StyledIonCard = styled(IonCard)`
  margin: 0 0 1.25rem;
  color: var(--color-gray);
`

const StyledIonCardContent = styled(IonCardContent)`
  padding: 0 1.5rem;
`

const OptionCard = ({ children, ...props }) => (
  <StyledIonCard {...props}>
    <StyledIonCardContent>{children}</StyledIonCardContent>
  </StyledIonCard>
)

export default OptionCard
