import * as React from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as IconArrowLeft } from 'icons/Icon-ArrowLeft.svg'
import { ReactComponent as IconArrowRight } from 'icons/Icon-ArrowRight.svg'

type Props = {
  direction: SwipeDirectionType
}

const LEFT = 'left'
const RIGHT = 'right'

type SwipeDirectionType = typeof LEFT | typeof RIGHT

const SwipeDirection: React.FC<Props> = props => {
  const { direction } = props

  return (
    <SwipeDirectionContainer {...props}>
      {direction === 'left' && <IconArrowLeft />}
      <SwipeDirectionText>Swipe</SwipeDirectionText>
      {direction === 'right' && <IconArrowRight />}
    </SwipeDirectionContainer>
  )
}

const SwipeDirectionContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  font-family: var(--font-secondary);
  font-size: 16px;
  top: calc(50% - 22px);
  left: ${(props: Props) => (props.direction === 'left' ? '23px' : 'auto')};
  right: ${(props: Props) => (props.direction === 'right' ? '23px' : 'auto')};
  font-style: italic;
  color: #969696;
`

const SwipeDirectionText = styled.div`
  padding: 0 0.5rem;
`

export default SwipeDirection
