import React from 'react';

import Label from './Label';
import BannerCard from './BannerCard';
import ShareAppSrc from 'legacy-icons/share-app.png';

export interface ShareAppCardContentProps {
  className?: string;
  title?: string;
  description?: string;
  onClick?: () => void;
}

const ShareAppCardContent: React.FC<ShareAppCardContentProps> = ({
  title = 'Share Your App!',
  description = 'Share your custom link with your clients and realtor partners.',
  onClick,
  className,
}) => (
  <BannerCard right={<img src={ShareAppSrc} alt="Icon" />} onClick={onClick} className={className}>
    <Label block color="gray" weight={500} className="ion-margin-bottom">
      {title}
    </Label>

    <Label
      style={{ paddingRight: '5px' }}
      block
      secondary
      size="xs"
      color="gray-light"
      fontStyle="italic"
      weight={300}
      isWrapped
    >
      {description}
    </Label>
  </BannerCard>
);

export default ShareAppCardContent;
