import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IonHeader, IonToolbar, IonButtons, IonTitle, useIonPopover } from '@ionic/react'

import MenuButton from './MenuButton'
import HeaderLogo from './HeaderLogo'
import ExpirationWarning from './ExpirationWarning'

import { useApplication } from 'store/Application'
import { useAuth } from '../store/Auth'
import { useIntro } from '../store/Intro'
import Button from './Button'
import HeaderQuickMenu from './HeaderQuickMenu'
import { ReactComponent as IconSettings } from 'icons/Icon-Settings.svg'
import { useHistory } from 'react-router-dom'
import { Capacitor } from '@capacitor/core'

interface Props {
  defaultHref?: string
}

const LogoHeader: React.FC<Props> = ({ defaultHref }) => {
  const { isLoanOfficer, isPartner } = useAuth()
  const { steps, setSteps } = useIntro()
  const { isDefault, logoUrl } = useApplication()
  const [isAndroid, setIsAndroid] = useState(false)
  const history = useHistory()

  const [present, dismiss] = useIonPopover(HeaderQuickMenu, {
    onHide: () => dismiss(),
    isLoanOfficer,
    isPartner,
    steps,
    setSteps,
    history,
  })

  useEffect(() => {
    Capacitor.getPlatform() === 'android' ? setIsAndroid(true) : setIsAndroid(false)
  }, [])

  return (
    <IonHeader className="ion-no-border">
      <ExpirationWarning />

      <StyledIonToolbar>
        <IonButtons slot="start">
          <MenuButton defaultHref={defaultHref} />
        </IonButtons>

        {!isDefault && logoUrl && (
          <StyledIonTitle isAndroid={isAndroid}>
            <HeaderLogo src={logoUrl} />
          </StyledIonTitle>
        )}

        {(isLoanOfficer || isPartner) && (
          <IonButtons slot="end">
            <Button onClick={e => present({ event: e.nativeEvent })}>
              {' '}
              <IconSettings width={25} height={25} color="accent" />{' '}
            </Button>
          </IonButtons>
        )}
      </StyledIonToolbar>
    </IonHeader>
  )
}

const StyledIonToolbar = styled(IonToolbar)`
  --color: var(--color-gray-light);
  --min-height: 60px;
`

const StyledIonTitle = styled(({ isAndroid, ...props }) => <IonTitle {...props} />)`
  text-align: center;
  padding: 0;
  margin-top: 8px;
  padding-bottom: 8px;
  ${props =>
    props.isAndroid &&
    `

    position: absolute;
    left: 2rem;
    right: 2rem;
    bottom: 0rem;
    padding-bottom: 0px;

  `}
`

export default LogoHeader
