import React from 'react'
import styled from 'styled-components'

const StyledForm = styled.form`
  padding-top: 20px;
`

const Form = ({ children, ...props }) => <StyledForm {...props}>{children}</StyledForm>

export default Form
