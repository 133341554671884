import * as React from 'react'
import styled from 'styled-components'
import Label from '../Label'

const SubtitleLabel: React.FC = styled(Label)<{ lg?: boolean }>`
  font-size: ${props => (props.lg ? '32px' : '27px')} !important;
  line-height: 1.2 !important;
  margin-bottom: 0.75rem;
`

export default SubtitleLabel
