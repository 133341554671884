import React from 'react'
import { Switch } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'

import PrivateRoute from 'layouts/auth/PrivateRoute'

import LeadsPage from 'pages/leads/Index'
import QuickIntakePage from 'pages/leads/pipeline/QuickIntake'
import CreatePartner from 'pages/leads/sections/CreatePartner'
import EditPartner from 'pages/leads/sections/EditPartner'

import LoanPage from 'pages/leads/pipeline/Loan'

const LeadRoutes = () => (
  <IonRouterOutlet>
    <Switch>
      <PrivateRoute exact path="/leads/quick-intake" component={QuickIntakePage} />
      <PrivateRoute exact path="/leads/partners/:id/edit" component={EditPartner} />
      <PrivateRoute exact path="/leads/partners/create" component={CreatePartner} />

      <PrivateRoute path="/leads/tabs" component={LeadsPage} />
      <PrivateRoute path={`/leads/loans/:loan_id`} component={LoanPage} />
    </Switch>
  </IonRouterOutlet>
)

export default LeadRoutes
