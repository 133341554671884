import React from 'react'
import styled from 'styled-components'

const TableContainer = styled.div``

const TableBody = styled.div`
  background: #fff;
  padding: 6px;
  border-radius: 7px;
`

const DocumentList = ({ children }) => (
  <TableContainer>
    <TableBody>{children}</TableBody>
  </TableContainer>
)

export default DocumentList
