import * as React from 'react'

import ModalContent from '../../../components/ModalContent'
import styled from 'styled-components'
import { Loan, LoanMilestone } from '../../../types/Pos'
import Button from '../../../components/Button'
import { resetMilestone } from '../../../api/pos'

type ResetMilestoneProps = {
  close: (reset?: boolean) => void
  didReset: () => void
  loan: Loan
  milestone: LoanMilestone
}

const MilestoneResetModal: React.FC<ResetMilestoneProps> = props => {
  const { close, didReset, loan, milestone } = props

  const confirm = async () => {
    try {
      await resetMilestone(loan, milestone.id)

      didReset()

      close()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ModalContent title="Reset this milestone?" class="ion-padding" dismiss={close} theme={{ bordered: true }}>
      <JustifiedContainer>
        <ScheduledUpdateText>
          If you would like to reset this milestone you may proceed below. Otherwise, cancel to quit.
        </ScheduledUpdateText>
        <ActionWrapper>
          <Button fill="outline" color="gray" size="sm" onClick={close}>
            {' '}
            Cancel{' '}
          </Button>
          <Button color="secondary" size="sm" onClick={confirm}>
            {' '}
            Yes, Reset{' '}
          </Button>
        </ActionWrapper>
      </JustifiedContainer>
    </ModalContent>
  )
}

const JustifiedContainer = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const ScheduledUpdateText = styled.div`
  text-align: center;
  margin: 0 auto;
`

const ActionWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
`

export default MilestoneResetModal
