import React from 'react'

import Animation, { AnimationProps } from './Animation'
import alertData from 'lotties/alert.json'

const AlertAnimation: React.FC<AnimationProps> = ({ options, ...props }) => (
  <Animation {...props} options={{ ...options, loop: false }} data={alertData} />
)

export default AlertAnimation
