import React from 'react'
import styled from 'styled-components/macro'

import { Loan } from 'types/Pos'
import MilestoneItem from './MilestoneItem'
import MilestoneEventItem from './MilestoneEventItem'

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  loan: Loan
}

const MilestoneList: React.FC<Props> = ({ loan, ...props }) => (
  <Container>
    <ItemsContainer {...props}>
      {loan.timeline.map((loanMilestone, index) => {
        return loanMilestone.model_type === 'event' ? (
          <MilestoneEventItem
            key={loanMilestone.id}
            loanMilestone={loanMilestone}
            isLast={index === loan.timeline.length - 1}
          />
        ) : (
          <MilestoneItem
            key={loanMilestone.id}
            loanMilestone={loanMilestone}
            isLast={index === loan.timeline.length - 1}
          />
        )
      })}
    </ItemsContainer>
  </Container>
)

const Container = styled.div`
  position: relative;
  padding: 1rem 0;
`

const ItemsContainer = styled.div``

export default MilestoneList
