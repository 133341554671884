import startCase from 'lodash/startCase'

const removeSign = (number: number) => (number === 0 ? 0 : number)

/**
 * In this application, formatting numbers to a currency format
 * is very common. Let's create a global helper that helps
 * solve it. Example: (10000 -> $10,000.00).
 */
export const formatCurrency = (number: number, fractionDigits = 2): string =>
  removeSign(Number(number)).toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })

export const formatDecimal = (number: number, fractionDigits = 2): string =>
  removeSign(Number(number)).toLocaleString('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

export const formatPercent = (number: number, precision: number): string => `${formatDecimal(number, precision)}%`

export const titleCase = (value: string) => startCase(value.toLowerCase())
