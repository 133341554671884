import React from 'react';
import { IonTitle } from '@ionic/react';
import HeaderLogo from 'components/HeaderLogo';
import Label from 'components/Label';
import ModalContent from 'components/ModalContent';
import { useApplication } from 'store/Application';
import styled from 'styled-components';
import { IntroState } from 'types/intro';
import Button from 'components/Button';

const StyledIonTitle = styled(IonTitle)`
  text-align: center;
  padding: 0;
  margin-top: 8px;
  padding-bottom: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.675rem;

  ion-button:first-child {
    margin-right: 1rem;
  }
`;

type GuidedTourModalProps = Pick<IntroState, 'setGuidedTour' | 'setSteps'> & {
  autoHeight: boolean;
  dismiss: () => void;
};

const GuidedTourModal: React.FC<GuidedTourModalProps> = ({ dismiss, setGuidedTour, setSteps, ...props }) => {
  const { isDefault, logoUrl } = useApplication();

  return (
    <ModalContent dismissible={false} {...props}>
      {!isDefault && logoUrl && (
        <StyledIonTitle>
          <HeaderLogo src={logoUrl} />
        </StyledIonTitle>
      )}

      <Label size="xl" color="primary" center>
        {' '}
        Let&apos;s get started!
      </Label>
      <br />

      <Label size="lg" fontStyle="italic" center color="gray" secondary>
        Take a quick tour <br /> of the app
      </Label>

      <ButtonWrapper>
        <Button
          size="md"
          fill="outline"
          onClick={() => {
            setGuidedTour!({ seen: true });
            dismiss();
          }}
        >
          {' '}
          Skip Tour{' '}
        </Button>
        <Button
          size="md"
          onClick={() => {
            setGuidedTour!({ seen: true });
            setSteps!({ enabled: true });
            dismiss();
          }}
        >
          {' '}
          Start Tour{' '}
        </Button>
      </ButtonWrapper>
    </ModalContent>
  );
};

export default GuidedTourModal;
