import React from 'react'

interface Slider {
  (direction: 'LEFT' | 'RIGHT'): void
}

const useSlider = <S extends string[]>(AVAILABLE_SLIDES: S): [S[number], Slider] => {
  const [slide, setSlide] = React.useState<S[number]>(AVAILABLE_SLIDES[0])

  const slideTo: Slider = direction => {
    const currentIndex = AVAILABLE_SLIDES.indexOf(slide)

    const nextIndex =
      direction === 'LEFT'
        ? currentIndex === 0
          ? AVAILABLE_SLIDES.length - 1
          : currentIndex - 1
        : currentIndex === AVAILABLE_SLIDES.length - 1
        ? 0
        : currentIndex + 1

    setSlide(AVAILABLE_SLIDES[nextIndex])
  }

  return [slide, slideTo]
}

export default useSlider
