import React from 'react'
import { IonImg } from '@ionic/react'

import { useApplication } from 'store/Application'
import { ReactComponent as DefaultLogoIcon } from 'legacy-icons/logo.svg'
import styled from 'styled-components'

interface Props {
  fallbackToLoanzify?: boolean
}

const ApplicationLogo: React.FC<Props> = ({ fallbackToLoanzify }) => {
  const { isDefault, logoUrl } = useApplication()

  if (isDefault) {
    return <DefaultLogoIcon />
  }

  if (logoUrl) {
    return <StyledIonImg className="logo ion-padding-horizontal" src={logoUrl} />
  }

  return fallbackToLoanzify ? <DefaultLogoIcon /> : null
}

const StyledIonImg = styled(IonImg)`
  max-height: 80px;
  max-width: 70%;
  overflow: hidden;
  margin: 0 auto;
`

export default ApplicationLogo
