import React from 'react';
import { useApplication } from 'store/Application';
import styled from 'styled-components';

const SIZES = {
  xs: '2.5rem',
  sm: '4rem',
  normal: '5rem',
  md: '6rem',
  lg: '7.5rem',
  xl: '10rem',
  '2xl': '15rem',
};

interface AvatarContainerProps {
  size: string;
  borderWidth?: string;
  borderColor?: string;
  url: string;
}

const AvatarContainer = styled.div<AvatarContainerProps>`
  width: ${props => getAvatarDimensions(props.size)};
  height: ${props => getAvatarDimensions(props.size)};

  background: url('${props => props.url}') var(--color-gray-lighter);
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  border-width: ${props => props.borderWidth};
  border-style: solid;
  border-color: ${props => `var(--color-${props.borderColor})`};
  border-radius: 999px;
`;

const getAvatarDimensions = (size: string) => {
  const [index] = Object.keys(SIZES)
    .map((s, idx) => {
      if (s === size) {
        return idx;
      }
    })
    .filter(res => !!res);
  return Object.values(SIZES)[index!];
};

const Avatar = ({ url, size }: AvatarContainerProps) => {
  const { photoUrl } = useApplication();

  return (
    <AvatarContainer
      size={size}
      borderWidth="0.25rem"
      borderColor="primary-lighter"
      url={url || photoUrl || 'https://loanzify.com/public/images/avatar_default.jpg'}
    />
  );
};

export default Avatar;
