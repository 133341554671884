import React, { useEffect } from 'react';
import { IonIcon, IonMenu, IonRouterLink } from '@ionic/react';
import styled from 'styled-components';
import Label from 'components/Label';
import ShareButton from 'components/ShareButton';
import SideMenuItemIcon from './SideMenuItemIcon';
import { chevronForward } from 'ionicons/icons';
import { useMenu } from '../../store/Menu';
import { useAuth } from '../../store/Auth';
import Button from '../../components/Button';

interface Props {
  menuRef: React.MutableRefObject<HTMLIonMenuElement | undefined>;
}

const SideMenu: React.FC<Props> = ({ menuRef }) => {
  const { items, isMenuPreview, isShareMenuItem } = useMenu();
  const { isConsumer, isUser } = useAuth();

  useEffect(() => {
    isMenuPreview && menuRef.current?.open();
  }, []);

  return (
    <StyledIonMenu contentId="tabbed-content" type="overlay" side="end" ref={menuRef}>
      <div className="safe-container">
        <SideMenuTitle hasSteps={isUser}>Navigation</SideMenuTitle>

        <div className="list">
          {items.map((item, index) =>
            isShareMenuItem(item) ? (
              <ShareButton
                key={`${item.title}-${index}`}
                render={open => (
                  <div className="list-item" onClick={open}>
                    <SideMenuItemIcon item={item} />

                    <Label secondary weight={400} size="normal">
                      {item.title}
                    </Label>

                    <IonIcon color="primary" size="large" icon={chevronForward} />
                  </div>
                )}
              />
            ) : (
              <IonRouterLink
                key={`${item.title}-${index}`}
                onClick={() => menuRef.current?.close()}
                routerLink={item.routerLink}
                href={item.externalUrl}
              >
                <div className="list-item">
                  <SideMenuItemIcon item={item} />

                  <Label secondary weight={400} size="normal">
                    {item.title}
                  </Label>

                  <IonIcon color="primary" size="large" icon={chevronForward} />
                </div>
              </IonRouterLink>
            )
          )}
        </div>
      </div>

      {isConsumer && (
        <BottomActionContainer>
          <Button routerLink={'/auth/delete'} fill={'outline'} size={'sm'}>
            {' '}
            DELETE{' '}
          </Button>
        </BottomActionContainer>
      )}
    </StyledIonMenu>
  );
};

const BottomActionContainer = styled.div`
  margin-top: 'auto';
  padding: 1rem;
`;

const StyledIonMenu = styled(IonMenu)`
  --background: #fff;

  .safe-container {
    padding-top: var(--ion-safe-area-top);
  }

  .list {
    margin-top: 1rem;
    padding: 0 1rem;
  }

  .list-item {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebecef;
    padding-bottom: 1.125rem;
    margin: 1.125rem 0;

    svg {
      width: 40px;
      height: 40px;
      color: var(--color-primary);
      margin-right: 5px;
    }

    ion-icon {
      position: absolute;
      right: 10px;
    }
  }
`;

const SideMenuTitle = styled.div`
  padding: 1rem 0;
  text-align: center;
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 23px;
  color: var(--color-gray);
  text-transform: uppercase;
  border-bottom: 3px solid #ebecef;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.hasSteps ? 'space-between' : 'center')};
`;

export default SideMenu;
