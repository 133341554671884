import React, { useState, useEffect } from 'react';
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';

import Label from 'components/Label';
import Searchbar from 'components/Searchbar';
import ConsumerListItem from 'pages/home/components/UserDashboard/ConsumerListItem';

import ListItemSkeleton from '../../../components/skeletons/ListItemSkeleton';
import NoDataLoaded from '../../../components/NoDataLoaded';
import NoBorrowersAnimation from '../../../components/animations/NoBorrowers';
import { useConsumers } from '../../../store/Consumers';
import { FetchConsumerPayload } from '../../../types/Consumer';
import usePrevious from '../../../hooks/usePrevious';

const ConsumersPage: React.FC = () => {
  const { consumers, clearConsumers, fetchConsumers } = useConsumers();

  const [hasMorePages, setHasMorePages] = useState(true);

  const [status, setStatus] = useState<'loading' | 'ready'>('loading');

  const [search, setSearch] = useState('');
  const prevSearchRef = usePrevious(search);

  const loadConsumers = async (payload?: FetchConsumerPayload) => {
    fetchConsumers(payload).finally(() => setStatus('ready'));
  };

  const loadMore = (event: any) => {
    fetchConsumers({ append: true }).then(newConsumers => {
      if (newConsumers.length === 0) {
        setHasMorePages(false);

        return;
      } else {
        setHasMorePages(true);
      }
      event.target.complete();
    });
  };

  useEffect(() => {
    setStatus('loading');
    setHasMorePages(true);

    if (search) {
      console.log('has search');
      clearConsumers()
        .then(() => {
          loadConsumers({
            page: 1,
            perPage: 10,
            search,
          }).catch(console.error);
        })
        .catch(console.error);
    } else {
      let fetchConsumerPayload: FetchConsumerPayload = {
        search: undefined,
      };
      if (prevSearchRef !== search) {
        fetchConsumerPayload.page = 1;
        fetchConsumerPayload.perPage = 10;
      }

      loadConsumers(fetchConsumerPayload).catch(console.error);
    }
  }, [search]);

  return (
    <div>
      <Searchbar value={search} onIonChange={e => setSearch(e.detail.value || '')} debounce={1000} />

      {status === 'loading' &&
        Array.from(Array(6)).map((_, i) => {
          return <ListItemSkeleton key={i} />;
        })}
      {status === 'ready' && (
        <>
          {consumers.length === 0 && (
            <NoDataLoaded icon={false} message="No borrowers have been added to your account...">
              <NoBorrowersAnimation />
            </NoDataLoaded>
          )}

          {consumers.length > 0 &&
            consumers.map(consumer => <ConsumerListItem key={consumer.id} consumer={consumer} tall={true} />)}

          {hasMorePages ? (
            <IonInfiniteScroll onIonInfinite={loadMore} threshold="100px">
              <IonInfiniteScrollContent />
            </IonInfiniteScroll>
          ) : (
            <Label className="ion-margin" center>
              You&apos;ve loaded all users.
            </Label>
          )}
        </>
      )}
    </div>
  );
};

export default ConsumersPage;
