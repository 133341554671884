import React, { useState, useEffect } from 'react'

import useStorage from './useStorage'

const usePersistentState = <S>(key: string, initialState: S): [S, React.Dispatch<React.SetStateAction<S>>, boolean] => {
  const { get, set } = useStorage()

  const [loading, setLoading] = useState(true)
  const [state, setState] = useState(initialState)

  // Load the state from the session storage.
  // It also merges any missing props with
  // the "initialState" object.
  useEffect(() => {
    get(key, initialState).then(data => {
      setState({
        ...initialState,
        ...data,
      })

      setLoading(false)
    })
  }, [key, initialState])

  // Watch for the state changes to update
  // the session storage with a new data.
  useEffect(() => {
    if (!loading) {
      set(key, state)
    }
  }, [key, state, loading])

  return [state, setState, loading]
}

export default usePersistentState
