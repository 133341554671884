import { getPartners as getPosPartners, getSSOUsersInfo, getSSOUsersInfoByConsumer } from '../api/pos'
import { getPartners as getSSOPartners } from '../api/sso'
import { getInstallation, getInstallations } from '../api/loanzify'
import { getDocumentsAsParent } from '../api/documents'

/**
 * Handles getting partners for non-pos users + pos users
 *
 * @returns {Promise<*>}
 */
export const getPartners = async () => {
  let { partners } = await getSSOPartners()

  try {
    const { partners: posPartners } = await getPosPartners()

    partners = partners.map(partner => {
      const posData = posPartners.find(p => p.sso_user_id === partner.id)

      return {
        ...{ active_loan_count: posData.active_loan_count },
        ...partner,
      }
    })
  } catch (e) {}

  return partners
}

/**
 * Handles the install mapping when data is coming from Loanzify
 *
 * @returns {Promise<*>}
 */
export const getInstallDocs = async install => {
  let files = []

  try {
    const response = await getDocumentsAsParent('loanzify', install.sso_consumer_service_id)

    files = response.files
  } catch (e) {
    console.warn(e)
  }

  return {
    ...install,
    docs_uploaded: files.length,
    sso_docs: files,
    docs_data: [],
  }
}

/**
 * Handles the install mapping when data is coming from POS
 *
 * @returns {Promise<*>}
 */
export const mapInstallWithSharedInfo = (install, sharedInfo) => {
  let docs_uploaded = 0
  let latest_loan_id = null
  let docs_data = []

  let consumer_data = sharedInfo.find(i => i.sso_consumer_service_id === install.sso_consumer_service_id)

  if (consumer_data) {
    docs_uploaded =
      (consumer_data.requests_accepted_count || 0) +
      (consumer_data.requests_pending_consumer_count || 0) +
      (consumer_data.requests_pending_user_count || 0)
    latest_loan_id = consumer_data.latest_loan_id || null
    docs_data = consumer_data.docs_data || []
  }

  return {
    ...install,
    docs_uploaded,
    latest_loan_id,
    docs_data,
    sso_docs: [],
  }
}

/**
 * Handles getting an individual install with documents
 *
 * @returns {Promise<*>}
 */
export const getInstallWithDocuments = async (install_id, hasPos, hasLoan) => {
  const install = await getInstallation(install_id)

  if (install.sso_consumer_id) {
    if (hasPos && hasLoan) {
      const { sso_consumer_ids: sharedInfo } = await getSSOUsersInfoByConsumer({
        sso_consumer_ids: [install.sso_consumer_id],
      })
      return mapInstallWithSharedInfo(install, sharedInfo)
    }
  }

  return hasLoan ? install : await getInstallDocs(install)
}

/**
 * Handles getting documents for pos admin and admins without pos
 *
 * @returns {Promise<*>}
 */
export const getInstallsWithDocuments = async (page, hasPos, search) => {
  let installs = await getInstallations({ search, page })

  if (hasPos) {
    const sso_consumer_ids = installs.map(i => i.sso_consumer_id).filter(id => !!id)

    if (!sso_consumer_ids.length) {
      return installs
    }

    const { sso_consumer_ids: sharedInfo } = await getSSOUsersInfo({ sso_consumer_ids })

    installs = installs.map(install => mapInstallWithSharedInfo(install, sharedInfo))
  } else {
    installs = await installs.map(async install => {
      let files = []

      try {
        const response = await getDocumentsAsParent('loanzify', install.sso_consumer_service_id)
        files = response.files
      } catch (e) {}

      return {
        ...install,
        docs_uploaded: files.length,
        sso_docs: files,
        docs_data: [],
      }
    })

    return Promise.all(installs)
  }

  return installs
}
