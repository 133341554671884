import React, { useState } from 'react';
import styled from 'styled-components';
import { cameraOutline } from 'ionicons/icons';
import { IonIcon, IonLoading, IonSlide } from '@ionic/react';

import { ReactComponent as AddPhotoIcon } from 'legacy-icons/add-photo.svg';
import HeadshotImgUrl from 'legacy-icons/headshot-placeholder.png';
import usePhotoGetter from 'hooks/usePhotoGetter';
import { useApplication } from 'store/Application';
import { uploadProfilePicture } from 'api/sso';

import Label from 'components/Label';
import Layout from '../components/Layout';
import DescriptionLabel from '../components/DescriptionLabel';
import ActionButtons from '../components/ActionButtons';
import { Dialog } from '@capacitor/dialog';

interface Props {
  onNext: () => Promise<void>;
  onPrev: () => Promise<void>;
  progress: number;
}

const UpdatePhoto: React.FC<Props> = ({ progress, onNext, onPrev }) => {
  const getPhoto = usePhotoGetter();
  const [loading, setLoading] = useState(false);
  let { ssoOwner, owner, setOwner, photoUrl } = useApplication();

  const changePhoto = async () => {
    const photo = await getPhoto();

    setLoading(true);

    uploadProfilePicture(ssoOwner.id, photo)
      .then(photo_url => {
        setOwner({ ...owner, photo_url });
        onNext();
      })
      .catch(error => {
        Dialog.alert({
          title: 'Error',
          message: 'It looks like something went wrong. Please try again later or contact support.',
        });

        throw error;
      })
      .finally(() => setLoading(false));
  };

  return (
    <IonSlide>
      <Layout
        progress={progress}
        onPrev={onPrev}
        content={
          <>
            <IonLoading isOpen={loading} message="Uploading..." />

            <DescriptionLabel style={{ margin: '2.5rem 0' }}>Add your profile photo</DescriptionLabel>

            <AddPhotoContainer onClick={changePhoto} style={{ backgroundImage: `url(${photoUrl || HeadshotImgUrl})` }}>
              <AddPhotoIcon />
            </AddPhotoContainer>

            <Label
              block
              center
              isWrapped
              size="xsplus"
              fontStyle="italic"
              color="gray-light"
              style={{ marginTop: '2rem' }}
            >
              Don’t worry you can always change this later
            </Label>
          </>
        }
        footer={
          <ActionButtons onSkip={onNext} onSubmit={changePhoto}>
            <IonIcon slot="start" icon={cameraOutline} /> Add your Photo
          </ActionButtons>
        }
      />
    </IonSlide>
  );
};

const AddPhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 187px;
  height: 187px;
  margin: 6rem auto 0 auto;

  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 9999px;
`;

export default UpdatePhoto;
