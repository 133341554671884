import React, { useEffect, useState } from 'react'
import { IonLoading } from '@ionic/react'
import { Redirect, useParams } from 'react-router-dom'

import { loginWithToken } from 'api/sso'
import { markAsClaimed } from 'api/loanzify'

import { useAuth } from 'store/Auth'
import { useSession } from 'store/Session'
import { useApplication } from 'store/Application'

import ExpiredMagicLinkPage from './ExpiredMagicLink'

interface Params {
  token: string
  email: string
  broker: 'users' | 'consumers'
}

const LoginUsingTokenPage: React.FC = () => {
  const { token, email, broker } = useParams<Params>()

  const { token: authToken } = useSession()
  const { login, user } = useAuth()
  const { application } = useApplication()

  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (authToken) {
      return
    }

    loginWithToken(token, email, broker)
      .then(authenticatable => {
        login(authenticatable).then(() => {
          markAsClaimed(application.id)
          setSuccess(true)
          setLoading(false)
        })
      })
      .catch(() => setLoading(false))
  }, [])

  if (user) {
    return <Redirect to="/auth/select-role" />
  }

  if (loading) {
    return <IonLoading isOpen={true} message="Loading..." />
  }

  if (success) {
    return <Redirect to="/auth/select-role" />
  }

  return <ExpiredMagicLinkPage />
}

export default LoginUsingTokenPage
