import React from 'react'
import { IonPage, IonContent } from '@ionic/react'
import Header from 'components/Header'
import glossaryData from './data/glossary.data'
import AccordionItem from 'components/AccordionItem'
import List from 'components/List'

const GlossaryPage = () => {
  return (
    <IonPage>
      <Header title="Glossary" back defaultHref="/content" />

      <IonContent>
        <List>
          {glossaryData.map(({ name, content }, index) => (
            <AccordionItem key={`glossary-${index}`} title={name} toggleOnContentClick>
              {content}
            </AccordionItem>
          ))}
        </List>
      </IonContent>
    </IonPage>
  )
}

export default GlossaryPage
