import React from 'react'
import styled from 'styled-components'
import { IonCol, IonGrid, IonRow } from '@ionic/react'

import { ReactComponent as PlusIcon } from 'legacy-icons/plus-partners.svg'
import { BRANDING_DUAL_ME_PARTNER, BRANDING_DUAL_PARTNER_ME, BRANDING_PARTNER_ONLY } from '../../../utils/general'

const StyledIonGrid = styled(IonGrid)`
  margin: 0;
  display: flex;
  width: 100%;
`

const StyledIonRow = styled(IonRow)`
  padding: 0;
  position: relative;
  width: 100%;
`

const StyledIonCol = styled(IonCol)`
  align-self: flex-start;
`

const ProfileImageRounded = styled.div`
  height: 85px;
  width: 85px;
  border-radius: 50%;

  background: url('${props => props.url}') var(--color-gray-lighter);
  background-repeat: no-repeat;
  background-size: cover;

  border: 3px solid var(--color-gray-lighter);

  margin: 0 auto 1rem auto;
`

const ProfileWrapper = styled.div`
  text-align: center;
`

const ProfileName = styled.h5`
  margin: 0;
  color: var(--color-gray-light);
`

const ProfileTitle = styled.p`
  font-size: 0.75rem;
  font-family: var(--font-secondary);
  font-weight: bold;
  margin-top: 2px;
  margin-bottom: 0;
  color: var(--color-gray-light);
`

const ProfileLicense = styled.p`
  font-size: 0.75rem;
  font-family: var(--font-secondary);
  font-weight: light;
  margin-top: 2px;
  margin-bottom: 0;
  color: var(--color-gray-light);
`

const StyledPlusWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  svg {
    margin-top: 20px;
  }
`

// const ProfileCompanyLogo = styled.img`
//   width: 95%;
//   max-width: 135px;
//   margin-bottom: 20px;
// `

const PartnerHeader = ({ user1, user2, dual_branding }) => {
  const LoanOfficerWrapper = () => (
    <ProfileWrapper>
      {/*{user1.logo_url && <ProfileCompanyLogo src={user1.logo_url} />}*/}
      <ProfileImageRounded url={user1.photo_url || 'https://loanzify.com/public/images/avatar_default.jpg'} />
      <ProfileName>
        {user1.first_name} {user1.last_name}
      </ProfileName>
      <ProfileTitle>{user1.title}</ProfileTitle>
      <ProfileLicense>NMLS# {user1.nmls}</ProfileLicense>
    </ProfileWrapper>
  )

  const PartnerWrapper = () => (
    <ProfileWrapper>
      <ProfileImageRounded url={user2.photo_url || 'https://loanzify.com/public/images/avatar_default.jpg'} />
      <ProfileName>
        {user2.first_name} {user2.last_name}
      </ProfileName>
      <ProfileTitle>{user2.title}</ProfileTitle>
      {user2.nmls ? <ProfileLicense>License: {user2.nmls}</ProfileLicense> : <ProfileLicense>&nbsp;</ProfileLicense>}
    </ProfileWrapper>
  )

  return (
    <StyledIonGrid>
      {dual_branding === BRANDING_PARTNER_ONLY && (
        <StyledIonRow>
          <StyledIonCol size={12}>{PartnerWrapper()}</StyledIonCol>
        </StyledIonRow>
      )}

      {dual_branding === BRANDING_DUAL_PARTNER_ME && (
        <StyledIonRow>
          <StyledIonCol size={6}>{PartnerWrapper()}</StyledIonCol>
          <StyledPlusWrapper>
            <PlusIcon />
          </StyledPlusWrapper>
          <StyledIonCol size={6}>{LoanOfficerWrapper()}</StyledIonCol>
        </StyledIonRow>
      )}

      {dual_branding === BRANDING_DUAL_ME_PARTNER && (
        <StyledIonRow>
          <StyledIonCol size={6}>{LoanOfficerWrapper()}</StyledIonCol>
          <StyledPlusWrapper>
            <PlusIcon />
          </StyledPlusWrapper>
          <StyledIonCol size={6}>{PartnerWrapper()}</StyledIonCol>
        </StyledIonRow>
      )}
    </StyledIonGrid>
  )
}

export default PartnerHeader
