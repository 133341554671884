import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { IonBackdrop, IonProgressBar } from '@ionic/react'

import Card from './Card'
import Label from './Label'

interface Props {
  progress: number
  title: string
}

const ProgressBar: React.FC<Props> = ({ progress, title }) => {
  const app = useMemo(() => {
    return document.getElementsByTagName('ion-app')[0]
  }, [])

  return ReactDOM.createPortal(
    <>
      <IonBackdrop tappable={false} stopPropagation={false} />
      <Wrapper>
        <Card>
          <Label center>{title}</Label>
          <IonProgressBar style={{ marginTop: '16px' }} value={progress / 100} />
        </Card>
      </Wrapper>
    </>,
    app
  )
}

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;

  ${Card} {
    width: 80%;
  }
`

export default ProgressBar
