import React, { useState } from 'react'
import { IonLoading } from '@ionic/react'
import styled from 'styled-components'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { login as ssoLogin, saLogin } from 'api/sso'
import { useAuth } from 'store/Auth'
import { useSession } from 'store/Session'
import { useOnboarding } from 'store/Onboarding'
import { useMagicLinkSender } from 'hooks/useMagicLinkSender'

import Form from './components/Form'
import Layout from './components/Layout'
import Button from './components/Button'
import FormInput from './components/FormInput'
import ButtonWrapper from './components/ButtonWrapper'
import LoginWithMagicLinkSection from './components/LoginWithMagicLinkSection'
import { useApplication } from '../../store/Application'

const schema = yup.object().shape({
  password: yup.string(),
})

const StyledCreateButton = styled(Button)`
  margin-bottom: 1rem;
`

const LoginPage = ({ history }) => {
  const { login, setRole } = useAuth()
  const { setShowWelcomeScreen } = useSession()
  const { email, provider, reset } = useOnboarding()
  const [sendLink, isSendingMagicLink] = useMagicLinkSender({ email, broker: provider })
  const { hasEncompassIntegration } = useApplication()

  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, errors, setError, trigger, getValues } = useForm({
    defaultValues: {
      email,
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = ({ email, password }) => {
    const handleError = error => {
      setError('email', {
        type: 'manual',
        message: error.response?.data?.errors?.email?.[0] || error.response?.data?.message,
      })
      setError('password', {
        type: 'manual',
        message: error.response?.data?.errors?.password?.[0] || error.response?.data?.message,
      })
    }

    const handleSuccess = authenticatable => {
      setLoading(false)

      login(authenticatable)

      if (authenticatable.user.roles) {
        setRole(authenticatable.user.roles[0])
      }

      reset()

      history.push('/auth/select-role')
    }

    setLoading(true)
    const emailSplit = email.split('|')

    if (emailSplit.length === 2 && email.indexOf('lenderhomepage.com') > -1) {
      saLogin(emailSplit[0], password, emailSplit[1])
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          setLoading(false)
          setShowWelcomeScreen(false)
        })
    } else {
      ssoLogin(email, password)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          setLoading(false)
          setShowWelcomeScreen(false)
        })
    }
  }

  const handleClick = async () => {
    const result = await trigger()

    if (result) {
      onSubmit(getValues())
    }
  }

  const Footer = (
    <ButtonWrapper>
      <StyledCreateButton onClick={() => handleClick()}>Login</StyledCreateButton>

      <LoginWithMagicLinkSection isSending={isSendingMagicLink} onSend={sendLink} />
    </ButtonWrapper>
  )

  return (
    <Layout title="Welcome Back" footer={Footer} backUrl="/auth/email" showBranding={false}>
      <IonLoading isOpen={loading} />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          ref={register}
          label={hasEncompassIntegration() ? 'Username' : 'Email'}
          error={errors.email?.message}
          name="email"
          type="email"
          placeholder="Enter your email"
          required
        />

        <FormInput
          ref={register}
          label="Password"
          error={errors.password?.message}
          name="password"
          type="password"
          placeholder="Enter Your Password"
          required
        />
      </Form>
    </Layout>
  )
}

export default LoginPage
