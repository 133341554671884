import React, { useState } from 'react'
import styled from 'styled-components'

import { IonSegment, IonSegmentButton } from '@ionic/react'

import ExtraGraphPage from 'pages/calculators/extraPayment/Graph'
import ExtraOverviewPage from 'pages/calculators/extraPayment/Overview'

const StyledIonSegment = styled(IonSegment)`
  margin: 0 0 1.5rem 0;
  padding: 0 1rem;
`

const ResultContent = ({ result }) => {
  const [tab, setTab] = useState('graph')

  return (
    <div className="ion-margin-vertical">
      <StyledIonSegment mode="md" value={tab} onIonChange={e => setTab(e.detail.value)}>
        <IonSegmentButton value="graph">Graph</IonSegmentButton>
        <IonSegmentButton value="overview">Overview</IonSegmentButton>
      </StyledIonSegment>

      {tab === 'graph' && <ExtraGraphPage result={result} />}

      {tab === 'overview' && <ExtraOverviewPage result={result} />}
    </div>
  )
}

export default ResultContent
