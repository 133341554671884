import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { IonSelect, IonSelectOption } from '@ionic/react';

const sizes = {
  sm: {
    fontSize: '.75rem',
  },
  normal: {
    fontSize: '1rem',
  },
  lg: {
    fontSize: '1.25rem',
  },
};

interface InputProps {
  title: string;
  value: any;
  options: any;
  size: string;
  onChange: (value: any, event: any) => void;
}

const StyledIonSelect = styled(IonSelect)<any>`
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;

  color: #000;

  ::part(text),
  ::part(placeholder),
  ::part(icon) {
    font-size: ${props => getSize(props.size || 'normal')};
  }

  ::part(text) {
    margin-left: 3rem;
  }
`;

const getSize = (size: string) => {
  const [index] = Object.keys(sizes)
    .map((s, idx) => {
      if (s === size) {
        return idx;
      }
    })
    .filter(res => !!res);
  return Object.values(sizes)[index!].fontSize;
};

const Input = forwardRef(({ title, value, options, size = 'normal', onChange }: InputProps, ref: any) => (
  <StyledIonSelect
    mode="ios"
    interfaceOptions={{ header: title }}
    value={value}
    placeholder={title}
    size={size}
    onIonChange={(e: any) => onChange(e.detail.value, 'fromSelect')}
    ref={ref}
  >
    {options.map(({ value, title }: Record<string, string>) => (
      <IonSelectOption key={value} value={value}>
        {title}
      </IonSelectOption>
    ))}
  </StyledIonSelect>
));

Input.displayName = 'Input';

export default Input;
