import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useCalculator } from 'store/Calculator';
import MainOption from 'components/calculators/MainOption';
import styled from 'styled-components';
import { formatCurrency, formatPercent } from '../../../utils/formatters';
import Label from '../../../components/Label';
import OptionCard from 'components/calculators/OptionCard';
import { PurchaseCalculatorMainOptionsConfig } from 'types/Calculator';

const StyledOptionLabel = styled(Label)`
  padding: 1.5rem 0rem 1rem 1.5rem;
`;

interface MainOptionsProps {
  optionsConfig: PurchaseCalculatorMainOptionsConfig;
  loanType: string;
  isRefi?: boolean;
  checkLoanLimits: boolean;
  onResetError: (message: string, field: string) => void;
  onPersistChanges: (keys: any) => void;
}
const MainOptions: React.FC<MainOptionsProps> = ({
  optionsConfig,
  loanType,
  isRefi = false,
  checkLoanLimits = false,
  onResetError,
  onPersistChanges,
}) => {
  const { options, limits, defaultOptionsConfig, setOption, currentTypeDefaults } = useCalculator();
  const [tracking, setTracking] = useState<Record<string, number[]>>({});
  const [trackedChanges, setTrackedChanges] = useState(0);
  const [trackedKeys, setTrackedKeys] = useState<Record<string, number>>({});
  const [initValues] = useState({});
  const [trackInit, setTrackInit] = useState(true);
  const [focusValues] = useState({});

  const getOptionConfig = useCallback(
    (optionKey: keyof typeof defaultOptionsConfig) => ({
      ...defaultOptionsConfig[optionKey],
      ...optionsConfig[optionKey],
    }),
    [defaultOptionsConfig, optionsConfig]
  );

  useEffect(() => {
    setTracking({});
    setTrackedChanges(0);
    setTrackedKeys({});
    setTrackInit(true);
  }, [loanType]);

  useEffect(() => {
    if (trackedChanges > 3) {
      if (onPersistChanges) {
        onPersistChanges(
          Object.keys(trackedKeys).filter(
            key => ['property_price', 'down_payment_rate', 'loan_amount'].indexOf(key) > -1
          )
        );
      }
    }
  }, [trackedChanges]);

  const changeOptionsDebounced = useCallback(
    debounce(
      async event => {
        const initPropertyPrice = initValues['property_price'];
        const initLoanAmount = initValues['loan_amount'];
        const initDownPaymentRate = initValues['down_payment_rate'];

        const trackedPropertyPrice = tracking['property_price'] || [initPropertyPrice];
        const trackedLoanAmount = tracking['loan_amount'] || [initLoanAmount];
        const trackedDownPaymentRate = tracking['down_payment_rate'] || [initDownPaymentRate];

        const latestPropertyPrice = trackedPropertyPrice[trackedPropertyPrice.length - 1];
        const latestLoanAmount = trackedLoanAmount[trackedLoanAmount.length - 1];
        const latestDownPaymentRate = trackedDownPaymentRate[trackedDownPaymentRate.length - 1];
        const currentLoanAmount = latestPropertyPrice * ((100 - latestDownPaymentRate) / 100);
        const expectedLoanAmount = latestLoanAmount || currentLoanAmount;

        const minDownPaymentRate = parseFloat(currentTypeDefaults.min_down_payment);
        let shouldReset = false;

        if (isRefi) {
          if (expectedLoanAmount > latestPropertyPrice) {
            setOption('property_price', initPropertyPrice);
            setOption('loan_amount', expectedLoanAmount);
            onResetError('Loan amount exceeds property value');
          } else {
            initValues['property_price'] = latestPropertyPrice;
            initValues['loan_amount'] = expectedLoanAmount;
          }

          shouldReset = true;
        }

        if (minDownPaymentRate && minDownPaymentRate > latestDownPaymentRate) {
          onResetError(
            'Minimum down payment for this loan is: ' +
              formatPercent(minDownPaymentRate) +
              ' or ' +
              formatCurrency(latestPropertyPrice * (minDownPaymentRate / 100)),
            'down_payment_rate'
          );
          setOption('down_payment_rate', minDownPaymentRate);
          shouldReset = true;
        }

        if (shouldReset) {
          setTracking({});
        }

        if (event && event.target) {
          event.target.blur();
        }
      },
      750,
      true
    ),
    [tracking, options.county, options.type, options.state]
  );

  const validateLoanAmount = async (key: string, value: number) => {
    return new Promise<void>((resolve, reject) => {
      if (!checkLoanLimits) {
        resolve();
      } else if (checkLoanLimits && (loanType === 'conventional' || loanType === 'fha' || loanType === 'jumbo')) {
        const initDownPaymentRate = initValues['down_payment_rate'];
        const initPropertyPrice = initValues['property_price'];
        const trackedPropertyPrice =
          key === 'property_price' ? [value] : tracking['property_price'] || [initPropertyPrice];
        const trackedDownPaymentRate =
          key === 'down_payment_rate' ? [value] : tracking['down_payment_rate'] || [initDownPaymentRate];
        const latestDownPaymentRate = trackedDownPaymentRate[trackedDownPaymentRate.length - 1];
        const latestPropertyPrice = trackedPropertyPrice[trackedPropertyPrice.length - 1];
        const currentLoanAmount = latestPropertyPrice * ((100 - latestDownPaymentRate) / 100);

        const countyData = {
          name: options.county,
          limits: window.stateAndCounties.states[options.state]?.data[options.county.toUpperCase()] || null,
        };

        if (Array.isArray(countyData.limits)) {
          let minLoan = 50000;
          let maxLoan = 1000000;
          let resetMessage = null;
          switch (loanType) {
            case 'fha':
              maxLoan = countyData.limits[0];
              break;
            case 'conventional':
              maxLoan = countyData.limits[1];
              break;
            case 'jumbo':
              minLoan = countyData.limits[1];
              maxLoan = 2000000;
              break;
            default:
              break;
          }

          if (currentLoanAmount > parseFloat(maxLoan)) {
            resetMessage =
              'Your loan amount exceeds the limit of ' +
              maxLoan +
              ' for an ' +
              options.type.toUpperCase() +
              ' loan in ' +
              options.county +
              ' County, ' +
              options.state +
              '.  You will need to get a JUMBO loan';
          } else if (currentLoanAmount < parseFloat(minLoan)) {
            resetMessage =
              'Your loan amount is below the limit of ' +
              minLoan +
              ' for an ' +
              options.type.toUpperCase() +
              ' loan in ' +
              options.county +
              ' County, ' +
              options.state +
              '. You will need to get a CONVENTIONAL loan';
          }

          if (resetMessage && key === 'property_price') {
            // setOption('property_price', trackedPropertyPrice[0])
            onResetError(resetMessage, 'property_price');
          }
        }
      }

      resolve();
    });
  };

  const changeOptions = (key: string) => {
    if (loanType === 'extraPayment' && key === 'loan_amount') {
      if (limits.payment_considered.min > options.payment_considered) {
        setOption('payment_considered', limits.payment_considered.min);
      }
    }
  };
  const trackValue = (key: string, val: number, event: CustomEvent | string) => {
    setTrackInit(false);
    if (tracking[key] === undefined) {
      tracking[key] = [];
    }
    tracking[key].push(val);
    trackedKeys[key] = 1;
    setTrackedChanges(trackedChanges + 1);
    setOption(key, val);
    changeOptionsDebounced(event);
    changeOptions(key);
  };

  const renderOptions = Object.keys(optionsConfig).map(key => {
    const config = getOptionConfig(key as keyof typeof defaultOptionsConfig);

    if (config.label && config.label === true) {
      return (
        <StyledOptionLabel key={key} block color="primary">
          {config.title}
        </StyledOptionLabel>
      );
    }

    if (trackInit) {
      initValues[key] = options[key];
    }

    return (
      <MainOption
        key={key}
        value={options[key]}
        limit={limits[key]}
        options={options}
        config={config}
        onChange={async (value: number, event: CustomEvent) => {
          try {
            await validateLoanAmount(key, value);
            trackValue(key, value, event);
          } catch (err) {
            console.log(err);
            return false;
          }
        }}
        onFocus={value => (focusValues[key] = value)}
        loanType={loanType}
      />
    );
  });

  return <OptionCard>{renderOptions}</OptionCard>;
};

export default MainOptions;
