import React from 'react'

import Button from 'components/Button'
import FileSelect from 'pages/documents/components/FileSelect'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  margin-top: 2rem;
`

const StartUploadingButton = ({ uploaded, ...props }) => (
  <FileSelect {...props}>
    {open => (
      <StyledButton center onClick={open} color="blue">
        {uploaded ? 'Upload New File(s)' : 'Start Uploading'}
      </StyledButton>
    )}
  </FileSelect>
)

export default StartUploadingButton
