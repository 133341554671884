import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'

import { CALCULATORS_CONFIG } from 'config/calculators'
import { formatCurrency } from 'utils/formatters'
import { getChartColors } from 'utils/theme'

import Label from 'components/Label'

import ResultChart from './ResultChart'

const ChartContainer = styled.div`
  position: relative;
  height: 15rem;
`

const ChartTitleContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const { resultTitles } = CALCULATORS_CONFIG.affordability

const ResultContent = ({ result }) => {
  const [loaded, setLoaded] = useState(false)
  const chartColors = useMemo(getChartColors, [])

  useEffect(() => {
    /**
     * This is a workaround to render <Doughnut /> component after 100ms.
     * If we render this cmoponent as soon as the component is ready,
     * for some reason it does not play the  animation.
     */
    setTimeout(() => setLoaded(true), 100)
  }, [])

  return (
    <div className="ion-margin-vertical">
      <ChartContainer>
        <ChartTitleContainer>
          <Label block secondary color="gray">
            Home Value
          </Label>

          <Label block size="xl" color="gray">
            {formatCurrency(result.home_value, 0)}
          </Label>
        </ChartTitleContainer>

        {loaded && <ResultChart result={result} resultTitles={resultTitles} colors={chartColors} />}
      </ChartContainer>
    </div>
  )
}

export default ResultContent
