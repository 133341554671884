const uuid = require('fake-uuid')

/**
 * Generate a fake device UUID for local installation testing.
 * Date here is parsed by the `fake-uuid` library to conform to device uuid standards.
 * @constructor
 */
const FakeUuid = () => {
  const existing = localStorage.getItem('fake-uuid')
  if (!existing) {
    localStorage.setItem('fake-uuid', uuid(new Date()))
  }

  return localStorage.getItem('fake-uuid')
}

export default FakeUuid
