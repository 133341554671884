import * as React from 'react'
import styled from 'styled-components'

const ContentHeader: React.FC = ({ children, props }) => {
  return <StyledContentHeader {...props}>{children}</StyledContentHeader>
}

const StyledContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;
  padding: 0.5rem 1rem 1rem;

  border-bottom-left-radius: 50% 1.5rem;
  border-bottom-right-radius: 50% 1.5rem;
  border-bottom: 2px solid var(--color-gray-lightest);

  text-align: center;
`

export default ContentHeader
