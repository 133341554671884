import React, { ReactNode, useEffect, useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';

interface ColorPickerProps {
  render: (opener: () => void) => ReactNode;
  onChange: (color: string) => void;
  color: string;
  title: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color: initialColor, title, render, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState(initialColor);

  const save = () => {
    onChange(color);
    setIsOpen(false);
  };

  const [presentingElement, setPresentingElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPresentingElement(document.querySelector('ion-router-outlet') as HTMLElement);
  }, []);

  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        swipeToClose={true}
        presentingElement={presentingElement!}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
            </IonButtons>
            <IonButtons slot="secondary">
              <IonButton onClick={save}>Save</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <StyledSketchPicker color={color} onChange={({ hex }) => setColor(hex)} />
        </IonContent>
      </IonModal>

      {render(() => setIsOpen(true))}
    </>
  );
};

const StyledSketchPicker = styled(SketchPicker)`
  margin: 1rem auto;
  width: 80% !important;
`;

export default ColorPicker;
