import { ReactComponent as MessagesIcon } from 'legacy-icons/messages.svg'
import { ReactComponent as GlossaryIcon } from 'legacy-icons/glossary.svg'
import { ReactComponent as LoanIcon } from 'legacy-icons/loan.svg'
import { ReactComponent as HomeIcon } from 'legacy-icons/home.svg'
import { ReactComponent as PhoneIcon } from 'legacy-icons/phone.svg'
import { ReactComponent as ToolsIcon } from 'legacy-icons/tools.svg'
import { ReactComponent as LogoutIcon } from 'legacy-icons/logout.svg'
import { ReactComponent as DocumentsIcon } from 'legacy-icons/documents.svg'
import { ReactComponent as CalculatorIcon } from 'legacy-icons/calculator.svg'
import { ReactComponent as ShareIcon } from 'legacy-icons/share.svg'
import { ReactComponent as HomePurchaseIcon } from 'legacy-icons/home-purchase.svg'

import dashboardContactMeIcon from 'legacy-icons/dashboard/contact-me.png'
import dashboardDocumentsIcon from 'legacy-icons/dashboard/documents.png'
import dashboardCalculatorIcon from 'legacy-icons/dashboard/calculator.png'
import dashboardApplicationIcon from 'legacy-icons/dashboard/application.png'
import dashboardMortgageLibraryIcon from 'legacy-icons/dashboard/mortgage-library.png'
import dashboardHomeSearch1Icon from 'legacy-icons/dashboard/home-search-1.png'
import dashboardHomeSearch2Icon from 'legacy-icons/dashboard/home-search-2.png'
import dashboardHomeSearch3Icon from 'legacy-icons/dashboard/home-search-3.png'
import dashboardContactCardIcon from 'legacy-icons/dashboard/contact-card.png'
import dashboardCrmIcon from 'legacy-icons/dashboard/crm.png'
import dashboardEmailIcon from 'legacy-icons/dashboard/email.png'
import dashboardMessagesIcon from 'legacy-icons/dashboard/messages.png'
import dashboardRatesIcon from 'legacy-icons/dashboard/rates.png'

export const getIcon = name => {
  switch (name) {
    case 'dashboard-contact':
      return dashboardContactMeIcon
    case 'dashboard-documents':
      return dashboardDocumentsIcon
    case 'dashboard-calculator':
    case 'dashboard-calculators':
      return dashboardCalculatorIcon
    case 'dashboard-apply':
      return dashboardApplicationIcon
    case 'dashboard-learning':
    case 'dashboard-guide':
      return dashboardMortgageLibraryIcon
    case 'dashboard-home-search-1':
      return dashboardHomeSearch1Icon
    case 'dashboard-home-search-2':
      return dashboardHomeSearch2Icon
    case 'dashboard-home-search-3':
      return dashboardHomeSearch3Icon
    case 'dashboard-contact-card':
      return dashboardContactCardIcon
    case 'dashboard-crm':
      return dashboardCrmIcon
    case 'dashboard-email':
      return dashboardEmailIcon
    case 'dashboard-chat':
      return dashboardMessagesIcon
    case 'dashboard-rates':
      return dashboardRatesIcon

    case 'menu-calculator':
    case 'menu-calculators':
      return CalculatorIcon
    case 'menu-home':
      return HomeIcon
    case 'menu-documents':
      return DocumentsIcon
    case 'menu-contact':
      return PhoneIcon
    case 'menu-guide':
      return GlossaryIcon
    case 'menu-logout':
      return LogoutIcon
    case 'menu-about':
      return PhoneIcon
    case 'menu-share':
      return ShareIcon
    case 'menu-chat':
      return MessagesIcon
    case 'menu-my-loan':
      return LoanIcon
    case 'menu-home-purchase':
      return HomePurchaseIcon
    default:
      return ToolsIcon
  }
}
