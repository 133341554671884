import React, { useMemo } from 'react'
import { IonPage } from '@ionic/react'

import { CALCULATORS_CONFIG } from 'config/calculators'
import { useCalculator } from 'store/Calculator'
import Header from 'components/Header'

import ResultAmortisation from './components/ResultAmortisation'

const RefiAmortisationPage = () => {
  const { options, result } = useCalculator()
  const amortisation = useMemo(
    () =>
      window.lhpCalculator.amortisation({
        propertyPrice: options.property_price,
        loanAmount: options.loan_amount,
        interestRate: options.interest_rate,
        monthlyPayment: result.total,
        monthlyHoaFees: result.breakdown.taxes_and_hoa,
        monthlyTaxesDollar: 0,
        monthlyHazardInsurance: result.breakdown.monthly_insurance,
        monthlyMortgageInsurance: result.breakdown.insurance,
      }),
    [options, result]
  )

  return (
    <IonPage>
      <Header defaultHref="/calculators/refi/result" title={CALCULATORS_CONFIG.refi.title} />

      <ResultAmortisation options={options} amortisation={amortisation} />
    </IonPage>
  )
}

export default RefiAmortisationPage
