import React from 'react'
import styled from 'styled-components'
import Label from '../Label'
import { IonModal } from '@ionic/react'
import { ReactComponent as CloseIcon } from 'legacy-icons/pre-approval/close.svg'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${props => (props.noHeaderBorder ? 'none' : '1px solid var(--color-gray-lightest)')};
  padding-bottom: 0.25rem;
  margin-bottom: 0.5rem;
  min-height: 45px;
`

const StyledLabel = styled(Label)`
  margin-left: 10px;
`

const StyledModal = styled(IonModal)`
  --background: #fff;

  .ion-page {
    padding: 1rem;
    justify-content: start;
  }
`

const StyledContent = styled.div`
  overflow-y: scroll;
  max-height: 100%;
`

const LoanCardModal = ({ isOpen, onCloseClick, children, title = 'Pre-Approval Letter' }) => {
  return (
    <>
      <StyledModal isOpen={isOpen} showBackdrop={false} onDidDismiss={() => isOpen && onCloseClick()}>
        <Header>
          <StyledLabel color="primary-light" size="sm">
            {title}
          </StyledLabel>
          <CloseIcon onClick={() => onCloseClick()} />
        </Header>
        <StyledContent>{children}</StyledContent>
      </StyledModal>
    </>
  )
}

export default LoanCardModal
