import React from 'react'

import { useAuth } from 'store/Auth'
import { useApplication } from 'store/Application'

import SsoDocumentsPage from './SSO/Index'
import PosDocumentsPage from './POS/Index'
import LocalDocumentsPage from './Local/Index'
import { Redirect } from 'react-router-dom'

const DocumentsPage = (props: any) => {
  const { posUser, isUser } = useAuth()
  const { documentsEnabled, isPreview } = useApplication()

  if (!documentsEnabled) {
    return <Redirect to={{ pathname: '/' }} />
  }

  if (isPreview || isUser) {
    return <LocalDocumentsPage {...props} />
  }

  if (posUser) {
    return <PosDocumentsPage {...props} />
  }

  return <SsoDocumentsPage {...props} />
}

export default DocumentsPage
