import React from 'react'
import styled from 'styled-components'

import BaseButton from 'components/Button'
import { IonSpinner } from '@ionic/react'

const StyledButton = styled(BaseButton)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const AuthButton = props => {
  const { children, loading } = props
  const propsWithoutChildren = props

  Reflect.deleteProperty(propsWithoutChildren, 'children')

  return (
    <StyledButton {...propsWithoutChildren} color="secondary" type="submit" expand="block" size="lg">
      {loading ? <IonSpinner></IonSpinner> : <>{children}</>}
    </StyledButton>
  )
}

export default AuthButton
