import React from 'react'
import { IonRouterOutlet } from '@ionic/react'

import PrivateRoute from 'layouts/auth/PrivateRoute'

import HomePage from 'pages/home/Index'
import AboutPage from 'pages/about/Index'
import ProfilePage from 'pages/profile/Index'
import PushNotificationsPage from 'pages/home/PushNotifications'
import SwitchView from 'pages/home/SwitchView'

import SupportPage from 'pages/home/Support'
import SettingsPage from 'pages/home/Settings'
import SettingsLogoPage from 'pages/home/SettingsLogo'
import SettingsPhotoPage from 'pages/home/SettingsPhoto'

const HomeRoutes = () => (
  <IonRouterOutlet>
    <PrivateRoute exact path="/home" component={HomePage} />
    <PrivateRoute exact path="/home/profile" component={ProfilePage} />
    <PrivateRoute exact path="/home/profile/partner" component={ProfilePage} />
    <PrivateRoute exact path="/home/profile/loan-officer" component={ProfilePage} />
    <PrivateRoute exact path="/home/settings" component={SettingsPage} />
    <PrivateRoute exact path="/home/settings/logo" component={SettingsLogoPage} />
    <PrivateRoute exact path="/home/settings/photo" component={SettingsPhotoPage} />
    <PrivateRoute exact path="/home/switch-view" component={SwitchView} />
    <PrivateRoute exact path="/home/about" component={AboutPage} />
    <PrivateRoute exact path="/home/push-notifications" component={PushNotificationsPage} />
    <PrivateRoute exact path="/home/support" component={SupportPage} />
  </IonRouterOutlet>
)

export default HomeRoutes
