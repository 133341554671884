import Bugsnag from '@bugsnag/js'

import retryCatch from 'utils/retryCatch'
import { getFullApplicationData } from 'api/loanzify'
import { InitialApplicationState } from 'types/Application'
import { PageError } from 'components/ErrorPage'

const urlSearch = new URLSearchParams(window.location.search)

interface ApplicationloaderState {
  isPreview: boolean
  isConfiguringSteps: boolean
  isMenuPreview: boolean
  menuPreview: string
  menuPreviewType: String
}

export const initialState: ApplicationloaderState = {
  isPreview: urlSearch.has('preview-mode'),
  isConfiguringSteps: urlSearch.has('configure-steps') && urlSearch.get('configure-steps') === 1,
  isMenuPreview: urlSearch.get('preview-view') === 'menu',
  menuPreview: urlSearch.get('preview-menu'),
  menuPreviewType: urlSearch.get('menu-type') || '',
}

const useApplicationLoader = (): ((id: number) => Promise<InitialApplicationState>) => {
  return id => {
    return getFullApplicationData(id)
      .catch(retryCatch(() => getFullApplicationData(id), 1000))
      .then(data => {
        return { ...data, ...initialState }
      })
      .catch((error: any) => {
        console.error(error)
        Bugsnag.notify(error)

        throw error
      })
      .catch((error: any) => {
        if (error instanceof PageError) {
          throw error
        }

        throw new PageError({
          message: error.message,
          context: { error },
        })
      })
  }
}

export default useApplicationLoader
