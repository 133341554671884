import React from 'react'
import { IonRouterOutlet } from '@ionic/react'

import PrivateRoute from 'layouts/auth/PrivateRoute'

import LoanPage from 'pages/loan/Index'
import LoanTeamPage from 'pages/loan/Team'

const LoanRoutes = () => (
  <IonRouterOutlet>
    <PrivateRoute exact path="/loans/current" component={LoanPage} />
    <PrivateRoute exact path="/loans/current/team" component={LoanTeamPage} />
  </IonRouterOutlet>
)

export default LoanRoutes
