import * as React from 'react'
import { useApplication } from '../store/Application'
import styled from 'styled-components'
import { ReactComponent as IconIconSettings } from 'icons/Icon-Settings.svg'
import { get } from 'lodash'

type StepManagerParentMessageEventDetailType = 'step.highlight'

type StepManagerActionType = 'start' | 'stop'

type StepManagerParentMessageEvent = {
  data: {
    detail: {
      action: StepManagerActionType
      type: StepManagerParentMessageEventDetailType
    }
  }
  id: string
} & MessageEvent

const StepManager: React.FC = props => {
  const { isConfiguringSteps } = useApplication()

  window.addEventListener('message', (e: StepManagerParentMessageEvent) => {
    const type = get(e, 'data.detail.type', null)

    switch (type) {
      case 'step.highlight':
        return toggleStepHighlight(e.data.detail.action, e.data.detail.id)
      default:
        return null
    }
  })

  const notifyLoanzifyOfStepConfigurationRequest = () => {
    window.parent.postMessage(
      {
        event: 'step.configure',
        id: props && props.children && props.children.props.id ? props.children.props.id : false,
        path: window.location.hash,
      },
      '*'
    )
  }

  const toggleStepHighlight = (action: StepManagerActionType, id: string) => {
    if (action === 'start') {
      const el = document.getElementById(id.replace('#', ''))

      if (el && el.nextElementSibling) {
        console.log(el.nextElementSibling)
        el.nextElementSibling.classList.toggle('step-highlight')
      }
    }
  }

  if (!isConfiguringSteps) {
    return <React.Fragment>{props.children}</React.Fragment>
  }

  return (
    <React.Fragment>
      {props.children}
      <ConfigureStep {...props} onClick={notifyLoanzifyOfStepConfigurationRequest} />
    </React.Fragment>
  )
}

const ConfigureStep: React.FC = props => {
  return (
    <ConfigureStepWrapper onClick={props.onClick}>
      <IconHelperWrapper>
        <IconIconSettings width={22} height={22} />
      </IconHelperWrapper>
    </ConfigureStepWrapper>
  )
}

const ConfigureStepWrapper = styled.div`
  position: absolute;
  z-index: 9999999;
  top: 0;
  right: 0;
  cursor: pointer;
`

const IconHelperWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`

export default StepManager
