const items = {
  milestones_count: 10,
  milestones: [
    // {
    //     "loan_milestone_id": 16496,
    //     "id": 72,
    //     "sequence": 1,
    //     "title": "Account Created",
    //     "title_completed": "Account Created",
    //     "link": null,
    //     "img": "https://pos.lhpstaging.com/images/borrower-timeline/",
    //     "show": false,
    //     "next_steps": [
    //         {
    //             "title": "Account Created",
    //             "title_completed": "Account Created",
    //             "explanation_1": "",
    //             "explanation_2": "",
    //             "link": null
    //         }
    //     ],
    //     "completed": {
    //         "date": "2020-03-03 22:03:54.000000",
    //         "timezone_type": 3,
    //         "timezone": "UTC"
    //     }
    // },
    {
      loan_milestone_id: 16497,
      id: 73,
      sequence: 2,
      title: 'Loan Application',
      title_completed: 'Application Completed',
      link: null,
      img: 'https://pos.lhpstaging.com/images/borrower-timeline/sequence-2.png',
      show: true,
      next_steps: [
        {
          title: 'Loan Application',
          title_completed: 'Application Completed',
          explanation_1:
            'During the application intake, we\u2019ll build your borrower profile with basic info like your name, address and social security number. <br /><br />We\u2019ll also collect details about your assets, income and other real estate owned. Call or message us with any questions. We\u2019re here to help.',
          explanation_2: '',
          link: '/application',
        },
      ],
      completed: null,
    },
    {
      loan_milestone_id: 16498,
      id: 74,
      sequence: 3,
      title: 'Upload Documents',
      title_completed: 'Documents Uploaded',
      link: null,
      img: 'https://pos.lhpstaging.com/images/borrower-timeline/sequence-3.png',
      show: false,
      next_steps: [
        {
          title: 'Upload Documents',
          title_completed: 'Documents Uploaded',
          explanation_1:
            'You\u2019re now ready to upload your docs to verify the details you shared in the previous step.<br /><br />Drag and drop your docs from your computer or snap a photo with your mobile phone. We\u2019ll let you know precisely which documents we need, and if there is anything missing, we\u2019ll notify you right away.',
          explanation_2: '',
          link: '/documents',
        },
      ],
      completed: null,
    },
    {
      loan_milestone_id: 16499,
      id: 75,
      sequence: 4,
      title: 'Pre-Approval',
      title_completed: null,
      link: null,
      img: 'https://pos.lhpstaging.com/images/borrower-timeline/sequence-4.png',
      show: false,
      next_steps: [
        {
          title: 'Pre-Approval',
          title_completed: 'Pre-Approval',
          explanation_1:
            'Time to relax and let us do all the heavy lifting. Now that we have your borrower profile and uploaded docs, we\u2019ll get to work on getting you pre-approved for a home loan.<br /><br />This process may take up to a few days so you won\u2019t see much action, but rest assured we\u2019re on it! If we need any additional info, we\u2019ll contact you.',
          explanation_2: '',
          link: null,
        },
      ],
      completed: null,
    },
    {
      loan_milestone_id: 16500,
      id: 76,
      sequence: 5,
      title: 'Escrow Opened',
      title_completed: null,
      link: null,
      img: 'https://pos.lhpstaging.com/images/borrower-timeline/sequence-5.png',
      show: false,
      next_steps: [
        {
          title: 'Escrow Opened',
          title_completed: 'Escrow Opened',
          explanation_1:
            'Congratulations! You found the perfect home, made an offer, and the seller has accepted. In this phase, you\u2019ll make a good-faith deposit into an escrow account which will \u201chold\u201d your offer.<br /><br />Then, your future home will be appraised and inspected to make sure the asking price is reasonable. Once all is cleared, you\u2019ll move on to the next phase and your good-faith deposit will eventually be added to your down payment.',
          explanation_2: '',
          link: null,
        },
      ],
      completed: null,
    },
    {
      loan_milestone_id: 16501,
      id: 77,
      sequence: 6,
      title: 'Processing',
      title_completed: null,
      link: null,
      img: 'https://pos.lhpstaging.com/images/borrower-timeline/sequence-6.png',
      show: false,
      next_steps: [
        {
          title: 'Processing',
          title_completed: 'Processing',
          explanation_1:
            'You\u2019re so close to getting your loan! In this phase, we\u2019ll gather your entire home loan package, including your borrower profile, documents, verifications, credit report, appraisal, inspection, and title report and make sure everything is in order.<br /><br />If there\u2019s any additional information we need from you, we\u2019ll notify you immediately. Once your package is complete, we\u2019ll send it over to the Underwriter for funding approval.',
          explanation_2: '',
          link: null,
        },
      ],
      completed: null,
    },
    {
      loan_milestone_id: 16502,
      id: 78,
      sequence: 7,
      title: 'Underwriting',
      title_completed: null,
      link: null,
      img: 'https://pos.lhpstaging.com/images/borrower-timeline/sequence-7.png',
      show: false,
      next_steps: [
        {
          title: 'Underwriting',
          title_completed: 'Underwriting',
          explanation_1:
            'We\u2019re in the home stretch! The underwriter will receive your home loan package that we carefully prepared and review it to see if the loan is acceptable.<br /><br />If the Underwriter asks for additional documentation, we\u2019ll notify you right away. Other than that, all you have to do is wait for your \u201capproval\u201d status. Of course, you\u2019ll receive a notification as soon as that happens.',
          explanation_2: '',
          link: null,
        },
      ],
      completed: null,
    },
    {
      loan_milestone_id: 16503,
      id: 79,
      sequence: 8,
      title: 'Conditions',
      title_completed: null,
      link: null,
      img: 'https://pos.lhpstaging.com/images/borrower-timeline/sequence-8.png',
      show: false,
      next_steps: [
        {
          title: 'Conditions',
          title_completed: 'Conditions',
          explanation_1:
            'You\u2019ve been approved and you\u2019re almost ready to take the keys to your new home! We just need to tie up a few loose ends first.<br /><br />In this \u201cconditions\u201d phase, we\u2019ll break down all terms of your home loan including your estimated monthly payments and final closing costs. You\u2019ll have a few days to look over the document and ask us any questions. If it all looks good, we move on to the final phase --closing!',
          explanation_2: '',
          link: null,
        },
      ],
      completed: null,
    },
    {
      loan_milestone_id: 16504,
      id: 80,
      sequence: 9,
      title: 'Clear to Close',
      title_completed: null,
      link: null,
      img: 'https://pos.lhpstaging.com/images/borrower-timeline/sequence-9.png',
      show: false,
      next_steps: [
        {
          title: 'Clear to Close',
          title_completed: 'Clear to Close',
          explanation_1:
            'You\u2019re now ready to close the loan! In this final phase, you\u2019ll bring a cashier\u2019s check for your down payment, another for your closing costs, proof of insurance, and your ID.<br /><br />You\u2019ll have one last opportunity to review the terms and verify that all the info is correct. All that\u2019s left is for you to sign. Congratulations --you are now a homeowner!',
          explanation_2: '',
          link: null,
        },
      ],
      completed: null,
    },
    {
      loan_milestone_id: 16505,
      id: 81,
      sequence: 10,
      title: 'Funding',
      title_completed: null,
      link: null,
      img: 'https://pos.lhpstaging.com/images/borrower-timeline/sequence-10.png',
      show: false,
      next_steps: [
        {
          title: 'Funding',
          title_completed: 'Funding',
          explanation_1: '',
          explanation_2: '',
          link: null,
        },
      ],
      completed: null,
    },
  ],
  current_milestone: {
    loan_milestone_id: 16498,
    id: 74,
    sequence: 3,
    title: 'Upload Documents',
    title_completed: 'Documents Uploaded',
    link: null,
    img: 'https://pos.lhpstaging.com/images/borrower-timeline/sequence-3.png',
    show: true,
    next_steps: [
      {
        title: 'Upload Documents',
        title_completed: 'Documents Uploaded',
        explanation_1:
          'You\u2019re now ready to upload your docs to verify the details you shared in the previous step.<br /><br />Drag and drop your docs from your computer or snap a photo with your mobile phone. We\u2019ll let you know precisely which documents we need, and if there is anything missing, we\u2019ll notify you right away.',
        explanation_2: '',
        link: '/documents',
      },
    ],
    completed: null,
  },
}

export default items
