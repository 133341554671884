import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonSegment, IonSegmentButton } from '@ionic/react'

import Header from 'components/Header'
import LeadsTabRoutes from 'routes/LeadsTabRoutes'
import { useAuth } from 'store/Auth'
import { useConsumers } from '../../store/Consumers'

const Index = () => {
  const history = useHistory()
  const { fetchConsumers } = useConsumers()
  const { path } = useRouteMatch()
  const { isPartner } = useAuth()

  const tabValues = ['consumers', 'partners']
  const tabOnPageLoad = history.location.pathname.split('/').pop()

  const [tab, setTab] = useState(tabOnPageLoad && tabValues.includes(tabOnPageLoad) ? tabOnPageLoad : 'consumers')

  useEffect(() => {
    history.replace(`/leads/tabs/${tab}`)
  }, [tab])

  /**
   * @Author - Garren Sweet
   * @Date - 02/09/2022
   *
   * Cheap hack with a slight performance cost due to the fact that it is executed each render.
   * However, this tradeoff is fine for now until a more reasonable solution can be implemented
   * with less time constraint.
   *
   */
  useEffect(() => {
    let newTab = history.location.pathname.split('/').pop()

    if (newTab !== tab && history.location.pathname.indexOf('/leads/tabs') > -1) {
      setTab(newTab)
    }
  })

  const doRefresh = (event: CustomEvent) => {
    if (tab === 'consumers') {
      fetchConsumers({ append: false, page: 1, perPage: 20 }).then(() => {
        event.detail.complete()
      })
    }
  }

  return (
    <IonPage>
      <Header title="Users" />

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {!isPartner && (
          <StyledIonSegment mode="md" value={tab} onIonChange={e => setTab(e.detail.value)}>
            <IonSegmentButton value="consumers">Borrowers</IonSegmentButton>
            <IonSegmentButton value="partners">Partners</IonSegmentButton>
          </StyledIonSegment>
        )}

        <LeadsTabRoutes path={path} />
      </IonContent>
    </IonPage>
  )
}

const StyledIonSegment = styled(IonSegment)`
  margin: 1rem 0;
  padding: 0 1rem;
  margin-bottom: 0;

  ion-segment-button {
    font-family: var(--font-primary);
  }
`

export default Index
