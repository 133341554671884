import * as React from 'react'
import styled from 'styled-components'

interface Props {
  backgroundColor?: string
  size?: number
  transparent?: boolean
}

const AvatarSkeleton: React.FC<Props> = ({
  backgroundColor = 'var(--color-primary)',
  children,
  size = 33,
  transparent = false,
}) => {
  return (
    <AvatarSkeletonWrapper backgroundColor={backgroundColor} size={size} transparent={transparent}>
      {children}
    </AvatarSkeletonWrapper>
  )
}

const AvatarSkeletonWrapper = styled.div`
  position: relative;
  width: ${(props: Props) => props.size + 'px'};
  height: ${(props: Props) => props.size + 'px'};
  background-color: ${(props: Props) => (props.transparent ? 'transparent' : props.backgroundColor)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`

export default AvatarSkeleton
