import React, { useRef } from 'react'
import styled from 'styled-components'

import Label from './Label'
import { ReactComponent as CloseIcon } from 'legacy-icons/close.svg'
import { IonContent } from '@ionic/react'

export interface Props extends React.ComponentProps<typeof IonContent> {
  title?: string
  dismiss?: () => void
  dismissible?: boolean
  theme?: ModalContentTheme
}

interface ModalContentTheme {
  bordered: boolean
}

const ModalContent: React.FC<Props> = ({ theme, title, dismiss, dismissible = true, children, ...props }) => {
  const contentEl = useRef<HTMLIonContentElement>(null)

  const closeModal = () => {
    if (dismiss) {
      return dismiss()
    }

    contentEl.current?.closest<HTMLIonModalElement>('ion-modal.loanzify-modal')?.dismiss()
  }

  return (
    <React.Fragment>
      <ModalHeader theme={theme}>
        {title ? (
          <Header>
            <Label size="xs" isWrapped>
              {title?.toUpperCase()}
            </Label>
          </Header>
        ) : (
          <span></span>
        )}
        {dismissible && <StyledCloseIcon className="close-icon" onClick={closeModal} />}
      </ModalHeader>

      {props && props.autoHeight ? (
        <div className="inner-content" ref={contentEl}>
          {children}
        </div>
      ) : (
        <IonContent ref={contentEl} {...props}>
          {children}
        </IonContent>
      )}
    </React.Fragment>
  )
}

const ModalHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  margin-top: 20px;
  border-bottom: ${props => (props.theme && props.theme.bordered ? '1px solid var(--color-gray)' : 'none')};
  margin-bottom: ${props => (props.theme && props.theme.bordered ? '1.25rem' : '0')};
`

const StyledCloseIcon = styled(CloseIcon)`
  display: flex;
  align-self: center;
`

const Header = styled.div`
  position: relative;
  text-align: center;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ModalContent
