import * as React from 'react'
import DateTimePickerModal from '../../../components/DateTimePickerModal'
import styled from 'styled-components/macro'
import { useIonToast } from '@ionic/react'
import MilestoneUpdateConfirmationModal from './MilestoneUpdateConfirmationModal'
import { useEffect, useState } from 'react'
import { Loan, LoanMilestone, MilestoneUpdatedResponse, TimelineItem } from '../../../types/Pos'
import useModal from '../../../hooks/useModal'
import InputWithAction from '../../../components/Forms/InputWithAction'
import CloseIcon from 'icons/Icon-Close.svg'
import MilestoneResetModal from './MilestoneResetModal'
import { format, parseISO } from 'date-fns'
import { DayValue } from 'react-modern-calendar-datepicker'

type MilestoneDateTimePickerProps = {
  loan: Loan
  milestone: LoanMilestone | TimelineItem
}

type DateTimePickerModalProps = {
  value: DayValue
  close: (reset?: boolean) => void
  setCurrentDate: (date: string | null) => void
}

type UpdateConfirmationModalProps = {
  close: (reset?: boolean) => void
  date: Date
  didUpdate: (data: MilestoneUpdatedResponse) => void
  loan: Loan
  milestone: LoanMilestone
}

type ResetMilestoneProps = {
  close: (reset?: boolean) => void
  didReset: () => void
  loan: Loan
  milestone: LoanMilestone
}

const MilestoneDateTimePicker: React.FC<MilestoneDateTimePickerProps> = ({
  loan,
  milestone,
  originalValue,
  value,
  onChange,
  reload,
}) => {
  const [showToast] = useIonToast()
  const [currentDate, setCurrentDate] = useState<string | null>(null)
  const [editable, setEditable] = useState<boolean>(false)
  const [pickerDateValue, setPickerDateValue] = useState({})
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  const closeConfirmationModal = (reset: boolean = false) => {
    if (reset && originalValue) {
      setCurrentDate(originalValue)
    }

    hideConfirmationModal()
  }

  const closePickerModal = (reset: boolean = false) => {
    if (reset && originalValue) {
      setCurrentDate(originalValue)
    }

    hideDatePickerModal()
  }

  const closeResetModal = () => {
    hideDeleteMilestoneDateModal()
  }

  const actionProperties = () => {
    return {
      action: {
        click: showDeleteMilestoneDateModal,
        icon: CloseIcon,
        visible: !!currentDate,
        color: 'danger',
        size: 16,
      },
    }
  }

  const isSystemMilestone = () => {
    const systemMilestones = ['ACCOUNT', 'APPLICATION', 'DOCUMENTS']

    return (
      milestone && milestone.milestone && milestone.milestone.cta && systemMilestones.includes(milestone.milestone.cta)
    )
  }

  const didUpdate = (data: MilestoneUpdatedResponse) => {
    const { completed_at } = data

    console.log(reload)

    setCurrentDate(format(parseISO(completed_at), 'P'))

    showToast({
      message: 'Milestone updated!',
      color: 'success',
      duration: 3000,
    })

    hideConfirmationModal()

    onChange(format(parseISO(completed_at), 'P'))

    if (reload) {
      reload()
    }
  }

  const didReset = () => {
    setCurrentDate(null)

    showToast({
      message: 'Milestone reset!',
      color: 'success',
      duration: 3000,
    })
  }

  const [showDatePickerModal, hideDatePickerModal] = useModal(DateTimePickerModal, {
    value: pickerDateValue,
    close: closePickerModal,
    onChange: setSelectedDate,
  } as DateTimePickerModalProps)

  const [showConfirmationModal, hideConfirmationModal] = useModal(MilestoneUpdateConfirmationModal, {
    date: selectedDate,
    didUpdate,
    loan,
    milestone,
    close: closeConfirmationModal,
  } as UpdateConfirmationModalProps)

  const [showDeleteMilestoneDateModal, hideDeleteMilestoneDateModal] = useModal(MilestoneResetModal, {
    close: closeResetModal,
    didReset,
    loan,
    milestone,
  } as ResetMilestoneProps)

  useEffect(() => {
    if (milestone.completed_at) {
      setCurrentDate(format(parseISO(milestone.completed_at), 'P'))
    }

    if ((milestone.is_current || milestone.in_progress) && !milestone.completed_at) {
      setCurrentDate(format(parseISO(milestone.updated_at), 'P'))
    }

    setPickerDateValue({
      year: parseInt(format(new Date(value ? value : new Date()), 'yyyy')),
      month: parseInt(format(new Date(value ? value : new Date()), 'MM')),
      day: parseInt(format(new Date(value ? value : new Date()), 'dd')),
    })
  }, [setCurrentDate, milestone])

  useEffect(() => {
    if (selectedDate) {
      showConfirmationModal()
    }
  }, [selectedDate])

  useEffect(() => {
    setEditable(
      (!milestone.is_current || (milestone.is_current && milestone.completed_at)) &&
        !milestone.in_progress &&
        !isSystemMilestone()
    )
  }, [currentDate])

  return (
    <StyledInputWithAction
      value={currentDate}
      onClick={e => (editable ? showDatePickerModal() : e.preventDefault())}
      disabled={!editable}
      {...actionProperties()}
    />
  )
}

const StyledInputWithAction = styled(InputWithAction)`
  width: 132px;
  height: 32px;
`

export default MilestoneDateTimePicker
