import React, { useState } from 'react'
import { Toast } from '@capacitor/toast'

import Button from '../Button'
import FormInput from './form/Input'
import LoanCardModal from './LoanCardModal'

import { emailLoanPreApprovalLetter } from 'api/pos'

const EmailPreApprovalLetter = ({
  loan,
  letter,
  defaultEmail = '',
  defaultFilename = '',
  defaultBody = '',
  isOpen,
  onCloseClick,
  onSent = () => {},
}) => {
  const [email, setEmail] = useState(defaultEmail)
  const [body, setBody] = useState('')
  const [sending, setSending] = useState(false)

  const emailLetter = () => {
    setSending(true)
    emailLoanPreApprovalLetter(loan.id, {
      body,
      file_name: defaultFilename,
      preApprovalOption: letter,
      recipient_email: email,
    })
      .then(data => {
        onSent(data)
      })
      .catch(err => {
        Toast.show({ text: 'There was an error. Try again' })
      })
      .finally(() => setSending(false))
  }
  return (
    <LoanCardModal isOpen={isOpen} onCloseClick={() => onCloseClick()} title="Pre-Approval Letters">
      <FormInput
        required
        disabled={sending}
        onValChange={val => setEmail(val)}
        value={defaultEmail}
        label="Recipient Email"
        placeholder="Email"
      />
      <FormInput disabled={sending} onValChange={val => setBody(val)} label="Email Body" value={defaultBody} />
      <FormInput required disabled={true} value={defaultFilename} label="Attachment" placeholder="Attachment" />
      <Button disabled={sending} center block color="secondary" onClick={() => emailLetter()}>
        Send Email
      </Button>
    </LoanCardModal>
  )
}

export default EmailPreApprovalLetter
