import React from 'react'

import CalculatorResultPage from 'containers/CalculatorResultPage'

import ResultContent from './components/ResultContent'

const AffordabilityResultPage = () => {
  return (
    <CalculatorResultPage
      calculator="affordability"
      calculate={window.lhpCalculator.affordability}
      renderContent={result => <ResultContent result={result} />}
    />
  )
}

export default AffordabilityResultPage
