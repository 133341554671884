import React from 'react'
import { IonReactHashRouter } from '@ionic/react-router'
import { Redirect, Route, Switch } from 'react-router-dom'

import Providers from './Providers'
import TabbedLayout from './TabbedLayout'
import SideMenuLayout from './SideMenuLayout'

import Routes from 'routes/Index'
import AuthRoutes from 'routes/AuthRoutes'
import OnboardingRoutes from '../routes/OnboardingRoutes'

const Router = () => {
  return (
    <IonReactHashRouter>
      <Providers>
        <Switch>
          <Redirect exact from="/" to="/auth" />
          <Route path="/auth" component={AuthRoutes} />
          <Route path="/onboarding" component={OnboardingRoutes} />

          <SideMenuLayout>
            <TabbedLayout>
              <Routes />
            </TabbedLayout>
          </SideMenuLayout>
        </Switch>
      </Providers>
    </IonReactHashRouter>
  )
}

export default Router
