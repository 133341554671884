import React from 'react'
import styled from 'styled-components'
import { IonIcon, IonSpinner } from '@ionic/react'
import { colorWandOutline } from 'ionicons/icons'

import Button from 'components/Button'
import Label from 'components/Label'

interface Props {
  isSending: boolean
  onSend: () => void
}

const LoginWithMagicLinkSection: React.FC<Props> = ({ isSending, onSend }) => (
  <Container>
    <div className="separator">
      <span>or</span>
    </div>

    {isSending ? (
      <Button expand="block">
        <IonSpinner name="dots" />
      </Button>
    ) : (
      <Button expand="block" onClick={onSend}>
        <IonIcon slot="start" icon={colorWandOutline} />
        Send me a Magic Link
      </Button>
    )}

    <div className="explanation">
      <Label color="gray" center size="xs" weight={400} isWrapped fontStyle="italic" secondary>
        We can email you a magic link so you can sign in without having to type your password.
      </Label>
    </div>
  </Container>
)

const Container = styled.div`
  margin-top: 2rem;

  .separator {
    margin-bottom: 1rem;
    border-top: 1px solid var(--color-gray-lighter);
    text-align: center;
    display: flex;
    justify-content: center;

    span {
      margin-top: -10px;
      background: var(--color-background);
      padding: 0 0.5rem;
    }
  }

  .explanation {
    margin-top: 1rem;
    background: var(--color-gray-lightest);
    border-radius: 10px;
    padding: 1rem 0.5rem;
  }

  ion-button {
    margin-top: 1rem;
  }
`

export default LoginWithMagicLinkSection
