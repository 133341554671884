import React, { useState } from 'react'

import { AvatarProps, Avatar as DefaultAvatar, useChannelStateContext, useTranslationContext } from 'stream-chat-react'

import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from 'stream-chat-react/dist/types'
import { UserResponse } from 'stream-chat'

import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonPopover } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { chevronForward, ellipsisHorizontalOutline } from 'ionicons/icons'

export type ChannelHeaderProps = {
  /** UI component to display a user's avatar, defaults to and accepts same props as: [Avatar](https://github.com/GetStream/stream-chat-react/blob/master/src/components/Avatar/Avatar.tsx) */
  Avatar?: React.ComponentType<AvatarProps>
  /** Manually set the image to render, defaults to the Channel image */
  image?: string
  /** Show a little indicator that the Channel is live right now */
  live?: boolean
  /** Set title manually */
  title?: string
}

type ChannelMemberBorrower = {
  pos_role?: string
}

const UnMemoizedChannelHeader = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
  props: ChannelHeaderProps
) => {
  const history = useHistory()
  const { Avatar = DefaultAvatar, image: propImage, live, title } = props
  const [conversationMenuState, setShowConversationMenu] = useState<{ showConversationMenu: boolean; event: any }>({
    showConversationMenu: false,
    event: undefined,
  })

  const { channel, watcher_count } = useChannelStateContext<At, Ch, Co, Ev, Me, Re, Us>('ChannelHeader')

  const { t } = useTranslationContext('ChannelHeader')

  const { image: channelImage, member_count, name, subtitle } = channel?.data || {}

  const image = propImage || channelImage

  const {
    state: { members },
  } = channel
  const borrowers: Array<UserResponse<ChannelMemberBorrower>> = Object.keys(members).reduce((carry: any, mid) => {
    const { user }: UserResponse<ChannelMemberBorrower> = members[mid]

    if (user && user.pos_role && user.pos_role === 'borrower') {
      carry.push(user)
    }

    return carry
  }, [])

  const goToLoan = () => {
    history.push(`/leads/loans/${channel.id}`)
  }

  /**
   * I'm unsure if we need to support co-borrowers in our chat system or not.
   * In this case, we provide a graceful fallback. If not needed, we can refactor in PR.
   */
  const borrowerNameForTitle = borrowers ? Array.prototype.map.call(borrowers, i => i.name).join(', ') : title

  return (
    <div className="str-chat__header-livestream">
      {image && <Avatar image={image} shape="rounded" size={channel?.type === 'commerce' ? 60 : 40} />}
      <div className="str-chat__header-livestream-left">
        <p className="str-chat__header-livestream-left--title">
          {borrowerNameForTitle || name}{' '}
          {live && <span className="str-chat__header-livestream-left--livelabel">{t('live')}</span>}
        </p>
        {subtitle && <p className="str-chat__header-livestream-left--subtitle">{subtitle}</p>}
        <p className="str-chat__header-livestream-left--members">
          {!live && !!member_count && member_count > 0 && (
            <>
              {t('{{ memberCount }} members', {
                memberCount: member_count,
              })}
              ,{' '}
            </>
          )}
          {t('{{ watcherCount }} online', { watcherCount: watcher_count })}
        </p>
      </div>
      <IonButton
        onClick={(e: any) => {
          e.persist()
          setShowConversationMenu({ showConversationMenu: true, event: e })
        }}
        iconOnly={true}
        fill="clear"
      >
        <IonIcon icon={ellipsisHorizontalOutline} color="medium" />
      </IonButton>
      <IonPopover
        isOpen={conversationMenuState.showConversationMenu}
        event={conversationMenuState.event}
        reference="event"
        onDidDismiss={() => setShowConversationMenu({ showConversationMenu: false, event: undefined })}
      >
        <IonList>
          <IonItem lines="none" onClick={goToLoan}>
            <IonLabel> View Loan </IonLabel>
            <IonIcon icon={chevronForward} slot="end" color="medium" />
          </IonItem>
        </IonList>
      </IonPopover>
    </div>
  )
}

/**
 * The ChannelHeader component renders some basic information about a Channel.
 */
export default React.memo(UnMemoizedChannelHeader) as typeof UnMemoizedChannelHeader
