import React from 'react';
import { IonContent } from '@ionic/react';

import { formatCurrency, formatPercent } from 'utils/formatters';
import ResultCard from '../../../../components/calculators/ResultCard';
import Button from '../../../../components/Button';
import { useCalculations } from '../../../../store/Calculations';
import { handleShare, retrieveDownPayment } from '../../../../utils/calculator';
import { CalculatorDefaultOptions, CalculatorOptions } from 'types/Calculator';

const money = (amount: number) => amount && formatCurrency(amount);

/**
 * THIS IS A HACK!
 * This method extracts the real down payment value from the given options.
 *
 * Computed options do not work correctly.
 * • If down payment changes, the down payment rate is not updated.
 * • If down payment rate changes, the down payment is not updated.
 *
 */

interface ResultDetails {
  options: CalculatorOptions | CalculatorDefaultOptions;
  result: any;
}
const ResultDetails = ({ options, result }: ResultDetails) => {
  const { runCalculation } = useCalculations();

  console.log(result);

  const resultsData: { title?: string; text?: string | 0 }[] = [
    { title: 'Property Price', text: formatCurrency(options.property_price) },
    { title: 'Down Payment', text: formatCurrency(retrieveDownPayment(options)) },
    {
      title: ['va', 'rural', 'fha'].indexOf(options.type) > -1 ? 'Loan Amount' : 'Total Loan Amount',
      text: formatCurrency(options.property_price - retrieveDownPayment(options)),
    },
    { title: 'Interest Rate', text: formatPercent(options.interest_rate, 3) },
    ...[options.type === 'fha' ? { title: 'FHA Upfront MIP', text: formatCurrency(result.fha_upfront_mip) } : {}],
    ...[
      options.type === 'rural' ? { title: 'USDA Guarantee fee', text: formatCurrency(result.rural_upfront_mip) } : {},
    ],
    ...[options.type === 'va' ? { title: 'VA Funding Fee', text: formatCurrency(result.va_fee) } : {}],
    ...[
      ['va', 'rural', 'fha'].indexOf(options.type) > -1
        ? {
            title: 'Total Loan Amount',
            text: formatCurrency(
              options.property_price -
                retrieveDownPayment(options) +
                (result.fha_upfront_mip | result.rural_upfront_mip | result.va_fee)
            ),
          }
        : {},
    ],
    { title: 'Principal & Interest', text: money(result?.breakdown?.principal_and_interest) },
    { title: 'Taxes & HOA', text: money(result?.breakdown?.taxes_and_hoa) },
    { title: 'Hazard Insurance', text: money(result?.breakdown?.monthly_insurance) },
    { title: 'Mortgage Insurance', text: money(result?.breakdown?.insurance) },
  ].filter(i => Object.keys(i).length > 0);

  return (
    <IonContent>
      <ResultCard data={resultsData} finalData={{ title: 'Monthly Payment', text: money(result?.total) }} />
      <Button center onClick={async () => await handleShare(runCalculation?.id || result?.id)}>
        Share This Calculation
      </Button>
    </IonContent>
  );
};
export default ResultDetails;
