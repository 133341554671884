import { Preferences } from '@capacitor/preferences'

/**
 * Migrate data from the Capacitor 2 Storage plugin.
 *
 * This action is non-destructive.
 * It will not remove old data and will only write new data if they key was not already set.
 * To remove the old data after being migrated, call removeOld().
 */
// Preferences.migrate()

export default class Storage {
  static async get<S>(key: string, defaultValue?: S): Promise<S | undefined> {
    const { value } = await Preferences.get({ key })

    if (value === null) {
      return defaultValue
    }

    return JSON.parse(value)
  }

  static set(key: string, value: any) {
    return Preferences.set({ key, value: JSON.stringify(value) })
  }

  static remove(key: string) {
    return Preferences.remove({ key })
  }

  static clear() {
    return Preferences.clear()
  }
}
