import React from 'react'
import { IonApp } from '@ionic/react'
import 'intro.js/introjs.css'

import Router from './Router'

const App = () => {
  return (
    <IonApp>
      <Router />
    </IonApp>
  )
}

export default App
