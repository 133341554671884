import React from 'react'

import Animation, { AnimationProps } from './Animation'
import checkmarkData from 'lotties/checkmark.json'

const CheckmarkAnimation: React.FC<AnimationProps> = ({ options = {}, ...props }) => (
  <Animation {...props} data={checkmarkData} options={{ ...options, loop: false }} />
)

export default CheckmarkAnimation
