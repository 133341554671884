import { useCallback } from 'react'
import { Camera } from '@capacitor/camera'

const usePhotoGetter = () => {
  const getPhoto = useCallback(async ({ name, cameraOptions } = {}) => {
    name = name || 'profile-picture'
    cameraOptions = cameraOptions || {}

    const photo = await Camera.getPhoto({
      quality: 100,
      height: 600,
      allowEditing: true,
      resultType: 'uri',
      webUseInput: true,
      correctOrientation: true,
      ...cameraOptions,
    })
    const response = await fetch(photo.webPath)
    const blob = await response.blob()

    return new File([blob], `${name}.${photo.format}`, { type: blob.type })
  }, [])

  return getPhoto
}

export default usePhotoGetter
