import React from 'react';

import ApplicationTaskCard from 'pages/loan/components/ApplicationTaskCard';
import CreditTaskCard from 'pages/loan/components/CreditTaskCard';
import DocumentsTaskCard from 'pages/loan/components/DocumentsTaskCard';
import TaskCardWithBrowser from 'pages/loan/components/TaskCardWithBrowser';
import { useAuth } from '../../store/Auth';
import NoTasksAnimation from '../../components/animations/NoTasksAnimation';
import styled from 'styled-components';
import Label from '../../components/Label';

interface Props {
  //
}

const PosTasksPage: React.FC<Props> = () => {
  const { loan } = useAuth();

  const TaskCardComponents = {
    application: ApplicationTaskCard,
    'credit-authorization': CreditTaskCard,
    'loan-documents': DocumentsTaskCard,
    'encompass-borrower-portal': TaskCardWithBrowser,
  };

  return (
    <div className="ion-padding">
      {loan && Object.keys(loan.tasks).length === 0 && (
        <>
          <NoTasksAnimation />
          <EmptyTaskWrapper>
            <Label size={'lg'} fontStyle={'italic'} weight={500} color={'gray-light'} align={'center'} block>
              {' '}
              You&apos;re Up To Date!
            </Label>
            <EmptyTaskDescriptionWrapper>
              <Label
                style={{ marginTop: '1.25rem' }}
                size={'lg'}
                fontStyle={'italic'}
                weight={300}
                color={'gray-light'}
                align={'center'}
                block
              >
                {' '}
                No tasks are left to be <br /> completed...
              </Label>
            </EmptyTaskDescriptionWrapper>
          </EmptyTaskWrapper>
        </>
      )}

      {loan &&
        loan.tasks.map(task => {
          const TaskComponent = TaskCardComponents[task.name];

          if (TaskComponent === undefined) {
            return null;
          }

          return <TaskComponent task={task as any} key={`task-${task.name}`} />;
        })}
    </div>
  );
};

const EmptyTaskWrapper = styled.div`
  margin-top: 2.5rem;
`;

const EmptyTaskDescriptionWrapper = styled.div``;

export default PosTasksPage;
