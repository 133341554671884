import React from 'react'
import styled from 'styled-components'
import { Redirect } from 'react-router'
import { IonPage, IonContent } from '@ionic/react'

import { useAuth } from 'store/Auth'
import { ReactComponent as MilestoneStartIcon } from 'legacy-icons/loans/Notification/Success.svg'

import Header from 'components/Header'
import MilestoneItem from './components/MilestoneItem'

const LoanPage: React.FC = () => {
  const { loan } = useAuth()

  if (!loan) {
    return <Redirect to="/home" />
  }

  return (
    <IonPage>
      <Header title="My Loan Status" />

      <IonContent>
        <MilestoneStart>
          <MilestoneStartIcon />
        </MilestoneStart>

        <TimelineContainer>
          <TimelineSeparator />

          <ItemsContainer>
            {loan.loan_milestones.map((loanMilestone, index) => (
              <MilestoneItem key={loanMilestone.id} loanMilestone={loanMilestone} index={index} />
            ))}
          </ItemsContainer>
        </TimelineContainer>
      </IonContent>
    </IonPage>
  )
}

const TimelineContainer = styled.div`
  position: relative;
  padding-bottom: 1rem;
`

const ItemsContainer = styled.div`
  padding-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`

const TimelineSeparator = styled.div`
  position: absolute;
  left: 50%;
  width: 2px;
  top: 0;
  bottom: 1rem;
  background-color: var(--color-gray-lighter);
`

const MilestoneStart = styled.div`
  padding-top: 25px;
  text-align: center;
`

export default LoanPage
