import React from 'react'

import PrivateRoute from 'layouts/auth/PrivateRoute'

import ConsumersPage from 'pages/leads/sections/Consumers'
import PipelinePage from 'pages/leads/pipeline/Index'
import PartnersPage from 'pages/leads/sections/Partners'
import InstallationsPage from 'pages/Installations/Index'

const LeadsTabRoutes = ({ path }) => (
  <>
    <PrivateRoute exact path={`${path}/partners`} component={PartnersPage} />
    <PrivateRoute exact path={`${path}/consumers`} component={ConsumersPage} />
    <PrivateRoute exact path={`${path}/prospects`} component={InstallationsPage} />

    <PrivateRoute exact path={`${path}/loans`} component={PipelinePage} />
    <PrivateRoute exact path={`${path}/installations`} component={InstallationsPage} />
  </>
)

export default LeadsTabRoutes
