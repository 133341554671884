import React from 'react'
import styled from 'styled-components'

import ApplicationLogo from 'components/ApplicationLogo'
import { ReactComponent as ChevronLeftIcon } from 'legacy-icons/chevron-left.svg'
import Stepper from 'components/Stepper'

interface Props {
  footer?: React.ReactNode
  content?: React.ReactNode
  onPrev?: () => Promise<void>
  progress?: number
  max?: number
}

const Layout: React.FC<Props> = ({ children, content, footer, progress, max = 4, onPrev }) => (
  <Wrapper>
    <Header>
      {onPrev && <ChevronLeftIcon className="back-button" onClick={() => onPrev()} />}

      <ApplicationLogo fallbackToLoanzify={true} />
    </Header>

    {progress && <Stepper title={`Your app Set-up in ${max} Easy Steps`} value={progress} max={max} />}

    <Content>{children || content}</Content>

    {footer && <Footer>{footer}</Footer>}
  </Wrapper>
)

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  background: #fff;
  padding: 0.5rem 1rem 0.5rem 1rem;

  border-bottom-left-radius: 50% 20px;
  border-bottom-right-radius: 50% 20px;
  border-bottom: 2px solid var(--color-gray-lightest);

  svg.back-button {
    position: absolute;
    left: 25px;
    top: 25px;
  }
`

const Content = styled.div`
  position: relative;
  flex-grow: 1;
  padding: 0 2rem;
  overflow: scroll;
`

const Footer = styled.div``

export default Layout
