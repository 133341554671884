import React from 'react'
import styled from 'styled-components/macro'

import { LoanMilestone, TimelineItem } from 'types/Pos'
import MilestoneStatusIcon from './MilestoneStatusIcon'

interface Props {
  loanMilestone: LoanMilestone | TimelineItem
}

const MilestoneStatus: React.FC<Props> = ({ loanMilestone }) => (
  <Wrapper>
    <MilestoneStatusIcon loanMilestone={loanMilestone} />
  </Wrapper>
)

const Wrapper = styled.div`
  background: ${props => (props.dense ? 'var(--color-background)' : 'transparent')};
  padding: 5px;
  border-radius: 9999px;
  display: flex;
  align-items: center;

  svg {
    width: 22px;
    height: 22px;
  }
`

export default MilestoneStatus
