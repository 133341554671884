import React, { useState, useEffect } from 'react'
import { MessageList, MessageInput, Chat, Channel, Thread, Window } from 'stream-chat-react'
import 'stream-chat-react/dist/css/index.css'

import { useAuth } from 'store/Auth'
import { IonLoading } from '@ionic/react'
import { useStreamChat } from 'store/StreamChat'

interface Props {
  token: string
}

const ChatScreen: React.FC<Props> = ({ token }) => {
  const { posUser, loan } = useAuth() as any
  const { client } = useStreamChat()
  const [channel, setChannel] = useState<any>()
  const [status, setStatus] = useState<'loading' | 'ready'>('loading')

  useEffect(() => {
    if (!posUser) {
      return
    }

    const setup = async () => {
      setChannel(client.channel('loan', loan.id))
      setStatus('ready')
    }

    setup()
  }, [posUser.id, loan.id, token])

  if (status === 'loading' || !client) {
    return <IonLoading isOpen={true} />
  }

  return (
    <Chat client={client} theme="messaging light">
      <Channel channel={channel}>
        <Window>
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  )
}

export default ChatScreen
