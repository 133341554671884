import React, { useEffect } from 'react'
import { IonPage } from '@ionic/react'

import { CALCULATORS_CONFIG } from 'config/calculators'
import { useCalculator } from 'store/Calculator'
import Header from 'components/Header'
import Content from 'components/Content'

import MainOptions from '../components/MainOptions'
import InfoBox from '../../../components/InfoBox'
import { useApplication } from '../../../store/Application'
import ResultContent from './components/ResultContent'
import CalculatorResultPage from 'containers/CalculatorResultPage'

const ExtraOptionsPage = () => {
  const {
    application: { calculator_descriptions = {} },
  } = useApplication()
  const { resetOptions } = useCalculator()

  const calculatorDescription = calculator_descriptions.extra_payment || ''

  // When mounted
  useEffect(() => {
    // Reset options
    resetOptions()
  }, [])

  return (
    <IonPage>
      <Header back title={CALCULATORS_CONFIG.extraPayment.title} defaultHref="/calculators" />

      <Content>
        {calculatorDescription && <InfoBox text={calculatorDescription} />}

        <CalculatorResultPage
          calculator="extraPayment"
          calculate={window.lhpCalculator.payoff}
          autoSaveEnabled={false}
          renderContent={result => result && <ResultContent result={result} />}
        />

        <MainOptions loanType="extraPayment" optionsConfig={CALCULATORS_CONFIG.extraPayment.mainOptionsConfig} />
      </Content>
    </IonPage>
  )
}

export default ExtraOptionsPage
