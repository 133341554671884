import React from 'react'
import styled from 'styled-components'

const BadgeContainer = styled.div`
  text-align: center;
`

const BadgeTitle = styled.div`
  font-size: 0.68rem;
  color: var(--color-gray-light);
`

const BadgeValue = styled.div`
  width: 2.5rem;
  height: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto 2px auto;
  border-radius: 9999px;

  color: #fff;
  font-weight: 700;
  background-color: ${props => `var(--color-${props.color})`};
`

BadgeValue.defaultProps = {
  color: 'info',
}

type BadgeProps = {
  title: string
  badge: string
  color?: string
}

const Badge = ({ title, badge, color }: BadgeProps) => (
  <BadgeContainer>
    <BadgeValue color={color}>{badge}</BadgeValue>
    <BadgeTitle>{title}</BadgeTitle>
  </BadgeContainer>
)

export default Badge
