import { getCurrentLoanTeam } from 'api/pos'
import { useQuery } from 'react-query'

import { TeamMember } from 'types/Pos'

interface LoanTeamResponse {
  members: TeamMember[]
}

const useLoanTeam = () =>
  useQuery<LoanTeamResponse>('loan-team', getCurrentLoanTeam, {
    staleTime: 1000 * 60 * 10, // 10 minutes
  })

export default useLoanTeam
