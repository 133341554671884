import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { Dialog } from '@capacitor/dialog';
import { Capacitor } from '@capacitor/core';
import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';

import { useSession } from './Session';
import { updatePushNotificationToken } from 'api/loanzify';

interface Context {
  pushNotifications: PushNotificationSchema[];
  removeAllDeliveredNotifications: typeof PushNotifications.removeAllDeliveredNotifications;
}

const PushNotificationsContext = createContext({} as Context);

export const PushNotificationsProvider: React.FC = props => {
  const history = useHistory();
  const { token } = useSession();

  const [fcmToken, setFcmToken] = useState<string>('');

  // prettier-ignore
  const query = useQuery('push-notifications', async () => {
    return Capacitor.isNativePlatform()
      ? (await PushNotifications.getDeliveredNotifications()).notifications
      : []
  }, {
    initialData: [],
  })

  useEffect(() => {
    if (!fcmToken || !token) {
      return;
    }

    // Adding a 3 second timeout herre just in case
    // Loanzify client is not authenticated yet.
    //
    // @todo:
    // Move this provider "under" auth provider and
    // watch for the  "user" variable instead of
    // "token" from the session.
    setTimeout(() => {
      updatePushNotificationToken({ token: fcmToken })
        .then(() => {
          console.log('[Push] Token Updated');
        })
        .catch(error => {
          Bugsnag.notify(error);
          console.log('[Push] Token update failed', error);
        });
    }, 3000);
  }, [fcmToken, token]);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      console.log('[Push] Skipping on web.');

      return;
    }

    if (!token) {
      console.log('[Push] Auth token not found.');

      PushNotifications.removeAllListeners();

      return;
    }

    PushNotifications.requestPermissions().then(result => {
      console.log('[Push] Permission Result', result);

      if (result.receive) {
        PushNotifications.register();
      }
    });

    PushNotifications.addListener('registration', token => {
      setFcmToken(token.value);
      console.log('[Push] Listener registered', token);
    });

    PushNotifications.addListener('registrationError', error => {
      console.error('[Push] Error registering listener', error);
      Bugsnag.notify(error);
    });

    PushNotifications.addListener('pushNotificationReceived', notification => {
      Dialog.alert({
        title: notification.title || '',
        message: notification.body || '',
        buttonTitle: 'OK',
      });

      query.refetch();

      console.log('[Push] Notification Received', notification);
    });

    PushNotifications.addListener('pushNotificationActionPerformed', event => {
      console.log('[Push] Notification Action: ' + JSON.stringify(event), event);

      const routerLink = event?.notification?.data?.routerLink;

      history.push(routerLink || '/notifications');
    });

    return () => {
      if (Capacitor.isNativePlatform()) {
        PushNotifications.removeAllListeners();
      }
    };
  }, [token]);

  return (
    <PushNotificationsContext.Provider
      {...props}
      value={{
        pushNotifications: query.data || [],
        async removeAllDeliveredNotifications() {
          if (!Capacitor.isNativePlatform()) {
            return;
          }

          await PushNotifications.removeAllDeliveredNotifications();
          query.refetch();
        },
      }}
    />
  );
};

export const usePushNotifications = () => useContext(PushNotificationsContext);
