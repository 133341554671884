import React from 'react'
import { IonRouterOutlet } from '@ionic/react'

import PrivateRoute from 'layouts/auth/PrivateRoute'

import InstallationsPage from 'pages/Installations/Index'
import InstallationDetailsPage from 'pages/Installations/Show'

const InstallationRoutes = () => (
  <IonRouterOutlet>
    <PrivateRoute exact path="/installations" component={InstallationsPage} />
    <PrivateRoute path="/installations/:installation_id" component={InstallationDetailsPage} />
  </IonRouterOutlet>
)

export default InstallationRoutes
