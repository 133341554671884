import React from 'react'
import styled from 'styled-components'
import { IonCard, IonCardContent, IonImg } from '@ionic/react'

import Label from './Label'

const StyledCardContent = styled(IonCardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 149px;
`

const StyledIonImg = styled(IonImg)`
  margin-top: auto;
  margin-bottom: 1rem;

  ::part(image) {
    width: ${props => (props.width ? props.width : props.size)}px;
    height: ${props => (props.height ? props.height : props.size)}px;
  }
`

const StyledLabel = styled(Label)`
  margin-top: auto;
`

const CardImgButton = ({ src, size = 50, width = null, height = null, title, ...cardProps }) => (
  <IonCard {...cardProps}>
    <StyledCardContent>
      <StyledIonImg src={src} size={size} width={width} height={height} />

      <StyledLabel size="sm" color="gray" weight={500}>
        {title}
      </StyledLabel>
    </StyledCardContent>
  </IonCard>
)

export default CardImgButton
