import React, { useEffect, useState } from 'react'

import CollectionCard from 'components/CollectionCard'
import ConsumerListItem from './ConsumerListItem'
import { useConsumers } from '../../../../store/Consumers'

const RecentConsumers: React.FC = () => {
  const { consumers } = useConsumers()

  const [recentConsumers, setRecentConsumers] = useState<any[]>(consumers)

  useEffect(() => {
    setRecentConsumers(consumers.slice(0, 4))
  }, [consumers])

  return (
    <CollectionCard title="Recent Users" routerLink="/leads/tabs/consumers">
      {recentConsumers.map((consumer, i) => (
        <ConsumerListItem key={consumer.id} consumer={consumer} flat={true} className={`consumer-list-item-${i}`} />
      ))}
    </CollectionCard>
  )
}

export default RecentConsumers
