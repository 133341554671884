import React from 'react'
import styled from 'styled-components'
import { IonCard, IonCardContent } from '@ionic/react'

import { ReactComponent as ChevronRightIcon } from 'legacy-icons/chevron-right.svg'

import Label from './Label'

const StyledIonCard = styled(IonCard)`
  margin: 0 0 0.5rem;
`

const StyledIonCardContent = styled(IonCardContent)`
  color: var(--color-gray-lighter);
  padding: 1rem;
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  color: var(--color-primary);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContentContainer = styled.div`
  flex: 1;
  margin: 0 1rem;
  color: var(--color-gray);
`

const LinkCard = ({ icon: IconComponent, label, ...props }) => (
  <StyledIonCard {...props}>
    <StyledIonCardContent>
      {IconComponent && (
        <IconContainer>
          <IconComponent />
        </IconContainer>
      )}

      <ContentContainer>
        <Label secondary>{label}</Label>
      </ContentContainer>

      <ChevronRightIcon />
    </StyledIonCardContent>
  </StyledIonCard>
)

export default LinkCard
