import { InitialApplicationState } from 'types/Application'
import parseApplicationData from './parseApplicationData'

interface Util {
  (application_id: number): InitialApplicationState | undefined
}

const getGeneratedAppData: Util = application_id => {
  if (application_id !== Number(process.env.REACT_APP_DEFAULT_APP_ID)) {
    return
  }

  let app
  let owner

  try {
    app = require('../generated/app.json')
  } catch (error) {
    return
  }

  try {
    owner = require('../generated/owner.json')
  } catch (error) {
    return
  }

  if (app && owner) {
    return parseApplicationData(app, owner)
  }
}

export default getGeneratedAppData
