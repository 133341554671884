import React from 'react'

import { AuthProvider } from 'store/Auth'
import { SessionProvider } from 'store/Session'
import { ContentProvider } from 'store/Content'
import { OnboardingProvider } from 'store/Onboarding'
import { ApplicationProvider } from 'store/Application'
import { PushNotificationsProvider } from 'store/PushNotifications'

import PosProvider from 'store/Pos'
import LiveUpdateProvider from 'store/LiveUpdateProvider'
import QueryClientProvider from 'store/QueryClientProvider'
import BranchDeepLinksProvider from 'store/BranchDeepLinksProvider'
import VerificationProvider from 'store/VerificationProvider'
import { StreamChatProvider } from 'store/StreamChat'
import { CalculationsProvider } from 'store/Calculations'
import { CalculatorProvider } from 'store/Calculator'
import CalculatorDefaultsProvider from 'store/CalculatorDefaultsProvider'
import { IntroProvider } from 'store/Intro'
import { MenuProvider } from '../store/Menu'
import { ConsumersProvider } from '../store/Consumers'

const Providers = ({ children }) => (
  <LiveUpdateProvider>
    <QueryClientProvider>
      <SessionProvider>
        <OnboardingProvider>
          <BranchDeepLinksProvider>
            <ApplicationProvider>
              <StreamChatProvider>
                <AuthProvider>
                  <ConsumersProvider>
                    <IntroProvider>
                      <MenuProvider>
                        <VerificationProvider>
                          <PushNotificationsProvider>
                            <ContentProvider>
                              <PosProvider>
                                <CalculatorDefaultsProvider>
                                  <CalculatorProvider>
                                    <CalculationsProvider>{children}</CalculationsProvider>
                                  </CalculatorProvider>
                                </CalculatorDefaultsProvider>
                              </PosProvider>
                            </ContentProvider>
                          </PushNotificationsProvider>
                        </VerificationProvider>
                      </MenuProvider>
                    </IntroProvider>
                  </ConsumersProvider>
                </AuthProvider>
              </StreamChatProvider>
            </ApplicationProvider>
          </BranchDeepLinksProvider>
        </OnboardingProvider>
      </SessionProvider>
    </QueryClientProvider>
  </LiveUpdateProvider>
)

export default Providers
