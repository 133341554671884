import React, { useEffect } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import styled from 'styled-components'

import { ReactComponent as ErrorIcon } from 'legacy-icons/alert-error.svg'

import Header from './Header'
import Label from './Label'
import Button from './Button'
import SendFeedbackModal from './SendFeedbackModal'

import { sendError } from 'api/loanzify'

import useModal from 'hooks/useModal'
import { useSession } from 'store/Session'
import { useApplication } from '../store/Application'
import { useAuth } from '../store/Auth'
import useApplicationSetter from '../hooks/useApplicationSetter'

export interface ErrorPageProps {
  title?: string
  description?: string
  message: string
  context?: Record<string, any>
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  title = 'There is an issue with loading the app.',
  description = 'We are really sorry for the inconvenience. We have already informed our support team with the issue.',
  message,
  context = {},
}) => {
  const { ssoOwner } = useApplication()
  const { user } = useAuth()
  const { application_id } = useSession()
  const setApplication = useApplicationSetter()

  const [openModal] = useModal(SendFeedbackModal, {
    context: {
      application_id,
      sso_owner: ssoOwner?.id,
      user_name: user ? `${user.first_name} ${user.last_name}` : 'Cannot resolve user name from app.',
      user_id: user ? user.id : 'Cannot resolve user id from app.',
    },
    cssClass: 'h-full w-full border-radius-0',
  })

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      sendError({ level: 'error', message, context })
    }
  }, [])

  return (
    <IonPage>
      <Header title="Error" />

      <IonContent className="ion-padding">
        <Container>
          <div>
            <div style={{ textAlign: 'center' }}>
              <ErrorIcon
                style={{
                  color: 'var(--color-danger)',
                  width: '110px',
                  height: '110px',
                  margin: '1.5rem auto',
                }}
              />
            </div>

            <Label block center color="gray" isWrapped>
              {title}
            </Label>

            <Label size="sm" justify center isWrapped block className="ion-margin">
              {description}
            </Label>
          </div>

          <ButtonContainer>
            <Button color="secondary" expand="block" onClick={openModal}>
              Submit Feedback
            </Button>
            <Button color="primary" expand="block" onClick={() => setApplication(process.env.REACT_APP_DEFAULT_APP_ID)}>
              Go To Loanzify
            </Button>
          </ButtonContainer>
        </Container>
      </IonContent>
    </IonPage>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;

  ${Button} {
    width: 100%;

    &:nth-of-type(1) {
      margin-bottom: 1rem;
    }
  }
`

export class PageError extends Error {
  props: ErrorPageProps

  constructor(props: ErrorPageProps) {
    super(props.message)
    this.props = props
  }
}

export default ErrorPage
