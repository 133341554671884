import React, { useState } from 'react';
import styled from 'styled-components';
import { LightenDarkenColor } from 'lighten-darken-color';
import { IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading } from '@ionic/react';
import { colorFillOutline, colorPaletteOutline, refreshCircleOutline } from 'ionicons/icons';

import { updateCssVariables } from 'api/loanzify';
import ColorPicker from 'components/ColorPicker';
import { useApplication } from 'store/Application';
import { Dialog } from '@capacitor/dialog';

const ThemeSettings: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { application, cssVariables, setCssVariables }: any = useApplication();

  const primaryColor = cssVariables['--color-primary'];
  const secondaryColor = cssVariables['--color-secondary'];

  const setPrimaryColor = (color: string) => {
    updateColors({
      ...cssVariables,
      '--color-primary': color,
      '--color-primary-light': LightenDarkenColor(color, 15),
      '--color-primary-lighter': LightenDarkenColor(color, 30),
      '--color-primary-lightest': LightenDarkenColor(color, 40),
    });
  };

  const setSecondaryColor = (color: string) => {
    updateColors({
      ...cssVariables,
      '--color-secondary': color,
    });
  };

  const updateColors = (values: any) => {
    setCssVariables(values);
    updateCssVariables(application.id, values);
  };

  const reset = async () => {
    const result = await Dialog.confirm({
      title: 'Are you sure?',
      message: 'Are you sure want to reset the theme options?',
    });

    if (!result?.value) {
      return;
    }

    setLoading(true);
    const defaults = await updateCssVariables(application.id, {});
    setLoading(false);

    setCssVariables(defaults);
  };

  return (
    <>
      <IonLoading isOpen={loading} message="Saving..." />

      <IonListHeader lines="full">
        <IonLabel>Theme</IonLabel>
      </IonListHeader>

      <IonList>
        <ColorPicker
          title="Primary Color"
          color={primaryColor}
          onChange={setPrimaryColor}
          render={open => (
            <IonItem button onClick={open}>
              <IonIcon slot="start" icon={colorPaletteOutline} />
              <IonLabel>Primary Color</IonLabel>
              <SelectedColor backgroundColor={primaryColor} />
            </IonItem>
          )}
        />

        <ColorPicker
          title="Secondary Color"
          color={secondaryColor}
          onChange={setSecondaryColor}
          render={open => (
            <IonItem button onClick={open}>
              <IonIcon slot="start" icon={colorFillOutline} />
              <IonLabel>Secondary Color</IonLabel>
              <SelectedColor backgroundColor={secondaryColor} />
            </IonItem>
          )}
        />

        <IonItem button onClick={reset}>
          <IonIcon slot="start" icon={refreshCircleOutline} />
          <IonLabel>Reset</IonLabel>
        </IonItem>
      </IonList>
    </>
  );
};

const SelectedColor = styled.div<{ backgroundColor: string }>`
  width: 20px;
  height: 20px;
  border-radius: 9999px;
  background-color: ${props => props.backgroundColor};
  margin-right: 5px;
`;

export default ThemeSettings;
