import VerifyPhonePage from 'pages/auth/VerifyPhonePage'
import React from 'react'
import { useAuth } from './Auth'
interface Props {
  //
}

const VerificationProvider: React.FC<Props> = ({ children }) => {
  const urlSearch = new URLSearchParams(window.location.search)
  const { user } = useAuth()

  const isPreview: boolean = urlSearch.has('preview-mode')

  if (user && user.is_consumer && !user.is_verified && !isPreview) {
    return <VerifyPhonePage />
  }

  return children as React.ReactElement
}

export default VerificationProvider
