import Axios from 'axios'
import { default as useBaseSWR } from 'swr'
import { parse } from 'content-disposition-attachment'

/**
 * POS Http client.
 *
 * @type {Axios}
 */
export const client = Axios.create({
  baseURL: `${process.env.REACT_APP_POS_URL}/api`,
  headers: {
    'X-Mobile-App': true,
  },
})

/**
 * POS data fetcher.
 *
 * @type {Function}
 */
export const fetcher = url => client.get(url).then(response => response.data)

/**
 * SWR hook wrapper for POS
 *
 * @type {Function}
 */
export const useSWR = data => useBaseSWR(data, fetcher)

/**
 * Get user loans.
 */
export const getLoans = () => client.get('v2/loans').then(response => response.data.data)

/**
 * Get loan.
 */
export const getLoan = id => client.get(`v2/loans/${id}?detailed=1`).then(response => response.data.data)

/**
 * Get loan officers
 */
export const getLoanOfficers = () => client.get(`v2/loan-officers`).then(response => response.data)

/**
 * Create documents token for the givne loan.
 *
 * @param {Number} loan
 */
export const createLoanToken = loan => client.post(`v2/loans/${loan}/tokens`).then(response => response.data)

/**
 * Create a stream chat token.
 *
 * @returns {Promise<string>}
 */
export const createStreamChatToken = () => client.post(`v2/stream-chat/tokens`).then(response => response.data.token)

/**
 * Get currently authenticated user.
 */
export const getCurrentUser = () => client.get(`v2/users/current`).then(response => response.data)

/**
 * Fetches dashboard chart data
 *
 * @param {object} params
 * @param {string} params.dateStart Start date
 * @param {string} params.dateEnd End date
 */
export const getUserDashboardCharts = params => client.get(`v1/authenticated/dashboard/charts`, { params })

/**
 * Get pipeline
 *
 * @param {object} params
 */
export const getPipeline = params => client.get(`v1/authenticated/pipeline`, { params })

/**
 * Get loan team.
 */
export const getCurrentLoanTeam = params => client.get(`v2/teams/current`).then(response => response.data.data)

/**
 * Get lhp-forms config.
 *
 * @param {Number} loan_id
 */
export const loadLhpFormsConfig = loan_id =>
  client.get(`v2/loans/${loan_id}/configurations/lhp-forms`).then(response => response.data.data)

/**
 * Get lhp-forms config.
 *
 * @param {Number} sso_user_id
 * @param {Number} sso_parent_user_id
 */
export const register = (sso_user_id, sso_parent_user_id) =>
  client.post(`v2/register`, { sso_user_id, sso_parent_user_id }).then(response => response.data)

// Loan Details

export const getLoanCreditRequests = loan_id =>
  client.get(`v1/authenticated/admin/loan/${loan_id}/credit-request`).then(response => response.data)

export const createLoanCreditRequest = loan_id =>
  client.post(`v1/authenticated/admin/loan/${loan_id}/credit-request`).then(response => response.data)

export const refreshLoanCreditRequest = (loan_id, request_id) =>
  client
    .post(`v1/authenticated/admin/loan/${loan_id}/credit-request/${request_id}/refresh`)
    .then(response => response.data)

export const archiveLoanCreditRequest = (loan_id, request_id) =>
  client
    .post(`v1/authenticated/admin/loan/${loan_id}/credit-request/${request_id}/archive`)
    .then(response => response.data)

export const getLoanIntegrationHistory = loan_id =>
  client.get(`v1/authenticated/admin/loan/${loan_id}/integration-logs`).then(response => response.data)

export const postLoanIntegration = (loan_id, service) =>
  client
    .post(`v1/authenticated/admin/loan/${loan_id}/integration-logs/post-data`, { services: [service] })
    .then(response => response)

export const getLoanTeamRoles = () =>
  client.get(`v1/authenticated/admin/loan-teams/roles`).then(response => response.data)

export const getLoanTeamAvailableStaff = team_id =>
  client.get(`v1/authenticated/admin/loan-teams/${team_id}/available-staff`).then(response => response.data)

export const getLoanTeamPartners = team_id =>
  client.get(`v1/authenticated/admin/loan-teams/${team_id}/partners`).then(response => response.data)

export const getLoanTeamMembers = team_id =>
  client.get(`v1/authenticated/admin/loan-teams/${team_id}/members`).then(response => response.data)

export const addLoanTeamMember = (team_id, account_user_id, role_id) =>
  client
    .post(`v1/authenticated/admin/loan-teams/${team_id}/member/${account_user_id}`, { roleId: role_id })
    .then(response => response)

export const removeLoanTeamMember = (team_id, account_user_id, role_id) =>
  client
    .delete(`v1/authenticated/admin/loan-teams/${team_id}/member/${account_user_id}`, { roleId: role_id })
    .then(response => response)

export const updateLoanLoanOfficer = (loan_id, account_user_id) =>
  client
    .put(`v1/authenticated/admin/loans/${loan_id}/update-loan-officer/${account_user_id}`)
    .then(response => response)

export const getLoanNotes = loan_id =>
  client.get(`v1/authenticated/admin/loan/${loan_id}/notes`).then(response => response.data)

export const createLoanNote = (loan_id, body) =>
  client.post(`v1/authenticated/admin/loan/${loan_id}/notes`, { body }).then(response => response.data)

export const emailDownloadFNM = loan_id =>
  client.post(`v2/loans/${loan_id}/fnm-32/email`).then(response => response.data)

export const getLetterParams = loan_id =>
  client.get(`v1/authenticated/loans/${loan_id}/pre-approval/settings`).then(response => response.data)

export const storeLetterParams = (loan_id, letterParams) =>
  client
    .post(`v1/authenticated/admin/loans/${loan_id}/settings/store-pre-approval-letter-params`, letterParams)
    .then(response => response.data)

/**
 * @DEPRECATED
 *
 * @param loan_id
 * @returns {*}
 */
export const getLoanActivePreApproval = loan_id =>
  client.get(`v1/authenticated/admin/loan/${loan_id}/pre-qualify/primary`).then(response => response.data)

/**
 * @DEPRECATED
 *
 * @param loan_id
 * @returns {*}
 */
export const getLoanPreApprovalScenarios = loan_id =>
  client.get(`v1/authenticated/admin/loan/${loan_id}/pre-qualify`).then(response => response.data)

export const setActiveLoanPreApprovalScenario = (loan_id, scenarioId) =>
  client
    .post(`v1/authenticated/admin/loan/${loan_id}/pre-qualify/${scenarioId}/make-primary`)
    .then(response => response.data)

/**
 * @DEPRECATED
 *
 * @param loan_id
 * @param scenarioId
 * @returns {*}
 */
export const deleteLoanPreApprovalScenario = (loan_id, scenarioId) =>
  client.delete(`v1/authenticated/admin/loan/${loan_id}/pre-qualify/${scenarioId}`).then(response => response)

/**
 * @DEPRECATED
 *
 * @param loan_id
 * @param scenarioId
 * @param scenarioData
 * @returns {*}
 */
export const updatePreApprovalScenario = (loan_id, scenarioId, scenarioData) =>
  client
    .patch(`v1/authenticated/admin/loan/${loan_id}/pre-qualify/${scenarioId}`, scenarioData)
    .then(response => response.data)

/**
 * @DEPRECATED
 *
 * @param loan_id
 * @param scenarioData
 * @returns {*}
 */
export const createPreApprovalScenario = (loan_id, scenarioData) =>
  client.post(`v1/authenticated/admin/loan/${loan_id}/pre-qualify`, scenarioData).then(response => response.data)

export const getLoanPreApprovalLetters = loan_id =>
  client.get(`v1/authenticated/admin/loan/${loan_id}/pre-approval-options`).then(response => response.data)

export const downloadPreApprovalLetter = (loan_id, preApprovalOption) => {
  client
    .get(`/v2/loans/${loan_id}/pre-approval/${preApprovalOption.id}/download`, {
      responseType: 'arraybuffer',
    })
    .then(data => {
      const defaultName = 'untitled'
      const fileName = headers => {
        try {
          const { attachment, filename } = parse(headers['content-disposition'])
          return attachment ? filename : defaultName
        } catch (e) {
          console.error(e)
          return defaultName
        }
      }

      const blob = new Blob([data.data], { type: 'application/pdf' })
      const downloadUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = downloadUrl
      a.download = fileName(data.headers)
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
    .catch(err => {
      return false
    })
}

export const createLoanPreApprovalLetter = (
  loan_id,
  {
    name,
    downPayment,
    requested_amount,
    pre_approval_limit,
    address_street_1,
    address_city,
    address_state,
    address_zipcode,
    note,
  }
) =>
  client
    .post(`v1/authenticated/admin/loan/${loan_id}/pre-approval-options`, {
      name,
      create_letter: true,
      is_primary: true,
      data: {
        downPayment,
        requested_amount,
        pre_approval_limit,
        address_street_1,
        address_city,
        address_state,
        address_zipcode,
        note,
      },
    })
    .then(response => response.data)

export const emailLoanPreApprovalLetter = (loan_id, { body, file_name, preApprovalOption, recipient_email }) =>
  client
    .post(`v1/authenticated/admin/loan/${loan_id}/pre-qualify/email`, {
      body,
      file_name,
      preApprovalOption,
      recipient_email,
    })
    .then(response => response.data)

export const deleteLoanPreApprovalLetter = (loan_id, letter_id) =>
  client.delete(`v1/authenticated/admin/loan/${loan_id}/pre-approval-options/${letter_id}`).then(response => response)

export const getRecentPosStats = (from = 7) =>
  client.get(`v1/authenticated/admin/recent-stats`, { params: { from } }).then(response => response.data)

export const getPartners = () => client.get(`v1/authenticated/admin/partners`).then(response => response.data)

export const getSSOUsersInfo = ({ sso_consumer_service_ids = [] }) =>
  client.get(`v2/sso-user-info`, { params: { sso_consumer_service_ids } }).then(response => response.data)

export const getSSOUsersInfoByConsumer = ({ sso_consumer_ids = [] }) =>
  client.get(`v2/sso-user-info/by-consumer`, { params: { sso_consumer_ids } }).then(response => response.data)

/**
 * @param {number} consumer_id
 * @returns {Promise<any>}
 */
export const getUserInfoByConsumerService = async consumer_id => {
  return (
    await getSSOUsersInfoByConsumer({
      sso_consumer_ids: [consumer_id],
    })
  )?.sso_consumer_ids?.[0]
}

export const storeQuickIntake = (model, sso_id, partner_sso_id = null) =>
  client.post(`v2/quick-intake`, { model, sso_id, partner_sso_id }).then(response => response.data)

/**
 *
 * @param {Record<string, string>} model
 * @returns {Promise<{submission_id: number}>}
 */
export const storeLoanQuickIntake = model =>
  client.post(`v1/authenticated/quick-intakes`, { model }).then(response => response.data)

/**
 * @param {number} loan
 * @param {'mismo' | 'fnm' | 'csv'} type
 * @returns {Promise<void>}
 */
export const exportLoan = (loan_id, type) =>
  client.post(`v2/loans/${loan_id}/send-export-email`, { type }).then(response => response.data)

export const setMilestoneOrEventDate = (loan, eventData, date, config = {}) =>
  client.put(`v1/authenticated/loans/${loan.id}/milestone-events`, { loan, ...eventData, date, config })

export const resetMilestone = (loan, milestone_id) =>
  client.post(`v1/authenticated/loans/${loan.id}/milestone/reset`, { loan, milestone_id })

/**
 * Get currently authenticated user.
 */
export const resetPassword = data => client.post(`v2/users/reset-password`, data).then(response => response.data)

export const storeUnreadMessage = (from_account_user_id, loan_id, message) => {
  client.post(`v2/unread-message`, { from_account_user_id, loan_id, message })
}
