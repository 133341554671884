import React from 'react'
import styled from 'styled-components'

import Label from 'components/Label'

const Root = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  gap: 10px;
  justify-items: center;

  margin-top: 1rem;
  max-width: 400px;
`

const Legend = styled.div`
  display: flex;
  align-items: center;
`

const LegendBox = styled.div`
  background: ${props => props.color};
  border-radius: 9px;
  width: 9px;
  height: 9px;
  margin-right: 0.25rem;
`

const ChartLegend = ({ labels, colors }) => (
  <Root>
    {labels.map((label, index) => (
      <Legend key={index}>
        <LegendBox color={colors[index]} />

        <Label color="gray-light" fontStyle="italic" secondary size="2xs" weight={300}>
          {label}
        </Label>
      </Legend>
    ))}
  </Root>
)

export default ChartLegend
