import React from 'react'
import { Route } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'

import PrivacyPage from '../pages/legal/PrivacyPage'

const LegalRoutes = () => (
  <IonRouterOutlet>
    <Route exact path="/legal/privacy" component={PrivacyPage} />
  </IonRouterOutlet>
)

export default LegalRoutes
