import React, { useState } from 'react'
import { useMutation } from 'react-query'

import { sendPhoneVerificationNotification } from 'api/sso'

import Layout from 'pages/auth/components/Layout'
import AuthButton from 'pages/auth/components/Button'
import FormInput from 'pages/auth/components/FormInput'
import useAlert from 'hooks/useAlert'
import { useAuth } from 'store/Auth'

interface Props {
  //
}

const UpdatePhoneNumberPage: React.FC<Props> = () => {
  const alert = useAlert()
  const { user, setUser } = useAuth()

  const [phone, setPhone] = useState('')

  const mutation = useMutation(async () => {
    return await sendPhoneVerificationNotification({ phone })
  })

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      await mutation.mutateAsync()
    } catch (error) {
      //
    }

    if (mutation.isError) {
      alert.error(
        'There was an error sending you verification code. Make sure you have entered the correct phone number.'
      )
      mutation.reset()
    }

    if (mutation.isSuccess) {
      user && setUser({ ...user, phone })
      alert.success('A one-time passcode was sent to your phone')
    }
  }

  return (
    <Layout title="Phone Verification" subtitle="A one-time passcode will be sent to your phone">
      <form onSubmit={submit}>
        <FormInput
          type="tel"
          label="Phone Number"
          placeholder="Enter Phone Number"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />

        <AuthButton>Send</AuthButton>
      </form>
    </Layout>
  )
}

export default UpdatePhoneNumberPage
