import React from 'react'
import { IonPage, IonContent } from '@ionic/react'

import List from 'components/List'
import Header from 'components/Header'
import AccordionChecklistItem from 'components/AccordionChecklistItem'

import { useContent } from 'store/Content'

const ChecklistPage = () => {
  const { content } = useContent()

  const checklist = JSON.parse(content.checklist[0].content).map(item => ({
    title: item.name,
    items: item.checks.map(check => ({
      title: check,
      checked: false,
    })),
  }))

  return (
    <IonPage>
      <Header title="Checklist" back defaultHref="/content" />

      <IonContent>
        <List>
          {checklist.map((item, index) => (
            <AccordionChecklistItem key={`checklist-${index}`} {...item} />
          ))}
        </List>
      </IonContent>
    </IonPage>
  )
}

export default ChecklistPage
