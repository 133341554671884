import React from 'react'
import { IonContent, IonPage } from '@ionic/react'

import ProgressBar from './ProgressBar'

interface Props {
  progress: number
}

const UpdateInProgress: React.FC<Props> = ({ progress }) => (
  <IonPage>
    <IonContent>
      <ProgressBar progress={progress} title="Downloading latest App updates..." />
    </IonContent>
  </IonPage>
)

export default UpdateInProgress
