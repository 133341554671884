import React from 'react'
import { IonRouterOutlet } from '@ionic/react'

import PrivateRoute from 'layouts/auth/PrivateRoute'

import DocumentsPage from 'pages/documents/Index'
import DocumentHistoryPage from 'pages/documents/POS/History'

const DocumentRoutes = () => (
  <IonRouterOutlet>
    <PrivateRoute exact path="/documents" component={DocumentsPage} />
    <PrivateRoute exact path="/documents/:document_id/history" component={DocumentHistoryPage} />
  </IonRouterOutlet>
)

export default DocumentRoutes
