import React from 'react'
import styled from 'styled-components/macro'

import { Milestone, MilestoneEvent } from 'types/Pos'

interface Props extends React.ComponentPropsWithoutRef<'img'> {
  milestone: Milestone | MilestoneEvent
  dense?: boolean | undefined
}

const MilestoneImage: React.FC<Props> = ({ milestone, ...props }) => {
  if (!milestone.icon) {
    return <Image {...props} src={`https://api-pos.loanzify.io/images/borrower-timeline/sequence-7.png`} />
  }

  return <Image {...props} src={`https://api-pos.loanzify.io/images/borrower-timeline/${milestone.icon}`} />
}

const Image = styled.img`
  max-width: 107px;
  background-color: white;
  border: 3px solid var(--border-color);
  border-radius: 9999px;
  margin-right: ${props => (props.dense ? '0' : '18px')};
  margin-left: ${props => (props.dense ? '4px' : '0px')};
`

export default MilestoneImage
