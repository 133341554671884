import React from 'react'
import styled from 'styled-components'
import { IonIcon } from '@ionic/react'
import { Toast } from '@capacitor/toast'
import { Browser } from '@capacitor/browser'
import { trashOutline } from 'ionicons/icons'

import DocumentItem from 'pages/documents/components/DocumentItem'
import DocumentList from 'pages/documents/components/DocumentList'

import { ReactComponent as EyeIcon } from 'legacy-icons/eye.svg'
import { deleteDocument, getDocumentLink } from 'api/documents'

const ActionWrapper = styled.div`
  display: flex;
`

const IconWrapper = styled.div`
  align-self: center;
  margin-left: 1.25rem;

  &:first-child {
    margin-left: 0;
  }

  ion-icon {
    color: var(--color-gray-light);
    font-size: 24px;
  }

  svg {
    fill: var(--color-gray-light);

    path {
      fill: var(--color-gray-light);
    }
  }
`

const SsoDocumentList = ({ documents, onRefresh = () => {} }) => {
  const viewDocument = documentId => {
    getDocumentLink(null, documentId).then(({ preview_url }) => {
      Browser.open({
        url: `http://docs.google.com/gview?embedded=true&url=${encodeURIComponent(preview_url)}`,
      })
    })
  }

  const removeDocument = documentId => {
    deleteDocument(documentId)
      .then(() => {
        Toast.show({ text: 'Files deleted successfully.' })
      })
      .finally(() => onRefresh())
  }

  const renderAction = documentId => (
    <ActionWrapper>
      <IconWrapper onClick={() => viewDocument(documentId)}>
        <EyeIcon className="custom-icon" width={28} height={21} />
      </IconWrapper>
      <IconWrapper onClick={() => removeDocument(documentId)}>
        <IonIcon icon={trashOutline} />
      </IconWrapper>
    </ActionWrapper>
  )

  return (
    <DocumentList>
      {documents.map((document, index) => (
        <DocumentItem
          key={`document-${index}-${document.title}`}
          title={document.title}
          action={() => renderAction(document.id)}
        />
      ))}
    </DocumentList>
  )
}

export default SsoDocumentList
