import Axios, { AxiosError } from 'axios'

export type MessageBag = Record<string, string[]>

export interface ValidationResponse {
  message: string
  errors: MessageBag
}

type AxiosValidationError = AxiosError<ValidationResponse>

export const isValidationError = (error: Error | AxiosError) => {
  return Axios.isAxiosError(error) && error.response?.status === 422 && error?.response?.data.errors
}

export const convertToErrorBag = (error: AxiosValidationError) => {
  return new ErrorBag(error.response?.data?.errors || {})
}

export class ErrorBag {
  errors: MessageBag

  constructor(errors: MessageBag) {
    this.errors = errors
  }

  get keys() {
    return Object.keys(this.errors)
  }

  get all() {
    return Object.values(this.errors).flat()
  }

  first(key?: string) {
    if (key === undefined) {
      return this.all[0]
    }

    return this.errors[key]?.[0]
  }

  hasError(key: string) {
    return Boolean(this.first(key))
  }

  hasErrors() {
    return this.all.length > 0
  }
}
