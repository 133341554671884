import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { handleShare } from 'utils/calculator';
import { CALCULATORS_CONFIG } from 'config/calculators';

import { useAuth } from 'store/Auth';
import { useCalculator } from 'store/Calculator';

import useAlert from 'hooks/useAlert';
import useCalculatorResult from 'hooks/useCalculatorResult';

import ResultFooter from 'components/calculators/ResultFooter';
import AlertInput from 'components/AlertInput';
import { useDebouncedCallback } from 'use-debounce';
import { CalculatorDefaultOptions, CalculatorOptions, CalculatorResult, Factor } from 'types/Calculator';

interface CalculatorResultPageProps {
  calculator: string;
  calculate: (options: CalculatorOptions | CalculatorDefaultOptions, factors: Factor[]) => CalculatorResult;
  renderContent: (result: CalculatorResult) => void;
  autoSaveEnabled?: boolean;
}
const CalculatorResultPage = ({
  calculator,
  calculate,
  renderContent,
  autoSaveEnabled = true,
}: CalculatorResultPageProps) => {
  const showAlert = useAlert();
  const history = useHistory();
  const { user } = useAuth();
  const { currentTypeDefaults, options, result, setResult } = useCalculator();

  const updateCounter = useRef(0);

  const {
    calculatorNameOpen,
    saved,
    error,
    setCalculatorNameOpen,
    handleSave,
    saveCalculator,
    saveRunCalculation,
    sendPdfCalculation,
    sendPreQualify,
    runCalculation,
  } = useCalculatorResult(calculator);

  const handleDetails = () => {
    history.push(`/calculators/${calculator}/details`);
  };

  const handleAmortisation = () => {
    history.push(`/calculators/${calculator}/amortisation`);
  };

  const showSuccessDownloaded = () => {
    showAlert.success('We have received your request. Please check your email shortly.');
  };

  const showFail = () => {
    showAlert.error('Oops... There was an error. Please try again.');
  };

  const handleDownload = () =>
    sendPdfCalculation()
      .then(() => showSuccessDownloaded())
      .catch(() => showFail());

  const handlePrequalify = () =>
    sendPreQualify()
      .then(() => showSuccessDownloaded())
      .catch(() => showFail());

  const handleCustomShare = async () => {
    await handleShare(runCalculation?.id || result?.id);
  };

  useEffect(() => {
    saved && showAlert.success('We have received your request. Your calculation has been saved');
  }, [saved]);

  useEffect(() => {
    error && showAlert.error('Oops... There was an error. Please try again.');
  }, [error]);

  const debouncedSaveRunCalculation = useDebouncedCallback(result => saveRunCalculation(result), 3000);

  useEffect(() => {
    const calcResult = calculate(options, currentTypeDefaults.factors);

    // This "updateCounter" is a crazy workaround to
    // make it save the calculation only after the
    // options has changed at least once.
    //
    // P.S. On the initial render it calculates
    // the result twice. 😢
    if (updateCounter.current > 2 && autoSaveEnabled) {
      debouncedSaveRunCalculation(result);
    }

    setResult(calcResult);
    updateCounter.current += 1;
  }, [options, setResult]);

  if (isEmpty(result)) {
    return null;
  }

  return (
    <React.Fragment>
      <AlertInput
        isOpen={calculatorNameOpen}
        title="Enter calculator name"
        inputs={[
          {
            value: '',
            name: 'name',
            placeholder: 'Calculator Name',
            autofocus: true,
          },
        ]}
        onCancel={() => setCalculatorNameOpen(false)}
        onOk={saveCalculator}
      />

      {renderContent(result)}

      {['purchase', 'refi'].includes(calculator) && (
        <ResultFooter
          canSave={!saved}
          canDownload={CALCULATORS_CONFIG[calculator].canDownload && user?.is_consumer}
          onDetails={handleDetails}
          onAmortisation={handleAmortisation}
          onDownload={handleDownload}
          onSave={handleSave}
          onPreQualify={handlePrequalify}
          onShare={handleCustomShare}
          hideSave={!user?.is_consumer}
          hidePrequalify={!user?.is_consumer}
        />
      )}
    </React.Fragment>
  );
};

export default CalculatorResultPage;
