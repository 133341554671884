import React, { useMemo, useState } from 'react'
import { Share } from '@capacitor/share'
import { Capacitor } from '@capacitor/core'
import { IonButton, IonIcon } from '@ionic/react'
import { shareOutline, shareSocialOutline } from 'ionicons/icons'

import { useApplication } from 'store/Application'
import { ShareActionSheet } from './ShareActionSheet'

interface Props {
  render?: (open: () => void) => React.ReactNode
}

const ShareButton: React.FC<Props> = ({ render }) => {
  const { application } = useApplication()
  const [showShare, setShowShare] = useState(false)

  const openShare = () => {
    Capacitor.isNativePlatform()
      ? Share.share({ text: application.share_text }).catch(e => console.error(e))
      : setShowShare(true)
  }

  const icon = useMemo(() => {
    if (Capacitor.getPlatform() === 'ios') {
      return shareOutline
    }

    return shareSocialOutline
  }, [Capacitor.platform])
  return (
    <>
      {render?.(openShare) || (
        <IonButton onClick={openShare}>
          <IonIcon slot="icon-only" icon={icon} />
        </IonButton>
      )}
      <ShareActionSheet showShare={showShare} handleDismiss={() => setShowShare(false)} />
    </>
  )
}

export default ShareButton
