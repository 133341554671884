import { getConsumerService, getCurrentUser as getCurrentSsoUser } from 'api/sso'
import { getCurrentUser as getCurrentPosUser, getLoan } from 'api/pos'
import { InitialSessionState } from '../types/Session'
import { InitialAuthState } from '../types/Auth'

interface Loader {
  (session: InitialSessionState, setState: Partial<InitialAuthState>, hasPos: boolean): Promise<any>
}

/**
 * Recover authentication state from session.
 *
 * @param {Object} session
 * @param {Function} setState
 * @param {Object} hasPos
 * @returns {Promise}
 */
export const recoverAuthFromSession = async (session, setState, hasPos) => {
  const loaders = [loadUser, loadLoan, loadRole, loadConsumerService].map(async (loader: Loader) => {
    return new Promise(resolve => resolve(loader(session, setState, hasPos)))
  })
  return await Promise.all(loaders)
}

/**
 * Load the current "sso" user.
 *
 * @param {Object} session
 * @param {Function} setState
 */
const loadUser = async (session, setState) => {
  if (!session.token) {
    return
  }

  const user = await getCurrentSsoUser()
  const { pos_user, loanzify_user } = user

  setState(state => ({ ...state, user, posUser: pos_user, loanzifyUser: loanzify_user }))
}

/**
 * Load the current "pos" user.
 *
 * @param {Object} session
 * @param {Function} setState
 */
const loadPosUser = async (session, setState) => {
  const posUser = await getCurrentPosUser()
  setState(state => ({ ...state, posUser }))

  return posUser
}

/**
 * Load the current loan.
 *
 * @param {Object} session
 * @param {Function} setState
 */
const loadLoan = async (session, setState) => {
  if (!session.loan_id) {
    return
  }

  const loan = await getLoan(session.loan_id)
  setState(state => ({ ...state, loan }))
}

/**
 * Load the current role.
 *
 * @param {Object} session
 * @param {Function} setState
 */
const loadRole = async ({ role }, setState) => {
  role && setState(state => ({ ...state, role }))
}

/**
 * Load consumer service.
 *
 * @param {Object} session
 * @param {Function} setState
 */
const loadConsumerService = async (session, setState) => {
  if (!session.consumer_service_id) {
    return
  }

  const consumerService = await getConsumerService(session.consumer_service_id)
  setState(state => ({ ...state, consumerService }))

  if (consumerService.userService.service === 'pos') {
    await loadPosUser(session, setState)
  }
}
