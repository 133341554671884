import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { IonContent, IonLoading, IonPage, IonToast } from '@ionic/react'

import { getLoan } from 'api/pos'
import Header from 'components/Header'
import LoanCard from 'components/loans/LoanCard'
import LoanTabRoutes from 'routes/LoanTabRoutes'
import { TabItem, TabContainer } from 'components/Tabs/TabContainer'
import { appends } from 'utils/loan'
import { useAuth } from 'store/Auth'
import styled from 'styled-components'

const LoanPage = () => {
  const history = useHistory()
  const { loan_id } = useParams()
  const { path, url } = useRouteMatch()

  const [loan, setLoan] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const { isLoanOfficer, isPartner, isUser } = useAuth()

  const fetchLoan = () => {
    console.log('fetching')
    getLoan(loan_id)
      .then(loan => setLoan(appends(loan)))
      .catch(setError)
      .finally(() => setLoading(false))
  }

  const loanTabRouteProps = { loan, isLoanOfficer, isPartner, isUser, reload: fetchLoan }

  const [tab, setTab] = useState(history.location.pathname.split('/').pop())

  useEffect(() => {
    fetchLoan()
  }, [])

  useEffect(() => {
    history.replace(`${url}/${tab}`)
  }, [tab])

  if (error) {
    return <IonToast isOpen color="danger" message="Error in loading dashboard data" />
  }

  if (loading) {
    return <IonLoading isOpen message="Loading loan..." />
  }

  return (
    <IonPage>
      <Header title="Loan Details" defaultHref="/leads/loans" />

      <IonContent>
        <LoanCard loan={loan} showForward={false} onTabChange={(e: any) => setTab(e.detail.value as string)} />

        <StyledTabContainer value={tab} onTabChange={e => setTab(e.detail.value as string)}>
          <TabItem value="overview">{isPartner ? 'Loan Status' : 'Overview'}</TabItem>
          {!isPartner && <TabItem value="application">Application</TabItem>}
          {!isPartner && <TabItem value="documents">Documents</TabItem>}
          {!isPartner && <TabItem value="credit-report">Credit Report</TabItem>}
          <TabItem value="pre-approval">Pre-Approval</TabItem>
          {!isPartner && <TabItem value="integrations">Integrations</TabItem>}
          {!isPartner && <TabItem value="export">Export</TabItem>}
          {!isPartner && <TabItem value="notes">Notes</TabItem>}
          {/*{!isPartner && <TabItem value="loan-team">Loan Team</TabItem>}*/}
        </StyledTabContainer>
        <LoanTabRoutes path={path} {...loanTabRouteProps} onTabChange={(e: any) => setTab(e.detail.value as string)} />
      </IonContent>
    </IonPage>
  )
}

const StyledTabContainer = styled(TabContainer)`
  padding: 1.25rem 0;
`

export default LoanPage
