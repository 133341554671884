import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Toast } from '@capacitor/toast'
import { IonLoading, IonSearchbar } from '@ionic/react'

import useLoanOfficerSelect from 'hooks/useLoanOfficerSelect'

import Layout from './components/Layout'
import LoanOfficerList from './components/LoanOfficerList'
import { useAuth } from '../../store/Auth'

const SearchWrapper = styled.div`
  padding: 0.25rem;
`

const SelectLoanOfficerPage = () => {
  const { user } = useAuth()
  const { officers, loading, skip, error, pickLoanOfficer, searchLoanOfficers } = useLoanOfficerSelect()
  const [searchTerm, setSearchTerm] = useState()
  const [searchable, setSearchable] = useState<boolean>(false)
  const [loanOfficers, setLoanOfficers] = useState([])
  const [searching, setSearching] = useState<boolean>(false)

  useEffect(() => {
    error && Toast.show({ text: error })
  }, [error])

  useEffect(() => {
    let mounted = true

    if (mounted) {
      if (searchTerm === undefined) {
        return
      }
      const fetch = async () => {
        setSearching(true)
        const fetched = await searchLoanOfficers(searchTerm)
        setLoanOfficers(fetched)
        setSearching(false)
      }

      fetch()
    }

    return () => {
      mounted = false
    }
  }, [searchTerm])

  if (error) {
    return <Redirect to={`/auth/logout`} />
  }

  if (skip) {
    return <Redirect to={`/auth/select-loan`} />
  }

  if (!user) {
    return <Redirect to={`/auth/login`} />
  }

  if (loading) {
    return <IonLoading isOpen={loading} message="Loading..." />
  }

  if (searchable === undefined) {
    setSearchable(officers.length > 7)
  }

  return (
    <Layout subtitle="Connect to your App" justifyBody={!searchable}>
      <IonLoading isOpen={searching} />
      {searchable && (
        <SearchWrapper>
          <IonSearchbar
            placeholder="Search for Loan Officer"
            value={searchTerm}
            onIonChange={e => setSearchTerm(e.detail.value)}
            debounce={500}
          />
        </SearchWrapper>
      )}
      <LoanOfficerList officers={loanOfficers.length ? loanOfficers : officers} onSelect={pickLoanOfficer} />
    </Layout>
  )
}

export default SelectLoanOfficerPage
