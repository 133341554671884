import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Doughnut } from 'react-chartjs-2'

import { getColorsFromStyle } from 'utils/theme'
import ChartLegend from './ChartLegend'

const ChartContainer = styled.div`
  position: relative;
  height: 120px;
`

const ChartTitle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

/**
 * @param {{
 *    data: any
 *    children: any
 *    colorNames: any
 *    widthPercent?: number
 *    showLegend?: boolean
 *   labels?: string[]
 * }} param0
 */
const DoughnutChart = ({ data, labels = undefined, colorNames, children, widthPercent = 15, showLegend = true }) => {
  const colors = useMemo(() => getColorsFromStyle(colorNames), [colorNames])

  // prettier-ignore
  const chartOptions = useMemo(() => ({
    cutoutPercentage: 100 - widthPercent,
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
  }), [widthPercent])

  const chartData = {
    datasets: [
      {
        data,
        borderWidth: 0,
        backgroundColor: colors,
      },
    ],
  }

  if (labels) {
    chartData.labels = labels
  }

  return (
    <>
      <ChartContainer>
        <Doughnut options={chartOptions} data={chartData} width={15} height={10} />

        <ChartTitle>{children}</ChartTitle>
      </ChartContainer>

      {showLegend && <ChartLegend labels={labels} colors={colors} />}
    </>
  )
}

export default DoughnutChart
