import * as React from 'react'
import { format, isAfter, isBefore } from 'date-fns'

import ModalContent from '../../../components/ModalContent'
import Label from '../../../components/Label'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { Loan, LoanMilestone, LoanMilestoneRecipient, MilestoneUpdatedResponse } from '../../../types/Pos'
import { useIonModal } from '@ionic/react'
import MilestoneRecipientNotificationModal from './MilestoneRecipientNotificationModal'
import Button from '../../../components/Button'
import { setMilestoneOrEventDate } from '../../../api/pos'

type Props = {
  close: (reset?: boolean) => void
  date: Date
  didUpdate: (data: MilestoneUpdatedResponse) => void
  loan: Loan
  milestone: LoanMilestone
}

const MilestoneUpdateConfirmationModal: React.FC<Props> = props => {
  const { close: closeParentModal, date, didUpdate, loan, milestone } = props
  const [dateIsInTheFuture, setDateIsInTheFuture] = useState<boolean>(false)
  const [dateIsInThePast, setDateIsInThePast] = useState<boolean>(false)

  const confirm = async (config: { recipients?: LoanMilestoneRecipient[] } = {}) => {
    try {
      const { data } = await setMilestoneOrEventDate(
        loan,
        {
          id: milestone.id,
          type: 'borrower-milestone',
        },
        format(new Date(date), 'yyyy-MM-d'),
        config
      )

      didUpdate(data)

      closeRecipientModal() && closeParentModal()
    } catch (error) {
      console.log(error)
    }
  }

  const closeRecipientModal = () => {
    dismiss()
  }

  const [present, dismiss] = useIonModal(MilestoneRecipientNotificationModal, {
    recipientList: loan.milestone_recipients,
    close: closeRecipientModal,
    confirm: confirm,
  })

  useEffect(() => {
    let mounted = true

    if (mounted) {
      const comparator = new Date(new Date(date).toDateString())
      const now = new Date(new Date().toDateString())
      setDateIsInTheFuture(isAfter(comparator, now))
      setDateIsInThePast(isBefore(comparator, now))
    }

    return () => {
      mounted = false
    }
  }, [date, setDateIsInTheFuture, setDateIsInThePast])

  return (
    <ModalContent
      title="Are you sure you want to update this milestone?"
      class="ion-padding"
      dismiss={() => closeParentModal(true)}
      theme={{ bordered: true }}
    >
      <CenteredElements>
        <ScheduledUpdateText>
          {dateIsInTheFuture && (
            <Label size="sm" color="info" isWrapped={true}>
              Date is in the future, notification will be
              <br /> scheduled for the morning of the date.
            </Label>
          )}

          {dateIsInThePast && (
            <Label size="sm" color="danger">
              Date is in the past, no notifications will be sent.
            </Label>
          )}
        </ScheduledUpdateText>

        <SpacedContainer spaced={dateIsInThePast || dateIsInTheFuture}>
          <Button size="md" onClick={() => confirm()} color="secondary">
            {' '}
            Update {dateIsInTheFuture ? ' & Schedule Notification' : ''}
          </Button>
        </SpacedContainer>

        {dateIsInTheFuture && (
          <SpacedContainer>
            <Label size="xs" color="gray" onClick={() => present()}>
              {' '}
              Customize Notifications{' '}
            </Label>
          </SpacedContainer>
        )}
      </CenteredElements>
    </ModalContent>
  )
}

const CenteredElements = styled.div`
  text-align: center;
  margin: 0 auto;
`

const SpacedContainer = styled.div`
  margin: ${props => (props.spaced ? '1.25rem 0' : '0')};
`

const ScheduledUpdateText = styled.div`
  text-align: center;
  margin: 0 auto;
`

export default MilestoneUpdateConfirmationModal
