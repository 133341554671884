import React from 'react'
import styled from 'styled-components'
import { IonIcon, IonSlide } from '@ionic/react'
import { rocketOutline } from 'ionicons/icons'

import AppCompletedImgUrl from 'legacy-icons/loan-app-complete.png'

import Label from 'components/Label'
import Button from 'components/Button'
import Layout from '../components/Layout'
import DescriptionLabel from '../components/DescriptionLabel'

interface Props {
  onNext: () => Promise<void>
  onPrev: () => Promise<void>
}

const LaunchMyApp: React.FC<Props> = ({ onNext, onPrev }) => (
  <IonSlide>
    <Layout
      onPrev={onPrev}
      content={
        <>
          <CompletedImg src={AppCompletedImgUrl} alt="Success" />

          <Label style={{ marginTop: '2rem' }} block color="primary" size="2xl" isWrapped>
            Congratulations!
          </Label>

          <DescriptionLabel style={{ marginTop: '2rem' }}>Your app is now ready to be launched</DescriptionLabel>
        </>
      }
      footer={
        <Button color="secondary" onClick={onNext} style={{ marginBottom: '2.5rem' }}>
          <IonIcon slot="start" icon={rocketOutline} /> Launch my App
        </Button>
      }
    />
  </IonSlide>
)

const CompletedImg = styled.img`
  margin: 2rem auto 0 auto;
`

export default LaunchMyApp
