import React from 'react'
import styled from 'styled-components'

import ListItem from './ListItem'
import { ReactComponent as ChevronRightIcon } from 'legacy-icons/chevron-right.svg'
import { useState } from 'react'

const StyledListItem = styled(({ toggleOnContentClick, ...props }) => <ListItem {...props} />)`
  ${props =>
    props.toggleOnContentClick &&
    `
        cursor: pointer;
        user-select: none;
    `}
`

const StyledChevronIcon = styled(({ isOpen, ...rest }) => <ChevronRightIcon {...rest} />)`
  ${props =>
    props.isOpen &&
    `
        transform: rotate(90deg);
    `}
`

const ContentContainer = styled.div`
  margin-top: 1rem;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`

const AccordionItem = ({ children, ...listItemProps }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const EndIconComponent = () => <StyledChevronIcon isOpen={isOpen} />

  return (
    <StyledListItem {...listItemProps} endIcon={EndIconComponent} onClick={toggle}>
      {isOpen && <ContentContainer onClick={listItemProps.toggleOnContentClick && toggle}>{children}</ContentContainer>}
    </StyledListItem>
  )
}

export default AccordionItem
