import React from 'react';
import { StepsProps } from 'intro.js-react';
import { IonItem, IonList, IonListHeader } from '@ionic/react';

const HeaderQuickMenu: React.FC<{
  onHide: () => void;
  isLoanOfficer: boolean;
  isPartner: boolean;
  steps: StepsProps;
  setSteps: (props: Partial<StepsProps>) => void;
  history: any;
}> = ({ onHide, isLoanOfficer, isPartner, steps, setSteps, history }) => {
  const navigateTo = (url: string) => {
    onHide();
    history.push(url);
  };

  // Ionic 5.x workaround for popovers not working on Chrome and therefore Android webviews
  // Patched in Ionic 6.x / 7.x so can be removed when we upgrade
  // https://github.com/ionic-team/ionic-framework/issues/27599#issuecomment-1584711091
  const ref = React.useRef<HTMLIonListElement>(null);

  React.useEffect(() => {
    if (ref?.current?.parentElement?.hasAttribute('popover')) {
      ref.current.parentElement.removeAttribute('popover');
    }
  }, []);

  return (
    <IonList ref={ref}>
      <IonListHeader> Options </IonListHeader>
      {(isPartner || isLoanOfficer) && (
        <IonItem
          button
          onClick={() => {
            setSteps({ enabled: !steps.enabled });
            onHide();
          }}
        >
          {' '}
          Product Tour{' '}
        </IonItem>
      )}
      {isLoanOfficer && (
        <IonItem button onClick={() => navigateTo(`home/support`)}>
          {' '}
          Support{' '}
        </IonItem>
      )}
      <IonItem lines="none" detail={false} button onClick={onHide}>
        {' '}
        Close{' '}
      </IonItem>
    </IonList>
  );
};

export default HeaderQuickMenu;
