import React from 'react'

import ShareButton from './ShareButton'
import { IonRouterLink } from '@ionic/react'
import ShareAppCardContent, { ShareAppCardContentProps } from './ShareAppCardContent'

interface Props extends ShareAppCardContentProps {
  routerLink?: string
}

const ShareAppCard: React.FC<Props> = ({ routerLink, ...props }) =>
  routerLink ? (
    <IonRouterLink routerLink={routerLink} {...props}>
      <ShareAppCardContent {...props} />
    </IonRouterLink>
  ) : (
    <ShareButton render={(open: () => void) => <ShareAppCardContent {...props} onClick={open} />} />
  )

export default ShareAppCard
