import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  IonAlert,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonNote,
  IonPage,
  IonToggle,
  useIonModal,
} from '@ionic/react';
import { TextFieldTypes } from '@ionic/core';
import {
  alertCircleOutline,
  callOutline,
  imageOutline,
  logoFacebook,
  logoLinkedin,
  logoPinterest,
  logoTwitter,
  logoYoutube,
  personCircleOutline,
  phonePortraitOutline,
  readerOutline,
  reorderTwoOutline,
  notificationsOutline,
  notificationsOffOutline,
  cameraOutline,
  imagesOutline,
} from 'ionicons/icons';
import styled from 'styled-components';
import { Dialog } from '@capacitor/dialog';

import Header from 'components/Header';
import ThemeSettings from './components/Settings/ThemeSettings';

import { updateProfile as updateSsoProfile } from 'api/sso';
import { updateProfile as updateLoanzifyProfile } from 'api/loanzify';

import { useAuth } from 'store/Auth';
import { useApplication } from 'store/Application';
import TextEditorModal from '../../components/TextEditorModal';
import useModal from '../../hooks/useModal';

import { PushNotifications } from '@capacitor/push-notifications';

import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraPermissionType, CameraPluginPermissions } from '@capacitor/camera';

const StyledIonContent = styled(IonContent)`
  --padding-top: 20px;
  --padding-bottom: 20px;
  --padding-start: 20px;
  --padding-end: 20px;
`;

interface InitialEditProfileState {
  title: string;
  editing: boolean;
  inputType: TextFieldTypes | 'checkbox' | 'radio' | 'textarea';
  key: string;
  value: string;
  service: string;
  onSave?: (value: string) => Promise<void>;
}

const SettingsPage = () => {
  const [notificationToggle, setNotificationToggle] = useState(false);
  const [cameraToggle, setCameraToggle] = useState(false);
  const [photosToggle, setPhotosToggle] = useState(false);
  let { owner, setOwner, ssoOwner, setSsoOwner } = useApplication();
  const { isPartner, isUser } = useAuth();

  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState<InitialEditProfileState>({
    title: '',
    editing: false,
    inputType: 'text',
    key: '',
    value: '',
    service: '',
    onSave: async (value: string) => {},
  });

  const [present, dismiss] = useIonModal(TextEditorModal, {
    value: editData.value,
    title: editData.title,
    dismiss: () => dismiss(),
    onSave: editData.onSave,
  });

  interface EditProfileProps {
    title: string;
    key: string;
    inputType: TextFieldTypes | 'checkbox' | 'radio' | 'textarea';
    service: string;
    onSave?: (value: string) => Promise<void>;
  }

  const editProfile = ({ title, key, inputType, service }: EditProfileProps) => {
    setEditData(() => ({
      title,
      key,
      value: service === 'sso' ? ssoOwner[key] : owner[key],
      inputType,
      service,
      editing: true,
      onSave: async (value: string) => {
        if (inputType === 'textarea') {
          await updator({
            [key]: value,
          });
        }
      },
    }));

    if (inputType === 'textarea') {
      openTextEditorModal();
    }
  };

  const openTextEditorModal = () => {
    present({});
  };

  const resetProfileEdit = () =>
    setEditData({
      title: '',
      editing: false,
      inputType: 'text',
      key: '',
      value: '',
      service: '',
      onSave: async (value: string) => {},
    });

  const updator = useCallback(
    async data => {
      console.log(data);
      const performSsoUpdate = async data => {
        setOwner({ ...owner, ...(await updateSsoProfile(data)) });
      };

      const performLoanzifyUpdate = async data => {
        setOwner(await updateLoanzifyProfile(data));
      };

      const performUpdate = async (service, data) => {
        if (service === 'sso') {
          await performSsoUpdate(data);
          setSsoOwner({ ...ssoOwner, ...data });
        } else if (service === 'loanzify') {
          await performLoanzifyUpdate(data);
          setOwner({ ...owner, ...data });
        }
      };

      setLoading(true);

      performUpdate(editData?.service, data)
        .catch(error => {
          Dialog.alert({
            title: 'Error',
            message: 'Something went wrong. Please try again later.',
          });

          throw error;
        })
        .finally(() => {
          setLoading(false);
          dismiss();
        });
    },
    [editData?.service]
  );

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.requestPermissions().then(result => {
        console.log('[Push] Permission Result in Settings', result.receive);

        // No permission granted
        if (result.receive === 'denied') {
          setNotificationToggle(false);
        } else if (result.receive === 'granted') {
          setNotificationToggle(true);
        }
      });

      Camera.checkPermissions().then(res => {
        if (res.camera === 'granted') {
          setCameraToggle(true);
        }
        if (res.photos === 'granted' || res.photos === 'limited') {
          setPhotosToggle(true);
        }
      });
    }
  }, []);

  const handleNotificationToggleChange = async (event: CustomEvent) => {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.requestPermissions().then(async result => {
        console.log('[Push] Permission Result in Settings toggle', result.receive);

        if (!event.detail.checked && result.receive === 'granted') {
          // Turning off previously granted PN permissions
          await openNativeSettings();
          history.back();
        }

        if (event.detail.checked && result.receive === 'denied') {
          // Turning on permissions, denied before
          await openNativeSettings();
          history.back();
        }
      });
    } else {
      console.warn('Push Notifications not supported on web');
    }
  };

  const openNativeSettings = async () => {
    switch (Capacitor.getPlatform()) {
      case 'ios':
        await NativeSettings.openIOS({ option: IOSSettings.App });
        break;
      case 'android':
        await NativeSettings.openAndroid({ option: AndroidSettings.ApplicationDetails });
        break;
      case 'web':
        console.warn('Not supported on web');
        break;
    }
  };

  const handleCameraPhotoToggleChange = async (event: CustomEvent, type: CameraPermissionType) => {
    if (Capacitor.isNativePlatform()) {
      const permissions: CameraPluginPermissions = {
        permissions: [type],
      };
      const res = await Camera.requestPermissions(permissions);

      if (type === 'photos' && res.photos === 'denied') {
        await openNativeSettings();
        history.back();
      }
      if (type === 'camera' && res.camera === 'denied') {
        await openNativeSettings();
        history.back();
      }

      if (!event.detail.checked) {
        await openNativeSettings();
      }
    }
  };

  return (
    <IonPage>
      <Header title="Settings" defaultHref="/home" />

      <StyledIonContent>
        <IonListHeader lines="full">
          <IonLabel>App Permissions</IonLabel>
        </IonListHeader>
        <IonList>
          <IonItem>
            <IonIcon slot="start" icon={notificationToggle ? notificationsOutline : notificationsOffOutline} />
            <IonLabel>Push Notifications</IonLabel>
            <IonToggle slot="end" checked={notificationToggle} onIonChange={handleNotificationToggleChange}></IonToggle>
          </IonItem>
          <IonItem>
            <IonIcon slot="start" icon={cameraOutline} />
            <IonLabel>Camera</IonLabel>
            <IonToggle
              slot="end"
              checked={cameraToggle}
              onIonChange={event => handleCameraPhotoToggleChange(event, 'camera')}
            ></IonToggle>
          </IonItem>
          <IonItem>
            <IonIcon slot="start" icon={imagesOutline} />
            <IonLabel>Photo Library</IonLabel>
            <IonToggle
              slot="end"
              checked={photosToggle}
              onIonChange={event => handleCameraPhotoToggleChange(event, 'photos')}
            ></IonToggle>
          </IonItem>
        </IonList>

        {isUser && !isPartner && (
          <>
            <IonListHeader lines="full">
              <IonLabel>Application</IonLabel>
            </IonListHeader>
            <IonList>
              <IonItem routerLink={`/home/settings/photo`}>
                <IonIcon slot="start" icon={personCircleOutline} />
                <IonLabel>Profile Picture</IonLabel>
              </IonItem>

              <IonItem routerLink={`/home/settings/logo`}>
                <IonIcon slot="start" icon={imageOutline} />
                <IonLabel>Company Logo</IonLabel>
              </IonItem>

              <IonItem
                button
                onClick={() =>
                  editProfile({ title: 'About Me', key: 'bio', inputType: 'textarea', service: 'loanzify' })
                }
              >
                <IonIcon slot="start" icon={readerOutline} />
                <IonLabel>About Me</IonLabel>
              </IonItem>

              <IonItem
                button
                onClick={() =>
                  editProfile({
                    title: 'Calculator Disclaimer',
                    key: 'calc_disclaimer',
                    inputType: 'textarea',
                    service: 'loanzify',
                  })
                }
              >
                <IonIcon slot="start" icon={alertCircleOutline} />
                <IonLabel>Calculator Disclaimer</IonLabel>
              </IonItem>
            </IonList>
            <ThemeSettings />
            <IonListHeader lines="full">
              <IonLabel>Account</IonLabel>
            </IonListHeader>
            <IonList>
              <IonItem
                button
                onClick={() =>
                  editProfile({ title: 'First Name', key: 'first_name', inputType: 'text', service: 'sso' })
                }
              >
                <IonIcon slot="start" icon={reorderTwoOutline} />
                <IonLabel>First Name</IonLabel>
                <IonNote slot="end">{ssoOwner.first_name}</IonNote>
              </IonItem>

              <IonItem
                button
                onClick={() => editProfile({ title: 'Last Name', key: 'last_name', inputType: 'text', service: 'sso' })}
              >
                <IonIcon slot="start" icon={reorderTwoOutline} />
                <IonLabel>Last Name</IonLabel>
                <IonNote slot="end">{ssoOwner.last_name}</IonNote>
              </IonItem>

              <IonItem
                button
                onClick={() => editProfile({ title: 'Title', key: 'title', inputType: 'text', service: 'sso' })}
              >
                <IonIcon slot="start" icon={reorderTwoOutline} />
                <IonLabel>Title</IonLabel>
                <IonNote slot="end">{ssoOwner.title}</IonNote>
              </IonItem>

              {!isPartner && (
                <IonItem
                  button
                  onClick={() => editProfile({ title: 'NMLS', key: 'nmls', inputType: 'text', service: 'sso' })}
                >
                  <IonIcon slot="start" icon={reorderTwoOutline} />
                  <IonLabel>NMLS</IonLabel>
                  <IonNote slot="end">{ssoOwner.nmls}</IonNote>
                </IonItem>
              )}

              <IonItem
                button
                onClick={() => editProfile({ title: 'License', key: 'license', inputType: 'text', service: 'sso' })}
              >
                <IonIcon slot="start" icon={reorderTwoOutline} />
                <IonLabel>License</IonLabel>
                <IonNote slot="end">{ssoOwner.license}</IonNote>
              </IonItem>
            </IonList>
            <IonListHeader lines="full">
              <IonLabel>Social</IonLabel>
            </IonListHeader>
            <IonList>
              <IonItem
                button
                onClick={() =>
                  editProfile({ title: 'Facebook URL', key: 'facebook_url', inputType: 'text', service: 'sso' })
                }
              >
                <IonIcon slot="start" icon={logoFacebook} />
                {ssoOwner.facebook_url ? <IonNote>{ssoOwner.facebook_url}</IonNote> : <IonLabel>Facebook</IonLabel>}
              </IonItem>

              <IonItem
                button
                onClick={() =>
                  editProfile({ title: 'Twitter URL', key: 'twitter_url', inputType: 'text', service: 'sso' })
                }
              >
                <IonIcon slot="start" icon={logoTwitter} />
                {ssoOwner.twitter_url ? <IonNote>{ssoOwner.twitter_url}</IonNote> : <IonLabel>Twitter</IonLabel>}
              </IonItem>

              <IonItem
                button
                onClick={() =>
                  editProfile({ title: 'Youtube URL', key: 'youtube_url', inputType: 'text', service: 'sso' })
                }
              >
                <IonIcon slot="start" icon={logoYoutube} />
                {ssoOwner.youtube_url ? <IonNote>{ssoOwner.youtube_url}</IonNote> : <IonLabel>Youtube</IonLabel>}
              </IonItem>

              <IonItem
                button
                onClick={() =>
                  editProfile({ title: 'Pinterest URL', key: 'pinterest_url', inputType: 'text', service: 'sso' })
                }
              >
                <IonIcon slot="start" icon={logoPinterest} />
                {ssoOwner.pinterest_url ? <IonNote>{ssoOwner.pinterest_url}</IonNote> : <IonLabel>Pinterest</IonLabel>}
              </IonItem>

              <IonItem
                button
                onClick={() =>
                  editProfile({ title: 'LinkedIn URL', key: 'linkedin_url', inputType: 'text', service: 'sso' })
                }
              >
                <IonIcon slot="start" icon={logoLinkedin} />
                {ssoOwner.linkedin_url ? <IonNote>{ssoOwner.linkedin_url}</IonNote> : <IonLabel>LinkedIn</IonLabel>}
              </IonItem>
            </IonList>
            <IonListHeader lines="full">
              <IonLabel>Contact</IonLabel>
            </IonListHeader>
            <IonList>
              <IonItem
                button
                onClick={() => editProfile({ title: 'Mobile', key: 'mobile', inputType: 'text', service: 'sso' })}
              >
                <IonIcon slot="start" icon={phonePortraitOutline} />
                <IonLabel>Mobile Phone</IonLabel>
                <IonNote slot="end">{owner.mobile}</IonNote>
              </IonItem>

              <IonItem
                button
                onClick={() => editProfile({ title: 'Phone', key: 'phone', inputType: 'text', service: 'sso' })}
              >
                <IonIcon slot="start" icon={callOutline} />
                <IonLabel>Work Phone</IonLabel>
                <IonNote slot="end">{ssoOwner.phone}</IonNote>
              </IonItem>
            </IonList>
            <IonLoading isOpen={loading} message="Saving..." />
            <IonAlert
              isOpen={editData?.editing && editData?.inputType !== 'textarea'}
              onDidDismiss={resetProfileEdit}
              header={editData?.title}
              inputs={[
                {
                  name: editData?.key,
                  type: editData?.inputType,
                  value: editData?.value,
                  placeholder: `Enter ${editData?.title}`,
                  attributes: {
                    rows: editData?.inputType === 'textarea' ? 10 : undefined,
                  },
                },
              ]}
              buttons={[
                {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'secondary',
                },
                {
                  text: 'Save',
                  handler: updator,
                },
              ]}
            />
          </>
        )}
      </StyledIonContent>
    </IonPage>
  );
};

export default SettingsPage;
