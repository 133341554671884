import * as React from 'react'
import Header from '../../components/Header'
import { IonLoading, IonPage } from '@ionic/react'
import { Chat } from 'stream-chat-react'
import { useStreamChat } from 'store/StreamChat'
import SingleConversation from './components/SingleConversation'
import { useParams } from 'react-router'

const ShowConversation: React.FC = props => {
  const { client, token } = useStreamChat()
  const { conversation } = useParams()

  return (
    <IonPage>
      <Header title="Messages" defaultHref="/conversations" />
      <IonLoading isOpen={!token && !client} />
      {client && token && (
        <Chat client={client}>
          <SingleConversation conversation={conversation} />
        </Chat>
      )}
    </IonPage>
  )
}

export default ShowConversation
