import React from 'react'
import LoanCardModal from './LoanCardModal'
import FormInput from './form/Input'
import TextField from './form/TextField'
import Button from '../Button'
import * as yup from 'yup'
import { Formik, Form } from 'formik'

import { createLoanPreApprovalLetter } from '../../api/pos'
import { Toast } from '@capacitor/toast'
import FormSelect from './form/Select'
import { titleCase } from '../../utils/formatters'
import { usePreApprovals } from '../../store/usePreApprovals'
import styled from 'styled-components'

const StyledErrorMessage = styled.div`
  color: var(--color-danger);
  font-size: 20px;
  text-align: center;
  margin: 1rem 0;
`

const CreatePreApprovalLetter = ({
  loan,
  isOpen,
  onCloseClick,
  onCreate = () => {},
}: {
  loan: any
  isOpen: any
  onCloseClick: any
  onCreate?: any
}) => {
  const { letterParams } = usePreApprovals()

  const {
    minDownPayment = 0,
    maxDownPayment = 1000000,
    maxSalePrice,
  }: {
    minDownPayment: string | number
    maxDownPayment: string | number
    maxSalePrice: string | number
  } = letterParams

  const schema = yup.object().shape({
    down_payment: yup
      .number()
      .typeError('The Down Payment field can only be a number!')
      .required('The Down Payment field is required!')
      .test({
        name: 'min',
        test: (value, ctx) => {
          // do not validate this field without the sibling
          if (!ctx.parent.requested_amount) {
            return true
          }

          // fallback in the event that they dictated a minimum down payment is not required
          if (minDownPayment === 0) {
            return true
          }

          //express the desired down_payment amount as a whole number of the requested amount
          const downPaymentAsPercentOfRequestedAmount = (100 * value) / ctx.parent.requested_amount
          const minimumDownPaymentAsPercentOfRequestedAmount =
            (parseFloat(minDownPayment as string) / 100) * ctx.parent.requested_amount

          //evaluate the supplied down payment as a percentage of the requested amount and ensure it's greater than
          // the initially required down payment % amount
          if (downPaymentAsPercentOfRequestedAmount <= parseFloat(minDownPayment as string)) {
            return ctx.createError({
              message: `The minimum down payment must be greater than $${Intl.NumberFormat('en-us').format(
                minimumDownPaymentAsPercentOfRequestedAmount
              )}`,
              path: 'down_payment',
            })
          }

          return true
        },
      })
      .max(
        parseFloat(maxDownPayment as string),
        `The maximum down payment cannot be greater than $${Intl.NumberFormat('en-US').format(
          parseFloat(maxDownPayment as string)
        )}`
      ),
    requested_amount: yup
      .number()
      .typeError('The Requested Amount field can only be a number!')
      .required('The Requested Amount field is required!')
      .max(
        parseFloat(maxSalePrice as string),
        `The requested amount cannot exceed $${Intl.NumberFormat('en-US').format(parseFloat(maxSalePrice as string))}`
      ),
  })

  const createLetter = (values: Record<string, any>, setSubmitting: Function) => {
    setSubmitting(true)

    const { down_payment, requested_amount, address_street_1, address_city, address_state, address_zipcode, note } =
      values

    const d = new Date(),
      mm = d.getMonth(),
      dd = d.getDate(),
      yyyy = d.getFullYear(),
      name = `${mm}/${dd}/${yyyy} Pre-Approval`

    createLoanPreApprovalLetter(loan.id, {
      name,
      downPayment: down_payment,
      requested_amount: requested_amount,
      pre_approval_limit: null,
      address_street_1: address_street_1,
      address_city: address_city,
      address_state: address_state,
      address_zipcode: address_zipcode,
      note: note,
    })
      .then((data: any) => {
        onCreate()
      })
      .catch(({ response: { data } }) => {
        if (data && data.message) {
          Toast.show({ text: `${data.message}` })
        } else {
          Toast.show({ text: 'There was an error. Try again' })
        }
      })
      .finally(() => setSubmitting(false))
  }

  const mappedStates = () => {
    return Object.keys((window as any).stateAndCounties.states || {}).map(abbrev => {
      return {
        value: titleCase((window as any).stateAndCounties.states[abbrev].name),
        title: titleCase((window as any).stateAndCounties.states[abbrev].name),
      }
    })
  }

  return (
    <LoanCardModal isOpen={isOpen} onCloseClick={() => onCloseClick()} title="New Pre-Approval Letter">
      <Formik
        initialValues={{
          requested_amount: null,
          down_payment: null,
          address: null,
          address_city: null,
          address_state: null,
          address_zipcode: null,
          note: null,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await createLetter(values, setSubmitting)
        }}
        validateOnChange={true}
        validationSchema={schema}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <FormInput
              required
              disabled={isSubmitting}
              name={'requested_amount'}
              // @ts-ignore
              error={errors.requested_amount && touched.requested_amount && errors.requested_amount}
              value={values.requested_amount}
              // @ts-ignore
              onIonChange={handleChange}
              // @ts-ignore
              onIonBlur={handleBlur}
              label="Requested Amount"
              placeholder="$0,000,000.00"
            />
            <FormInput
              required
              disabled={isSubmitting}
              name={'down_payment'}
              // @ts-ignore
              error={errors.down_payment && touched.down_payment && errors.down_payment}
              value={values.down_payment}
              // @ts-ignore
              onIonChange={handleChange}
              // @ts-ignore
              onIonBlur={handleBlur}
              label="Down Payment Amount"
              placeholder="$0,000,000.00"
            />
            <FormInput
              disabled={isSubmitting}
              name={'address'}
              // @ts-ignore
              error={errors.address && touched.address && errors.address}
              value={values.address}
              // @ts-ignore
              onIonChange={handleChange}
              // @ts-ignore
              onIonBlur={handleBlur}
              label="Property Address (optional)"
              placeholder="Enter Address"
            />
            <FormInput
              disabled={isSubmitting}
              name={'address_city'}
              // @ts-ignore
              error={errors.address_city && touched.address_city && errors.address_city}
              value={values.address_city}
              // @ts-ignore
              onIonChange={handleChange}
              // @ts-ignore
              onIonBlur={handleBlur}
              label="City (optional)"
              placeholder="Enter City"
            />
            <FormSelect
              disabled={isSubmitting}
              options={mappedStates()}
              // @ts-ignore
              name={'address_state'}
              error={errors.address_state && touched.address_state && errors.address_state}
              value={values.address_state}
              onNativeChange={handleChange}
              label="State (optional)"
              placeholder="Enter State"
            />
            <FormInput
              disabled={isSubmitting}
              name={'address_zipcode'}
              // @ts-ignore
              error={errors.address_zipcode && touched.address_zipcode && errors.address_zipcode}
              value={values.address_zipcode}
              // @ts-ignore
              onIonChange={handleChange}
              // @ts-ignore
              onIonBlur={handleBlur}
              label="Enter Zipcode (optional)"
              placeholder="Enter Zipcode"
            />
            <TextField
              name={'notes'}
              disabled={isSubmitting}
              onValChange={handleChange}
              onIonBlur={handleBlur}
              value={values.note}
              label="Note (optional)"
            />
            {isValid && (
              <Button disabled={isSubmitting} center color="secondary" type={'submit'}>
                Create Letter
              </Button>
            )}

            {!isValid && Object.keys(touched).length > 0 && (
              <StyledErrorMessage>Please correct the errors in your form before submitting.</StyledErrorMessage>
            )}
          </Form>
        )}
      </Formik>
    </LoanCardModal>
  )
}

export default CreatePreApprovalLetter
