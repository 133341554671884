import * as React from 'react'
import { createStreamChatToken } from '../api/pos'
import { useContext, useEffect, useState } from 'react'
import { StreamChat } from 'stream-chat'
import { User } from '../types/Pos'
import usePersistentState from '../hooks/usePersistentState'

const StreamChatContext = React.createContext({} as StreamChatState)

type StreamChatState = {
  client: null | StreamChat
  token: string | null
  connect: (posUser: User) => void
  createToken: () => Promise<void>
  setChatUser: (user: any) => void
  unreadCounts: UnreadMessages
  setUnreadCounts: (prevState: Record<'current' | 'previous', number>) => void
  disconnect: () => void
}

type UnreadMessages = Record<'current' | 'previous', unknown | number>

const initialUnreadCounts: UnreadMessages & { channels: any[] } = {
  channels: [],
  current: 0,
  previous: 0,
}

export const StreamChatProvider: React.FC = props => {
  const [client, setClient] = useState<StreamChat | null>(null)
  const [token, setToken] = useState<string | null>(null)
  const [chatUser, setChatUser] = useState()
  const [unreadCounts, setUnreadCounts] = usePersistentState<UnreadMessages>('unread_messages', initialUnreadCounts)

  const connect = async (posUser: User) => {
    try {
      let client = StreamChat.getInstance(process.env.REACT_APP_STREAM_CHAT_KEY as string)

      if (!client.user || client.user.id !== `${posUser.id}`) {
        await client.connectUser(
          {
            id: String(posUser.id),
          },
          token
        )
      }

      setClient(client)
    } catch (err) {
      console.log(err)
    }
  }

  const disconnect = async (timeout?: number) => {
    const instance = StreamChat.getInstance(process.env.REACT_APP_STREAM_CHAT_KEY as string)

    if (instance) {
      await instance.disconnectUser(timeout)
    }

    return Promise.resolve()
  }

  const createToken = async () => {
    const token = await createStreamChatToken()

    setToken(token)
    return Promise.resolve()
  }

  const contextValue: StreamChatState = {
    client,
    token,
    connect,
    disconnect,
    createToken,
    setChatUser,
    unreadCounts,
    setUnreadCounts,
  }

  useEffect(() => {
    let mounted = true

    if (mounted && chatUser && !client) {
      connect(chatUser)
    }

    return () => {
      mounted = false
    }
  }, [chatUser, token])

  useEffect(() => {
    let mounted = true

    if (mounted && client) {
      client.on(event => {
        if (event.type === 'message.new' || event.type === 'notification.mark_read') {
          setUnreadCounts((prevState: UnreadMessages) => ({
            previous: prevState.current,
            current: event.unread_count || 0,
          }))
        }
      })

      setUnreadCounts((prevState: UnreadMessages) => ({
        previous: prevState.previous,
        current: client.user?.unread_count || 0,
      }))
    }

    return () => {
      mounted = false
    }
  }, [client])

  return <StreamChatContext.Provider {...props} value={contextValue} />
}

export const useStreamChat = () => useContext(StreamChatContext)
