import React from 'react';
import styled from 'styled-components/macro';
import { IonCol, IonGrid, IonRow } from '@ionic/react';

import AvatarAlt from 'components/AvatarAlt';
import ProfileInfoAlt from './ProfileInfoAlt';
import { User } from 'types/Sso';

const ProfileContainer = styled.div`
  padding-top: 93px;
`;

const TopCurve = styled.div`
  height: 1rem;
  background: white;
  border-bottom-left-radius: 50% 1rem;
  border-bottom-right-radius: 50% 1rem;

  box-sizing: content-box;
  margin: 0 -1rem;
  padding: 0 1rem;
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`;

const ContentCurve = styled.div<{ isDefault: boolean }>`
  ${props =>
    props.isDefault
      ? `
    background: linear-gradient(332.19deg,
      #D03555 31.64%,
      #D03555 32.38%,
      #E21B52 63.68%
    );
  `
      : `
    background: var(--color-primary);
  `}
  border-bottom-left-radius: 50% 1rem;
  border-bottom-right-radius: 50% 1rem;
  --ion-grid-column-padding: 0.5rem;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;
const StyledIonGrid = styled(IonGrid)`
  padding: 0;
  position: relative;
`;

const StyledIonRow = styled(IonRow)`
  padding: 0 1rem;
`;

const StyledIonCol = styled(IonCol)`
  margin-top: 28px;
`;
interface ProfileAltProps {
  owner: User;
  license: string | null;
  nmls: string | null;
  isDefault: boolean;
}
const ProfileAlt = ({ owner, license, nmls, isDefault }: ProfileAltProps) => {
  return (
    <StyledIonGrid>
      <TopCurve />
      <ContentCurve isDefault={isDefault}></ContentCurve>
      <StyledIonRow>
        <StyledIonCol size={'12'}>
          <ProfileContainer>
            <AvatarAlt size="lg" />
            <ProfileInfoAlt owner={owner} license={license} nmls={nmls} />
          </ProfileContainer>
        </StyledIonCol>
      </StyledIonRow>
    </StyledIonGrid>
  );
};

export default ProfileAlt;
