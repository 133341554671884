import React, { createContext, useState, useEffect, useContext } from 'react'
import Storage from 'store/Storage'

const OnboardingContext = createContext({
  email: '',
  setEmail: () => {},

  firstName: '',
  setFirstName: () => {},

  lastName: '',
  setLastName: () => {},

  password: '',
  setPassword: () => {},

  role: '',
  setRole: () => {},

  trial: false,
  setTrial: () => {},

  provider: '',
  setProvider: () => {},

  redirect: null,
  setRedirect: redirect => {},

  reset: () => {},
})

export const OnboardingProvider = props => {
  const defaultValues = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    role: '',
    trial: false,
    provider: '',
  }

  const [details, setDetails] = useState(defaultValues)
  const [setup, setSetup] = useState(false)

  useEffect(() => {
    Storage.get('onboarding:details')
      .then(value => value && setDetails(value))
      .finally(() => setSetup(true))
  }, [])

  useEffect(() => {
    setup && Storage.set('onboarding:details', details)
  }, [details])

  const update = key => value => {
    setDetails(details => ({ ...details, [key]: value }))
  }

  const reset = () => {
    setDetails(defaultValues)
  }

  return (
    <OnboardingContext.Provider
      {...props}
      value={{
        email: details.email,
        setEmail: update('email'),

        firstName: details.firstName,
        setFirstName: update('firstName'),

        lastName: details.lastName,
        setLastName: update('lastName'),

        phone: details.phone,
        setPhone: update('phone'),

        role: details.role,
        setRole: update('role'),

        trial: details.trial,
        setTrial: update('trial'),

        provider: details.provider,
        setProvider: update('provider'),

        redirect: details.redirect,
        setRedirect: update('redirect'),

        password: details.password,
        setPassword: update('password'),

        reset,
        setDetails,
      }}
    />
  )
}

export const useOnboarding = () => useContext(OnboardingContext)
