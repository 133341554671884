import React, { useState } from 'react'
import { IonLoading, IonSlide } from '@ionic/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { useApplication } from 'store/Application'
import { updateProfile as updateLoanzifyProfile } from 'api/loanzify'
import { updateProfile as updateSsoProfile } from 'api/sso'

import Layout from '../components/Layout'
import FormInput from 'pages/auth/components/FormInput'
import ActionButtons from '../components/ActionButtons'
import DescriptionLabel from '../components/DescriptionLabel'

const schema = yup.object().shape({
  title: yup.string(),
  nmls: yup.string(),
  zipcode: yup.string(),
})

interface Props {
  onNext: () => Promise<void>
  onPrev: () => Promise<void>
  progress: number
}

const UpdateProfileDetails: React.FC<Props> = ({ progress, onNext, onPrev }) => {
  let { owner, setOwner, ssoOwner, setSsoOwner } = useApplication()
  const [loading, setLoading] = useState(false)

  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: owner.title,
      nmls: owner.nmls,
      zipcode: owner.zip,
    },
  })

  const submit = async (data: Record<string, any>) => {
    const performSsoUpdate = async (data: Record<string, any>) => {
      setOwner({ ...owner, ...(await updateSsoProfile(data)) })
    }

    const performLoanzifyUpdate = async (data: Record<string, any>) => {
      setOwner(await updateLoanzifyProfile(data))
    }

    setLoading(true)

    await performLoanzifyUpdate({
      ...data,
      zip: data.zip_code,
    })
    setOwner({ ...owner, ...data, zip: data.zip_code })

    await performSsoUpdate(data)
    setSsoOwner({ ...ssoOwner, ...data })

    setLoading(false)

    onNext()
  }

  return (
    <IonSlide>
      <form onSubmit={handleSubmit(submit)}>
        <Layout
          progress={progress}
          onPrev={onPrev}
          content={
            <>
              <IonLoading isOpen={loading} message="Saving..." />

              <DescriptionLabel style={{ margin: '2.5rem 0' }}>Add more details to your profile</DescriptionLabel>

              <FormInput
                ref={register}
                error={errors.title?.message}
                label="Your Title"
                name="title"
                type="text"
                placeholder="Senior Mortgage Advisor"
                labelProps={{ style: { textAlign: 'left' } }}
              />

              <FormInput
                ref={register}
                error={errors.nmls?.message}
                label="NMLS"
                name="nmls"
                type="text"
                placeholder="12345"
                labelProps={{ style: { textAlign: 'left' } }}
              />

              <FormInput
                ref={register}
                error={errors.zipcode?.message}
                label="Target market zipcode"
                name="zip_code"
                type="text"
                placeholder="92685"
                labelProps={{ style: { textAlign: 'left' } }}
              />
            </>
          }
          footer={<ActionButtons onSkip={onNext} />}
        />
      </form>
    </IonSlide>
  )
}

export default UpdateProfileDetails
