import React, { useEffect, useRef, useState } from 'react'
import { UserResponse } from 'stream-chat'
import ConversationAvatar from './ConversationAvatar'
import { formatDistanceToNow, parseISO } from 'date-fns'
import styled from 'styled-components'

import type { ChannelPreviewUIComponentProps } from 'stream-chat-react'

import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from 'stream-chat-react/dist/types'
import { useHistory } from 'react-router'
import { useStreamChat } from '../../../store/StreamChat'

type ChannelMemberBorrower = {
  pos_role?: string
}

const UnMemoizedChannelPreviewMessenger = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
  props: ChannelPreviewUIComponentProps<At, Ch, Co, Ev, Me, Re, Us>
) => {
  const { active, channel, displayImage, displayTitle, latestMessage, unread } = props

  const { client } = useStreamChat()
  const history = useHistory()
  const [humanReadableDuration, setHumanReadableDuration] = useState<string | null>(null)
  const [userIsTyping, setUserIsTyping] = useState<string | null>(null)

  const {
    data: { last_message_at },
    state: { members },
  } = channel

  const borrower: UserResponse<ChannelMemberBorrower> = Object.keys(members).reduce((carry: any, mid) => {
    const { user }: UserResponse<ChannelMemberBorrower> = members[mid]

    if (user && user.pos_role && user.pos_role === 'borrower') {
      carry = user
    }

    return carry
  }, {})

  const borrowerNameForListItem = borrower ? borrower.name : displayTitle

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null)

  const activeClass = active ? 'str-chat__channel-preview-messenger--active' : ''
  const unreadClass = unread && unread >= 1 ? 'str-chat__channel-preview-messenger--unread' : ''
  const avatarName = borrowerNameForListItem

  const onSelectChannel = () => {
    history.push(`/conversations/${channel.id}`)
  }

  channel.on(event => {
    // When a user is not me is typing...
    if (event.type === 'typing.start' && event.user.id !== client.user.id) {
      setUserIsTyping(`${event.user.name} is typing...`)
    }

    if (event.type === 'typing.stop' && event.user.id !== client.user.id) {
      setUserIsTyping(null)
    }
  })

  useEffect(() => {
    setHumanReadableDuration(formatDistanceToNow(parseISO(last_message_at)).replace('about', ''))
  }, [last_message_at])

  return (
    <button
      className={`str-chat__channel-preview-messenger ${unreadClass} ${activeClass}`}
      data-testid="channel-preview-button"
      onClick={onSelectChannel}
      ref={channelPreviewButton}
    >
      <div className="str-chat__channel-preview-messenger--left">
        <ConversationAvatar image={displayImage} name={avatarName} size={40} />
      </div>
      <div className="str-chat__channel-preview-messenger--middle">
        <div className="str-chat__channel-preview-messenger--name">
          <span>{borrowerNameForListItem}</span>
        </div>
        <div className="str-chat__channel-preview-messenger--last-message">
          {userIsTyping ? userIsTyping : latestMessage}
          <div className={'str-chat__channel-preview-messenger--last-message-date'}>
            <HumanReadableSeparator /> {humanReadableDuration}
          </div>
          {unread >= 1 && <UnreadIndicator />}
        </div>
      </div>
    </button>
  )
}

const HumanReadableSeparator = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 9999px;
  background-color: var(--color-primary);
  margin: 0 0.25rem;
`

const UnreadIndicator = styled.div`
  height: 12px;
  width: 12px;
  background-color: var(--primary-color);
  border-radius: 9999px;
  flex: 0 0 12px;
`

/**
 * Used as preview component for channel item in [ChannelList](#channellist) component.
 * Its best suited for messenger type chat.
 */
export default React.memo(UnMemoizedChannelPreviewMessenger) as typeof UnMemoizedChannelPreviewMessenger
