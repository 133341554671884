import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';

import Label, { LabelProps } from 'components/Label';
import { IonIcon } from '@ionic/react';
import { eye as eyeIcon, eyeOff as eyeOffIcon } from 'ionicons/icons';

const StyledComponentLabel = styled(Label)`
  white-space: pre-wrap;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  .error {
    padding: 5px;
    color: var(--color-red);
  }
`;

const StyledErrorLabel = styled(Label)`
  white-space: normal;
`;

const StyledInputWrapper = styled.div`
  position: relative;

  margin-top: 0.5rem;
`;

const StyledInput = styled.input`
  width: 100%;

  padding: 1rem 2rem 1rem 0.5rem;

  background: #fff;

  color: var(--color-gray);
  border: var(--border);
  border-radius: 0.5rem;

  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;

  ::placeholder {
    color: var(--color-gray-lighter);
    opacity: 1;
  }
`;

const TogglePasswordView = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  labelProps?: LabelProps;
}

const FormInput = forwardRef<HTMLInputElement, Props>(({ label, error, labelProps, ...props }, ref) => {
  const [inputType, setInputType] = useState(props.type || 'text');

  const toggleInputType = () => {
    setInputType(prevState => (prevState === 'text' ? 'password' : 'text'));
  };

  return (
    <StyledLabel>
      <StyledComponentLabel {...labelProps} secondary color="gray" size="sm">
        {label}
      </StyledComponentLabel>

      <StyledInputWrapper>
        <StyledInput
          {...props}
          type={inputType}
          ref={ref}
          style={
            {
              '--border': error ? '1px solid var(--color-red)' : 'none',
            } as React.CSSProperties
          }
        />
        {props.type && props.type === 'password' && (
          <TogglePasswordView onClick={toggleInputType}>
            {inputType === 'password' ? (
              <IonIcon icon={eyeIcon} style={{ fontSize: '20px' }}></IonIcon>
            ) : (
              <IonIcon icon={eyeOffIcon} style={{ fontSize: '20px' }}></IonIcon>
            )}
          </TogglePasswordView>
        )}
      </StyledInputWrapper>

      {error && (
        <StyledErrorLabel className="error" secondary size="xs" color="danger">
          {error}
        </StyledErrorLabel>
      )}
    </StyledLabel>
  );
});

FormInput.displayName = 'FormInput';

export default FormInput;
