import React, { useState } from 'react'

import ShortMemberCard from './ShortMemberCard'
import ExpandedMemberCard from './ExpandedMemberCard'

import { TeamMember } from 'types/Pos'

interface Props {
  member: TeamMember
}

const MemberCard: React.FC<Props> = props => {
  const [isExpanded, setIsExpanded] = useState(false)

  return isExpanded ? (
    <ExpandedMemberCard {...props} onClose={() => setIsExpanded(false)} />
  ) : (
    <ShortMemberCard {...props} onExpanded={() => setIsExpanded(true)} />
  )
}

export default MemberCard
