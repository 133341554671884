import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Line } from 'react-chartjs-2'

import { getColorsFromStyle } from 'utils/theme'
const ChartContainer = styled.div`
  position: relative;
  height: 120px;
`

const LineChart = ({ data, labels, colorNames, showLegend = true }) => {
  const colors = useMemo(() => getColorsFromStyle(colorNames), [colorNames])

  const chartOptions = useMemo(
    () => ({
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: true,
              drawTicks: true,
            },
            ticks: {
              display: true,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawOnChartArea: true,
              drawTicks: true,
            },
            ticks: {
              min: 0,
              padding: 8,
              beginAtZero: true,
              callback: function (value) {
                if (value % 1 === 0) {
                  return value
                }
              },
            },
          },
        ],
      },
    }),
    []
  )
  const chartData = {
    labels,
    datasets: [
      {
        backgroundColor: colors[0],
        borderColor: colors[1] || colors[0],
        fill: false,
        data,
      },
    ],
  }

  return (
    <>
      <ChartContainer>
        <Line data={chartData} options={chartOptions} />
      </ChartContainer>
    </>
  )
}

export default LineChart
