import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IonPage, IonContent, IonLoading } from '@ionic/react'

import Header from 'components/Header'
import { useAuth } from 'store/Auth'
import { loadLhpFormsConfig } from 'api/pos'
import { useApplication } from '../../store/Application'
import { get } from 'lodash'
import { useSession } from '../../store/Session'

const ApplicationFormPage = () => {
  const history = useHistory()
  const { isPartner, loan, user } = useAuth()
  const { show_selfie_view } = useSession()
  const { application, user1, user2 } = useApplication()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if ((!loan || !loan.id) && !show_selfie_view && !isPartner) {
      return history.push('/home')
    }

    if (!show_selfie_view && !isPartner) {
      const setupForm = (form, formOptions) => {
        let params = {
          borrower_firstname: user.first_name,
          borrower_lastname: user.last_name,
          borrower_phone_cell: user.phone,
          borrower_working_with_loan_officer: !!get(formOptions, 'loan_officer_id', false),
          show_loan_officer_select: !get(formOptions, 'loan_officer_id', false),
          loan_officer_id: !!get(formOptions, 'null', false),
        }

        if (user2 && user2.account_type_id === 2 && !!get(formOptions, 'partner_realtor_id', false)) {
          params = {
            ...params,
            partner_realtor_id: !!get(formOptions, 'partner_realtor_id', false),
            hide_partner_steps: 1,
          }
        }

        form.setModel(params)

        form.on('completed', () => {
          history.push(`/home`)
        })

        form.on('authorized', () => {
          history.push(`/consumer/tasks`)
        })

        form.on('finish-later', () => {
          // TODO
          history.push(`/consumer-tasks`)
        })

        // Other setup stuff goes here...
      }

      loadLhpFormsConfig(loan.id)
        .then(options => {
          let formParams = {
            ...options,
            partner_realtor_id: application?.user2?.id,
            hide_partner_steps: application?.user2?.id ? 1 : 0,
            account: loan.lhp_forms_account_slug || 'default',
          }

          if (history.location.search.indexOf('authorization_mode') > -1) {
            formParams = {
              ...formParams,
              authorization_mode: true,
            }
          }

          window.LhpForms.create(formParams)
            .mount('#form-builder')
            .then(form => {
              console.log('what about here?')
              setupForm(form, options)
            })
            .catch(err => {
              console.log('we get here?')
              console.warn(err)
            })
            .finally(() => setLoading(false))
        })
        .catch(err => console.warn(err))
    }

    if (show_selfie_view || isPartner) {
      window.LhpForms.create({
        token: '123',
        schema: 1003,
        loan_officer_id: user1?.user_id,
        account_id: user1?.sso_user?.admin?.id,
        partner_realtor_id: application?.user2?.id,
        account: loan?.lhp_forms_account_slug || 'default',
      })
        .mount('#form-builder')
        .then(() => setLoading(false))
    }
  }, [])

  return (
    <IonPage>
      <Header title="Application" />
      <IonContent>
        <IonLoading isOpen={loading} message="Retrieving your application..." />

        <div id="form-builder" frameBorder="0" style={{ width: '100%', height: '100%' }}></div>
      </IonContent>
    </IonPage>
  )
}

export default ApplicationFormPage
