import React, { createContext, useContext, useState } from 'react'

import { Content, Features } from 'types/Application'
import { getApplicationContent, getApplicationFeatures } from 'api/loanzify'

import { useSession } from './Session'

interface ContentState {
  content?: Content
  features?: Features
  isLoaded: boolean
  loadIfNotLoaded: () => Promise<void>
}

const ContentContext = createContext({} as ContentState)

export const ContentProvider: React.FC = props => {
  const { application_id } = useSession()

  const [state, setState] = useState<ContentState>({
    isLoaded: false,
    loadIfNotLoaded: async () => {
      if (state.isLoaded) {
        return
      }

      const [features, content]: [Features, Content] = await Promise.all([
        getApplicationFeatures(application_id),
        getApplicationContent(application_id),
      ])

      /**
       * Here we remove the "Blog" item from the features object
       * if there are no blog posts. Ideally, this filtering
       * should happen on the server but to keep it simple
       * we do it here for now.
       */
      if (!content.blog) {
        features.mortgageguide = features.mortgageguide.filter(guide => guide.name !== 'blog')
      }

      setState(state => ({ ...state, isLoaded: true, features, content }))
    },
  })

  return <ContentContext.Provider {...props} value={state} />
}

export const useContent = () => useContext(ContentContext)
