import React from 'react'
import styled from 'styled-components'
import { IonSpinner } from '@ionic/react'

import Label from 'components/Label'
import Button from 'components/Button'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  image: string
  title: string
  isLoading: boolean
}

const ExportLoanButton: React.FC<Props> = ({ image, title, isLoading, ...props }) => (
  <Wrapper {...props}>
    <Image src={image} />

    <Label center size="sm">
      {title}
    </Label>

    <StyledButton center color="secondary" size="xs">
      {isLoading ? <IonSpinner name="dots" /> : 'Download'}
    </StyledButton>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  width: 50%;
  margin-bottom: 1rem;
`

const StyledButton = styled(Button)`
  margin: 0.5rem auto;
`

const Image = styled.img`
  margin: 0 auto;
  margin-bottom: 0.5rem;
  display: flex;
`

export default ExportLoanButton
