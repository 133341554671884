import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonPage,
  IonButton,
} from '@ionic/react'
import React from 'react'
import result from './items'
import Header from 'components/Header'

const MilestoneListPage = () => (
  <IonPage>
    <Header title="Application" />

    <IonContent>
      {result.milestones.map(milestone => (
        <IonCard key={milestone.id}>
          <img
            style={{
              display: 'block',
              margin: '0 auto',
              width: '50%',
              padding: '10px 0',
            }}
            src={milestone.img}
            alt={milestone.title}
          />
          <IonCardHeader>
            <IonCardSubtitle>{milestone.completed ? 'Completed' : 'Pending'}</IonCardSubtitle>
            <IonCardTitle>{milestone.title}</IonCardTitle>
          </IonCardHeader>
          {milestone.next_steps.map(step => (
            <IonCardContent key={step.title}>
              <div dangerouslySetInnerHTML={{ __html: step.explanation_1 }}></div>
            </IonCardContent>
          ))}
          {milestone.show && (
            <div style={{ textAlign: 'center', padding: '1rem 2rem' }}>
              <IonButton href="/application/form" expand="block" strong={true} color="primary">
                Complete Application
              </IonButton>
            </div>
          )}
        </IonCard>
      ))}
    </IonContent>
  </IonPage>
)

export default MilestoneListPage
