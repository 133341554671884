import React, { createContext, useContext, useState } from 'react'
import { getConsumers } from '../api/sso'
import { ConsumerPagination, ConsumerState, FetchConsumerPayload } from '../types/Consumer'
import { useApplication } from './Application'
import { getSSOUsersInfoByConsumer } from '../api/pos'
import { Consumer } from '../types/Sso'
import { getInstallations } from '../api/loanzify'

export const ConsumersContext = createContext({} as ConsumerState)

export const ConsumersProvider: React.FC = props => {
  const { hasPos } = useApplication()

  const [consumers, setConsumers] = useState<Consumer[]>([])

  const [pagination, setConsumerPagination] = useState<ConsumerPagination>({
    page: 1,
    perPage: 10,
    search: undefined,
  })

  const clearConsumers = () => {
    return new Promise(resolve => {
      setConsumers([])

      resolve({
        status: 'OK',
        message: 'Cleared consumers',
      })
    })
  }

  const fetchConsumers = async (payload?: FetchConsumerPayload) => {
    try {
      const request: ConsumerPagination = {
        page: payload?.page || pagination.page,
        perPage: payload?.perPage || pagination.perPage,
        search: payload?.search || pagination.search,
      }

      let consumers: Consumer[] = await getConsumers(request)

      let installations: any[] = await getInstallations({
        ids: consumers.map(consumer => consumer.id),
      })

      if (installations) {
        consumers = consumers.map(consumer => {
          return {
            ...consumer,
            install: installations.find(install => install.sso_consumer_id === consumer.pos_consumer_id),
          }
        })
      }

      if (hasPos) {
        let sso_consumer_ids = consumers
          .filter(consumer => {
            return !!consumer.pos_consumer_id
          })
          .map(consumer => consumer.pos_consumer_id)

        if (sso_consumer_ids.length) {
          const response = await getSSOUsersInfoByConsumer({ sso_consumer_ids })

          if (response?.sso_consumer_ids?.length) {
            consumers = consumers.map(consumer => {
              const loan_info = response.sso_consumer_ids.find(
                ({ sso_consumer_id }: { sso_consumer_id: number }) => sso_consumer_id === consumer.pos_consumer_id
              )

              return {
                loan_info,
                ...consumer,
              }
            })
          }
        }
      }

      setConsumers((prevConsumers: any[]) => {
        return payload?.append ? [...prevConsumers, ...consumers] : consumers
      })

      setConsumerPagination((prevState: ConsumerPagination) => ({
        ...prevState,
        page: prevState.page + 1,
      }))

      return Promise.resolve(consumers)
    } catch (err) {
      console.warn(err)

      return Promise.reject(err)
    }
  }

  const findConsumer = (match: string | number, field: string) => {
    return consumers.find(consumer => {
      return consumer[field] === match
    })
  }

  const value: ConsumerState = {
    clearConsumers,
    fetchConsumers,
    findConsumer,
    setConsumerPagination,
    consumers,
    pagination,
  }

  return <ConsumersContext.Provider {...props} value={value} />
}

export const useConsumers = () => useContext(ConsumersContext)
