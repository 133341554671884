import React from 'react'
import { IonPage, IonContent } from '@ionic/react'

import List from 'components/List'
import Header from 'components/Header'
import ListItem from 'components/ListItem'

import { useContent } from 'store/Content'

import { ReactComponent as FhaIcon } from 'legacy-icons/loan-programs/fha.svg'
import { ReactComponent as ConventionalIcon } from 'legacy-icons/loan-programs/conventional.svg'
import { ReactComponent as VaIcon } from 'legacy-icons/loan-programs/va.svg'
import { ReactComponent as UsdaIcon } from 'legacy-icons/loan-programs/usda.svg'
import { ReactComponent as JumboIcon } from 'legacy-icons/loan-programs/jumbo.svg'

const ICONS = {
  FHA: FhaIcon,
  Conventional: ConventionalIcon,
  VA: VaIcon,
  USDA: UsdaIcon,
  Jumbo: JumboIcon,
}

const LoanProgramsPage = () => {
  const { content } = useContent()

  return (
    <IonPage>
      <Header title="Loan Programs" back defaultHref="/content" />

      <IonContent>
        <List>
          {content.loanPrograms.map(({ id, name }) => (
            <ListItem startIcon={ICONS[name] || FhaIcon} key={id} title={name} routerLink={`/content/programs/${id}`} />
          ))}
        </List>
      </IonContent>
    </IonPage>
  )
}

export default LoanProgramsPage
