const placeholder = '/public/images/avatar_default.jpg'

export const unsetPlaceholderUrls = application => {
  unset(application.user1)
  unset(application.user2)
  unset(application.user1?.main_user)
  unset(application.user2?.main_user)
}

const unset = user => {
  if (user?.logo_url === placeholder) {
    user.logo_url = null
  }

  if (user?.photo_url === placeholder) {
    user.photo_url = null
  }
}
