import React from 'react'
import styled, { CSSProperties } from 'styled-components'

import getInitials from 'utils/getInitials'
import Label, { LabelSize } from './Label'
import { IonIcon } from '@ionic/react'
import { alertSharp } from 'ionicons/icons'

interface Props {
  name: string
  size?: Size
  confirmed?: boolean | null
}

type Size = 'normal' | 'large'

type SizeDefinition = {
  textSize: LabelSize
  circle: string
}

const SIZE_MAP: Record<Size, SizeDefinition> = {
  normal: {
    textSize: 'xsplus',
    circle: '33px',
  },

  large: {
    textSize: 'normal',
    circle: '45px',
  },
}

const AvatarInitials: React.FC<Props> = ({ name, size = 'normal', confirmed }) => (
  <Wrapper style={{ '--size': SIZE_MAP[size].circle } as CSSProperties}>
    <Label secondary weight={700} size={SIZE_MAP[size].textSize} color="white">
      {getInitials(name)}
    </Label>
    {!confirmed && confirmed !== null && (
      <ConfirmedCheckMark>
        <IonIcon icon={alertSharp} />
      </ConfirmedCheckMark>
    )}
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  width: var(--size);
  height: var(--size);
  background-color: var(--color-primary);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
`

const ConfirmedCheckMark = styled.div`
  background: var(--color-warning);
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -2px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  ion-icon {
    color: #fff;
  }
`

export default AvatarInitials
