export const fakeAuth = setAuth =>
  setAuth(auth => ({
    ...auth,
    user: {
      is_consumer: true,
    },
    consumerService: {
      userService: {
        service: 'loanzify',
      },
    },
  }))
