import React, { useState } from 'react';
import styled from 'styled-components';
import { LightenDarkenColor } from 'lighten-darken-color';
import { IonLoading, IonSlide } from '@ionic/react';

import Layout from '../components/Layout';
import DescriptionLabel from '../components/DescriptionLabel';
import ActionButtons from '../components/ActionButtons';

import Label from 'components/Label';
import ColorPicker from 'components/ColorPicker';

import { updateCssVariables } from 'api/loanzify';
import { useApplication } from 'store/Application';

interface Props {
  onNext: () => Promise<void>;
  onPrev: () => Promise<void>;
  progress: number;
}

const UpdateBranding: React.FC<Props> = ({ progress, onNext, onPrev }) => {
  const [loading, setLoading] = useState(false);
  const { application, cssVariables, setCssVariables } = useApplication();

  const primaryColor = cssVariables['--color-primary'];
  const secondaryColor = cssVariables['--color-secondary'];

  const setPrimaryColor = (color: string) => {
    updateColors({
      ...cssVariables,
      '--color-primary': color,
      '--color-primary-light': LightenDarkenColor(color, 15),
      '--color-primary-lighter': LightenDarkenColor(color, 30),
      '--color-primary-lightest': LightenDarkenColor(color, 40),
    });
  };

  const setSecondaryColor = (color: string) => {
    updateColors({
      ...cssVariables,
      '--color-secondary': color,
    });
  };

  const updateColors = (values: Record<string, string>) => {
    setLoading(true);
    setCssVariables(values);
    updateCssVariables(application.id, values).finally(() => setLoading(false));
  };

  return (
    <IonSlide>
      <Layout
        progress={progress}
        onPrev={onPrev}
        content={
          <>
            <IonLoading isOpen={loading} message="Saving..." />

            <DescriptionLabel style={{ margin: '2.5rem 0' }}>Pick your color scheme</DescriptionLabel>

            <ColorsWrapper>
              <ColorPicker
                title="Primary Color"
                color={primaryColor}
                onChange={setPrimaryColor}
                render={open => (
                  <>
                    <Label style={{ marginBottom: '.5rem' }} center block>
                      Primary Color
                    </Label>

                    <ColorPickerBar
                      onClick={open}
                      style={
                        {
                          '--background-color': primaryColor,
                        } as React.CSSProperties
                      }
                    />

                    <Label
                      style={{ marginTop: '.5rem', marginBottom: '2rem' }}
                      size="2xs"
                      color="gray-light"
                      block
                      center
                      secondary
                    >
                      Tap to change
                    </Label>
                  </>
                )}
              />

              <ColorPicker
                title="Secondary Color"
                color={secondaryColor}
                onChange={setSecondaryColor}
                render={open => (
                  <>
                    <Label style={{ marginBottom: '.5rem' }} center block>
                      Secondary Color
                    </Label>

                    <ColorPickerBar
                      onClick={open}
                      style={
                        {
                          '--background-color': secondaryColor,
                        } as React.CSSProperties
                      }
                    />

                    <Label
                      style={{ marginTop: '.5rem', marginBottom: '2rem' }}
                      size="2xs"
                      color="gray-light"
                      block
                      center
                      secondary
                    >
                      Tap to change
                    </Label>
                  </>
                )}
              />
            </ColorsWrapper>
          </>
        }
        footer={
          <ActionButtons onSubmit={onNext} onSkip={onNext}>
            Save & Continue
          </ActionButtons>
        }
      />
    </IonSlide>
  );
};

const ColorPickerBar = styled.div`
  height: 50px;
  border: 2px solid #fff;
  border-radius: 12px;
  width: 100%;
  max-width: 175px;
  margin: 0 auto;
  background-color: var(--background-color);
`;

const ColorsWrapper = styled.div`
  margin-top: 6rem;
`;

export default UpdateBranding;
