import React from 'react'
import styled from 'styled-components'
import { formatCurrency } from '../../../utils/formatters'
import Label from '../../../components/Label'
import Card from '../../../components/Card'
import { add, format } from 'date-fns'

const TODAY = new Date()

const StyledCard = styled(Card)`
  margin: 0;
`

const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: var(--font-secondary);
  font-size: 1rem;
  > div {
    flex-basis: 33.333333%;
    margin-bottom: 0.25rem;
  }
`

const ContentItem = styled.div`
  font-weight: bold;
`

const HeaderItem = styled.div`
  text-align: right;
  margin-bottom: 0.55rem;
  font-weight: bold;
`

const CurrencyItem = styled.div`
  text-align: right;
  font-size: 0.9rem;
`

const StyledTitle = styled(Label)`
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
`

const Overview = ({ result }) => {
  const originalMonths = result.base_amortisation.length
  const newMonths = result.new_amortisation.length
  const { totalInterest: originalTotalInterest, totalPaid: originalTotalPaid } = result.base_amortisation[
    originalMonths - 1
  ]
  const { totalInterest: newTotalInterest, totalPaid: newTotalPaid } = result.new_amortisation[newMonths - 1]

  return (
    <StyledCard>
      <InfoRow>
        <HeaderItem />
        <HeaderItem>Original</HeaderItem>
        <HeaderItem>New</HeaderItem>

        <ContentItem>Interest</ContentItem>
        <CurrencyItem>{formatCurrency(originalTotalInterest)}</CurrencyItem>
        <CurrencyItem>{formatCurrency(newTotalInterest)}</CurrencyItem>

        <ContentItem>Principal</ContentItem>
        <CurrencyItem>{formatCurrency(originalTotalPaid)}</CurrencyItem>
        <CurrencyItem>{formatCurrency(newTotalPaid)}</CurrencyItem>

        <ContentItem>Total</ContentItem>
        <CurrencyItem>{formatCurrency(originalTotalPaid + originalTotalInterest)}</CurrencyItem>
        <CurrencyItem>{formatCurrency(newTotalPaid + newTotalInterest)}</CurrencyItem>

        <ContentItem>Payments</ContentItem>
        <CurrencyItem>{originalMonths}</CurrencyItem>
        <CurrencyItem>{newMonths}</CurrencyItem>
      </InfoRow>

      <StyledTitle size="xl" center color="gray">
        Payoff Date
      </StyledTitle>
      <Label size="lg" center color="gray" weight="500">
        {format(add(TODAY, { months: newMonths }), 'MMM, yyyy')}
      </Label>
    </StyledCard>
  )
}

export default Overview
