import Label from 'components/Label';
import React from 'react';
import Layout from './components/Layout';
import { ReactComponent as CheckmarkIcon } from 'legacy-icons/onboarding/checkmark.svg';
import styled from 'styled-components';

const MagicLinkSent: React.FC = () => (
  <Layout subtitle title="Magic Link Sent" backUrl="/auth/login" showBranding={false}>
    <Container>
      <CheckmarkIcon />

      <Label isWrapped center color="gray">
        We&apos;ve sent you an email with a magic link. Tap on that link to sign in without typing your password.
      </Label>
    </Container>
  </Layout>
);

const Container = styled.div`
  text-align: center;

  svg {
    margin-top: 3rem;
  }

  ion-label {
    margin-top: 2rem;
  }
`;

export default MagicLinkSent;
