import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useAuth } from './Auth'

// This component is here only for the sake of additional protection.
// If POS consumer does not have a selected loan then, we redirect
// him to the loan selection page.
const PosProvider = ({ children }) => {
  const location = useLocation()

  const { isPos, loan } = useAuth()

  if (isPos && !loan && location.pathname !== '/auth/select-loan') {
    console.log('App has a POS account & consumer does not have an active loan.')

    return <Redirect to="/auth/select-loan" />
  }

  return children
}

export default PosProvider
