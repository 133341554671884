import React from 'react'

import Animation, { AnimationProps } from './Animation'
import data from 'lotties/no-documents.json'
import styled from 'styled-components'

type Props = {} & AnimationProps

const NoDocumentsAnimation: React.FC<Props> = ({ options = {}, ...props }) => (
  <Wrapper {...props}>
    <Animation {...props} data={data} options={{ ...options, loop: options && options.loop ? options.loop : false }} />
  </Wrapper>
)

const Wrapper = styled.div``

export default NoDocumentsAnimation
