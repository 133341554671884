import React, { useState } from 'react'
import styled from 'styled-components'

import FNMImage from 'legacy-icons/export/icon-fnm.png'
import CSVImage from 'legacy-icons/export/icon-csv.png'
import MismoImage from 'legacy-icons/export/icon-mismo.png'

import ExportLoanButton from '../components/ExportLoanButton'
import LoanCardSection from 'components/loans/LoanCardSection'

import { Loan } from 'types/Pos'
import { exportLoan } from 'api/pos'
import useAlert from 'hooks/useAlert'
import { convertToErrorBag, isValidationError } from 'utils/Validation'

interface Props {
  loan: Loan
}

type ExportType = 'mismo' | 'fnm' | 'csv'

const Export: React.FC<Props> = ({ loan }) => {
  const { success, error: showError } = useAlert()
  const [exporting, setExporting] = useState<ExportType>()

  const handleExport = (type: ExportType) => {
    setExporting(type)

    exportLoan(loan.id, type)
      .then(() => success('MISMO 3.4 file successfully sent to your email.'))
      .catch((error: any) => {
        isValidationError(error)
          ? showError(convertToErrorBag(error).first())
          : showError('There was an exporting MISMO 3.4 file.')
      })
      .finally(() => setExporting(undefined))
  }

  return (
    <LoanCardSection title="File Export Options" showActionsIcon={false}>
      <ExportsContainer>
        <ExportLoanButton
          title="Mismo 3.4"
          image={MismoImage}
          isLoading={exporting === 'mismo'}
          onClick={() => handleExport('mismo')}
        />

        <ExportLoanButton
          title="FNM 3.2"
          image={FNMImage}
          isLoading={exporting === 'fnm'}
          onClick={() => handleExport('fnm')}
        />

        <ExportLoanButton
          title="CSV"
          image={CSVImage}
          isLoading={exporting === 'csv'}
          onClick={() => handleExport('csv')}
        />
      </ExportsContainer>
    </LoanCardSection>
  )
}

const ExportsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
`

export default Export
