export const getChartColors = () => {
  const style = getComputedStyle(document.documentElement)

  return [
    style.getPropertyValue('--color-calculator-chart-1').trim(),
    style.getPropertyValue('--color-calculator-chart-2').trim(),
    style.getPropertyValue('--color-calculator-chart-3').trim(),
    style.getPropertyValue('--color-calculator-chart-4').trim(),
  ]
}

export const getColorsFromStyle = (colorNames = []) => {
  const style = getComputedStyle(document.documentElement)

  return colorNames.map(colorName => style.getPropertyValue(`--color-${colorName}`).trim())
}
