import React from 'react'
import { IonPage, IonContent } from '@ionic/react'

import List from 'components/List'
import Header from 'components/Header'
import ListItem from 'components/ListItem'

import { useContent } from 'store/Content'

const StepByStepPage = () => {
  const { content } = useContent()

  const getIcon = title => {
    switch (title.trim().toLowerCase()) {
      case 'is a mortgage right for you?':
        return 'mortgageRightForYou'
      case 'mortgage closing':
        return 'mortgageClosing'
      case 'what can i afford?':
        return 'questionAfford'
      case 'getting pre-approved':
        return 'checklistPreApproved'
      case 'choosing a home':
        return 'findHome'
      case 'apply for your loan':
        return 'loanApply'
      case 'loan processing':
        return 'loanProcessing'
      default:
        return title
    }
  }

  return (
    <IonPage>
      <Header title="Step By Step" back defaultHref="/content" />

      <IonContent>
        <List>
          {content.stepByStep.map(({ id, name, icon }) => (
            <ListItem
              key={id}
              title={name}
              startIcon={require(`legacy-icons/menu`)[getIcon(icon || name)]}
              routerLink={`/content/guide/${id}`}
            />
          ))}
        </List>
      </IonContent>
    </IonPage>
  )
}

export default StepByStepPage
