import React, { useMemo } from 'react';
import { IonPage } from '@ionic/react';

import { CALCULATORS_CONFIG } from 'config/calculators';
import { useCalculator } from 'store/Calculator';
import Header from 'components/Header';

import ResultAmortisation from './components/ResultAmortisation';

const PurchaseAmortisationPage: React.FC = () => {
  const { options, result } = useCalculator();
  const amortisation = useMemo(
    () =>
      window.lhpCalculator.amortisation({
        propertyPrice: options.property_price,
        loanAmount: options.property_price * (1 - options.down_payment_rate / 100), // For purchase calculator, override loan_amount
        interestRate: options.interest_rate,
        monthlyPayment: result.total,
        monthlyHoaFees: result.breakdown.taxes_and_hoa,
        monthlyTaxesDollar: 0,
        monthlyHazardInsurance: result.breakdown.monthly_insurance,
        monthlyMortgageInsurance: result.breakdown.insurance,
      }),
    [options, result]
  );

  return (
    <IonPage>
      <Header defaultHref="/calculators/purchase/result" title={CALCULATORS_CONFIG.purchase.title} />

      <ResultAmortisation options={options} amortisation={amortisation} />
    </IonPage>
  );
};

export default PurchaseAmortisationPage;
