import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ConsumerPage from 'pages/consumer/Index';

const ConsumerRoutes = () => (
  <IonRouterOutlet>
    <Switch>
      <Route path={`/consumer/tasks`} component={ConsumerPage} />
      <Route path={`/consumer/milestones`} component={ConsumerPage} />
      <Route path={`/consumer/team`} component={ConsumerPage} />
      <Route path={`/consumer/credit`} component={ConsumerPage} />
      <Route exact path="/consumer">
        <Redirect to="/consumer/tasks" />
      </Route>
    </Switch>
  </IonRouterOutlet>
);

export default ConsumerRoutes;
