import React from 'react'
import styled from 'styled-components'
import { IonBackButton, IonPage } from '@ionic/react'

import { useApplication } from 'store/Application'

import Label from 'components/Label'
import ApplicationLogo from 'components/ApplicationLogo'
import LayoutHeader from '../../../components/Headers/LayoutHeader'

interface Props {
  title?: string
  titleLarge?: boolean
  subtitle?: string
  footer?: React.ElementType
  backUrl?: string
  showBranding?: boolean
  justifyBody?: boolean
  showHeader?: boolean
}

const Layout: React.FC<Props> = ({
  title,
  titleLarge = false,
  subtitle = undefined,
  children,
  footer = undefined,
  backUrl = undefined,
  showBranding = true,
  justifyBody = true,
  showHeader = true,
}) => {
  let { isDefault, photoUrl, isMainEnterprise } = useApplication()

  photoUrl = photoUrl || 'https://loanzify.com/public/images/avatar_default.jpg'

  const renderLogoHeader = () => {
    return (
      <LogoHeader isDefault={isDefault}>
        <BackButton>
          <IonBackButton text="" defaultHref={backUrl} />
        </BackButton>

        <ApplicationLogo />
      </LogoHeader>
    )
  }

  const renderContentHeader = () => <LayoutHeader title={title} subtitle={subtitle} titleLarge={titleLarge} />

  const renderContentBody = () => {
    if (isDefault) {
      return (
        <ContentBody justifyBody={justifyBody}>
          {children}
          {footer}
        </ContentBody>
      )
    }

    if (photoUrl && !isMainEnterprise) {
      return (
        <ContentBody justifyBody={justifyBody}>
          <div>
            {title && (
              <TitleLabel lg={titleLarge} color="warning" block center isWrapped>
                {title}
              </TitleLabel>
            )}

            {subtitle && (
              <SubtitleLabel secondary block center isWrapped fontStyle="italic" color="gray">
                {subtitle}
              </SubtitleLabel>
            )}

            {children}
          </div>
          {footer}
        </ContentBody>
      )
    }

    return (
      <ContentBody justifyBody={justifyBody}>
        {children}
        {footer}
      </ContentBody>
    )
  }

  return (
    <StyledIonPage>
      {showHeader && renderLogoHeader()}
      {showBranding ? renderContentHeader() : <ContentHeader />}
      {renderContentBody()}
    </StyledIonPage>
  )
}

const LogoHeader = styled.div<{ isDefault: boolean }>`
  position: relative;
  background: #fff;
  padding: ${props => (props.isDefault ? '1rem 0 0.5rem' : '1rem 0')};
  text-align: center;
  margin-top: env(safe-area-inset-top);
  .logo {
    height: 5.5rem;
  }
`

const BackButton = styled.div`
  position: absolute;
  top: 2rem;
  left: 1rem;
`

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;
  padding: 0.5rem 1rem 1rem;

  border-bottom-left-radius: 50% 1.5rem;
  border-bottom-right-radius: 50% 1.5rem;
  border-bottom: 2px solid var(--color-gray-lightest);

  text-align: center;
`

const ContentBody = styled.div<{ justifyBody: boolean }>`
  padding: 0.75rem 1rem;
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: ${props => (props.justifyBody ? 'space-between' : 'initial')};
`

const TitleLabel = styled(Label)<{ subtitle?: boolean; lg: boolean }>`
  font-size: ${props => (props.lg ? '66px' : '43px')} !important;
  line-height: 1.1 !important;
  margin-top: 0.25rem;
  margin-bottom: ${props => (props.subtitle ? '0.25rem' : '0.75rem')};
`

const SubtitleLabel = styled(Label)<{ lg?: boolean }>`
  font-size: ${props => (props.lg ? '32px' : '27px')} !important;
  line-height: 1.2 !important;
  margin-bottom: 0.75rem;
`

const StyledIonPage = styled(IonPage)`
  justify-content: initial;
  overflow: auto;
`

export default Layout
