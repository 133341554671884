import { getOwed, getSubmitted, getRejected, getAccepted, uploadRequestedFiles } from 'api/documents'
import { createLoanToken } from 'api/pos'
import { client as documentsClient } from 'api/documents'

const PosDocuments = ({ loan, user }) => ({
  initial: {
    owed: [],
    submitted: [],
    rejected: [],
    accepted: [],
  },

  async setup() {
    if (documentsClient.bindings) {
      documentsClient.bindings.consumer_service_id = loan.borrower.sso_consumer_service_id
    }

    if (documentsClient.bindings?.service === 'pos') {
      return
    }

    const bindings = await createLoanToken(loan.id)

    documentsClient.bindings = bindings
    documentsClient.bindings.service = 'pos'
    documentsClient.bindings.consumer_id = user.id
    documentsClient.bindings.consumer_service_id = loan.borrower.sso_consumer_service_id
    documentsClient.defaults.headers.common['Authorization'] = `Bearer ${bindings.token}`
  },

  async load() {
    const [owed, submitted, rejected, accepted] = await Promise.all([
      getOwed(),
      getSubmitted(),
      getRejected(),
      getAccepted(),
    ])

    return { owed, submitted, rejected, accepted }
  },

  async upload(files, document_name, setProgress) {
    await uploadRequestedFiles(
      {
        files,
        document_name,
        smartapp_application_id: `pos-${loan.id}`,
      },
      setProgress
    )

    return await this.load()
  },
})

export default PosDocuments
