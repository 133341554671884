import React, { useState, useEffect } from 'react'
import { IonIcon, IonLoading, IonPage } from '@ionic/react'

import { getSavedCalculations } from 'api/loanzify'
import { useCalculations } from 'store/Calculations'
import { useCalculator } from 'store/Calculator'

import Content from 'components/Content'
import Header from 'components/Header'
import styled from 'styled-components'
import Card from '../../../components/Card'
import { formatCurrency } from '../../../utils/formatters'
import { chevronForward } from 'ionicons/icons'
import Label from '../../../components/Label'

const StyledIonIcon = styled(IonIcon)`
  background: var(--color-gray-lightest);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fbfdff;
`

const StyledIonCard = styled(Card)`
  padding: 12px 7px;
  margin-left: 0;
  margin-right: 0;
`

const StyledTable = styled.table`
  text-align: left;
  width: 100%;
  thead {
    font-size: 11px;
    border-bottom: 1px solid var(--color-gray-lightest);

    th {
      padding-bottom: 3px;
      font-weight: normal;
      color: #3a455f;
      text-align: center;

      &:first-child {
        padding-left: 8px;
        text-align: left;
      }
    }
  }
`

const StyledRow = styled.tr`
  td {
    padding: 12px 0;
    font-size: 12px;
    text-align: center;
    color: var(--color-gray-light);

    &:first-child {
      font-size: 1rem;
      padding-left: 8px;
      text-align: left;
    }
  }

  &:nth-child(even) {
    background: var(--color-background);
  }
`

const SavedCalculationsPage = ({ history }) => {
  const { setRunCalculation, savedCalculations, setSavedCalculations } = useCalculations()
  const { setOptions, setResult } = useCalculator()

  const [loading, setLoading] = useState(false)

  const handleClickItem = savedCalculation => {
    // Restore saved calculation to the currently tracked run calculation
    const newRunCalculation = { ...savedCalculation }

    newRunCalculation.id = newRunCalculation.calc_id
    delete newRunCalculation.calc_id

    setRunCalculation(newRunCalculation)

    // Restor calculator options
    const { version, ...newOptions } = JSON.parse(newRunCalculation.params_json)
    newOptions.type = newRunCalculation.program
    newOptions.interest_rate = newOptions.interest_rate >= 1 ? newOptions.interest_rate : newOptions.interest_rate * 100
    const result = window.lhpCalculator.calculate(newOptions)
    setOptions(newOptions)
    setResult({
      title: savedCalculation.name,
      ...result,
    })
    // Push to the calculator result page
    history.push(`/calculators/${newRunCalculation.calculator}/details`)
  }

  useEffect(() => {
    setLoading(true)

    getSavedCalculations()
      .then(({ data }) => {
        setSavedCalculations(data.map(i => ({ ...i, params: JSON.parse(i.params_json) })))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <IonPage>
      <IonLoading isOpen={loading} message="Loading calculations..." />

      <Header back title="Saved Calculations" defaultHref="/calculators" />

      <Content>
        {!loading && Array.isArray(savedCalculations) && savedCalculations.length > 0 && (
          <StyledIonCard>
            <StyledTable>
              <thead>
                <tr>
                  <th width={92}>Calculation</th>
                  <th width={92}>Property Price</th>
                  <th width={92}>Mortgage Payment</th>
                  <th width={30}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {savedCalculations.map(calculation => (
                  <StyledRow key={calculation.id} onClick={() => handleClickItem(calculation)}>
                    <td>{calculation.name}</td>
                    <td>{formatCurrency(calculation.params.property_price, 0)}</td>
                    <td>{formatCurrency(calculation.params.result.total)}</td>
                    <td>
                      <StyledIonIcon slot="icon-only" icon={chevronForward} />
                    </td>
                  </StyledRow>
                ))}
              </tbody>
            </StyledTable>
          </StyledIonCard>
        )}
        {!loading && Array.isArray(savedCalculations) && savedCalculations.length < 1 && (
          <Label block center color="gray-light">
            No Saved Calculations Found
          </Label>
        )}
      </Content>
    </IonPage>
  )
}

export default SavedCalculationsPage
