import React from 'react'
import { IonBackButton, IonIcon, IonRouterLink } from '@ionic/react'
import { chevronBack } from 'ionicons/icons'

const MenuButton = ({ defaultHref, forceDefaultHref = false }) => {
  if (forceDefaultHref) {
    return (
      <IonRouterLink routerLink={defaultHref} routerDirection="back">
        <IonIcon icon={chevronBack} size="large" color="dark" />
      </IonRouterLink>
    )
  }

  if (defaultHref) {
    return <IonBackButton text="" defaultHref={defaultHref} />
  }

  return null
}

export default MenuButton
