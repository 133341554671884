import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  IonContent,
  IonDatetime,
  IonFooter,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'

import Button from 'components/Button'

const StyledIonModal = styled(IonModal)`
  --border-radius: 0.5rem;
  --max-width: 80%;
  --max-height: 400px;
`

const ModalTitle = styled.h1`
  font-size: 1.25rem;
  color: var(--color-gray);
  text-align: center;
`

const FilterModal = ({ isOpen, defaultFilters, loanOfficers, onSubmit, onCancel }) => {
  const [filters, setFilters] = useState(defaultFilters)

  const updateFilter = (key, value) => {
    setFilters({ ...filters, [key]: value })
  }

  const handleSubmit = () => {
    onSubmit(filters)
  }

  useEffect(() => {
    setFilters(defaultFilters)
  }, [defaultFilters])

  return (
    <StyledIonModal isOpen={isOpen} onDidDismiss={onCancel}>
      <IonContent scrollY={false} className="ion-padding">
        <ModalTitle>Advanced Filter</ModalTitle>
        <IonItem lines="full">
          <IonLabel>From</IonLabel>

          <IonDatetime
            displayFormat="MM/DD/YYYY"
            placeholder="Select date"
            value={filters.searchStartDate}
            onIonChange={e => updateFilter('searchStartDate', e.detail.value)}
          />
        </IonItem>

        <IonItem lines="full">
          <IonLabel>To</IonLabel>

          <IonDatetime
            displayFormat="MM/DD/YYYY"
            placeholder="Select date"
            value={filters.searchEndDate}
            onIonChange={e => updateFilter('searchEndDate', e.detail.value)}
          />
        </IonItem>

        <IonItem lines="full">
          <IonLabel>Date Field</IonLabel>

          <IonSelect
            value={filters.searchDateMode}
            placeholder="Select date field"
            onIonChange={e => updateFilter('searchDateMode', e.detail.value)}
          >
            <IonSelectOption value="created">Created Date</IonSelectOption>
            <IonSelectOption value="last_activity">Last Activity</IonSelectOption>
            <IonSelectOption value="submitted_date">Submitted Date</IonSelectOption>
          </IonSelect>
        </IonItem>

        <IonItem lines="full">
          <IonLabel>Display by LO</IonLabel>

          <IonSelect
            value={filters.searchLoId}
            placeholder="Select LO"
            onIonChange={e => updateFilter('searchLoId', e.detail.value)}
          >
            <IonSelectOption value="">No LO</IonSelectOption>

            {loanOfficers.map(loanOfficer => (
              <IonSelectOption key={loanOfficer.id} value={loanOfficer.id}>
                {loanOfficer.full_name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </IonContent>

      <IonFooter className="ion-no-border ion-padding-vertical">
        <Button color="secondary" center onClick={handleSubmit}>
          Submit
        </Button>
      </IonFooter>
    </StyledIonModal>
  )
}

export default FilterModal
