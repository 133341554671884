import React from 'react'
import styled from 'styled-components'
import getInitials from '../../../utils/getInitials'
import Label from '../../../components/Label'

const Image = styled.div`
  width: ${props => props.width || 127}px;
  height: ${props => props.height || 127}px;

  ${props =>
    props.src &&
    `
        background-image: url('${props.src}');
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: multiply;
        object-fit: cover;
      `};

  ${props =>
    !props.src &&
    `
        background-color: var(--color-primary);
        display: flex;
        align-items: center;
        justify-content: center;
      `}

  border: ${props => (props.borderWidth ? props.borderWidth : '6px')} solid
    var(--color-${props => props.color || 'primary-light'});
  border-radius: 9999px;
`

const AvatarCircle = props => {
  return (
    <Image {...props}>
      {!props.src && (
        <Label secondary weight={700} color="white">
          {getInitials(props.name)}
        </Label>
      )}
    </Image>
  )
}

export default AvatarCircle
