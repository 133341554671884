import React from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { IonIcon, IonSpinner } from '@ionic/react'
import { colorWandOutline } from 'ionicons/icons'

import Label from 'components/Label'
import Button from 'components/Button'
import Layout from './components/Layout'

import { useMagicLinkSender } from 'hooks/useMagicLinkSender'
import { ReactComponent as AlertIcon } from 'legacy-icons/onboarding/alert.svg'

interface Params {
  email: string
  broker: 'users' | 'consumers'
}

const ExpiredMagicLinkPage: React.FC = () => {
  const { email, broker } = useParams<Params>()
  const [sendLink, isSendingMagicLink] = useMagicLinkSender({ email, broker })

  return (
    <Layout subtitle title="Magic Link Expired" backUrl="/auth" showBranding={false}>
      <Container>
        <AlertIcon />

        <Label isWrapped center color="gray">
          Looks like your magic link is expired. Magic Link is a one-time link that allows you to login without typing a
          password. Let us send you another one.
        </Label>

        {isSendingMagicLink ? (
          <Button expand="block" disabled>
            <IonSpinner name="dots" />
          </Button>
        ) : (
          <Button expand="block" onClick={() => sendLink()}>
            <IonIcon slot="start" icon={colorWandOutline} />
            Re-Send me a Magic Link
          </Button>
        )}
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  text-align: center;

  svg {
    margin-top: 3rem;
  }

  ion-label {
    margin: 2rem 0;
  }
`

export default ExpiredMagicLinkPage
