import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { IonPage } from '@ionic/react'

import Header from 'components/Header'
import Content from 'components/Content'
import LinkCard from 'components/LinkCard'

import { ReactComponent as PurchaseIcon } from 'legacy-icons/calculators/purchase.svg'
import { ReactComponent as RefiIcon } from 'legacy-icons/calculators/refi.svg'
import { ReactComponent as ExtraPaymentIcon } from 'legacy-icons/calculators/extra-payment.svg'
import { ReactComponent as PrincipalIcon } from 'legacy-icons/calculators/principal.svg'
import { ReactComponent as AffordabilityIcon } from 'legacy-icons/calculators/affordability.svg'
import { ReactComponent as SavedCalculationsIcon } from 'legacy-icons/calculators/saved-calculations.svg'
import { useAuth } from '../../store/Auth'
import { useApplication } from '../../store/Application'

const CalculatorsPage = () => {
  const { url } = useRouteMatch()
  const { isConsumer } = useAuth()
  const { isPreview } = useApplication()

  return (
    <IonPage>
      <Header title="Calculators" />

      <Content>
        <LinkCard routerLink={`${url}/purchase`} icon={PurchaseIcon} label="Home Purchase" />
        <LinkCard routerLink={`${url}/refi`} icon={RefiIcon} label="Refinance" />
        <LinkCard routerLink={`${url}/extra-payment`} icon={ExtraPaymentIcon} label="Extra Payment" />
        <LinkCard routerLink={`${url}/principal`} icon={PrincipalIcon} label="Principal" />
        <LinkCard routerLink={`${url}/affordability`} icon={AffordabilityIcon} label="Affordability" />
        {isConsumer && !isPreview && (
          <LinkCard routerLink={`${url}/saved`} icon={SavedCalculationsIcon} label="Saved Calculations" />
        )}
      </Content>
    </IonPage>
  )
}

export default CalculatorsPage
