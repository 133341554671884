import React from 'react'
import styled from 'styled-components'

import Label from './Label'
import ModalContent from './ModalContent'

import Button from './Button'
import AlertAnimation from './animations/AlertAnimation'
import CheckmarkAnimation from './animations/CheckmarkAnimation'

export interface AlertModalProps {
  status: 'success' | 'error'
  message: string
  dismiss: () => void
}

const AlertModal: React.FC<AlertModalProps> = ({ status, message, dismiss }) => (
  <ModalContent>
    <Wrapper>
      <div>
        {status === 'error' && <AlertAnimation width={140} style={{ margin: '-1.5rem auto' }} />}
        {status === 'success' && <CheckmarkAnimation width={140} style={{ margin: '-1rem auto' }} />}

        <Label block isWrapped color="gray">
          {message}
        </Label>

        <Button onClick={dismiss} color="secondary">
          Close
        </Button>
      </div>
    </Wrapper>
  </ModalContent>
)

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    text-align: center;
    padding: 1rem;
  }

  ion-label {
    margin: 2rem 0;
  }
`

export default AlertModal
