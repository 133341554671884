import React, { useMemo } from 'react'
import { IonContent, IonItem, IonLabel } from '@ionic/react'

import ResultAmortisationChart from './ResultAmortisationChart'
import ResultAmortisationTable from './ResultAmortisationTable'

const ResultAmortisation = ({ options, amortisation }) => {
  const propertyPrice = useMemo(() => options.property_price, [options.property_price])

  return (
    <IonContent>
      <IonItem lines="none" className="ion-text-center">
        <IonLabel>
          <h1>Amortization Schedule</h1>
        </IonLabel>
      </IonItem>

      <ResultAmortisationChart propertyPrice={propertyPrice} amortisation={amortisation} />
      <ResultAmortisationTable amortisation={amortisation} />
    </IonContent>
  )
}

export default ResultAmortisation
