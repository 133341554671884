import React from 'react'
import { IonLoading } from '@ionic/react'
import { format } from 'date-fns'
import styled from 'styled-components'
import { getCalculations } from 'api/loanzify'
import useApiLoader from 'hooks/useApiLoader'
import Card from '../../../components/Card'
import { DATE_FORMAT } from '../../../config/dates'
import { formatCurrency } from '../../../utils/formatters'
import { useCalculator } from '../../../store/Calculator'
import { useHistory } from 'react-router-dom'
import ForwardButton from '../../../components/ForwardButton'
import NoDataLoaded from '../../../components/NoDataLoaded'
import NoCalculationsAnimation from '../../../components/animations/NoCalculationsAnimation'

const StyledIonCard = styled(Card)`
  padding: 12px 7px;
  margin-top: 25px;
`

const StyledTable = styled.table`
  text-align: left;
  width: 100%;
  thead {
    font-size: 11px;
    border-bottom: 1px solid var(--color-gray-lightest);

    th {
      padding-bottom: 3px;

      &:first-child {
        padding-left: 8px;
      }
    }
  }
`

const StyledRow = styled.tr`
  td {
    padding: 12px 0;

    &:first-child {
      padding-left: 8px;
    }
  }

  &:nth-child(even) {
    background: var(--color-background);
  }
`

const CalculationList = ({ installation }) => {
  const history = useHistory()
  const { setOptions, setResult } = useCalculator()
  const [calculations, loading] = useApiLoader(() => {
    return getCalculations(installation.id)
  }, [])

  const goCalc = calc => {
    const { version, ...newOptions } = calc.params

    newOptions.type = calc.program
    newOptions.is_refi = calc.type === 'refi'

    let calculate = window.lhpCalculator.calculate
    let typePage = calc.type

    switch (calc.type) {
      case 'principal':
        calculate = window.lhpCalculator.principal
        break
      case 'extraPayment':
        calculate = window.lhpCalculator.payoff
        break
      case 'affordability':
        calculate = window.lhpCalculator.affordability
        break
      case 'pre_qualify':
        newOptions.is_refi = calc.params.purpose === 'Refi'
        typePage = newOptions.is_refi ? 'refi' : 'purchase'
        newOptions.type = 'conventional'
        break
      default:
        break
    }

    const result = calculate(newOptions)

    setOptions(newOptions)
    setResult({ id: calc.id, ...result })

    history.push(`/calculators/${typePage}/details`)
  }

  const getTypeText = calc => {
    switch (calc.type) {
      case 'pre_qualify':
        return `Pre Qualify - ${calc.params.purpose}`
      default:
        return calc.typeText
    }
  }

  return (
    <>
      <IonLoading isOpen={loading} message="Loading..." />

      {!loading && !calculations.length && (
        <NoDataLoaded icon={false} message="No calculations have been run yet...">
          <NoCalculationsAnimation options={{ loop: true }} />
        </NoDataLoaded>
      )}

      {calculations.length > 0 && (
        <StyledIonCard>
          <StyledTable>
            <thead>
              <tr>
                <th width={92}>Date</th>
                <th width={92}>Property Price</th>
                <th width={92}>Calc. Type</th>
                <th width={20}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {calculations.map(calculation => (
                <StyledRow key={calculation.id} onClick={() => goCalc(calculation)}>
                  <td>{format(new Date(calculation.created_at), DATE_FORMAT)}</td>
                  <td>{formatCurrency(calculation.params.property_price)}</td>
                  <td>{getTypeText(calculation)}</td>
                  <td>
                    <ForwardButton />
                  </td>
                </StyledRow>
              ))}
            </tbody>
          </StyledTable>
        </StyledIonCard>
      )}
    </>
  )
}

export default CalculationList
