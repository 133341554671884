import React from 'react'
import { Browser } from '@capacitor/browser'
import { IonIcon, IonItem, IonLabel, IonList, useIonActionSheet, useIonAlert } from '@ionic/react'

import { File, Request } from 'types/Documents'
import ModalContent from 'components/ModalContent'
import { checkmarkOutline, closeOutline } from 'ionicons/icons'
import { getDocumentLink, updateFileReview } from 'api/documents'

interface Props {
  document: Request
  onReviewed: () => void
}

const AcceptOrRejectDocumentModal: React.FC<Props> = ({ document, onReviewed }) => {
  const [present] = useIonActionSheet()
  const [showAlert] = useIonAlert()

  const askRejectReason = (item: File) =>
    showAlert({
      header: 'Enter reason (optional)',
      inputs: [
        {
          name: 'reason',
          type: 'textarea',
          placeholder: 'Enter reason (optional)',
        },
      ],
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Submit',
          handler: ({ reason }: { reason?: string }) => rejectDocument(item, reason),
        },
      ],
    })

  const rejectDocument = (item: File, reason?: string) => {
    updateFileReview(document, {
      id: item.id,
      status: 'REJECTED',
      review_comments: reason,
    }).finally(onReviewed)
  }

  const approveDocument = (item: File) => {
    updateFileReview(document, {
      id: item.id,
      status: 'APPROVED',
    }).finally(onReviewed)
  }

  const viewDocument = (item: File) => {
    getDocumentLink(null, item.id).then(({ preview_url }) => {
      Browser.open({
        url: `http://docs.google.com/gview?embedded=true&url=${encodeURIComponent(preview_url)}`,
      })
    })
  }

  const handle = (item: File) => {
    present([
      { text: 'View', handler: () => viewDocument(item) },
      { text: 'Accept', handler: () => approveDocument(item) },
      { text: 'Reject', role: 'destructive', handler: () => askRejectReason(item) },
      { text: 'Cancel', role: 'cancel' },
    ])
  }

  return (
    <ModalContent title={document.name}>
      <IonList>
        {document.current_response.items.map(
          item =>
            item.type !== 'COMBINED' && (
              <IonItem key={item.id} button onClick={() => handle(item)}>
                {item.status === 'APPROVED' && <IonIcon icon={checkmarkOutline} />}
                {item.status === 'REJECTED' && <IonIcon icon={closeOutline} />}
                <IonLabel>{item.title}</IonLabel>
              </IonItem>
            )
        )}
      </IonList>
    </ModalContent>
  )
}

export default AcceptOrRejectDocumentModal
