import React, { useMemo } from 'react'
import { IonPage, IonContent, IonRefresher, IonRefresherContent, IonLoading } from '@ionic/react'

import Header from 'components/Header'
import Placeholder from '../components/Placeholder'
import DocumentStatus from './components/DocumentStatus'
import PosDocumentList from './components/PosDocumentList'
import StartUploadingButton from '../components/StartUploadingButton'

import { useAuth } from 'store/Auth'
import useDocuments from '../hooks/useDocuments'
import PosDocuments from '../repositories/PosDocuments'
import ProgressBar from 'components/ProgressBar'

const PosDocumentsPage = () => {
  const { user, loan } = useAuth()

  const repository = useMemo(() => {
    return PosDocuments({ user, loan })
  }, [])

  const { loading, uploading, documents, progress, refresh, upload } = useDocuments(repository)

  const isEmpty =
    !loading &&
    Object.keys(documents).every(key => {
      return documents[key].length === 0
    })

  const doRefresh = event => refresh().then(event.detail.complete)

  return (
    <IonPage>
      <Header title="Your Documents" />

      <IonContent className="ion-padding">
        {uploading && <ProgressBar progress={progress} title={`Uploading... (${progress}%)`} />}

        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <DocumentStatus
          loading={loading}
          owed={documents.owed.length}
          submitted={documents.submitted.length}
          rejected={documents.rejected.length}
          accepted={documents.accepted.length}
        />

        {loading ? (
          <IonLoading isOpen={true} message="Loading" />
        ) : isEmpty ? (
          <Placeholder showImage={false} onFilesSelected={upload} />
        ) : (
          <>
            <PosDocumentList documents={documents} onUploaded={refresh} />
            <StartUploadingButton uploaded={1} onFilesSelected={upload} />
          </>
        )}
      </IonContent>
    </IonPage>
  )
}

export default PosDocumentsPage
