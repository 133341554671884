import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Card from '../../../components/Card'
import { IonLoading } from '@ionic/react'
import { ReactComponent as EyeIcon } from 'legacy-icons/eye.svg'
import { getDocumentLink } from '../../../api/documents'
import Label from '../../../components/Label'
import usePosOrLoanzifyDocuments from '../../../hooks/usePosOrLoanzifyDocuments'
import { Browser } from '@capacitor/browser'
import NoDataLoaded from '../../../components/NoDataLoaded'
import NoDocumentsAnimation from '../../../components/animations/NoDocumentsAnimation'

const StyledIonCard = styled(Card)`
  padding: 12px 7px;
  margin-top: 25px;
`

const StyledTable = styled.table`
  text-align: left;
  width: 100%;
  thead {
    font-size: 11px;
    border-bottom: 1px solid var(--color-gray-lightest);

    th {
      padding-bottom: 3px;

      &:first-child {
        padding-left: 8px;
      }
    }
  }
`

const StyledRow = styled.tr`
  td {
    padding: 12px 0;

    &:first-child {
      padding-left: 8px;
    }
  }

  &:nth-child(even) {
    background: var(--color-background);
  }
`

const IconTh = styled.th`
  text-align: center;
`

const IconTd = styled.td`
  text-align: center;
  svg {
    vertical-align: middle;
  }
`

const StyledLabel = styled(Label)`
  margin-top: 2rem;
`

const DocumentsList = ({ installation }) => {
  usePosOrLoanzifyDocuments({ id: installation.latest_loan_id }, installation)
  const [loading, setLoading] = useState(true)
  const [posDocs, setPosDocs] = useState([])
  const [ssoDocs, setSsoDocs] = useState([])

  useEffect(() => {
    setPosDocs(installation.docs_data || [])
    setSsoDocs(installation.sso_docs || [])
    setLoading(false)
  }, [])

  const openDoc = link => {
    setLoading(true)

    getDocumentLink(null, link)
      .then(({ preview_url }) => {
        Browser.open({
          url: `http://docs.google.com/gview?embedded=true&url=${encodeURIComponent(preview_url)}`,
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <IonLoading isOpen={loading} message="Loading..." />

      {!loading && !posDocs.length && !ssoDocs.length && (
        <StyledLabel isWrapped center className="ion-padding-horizontal" color="gray-light">
          <NoDataLoaded icon={false} message="No documents have been loaded yet...">
            <NoDocumentsAnimation options={{ loop: true }} />
          </NoDataLoaded>
        </StyledLabel>
      )}

      {posDocs.length > 0 && (
        <StyledIonCard>
          <StyledTable>
            <thead>
              <tr>
                <th>Doc Name</th>
                <IconTh width={60}>Action</IconTh>
              </tr>
            </thead>
            <tbody>
              {posDocs.map(({ current_response: { items_single_pdf }, name, link, id }) => (
                <StyledRow key={id || items_single_pdf[0].id}>
                  <td>{name}</td>
                  <IconTd onClick={() => openDoc(id || items_single_pdf[0].id)}>
                    <EyeIcon />
                  </IconTd>
                </StyledRow>
              ))}
            </tbody>
          </StyledTable>
        </StyledIonCard>
      )}
      {posDocs.length === 0 && ssoDocs.length > 0 && (
        <StyledIonCard>
          <StyledTable>
            <thead>
              <tr>
                <th>Doc Name</th>
                <IconTh width={60}>Action</IconTh>
              </tr>
            </thead>
            <tbody>
              {ssoDocs.map(({ id, title }) => (
                <StyledRow key={id}>
                  <td>{title}</td>
                  <IconTd onClick={() => openDoc(id)}>
                    <EyeIcon />
                  </IconTd>
                </StyledRow>
              ))}
            </tbody>
          </StyledTable>
        </StyledIonCard>
      )}
    </>
  )
}

export default DocumentsList
