import * as React from 'react'
import styled from 'styled-components'
import AvatarSkeleton from './AvatarSkeleton'
import SkeletonBarAnimation from '../animations/SkeletonBarAnimation'

interface Props {
  tall?: boolean
  flat?: boolean
  avatar?: AvatarSkeletonProps
}

type AvatarSkeletonProps = {
  backgroundColor?: string
  size?: number
  transparent?: boolean
}

const ListItemSkeleton: React.FC<Props> = props => {
  const { avatar, flat, tall } = props

  return (
    <ListItemSkeletonWrapper flat={flat} tall={tall}>
      <AvatarSkeleton {...avatar} />
      <SkeletonBarAnimation height={33} options={{ loop: true }} />
    </ListItemSkeletonWrapper>
  )
}

const ListItemSkeletonWrapper = styled.div`
  height: 66px;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 2.13px 2.13px 12.76px rgb(0 0 0 / 7%);
  margin: 0 0.5rem;

  &:nth-of-type(odd) {
    background: var(--color-background);
  }

  &:nth-of-type(even) {
    background: #fff;
  }

  &:first-of-type {
    border-radius: 6px 6px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 6px 6px;
  }
`

export default ListItemSkeleton
