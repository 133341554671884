import { useState } from 'react'

import useModal from './useModal'
import AlertModal, { AlertModalProps } from 'components/AlertModal'

interface Options {
  duration?: number
}

interface ShowOptions {
  message: string
  status: AlertModalProps['status']
}

interface Result {
  present: (props: AlertModalProps) => void
  success: (message: string) => void
  error: (message: string) => void
}

const useAlert = (options?: Options): Result => {
  const [props, setProps] = useState<AlertModalProps>()
  const [present, dismiss] = useModal(AlertModal, props)

  const showAlert = (props: ShowOptions) => {
    setProps({ ...props, dismiss })
    present()
  }

  return {
    present: showAlert,
    success: (message: string) => showAlert({ status: 'success', message }),
    error: (message: string) => showAlert({ status: 'error', message }),
  }
}

export default useAlert
