import React from 'react'
import styled from 'styled-components'
import { IonBadge } from '@ionic/react'

const StyledIonBadge = styled(IonBadge)`
  ${props => (props.backColor ? `--background: ${props.backColor}` : '')};
  display: ${props => props.display};
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 11px;
  border-radius: 14px;
  padding: 0.35rem 0.825rem;
  color: rgb(50, 61, 69);
`

const MilestoneWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LoanMilestone = ({ loan, display = 'block', showCounts, onTabChange, showActions }) => {
  const colorPerSequence = n => {
    if (n === 1) {
      return '#DBCCF7'
    } else if (n === 2) {
      return '#C8F1F6'
    } else if (n === 3) {
      return '#FEF1DB'
    } else if (n === 4) {
      return '#D5DFEE'
    } else if (n === 5) {
      return '#F8CDD9'
    } else if (n === 6) {
      return '#CDCCFF'
    } else if (n === 7) {
      return '#CFECE7'
    } else if (n === 8) {
      return '#FFF9BF'
    } else if (n === 9) {
      return '#ECC9ED'
    } else if (n === 10) {
      return '#D9EDFB'
    } else if (n === 11) {
      return '#E5FAD9'
    } else if (n === 12) {
      return '#E7D9FF'
    }
  }

  return (
    <MilestoneWrapper>
      <StyledIonBadge backColor={colorPerSequence(loan.current_milestone.sequence)} display={display}>
        {loan.current_milestone.name}
      </StyledIonBadge>

      {loan.current_milestone.cta === 'DOCUMENTS' && showCounts && (
        <>
          <br />
          <StyledIonBadge backColor="yellow">{loan.requests_pending_user_count}</StyledIonBadge>
          <StyledIonBadge backColor="blue">{loan.requests_pending_consumer_count}</StyledIonBadge>
          <StyledIonBadge backColor="green">{loan.requests_accepted_count}</StyledIonBadge>
          <StyledIonBadge backColor="red">{loan.requests_rejected_count}</StyledIonBadge>
        </>
      )}
    </MilestoneWrapper>
  )
}

export default LoanMilestone
