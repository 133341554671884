import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { IonLoading } from '@ionic/react';

import { useSession } from './Session';
import { ApplicationState, InitialApplicationState } from 'types/Application';
import useApplicationLoader from 'hooks/useApplicationLoader';
import ErrorPage, { PageError } from 'components/ErrorPage';
import getGeneratedAppData from 'utils/getGeneratedAppData';
import { get } from 'lodash';

export const ApplicationContext = createContext({} as ApplicationState);

export const ApplicationProvider: React.FC = props => {
  const { application_id, setApplicationId } = useSession();

  const [state, setState] = useState<InitialApplicationState | null>(null);

  const loadApplication = useApplicationLoader();

  const query = useQuery<InitialApplicationState, PageError>(
    ['application', application_id],
    async () => {
      return await loadApplication(application_id);
    },
    {
      initialData: getGeneratedAppData(application_id),
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    if (query.isSuccess && query.data) {
      setState(query.data);
    }
  }, [query.data]);

  // Update CSS Variables.
  useEffect(() => {
    Object.entries(state?.cssVariables || {}).forEach(([name, variable]) => {
      document.documentElement.style.setProperty(name, variable);
    });
  }, [state?.cssVariables]);

  if (query.isError) {
    return <ErrorPage {...query.error.props} />;
  }

  if (query.isLoading || state === null) {
    return <IonLoading isOpen={true} message={'Loading...'} />;
  }

  // if (state.ssoOwnerPartnersLo) {
  //   state.application.user1.nmls = state.ssoOwnerPartnersLo.nmls!
  // }

  const owner = state.application.user2 || state.application.user1;
  const ownerKey = state.application.user2 ? 'user2' : 'user1';

  const value: ApplicationState = {
    ...state,
    owner,
    ownerKey,
    user: state.application.user2,
    user1: state.application.user1,
    configuration: state.application.configuration,
    documentsEnabled: Boolean(Number(get(state.application.configuration, 'enable_documents', 1))),
    isDefault: application_id === Number(process.env.REACT_APP_DEFAULT_APP_ID),
    isBranded: application_id !== Number(process.env.REACT_APP_DEFAULT_APP_ID),
    isEnterprise: state.application?.is_enterprise || state.application?.package_id === 5,
    isMainEnterprise: !state.application?.is_enterprise && state.application?.package_id === 5,
    isPartnerApp: !!state.application?.user2_id,
    isParentWithPos: Boolean(state.ssoParent?.has_pos),
    hasPos: Boolean(state.ssoOwner?.services.includes('pos')),
    hasBranches: (state.application?.branch_count || 0) > 0,
    logoUrl: owner.logo_url || owner.main_user?.logo_url || null,
    photoUrl: owner.photo_url || owner.main_user?.photo_url || null,
    license: state.ssoOwner.license,
    nmls: state.ssoOwner.nmls,
    setApplicationId,
    setOwner: owner => {
      setState({
        ...state,
        application: {
          ...state.application,
          [ownerKey]: owner,
        },
      });
    },
    setSsoOwner: ssoOwner => setState({ ...state, ssoOwner }),
    refreshApplication: async () => {
      if (state.application?.id) {
        setState(await loadApplication(state.application.id));
      }
    },
    setCssVariables: cssVariables => setState({ ...state, cssVariables }),
    hasEncompassIntegration: () => {
      const integrations_used = state.application.user1?.sso_user?.integrations_used;

      if (integrations_used) {
        return integrations_used.split(',').includes('encompass');
      }

      return false;
    },
  };

  return <ApplicationContext.Provider {...props} value={value} />;
};

export const useApplication = () => useContext(ApplicationContext);
