import React from 'react'

import List from 'components/List'
import MemberCard from './MemberCard'
import { TeamMember } from 'types/Pos'
import Card from '../../../components/Card'
import Label from '../../../components/Label'
import styled from 'styled-components'

export interface MembersListProps {
  members: TeamMember[]
  title: string
}

const StyledLabel = styled(Label)`
  margin-bottom: 1rem;
  padding: 1rem;
`

const MembersList: React.FC<MembersListProps> = ({ members, title }) => {
  if (members.length === 0) {
    return <></>
  }

  return (
    <List>
      <Card paddingless={true}>
        <StyledLabel size="sm" color="black" weight={700} secondary block>
          {' '}
          {title}
        </StyledLabel>
        {members.map((member, index) => (
          <MemberCard key={`${member.full_name}-${index}`} member={member} />
        ))}
      </Card>
    </List>
  )
}

export default MembersList
