import React, { useEffect, useState } from 'react'
import { IonIcon, IonRouterLink } from '@ionic/react'
import { checkmarkCircleOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import List from 'components/List'
import Card from 'components/Card'
import Label from 'components/Label'
import Button from 'components/Button'
import Searchbar from 'components/Searchbar'
import PartnerDetailsModal from 'components/PartnerDetailsModal'
import { ReactComponent as PlusIcon } from 'legacy-icons/plus-partners.svg'

import useModal from 'hooks/useModal'

import { getPartners } from 'utils/shared-services'
import { getPartnersApplications } from 'api/loanzify'
import ListItemSkeleton from '../../../components/skeletons/ListItemSkeleton'
import NoPartnersAnimation from '../../../components/animations/NoPartners'
import NoDataLoaded from '../../../components/NoDataLoaded'

const Partners: React.FC = () => {
  const history = useHistory()
  const [initials, setInitials] = useState(null)
  const [loading, setLoading] = useState(true)
  const [partners, setPartners] = useState([])
  const [activePartner, setActivePartner] = useState({})
  const [search, setSearch] = useState('')
  const [partnerDetailsModalProps, setPartnerDetailsModalProps] = useState({})
  const [present, dismiss] = useModal(PartnerDetailsModal, partnerDetailsModalProps)
  const [searchActive, setSearchActive] = useState(false)

  useEffect(() => {
    let mounted = true

    async function loadInitialData() {
      try {
        let partners = await getPartners()
        const partnerIds = partners.filter(p => p.loanzify_service_user_id).map(p => p.loanzify_service_user_id)

        /**
         * If we have partner ID's then we can fetch their LZFY applications as well.
         * Otherwise, just return partners
         */
        if (partnerIds) {
          const { data: partnerApplications } = await getPartnersApplications(partnerIds)

          partners = partners.map(p => {
            const infoData =
              partnerApplications.find(i => parseInt(i.user2_id) === parseInt(p.loanzify_service_user_id)) || {}

            return { ...p, ...infoData, id: p.id, application_id: infoData.id }
          })
        }

        setPartners(partners)
        setLoading(false)
      } catch (err) {
        setPartners([])
        setLoading(false)
      }
    }

    if (mounted) {
      loadInitialData()
    }

    return () => {
      mounted = false
    }
  }, [])

  const initialsFromName = name => {
    if (!name) {
      return '--'
    }

    return name
      .split(' ')
      .map(part => part.slice(0, 1).toUpperCase())
      .join('')
  }

  const formatLoanNumber = number => {
    if (number < 10) {
      return `00${number}`
    }

    if (number < 100) {
      return `0${number}`
    }

    return number
  }

  const filteredPartners = partners
    .filter(app => {
      if (search === '') {
        return true
      }

      return (
        app.first_name.toLowerCase().startsWith(search.toLowerCase()) ||
        app.last_name.toLowerCase().startsWith(search.toLowerCase()) ||
        app.email.toLowerCase().startsWith(search.toLowerCase())
      )
    })
    .sort((a, b) => {
      switch (true) {
        case a.created_at_ms < b.created_at_ms:
          return 1
        case a.created_at_ms > b.created_at_ms:
          return -1
        default:
          return 0
      }
    })

  const showModalForPartner = partner => {
    setActivePartner(partner)
    setInitials(initialsFromName(activePartner.first_name + ' ' + activePartner.last_name))

    setPartnerDetailsModalProps({
      history,
      initials,
      partner,
      dismiss,
    })

    present()
  }

  return (
    <List>
      <SearchWithIcon>
        <Searchbar
          value={search}
          onIonChange={e => setSearch(e.detail.value)}
          clearIcon
          borderRadius="60px"
          onFocus={() => setSearchActive(true)}
          onBlur={() => setSearchActive(false)}
        />
        <AppendedButton routerLink="/leads/partners/create" size="md" searchActive={searchActive}>
          <StyledPlusIcon />
          Partner
        </AppendedButton>
      </SearchWithIcon>

      {loading && (
        <>
          {Array.from(Array(6)).map((_, i) => {
            return <ListItemSkeleton key={i} avatar={{ backgroundColor: 'var(--color-blue-light)', size: 40 }} />
          })}
        </>
      )}

      {!loading && filteredPartners.length === 0 && (
        <NoDataLoaded icon={false} message={<AddPartnerButton />}>
          <NoPartnersAnimation />
        </NoDataLoaded>
      )}

      {!loading && (
        <>
          {filteredPartners.map(partner => (
            <StyledIonCard key={`partner-${partner.id}`} onClick={() => showModalForPartner(partner)}>
              <Header>
                <AvatarContainer>
                  {partner.photo_url ? (
                    <Avatar url={partner.photo_url} />
                  ) : (
                    <Avatar>{initialsFromName(partner.first_name + ' ' + partner.last_name)}</Avatar>
                  )}
                  {partner.claimed_at && <IonIcon icon={checkmarkCircleOutline} />}
                </AvatarContainer>

                <NameContainer title={partner.title}>
                  <Label color="gray" size="normal">
                    {partner.first_name} {partner.last_name}
                  </Label>
                  {partner.title && (
                    <Label color="gray-light" secondary fontStyle="italic" size="xs">
                      {partner.title}
                    </Label>
                  )}
                </NameContainer>
              </Header>

              {partner.active_loan_count !== undefined && (
                <ActiveLoanAmount>
                  <div>
                    <ActiveLoanNumber size="2xs" color="gray">
                      Shared
                      <br /> Clients
                    </ActiveLoanNumber>
                    <ActiveLoanNumber size="lg" color="gray">
                      {formatLoanNumber(partner.active_loan_count)}
                    </ActiveLoanNumber>
                  </div>
                </ActiveLoanAmount>
              )}
            </StyledIonCard>
          ))}
        </>
      )}
    </List>
  )
}

const AddPartnerButton = () => {
  return (
    <IonRouterLink routerLink="/leads/partners/create">
      <Button color="secondary"> Add Partner </Button>
    </IonRouterLink>
  )
}

const StyledIonCard = styled(Card)`
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 2.13px 2.13px 12.76px rgb(0 0 0 / 7%);
`

const Header = styled.div`
  display: flex;
`

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: ${prop => (prop.title ? 'start' : 'center')};
`

const AvatarContainer = styled.div`
  position: relative;
  margin-right: 0.5rem;

  ion-icon {
    position: absolute;
    right: 0px;
    top: -5px;
    background-color: var(--color-success);
    color: white;
    border-radius: 50%;
  }
`

const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2.5rem;
  height: 2.5rem;

  border-radius: 9999px;
  color: #000;

  ${props =>
    props.url
      ? `
    background: url(${props.url});
    background-size: cover;
    `
      : `
    background: var(--color-blue-light);
  `}
`

const ActiveLoanAmount = styled.div`
  background: #c8e9d7;
  color: #3a455f;
  max-width: 75px;
  width: 75px;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 7px 12px;
  border-radius: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const ActiveLoanNumber = styled(Label).attrs({ color: 'gray' })`
  display: block;
`

const SearchWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const AppendedButton = styled(Button)`
  --padding-start: 5px;
  --padding-end: 12px;
  margin-right: 10px;
  position: ${props => (props.searchActive ? 'absolute' : 'relative')};
  right: ${props => (props.searchActive ? '-115px' : '0')};
  transition: right 0.25s ease;
`

const StyledPlusIcon = styled(PlusIcon)`
  height: calc(100% - 0.35rem);
  margin-left: -0.35rem;
  margin-right: 0.35rem;

  circle {
    fill: white;
    opacity: 1;
  }

  path {
    fill: var(--color-primary);
  }
`

export default Partners
