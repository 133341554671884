import React from 'react'
import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from 'react-query'

const client = new QueryClient()

const QueryClientProvider: React.FC = ({ children }) => (
  <ReactQueryClientProvider client={client}>{children}</ReactQueryClientProvider>
)

export default QueryClientProvider
