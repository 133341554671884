import Axios from 'axios'
import { UserService } from 'types/Application'

/**
 * SSO Http client.
 *
 * @type {Axios}
 */
export const client = Axios.create({
  baseURL: `${process.env.REACT_APP_SSO_URL}/api`,
})

/**
 * Validate a given email.
 */
export const validateEmail = (email: string) => client.post('v2/auth/validate-email', { email }).then(response => response.data)

/**
 * Login with the given credentials.
 */
export const login = (email: string, password: string) =>
  client.post('v2/auth/login', { email, password }).then(response => response.data)

/**
 * Login with the given credentials.
 */
export const saLogin = (email: string, password: string, sso_id: number) =>
  client.post('v2/auth/sadmin/login', { email, password, sso_id }).then(response => response.data)

/**
 * Register with the given user details.
 * loanzify_is_loan_officer
 */
export const register = (userId: number, isLoanOfficer: boolean, details: any, parentUserId: number | null = null) =>
  client
    .post(
      `v2/users/${userId}/consumers?loanzify_is_loan_officer=${Number(
        isLoanOfficer
      )}&loanzify_parent_user_id=${parentUserId}`,
      details
    )
    .then(response => response.data)

/**
 * Get the currently authenticated user.
 */
export const getCurrentUser = () => client.get('v2/users/current').then(response => response.data)

/**
 * Associate consumer with the given user in "loanzify" service.
 */
export const associateConsumer = (user_id: number, parent_user_id: number | null = null) => {
  return client
    .post(`v2/consumer-services`, { user_id, service: 'loanzify', parent_user_id })
    .then(response => response.data)
}

/**
 * Get loan officers that are associated with the current user.
 *
 */
export const getLoanOfficers = (enterprise = false, params = {}) =>
  client.get(`v2/users`, { params: { ...params, enterprise } }).then(response => response.data)

/**
 * Include Partners with Loan Officers
 *
 * @param enterprise
 * @param params
 */
export const getLoanOfficersWithPartners = (enterprise = false, params = {}) =>
  client.get(`v2/users?include[partners]`, { params: { ...params, enterprise } }).then(response => response.data)

/**
 * Get user by the specified loanzify_id, that is, loads SSO owner
 *
 */
export const getUserByLoanzifyId = async (
  id: number,
  isLoanOfficer: number,
  parentUserId: number
): Promise<UserService> =>
  client
    .get(
      `v2/lookup/loanzify-users/${id}?include[services]&loanzify_is_loan_officer=${Number(
        isLoanOfficer
      )}&loanzify_parent_user_id=${parentUserId}`
    )
    .then(response => response.data)

/**
 * Get consumer service.
 */
export const getConsumerService = (id: number) => client.get(`v2/consumer-services/${id}`).then(response => response.data)

/**
 * Get SSO owner features
 *
 */
export const getCalculatorDefaults = (user_id: number) =>
  client.get<any>(`v2/users/${user_id}/calculator-defaults`).then(response => response.data.data)

/**
 * Get auth token for documents.
 *
 */
export const createDocumentsToken = (consumer_service_id: number) =>
  client.post<any>(`v2/tokens`, { service: 'documents', consumer_service_id }).then(response => response.data.token)

export const createDocumentsParentToken = (from: any, user_type = 'parent') =>
  client.post(`v2/documents-tokens`, { service: 'documents', from, user_type }).then(response => response.data)

/**
 * Get partners
 */
export const getPartners = (from = null) =>
  client.get(`v2/partners`, { params: { from } }).then(response => response.data)

/**
 * Get an individual partner
 */
export const getPartner = (id: number) => client.get(`/v2/partners/${id}`).then(response => response.data)
/**
 * Reset password
 */
export const resetPassword = (data: any) => client.post(`v2/reset-password`, data)

/**
 * Upload profile picture.
 */
export const uploadProfilePicture = async (user_id: string, file: any) => {
  const data = new FormData()

  data.append('user_id', user_id)
  data.append('file', file)
  data.append('user_image_type', 'photo_url')

  const response = await client.post<any>(`v1/authenticated/user/file`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  await syncProfile(user_id)

  return response.data.data.url
}

/**
 * Upload profile picture.
 */
export const uploadLogoUrl = async (user_id: string, file: any) => {
  const data = new FormData()

  data.append('user_id', user_id)
  data.append('file', file)
  data.append('user_image_type', 'logo_url')

  const response = await client.post<any>(`v1/authenticated/user/file`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  await syncProfile(user_id)

  return response.data.data.url
}

export const syncProfile = async (user_id: string) => client.post(`v1/authenticated/user/file/sync`, { user_id })

export const updateProfile = (data: any) => client.put(`v2/profile`, data).then(response => response.data)

/**
 * Reset password
 *
 */
export const loginWithToken = (token: string, email: string, broker: string) =>
  client.post(`v2/auth/token-login`, { token, email, broker }).then(response => response.data)

/**
 *
 * Get Consumers
 */

interface GetConsumerOptions {
  page: number,
  perPage: number,
  search?: string
}
export const getConsumers = ({ page = 1, perPage = 10, search = '' }: GetConsumerOptions) =>
  client.get<any>(`v2/consumers?page=${page}&per-page=${perPage}&search=${search}`).then(response => response.data.data);


/**
* Create Partner
*
*
*/
interface Partner {
 email: string,
 first_name: string,
 last_name: string,
 mobile: string

}
export const createPartner = (partner: Partner) => client.post<any>(`v2/partners`, partner).then(response => response.data.data)

/**
 * Update Partner
 */
export const updatePartner = (id: number, partner: Partner) =>
  client.put<any>(`v2/partners/${id}`, partner).then(response => response.data.data)

/**
 * @returns {Promise<void>}
 */
export const extendTrial = () => client.post(`v2/extend-trial`)

/**
* Send Phone Verification Notification
 */
export const sendPhoneVerificationNotification = (data = {}) => client.post(`v2/phone-verification/send`, data)

/**
 * Verify Phone
 */
export const verifyPhone = (code: string) => client.post(`v2/phone-verification/verify`, { code })

export const deleteConsumer = () => client.delete('v2/consumers/delete').then(response => response.data)
