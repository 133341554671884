import React, { useState } from 'react';
import { useAuth } from '../../store/Auth';
import LoanCardSection from '../../components/loans/LoanCardSection';
import ThreeBureauReport from '../../components/ThreeBureauReport';

type CreditScore = {
  date: string;
  score: null | number;
  last_updated: null | string;
  service: {
    key: string;
    label: string;
  };
};

const BorrowerCreditReport = () => {
  const { loan } = useAuth();

  const [creditScores] = useState<Record<string, CreditScore>>(
    (loan?.credit_request || []).reduce((scores: Record<string, any>, score: CreditScore) => {
      scores[score.service.key] = {
        score: score.score,
        last_updated: score.date,
      };

      return scores;
    }, {})
  );

  return (
    <LoanCardSection title="Credit Scores" showActionsIcon={false}>
      <ThreeBureauReport creditScores={creditScores} />
    </LoanCardSection>
  );
};

export default BorrowerCreditReport;
