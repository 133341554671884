import Axios from 'axios'

export const client = Axios.create({
  baseURL: process.env.REACT_APP_LHP_FORMS_URL,
})

export const getStatistics = async (submission_id: string) => {
  return await client
    .get<CompletedFieldsResponse>(`/api/submissions/${submission_id}/statistics/completed-fields`)
    .then(response => response.data)
}
