import React from 'react'
import styled from 'styled-components/macro'

import Button from 'components/Button'
import Label from 'components/Label'
import DoughnutChart from 'pages/home/components/UserDashboard/components/DoughnutChart'
import TaskCard from 'pages/home/components/TaskCard'

interface Props {
  title: string
  actionText: string
  actionRouterLink: string
  progress: number
  progressText: string
  total?: number
  accepted?: number
  rejected?: number
  submitted?: number
  documentCounts?: boolean
}

const TaskCardWithGraph: React.FC<Props> = ({
  title,
  actionText,
  actionRouterLink,
  progress,
  progressText,
  total = 100,
  accepted,
  rejected,
  submitted,
  documentCounts = true,
}) => (
  <TaskCard>
    <Container>
      <ChartContainer>
        <DoughnutChart
          data={[progress, total - progress]}
          colorNames={['success', 'blue-lightest']}
          showLegend={false}
          widthPercent={15}
        >
          <Label color="gray-light" size="lg" weight={500}>
            {progressText}
          </Label>

          <Label color="gray-light" size="sm" weight={300} fontStyle="italic">
            Submitted
          </Label>
        </DoughnutChart>
      </ChartContainer>

      <ContentContainer>
        <Label weight={400} color="gray">
          {title}
        </Label>

        <Button size="sm" routerLink={actionRouterLink} color="secondary" margin="10px auto 0 0">
          {actionText}
        </Button>

        {documentCounts && (
          <LabelContainer>
            <Label size="xs" color="gray-light">
              {submitted} Pending
            </Label>

            <Label size="xs" color="green-light">
              {accepted} Accepted
            </Label>

            <Label size="xs" color="danger">
              {rejected} Rejected
            </Label>
          </LabelContainer>
        )}
      </ContentContainer>
    </Container>
  </TaskCard>
)

const ChartContainer = styled.div`
  flex: 0 0 35%;
`

const ContentContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  margin: 1rem 0 0 1.5rem;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

export default TaskCardWithGraph
