import React from 'react'
import styled from 'styled-components'

import Button, { ButtonProps } from 'components/Button'
import Label, { LabelProps } from 'components/Label'

interface Props {
  primary?: React.ReactNode
  secondary?: React.ReactNode
  onSkip?: LabelProps['onClick']
  onSubmit?: ButtonProps['onClick']
}

const ActionButtons: React.FC<Props> = ({
  primary = 'Continue',
  secondary = 'Skip for now',
  onSkip,
  onSubmit,
  children,
}) => (
  <Wrapper>
    <Button color="secondary" center type={onSubmit ? 'button' : 'submit'} onClick={onSubmit}>
      {children || primary}
    </Button>

    <Label center block size="sm" color="gray" onClick={onSkip}>
      {secondary}
    </Label>
  </Wrapper>
)

const Wrapper = styled.div`
  padding-bottom: 1rem;

  ${Button} {
    margin-bottom: 1rem;
  }
`

export default ActionButtons
