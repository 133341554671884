import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IonInfiniteScroll, IonInfiniteScrollContent, IonLoading } from '@ionic/react'

import InstallationList from './components/InstallationList'
import Searchbar from 'components/Searchbar'

import usePosOrLoanzifyDocuments from '../../hooks/usePosOrLoanzifyDocuments'
import { useApplication } from '../../store/Application'
import { getInstallsWithDocuments } from '../../utils/shared-services'

const InstallationsPage = () => {
  usePosOrLoanzifyDocuments()
  const { hasPos } = useApplication()

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [installations, setInstallations] = useState([])

  const loadInstallations = ({ search, page }) => {
    const mergeInstallations = newInstallations =>
      setInstallations(installations => [...installations, ...newInstallations])

    return getInstallsWithDocuments(page, hasPos, search)
      .then(installs => {
        page === 1 ? setInstallations(installs) : mergeInstallations(installs)
      })
      .then(() => setLoading(false))
  }

  useEffect(() => {
    setPage(1)
    setLoading(true)

    loadInstallations({ page: 1, search }).finally(() => setLoading(false))
  }, [search])

  const loadMore = async event => {
    await loadInstallations({ page: page + 1, search })
    setPage(page => page + 1)
    event.target.complete()
  }

  return (
    <>
      <IonLoading isOpen={loading} message="Loading..." />

      <SearchWrapper>
        <Searchbar value={search} onIonChange={e => setSearch(e.detail.value)} debounce={500} />
      </SearchWrapper>

      <InstallationList installations={installations} />

      <IonInfiniteScroll onIonInfinite={loadMore}>
        <IonInfiniteScrollContent loadingText="Loading..." />
      </IonInfiniteScroll>
    </>
  )
}

const SearchWrapper = styled.div``
export default InstallationsPage
