import React from 'react'
import { Redirect } from 'react-router-dom'

import { useSession } from '../../store/Session'

const AuthRedirectorPage = () => {
  const { show_welcome_screen } = useSession()

  if (!show_welcome_screen) {
    return <Redirect to="/auth/email" />
  }

  return <Redirect to="/auth/welcome" />
}

export default AuthRedirectorPage
