import React, { useEffect, useState } from 'react'

import { getWholeChar } from 'stream-chat-react/dist/utils'

import type { UserResponse } from 'stream-chat'

import type { DefaultUserType } from 'stream-chat-react/dist/types'

export type AvatarProps<Us extends DefaultUserType<Us> = DefaultUserType> = {
  /** Image URL or default is an image of the first initial of the name if there is one  */
  image?: string | null
  /** Name of the image, used for title tag fallback */
  name?: string
  /** click event handler */
  onClick?: (event: React.BaseSyntheticEvent) => void
  /** mouseOver event handler */
  onMouseOver?: (event: React.BaseSyntheticEvent) => void
  /** Shape of the avatar - circle, rounded or square
   * @default circle
   */
  shape?: 'circle' | 'rounded' | 'square'
  /** Size in pixels
   * @default 32px
   */
  size?: number
  /** The entire user object for the chat user displayed in the component */
  user?: UserResponse<Us>
}

/**
 * A round avatar image with fallback to username's first letter
 */
const ConversationAvatar = <Us extends DefaultUserType<Us> = DefaultUserType>(props: AvatarProps<Us>) => {
  const { image, name, onClick = () => undefined, onMouseOver = () => undefined, shape = 'circle', size = 32 } = props

  const [error, setError] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setError(false)
    setLoaded(false)
  }, [image])

  const nameStr = name?.toString() || ''
  const initials = getWholeChar(nameStr.split(' ')[0], 0) + '' + getWholeChar(nameStr.split(' ')[1], 0)

  return (
    <div
      className={`str-chat__avatar str-chat__avatar--${shape}`}
      data-testid="avatar"
      onClick={onClick}
      onMouseOver={onMouseOver}
      style={{
        flexBasis: `${size}px`,
        fontSize: `${size / 2}px`,
        height: `${size}px`,
        lineHeight: `${size}px`,
        width: `${size}px`,
      }}
      title={name}
    >
      {image && !error ? (
        <img
          alt={initials}
          className={`str-chat__avatar-image${loaded ? ' str-chat__avatar-image--loaded' : ''}`}
          data-testid="avatar-img"
          onError={() => setError(true)}
          onLoad={() => setLoaded(true)}
          src={image}
          style={{
            flexBasis: `${size}px`,
            height: `${size}px`,
            objectFit: 'cover',
            width: `${size}px`,
          }}
        />
      ) : (
        <div className="str-chat__avatar-fallback" data-testid="avatar-fallback">
          {initials}
        </div>
      )}
    </div>
  )
}

export default ConversationAvatar
