import React from 'react'
import styled from 'styled-components'

import Label from 'components/Label'
import StartUploadingButton from './StartUploadingButton'
import DocumentsAnimation from 'components/animations/DocumentsAnimation'

import { ReactComponent as CheckmarkIcon } from 'legacy-icons/documents/checkmark.svg'

const Placeholder = ({ showImage = true, onFilesSelected }) => (
  <PlaceholderContainer showImage={showImage}>
    <DocumentsAnimation width={250} style={{ margin: '0 auto -20px' }} />

    <Label size="xl" color="primary" isWrapped center style={{ marginTop: '1rem' }}>
      Send Your <br /> Documents Securely
    </Label>

    <List>
      {['Scan a Doc', 'Send a File', 'Drafts & Sent'].map(item => (
        <ListItem key={item}>
          <CheckmarkIcon />
          <Label color="gray">{item}</Label>
        </ListItem>
      ))}
    </List>

    <StartUploadingButton onFilesSelected={onFilesSelected} />
  </PlaceholderContainer>
)

const PlaceholderContainer = styled.div`
  ${props =>
    !props.showImage &&
    `
    margin-top: 3rem;
  `}
`

const List = styled.div`
  width: 165px;
  margin: 1rem auto 2rem;
`

const ListItem = styled.div`
  display: flex;
  margin-bottom: 0.75rem;

  ion-label {
    margin-left: 0.75rem;
  }
`

export default Placeholder
