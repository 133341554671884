import React from 'react'
import { IonSlides, IonSlide, IonContent } from '@ionic/react'
import styled from 'styled-components'
import Button from './components/Button'

import BrandedWelcomeSlide from './../../components/BrandedWelcomeSlide'

import SlideTwo from './../../images/homepage/screen-2-4x.png'
import SlideThree from './../../images/homepage/screen-3-4x.png'
import SlideFour from './../../images/homepage/screen-4-4x.png'
import SlideFive from './../../images/homepage/screen-5-4x.png'
import { useSession } from '../../store/Session'
import { Redirect } from 'react-router-dom'
import { useApplication } from '../../store/Application'

const slideOpts = {
  initialSlide: 0,
  speed: 400,
}

const StyledIonContent = styled(IonContent)`
  --background: var(--color-primary);
`

const StyledIonSlides = styled(IonSlides)`
  height: 85%;
  --bullet-background: var(--color-background);
  --bullet-background-active: var(--color-background);
`

const ButtonsWrapper = styled.div`
  height: 15%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
`

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  align-self: center;
`

const CustomText = styled.h1`
  align-self: flex-end;
  color: var(--color-background);
`

const StyledIonSlide = styled(IonSlide)`
  padding-bottom: 3rem;
  background-size: cover !important;
  position: relative;
`

const StyledImage = styled.img`
  object-fit: fill !important;
  position: absolute;
  left: 0;
  right: 0;
  width: 100% !important;
  max-width: initial !important;
`

const DefaultSlideHandler = props => {
  return (
    <React.Fragment>
      <StyledImage src={props.imageUrl} />
      <CustomText>{props.text}</CustomText>
    </React.Fragment>
  )
}

const SlideRenderer = props => {
  const { text, imageUrl, component } = props
  const SpecificImageHandlerComponent = component

  if (!SpecificImageHandlerComponent) {
    return <DefaultSlideHandler {...props} />
  }

  return (
    <React.Fragment>
      <SpecificImageHandlerComponent src={imageUrl} />
      <CustomText>{text}</CustomText>
    </React.Fragment>
  )
}

const WelcomePage = () => {
  let {
    owner,
    photoUrl,
    license,
    nmls,
    application: { user1, user2 },
  } = useApplication()

  const { token, show_welcome_screen } = useSession()

  const thePhotoUrl = user2 ? user1.photo_url : photoUrl

  const slides = [
    {
      text: 'Your Mortgage App to...',
      component: () => {
        return <BrandedWelcomeSlide {...{ photoUrl: thePhotoUrl, owner, license, nmls, user1, user2 }} />
      },
    },
    {
      text: 'Apply & Get Pre-Approved',
      imageUrl: SlideTwo,
    },
    {
      text: 'Calculate Monthly Payments',
      imageUrl: SlideThree,
    },
    {
      text: 'Upload Loan Docs',
      imageUrl: SlideFour,
    },
    {
      text: 'Connect with a Loan Officer',
      imageUrl: SlideFive,
    },
  ]

  if (token) {
    return <Redirect to="/home" />
  }

  if (!show_welcome_screen) {
    return <Redirect to="/auth/email" />
  }

  return (
    <StyledIonContent>
      <StyledIonSlides pager={true} options={slideOpts}>
        {slides.map(({ text, imageUrl, component }, index) => (
          <StyledIonSlide key={`ion-home-${index}`}>
            <SlideRenderer {...{ text, imageUrl, component }} />
          </StyledIonSlide>
        ))}
      </StyledIonSlides>
      <ButtonsWrapper>
        <StyledButton routerLink="/auth/email">Get Started</StyledButton>
      </ButtonsWrapper>
    </StyledIonContent>
  )
}

export default WelcomePage
