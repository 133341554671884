import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import PrivateRoute from 'layouts/auth/PrivateRoute'

import LoginPage from 'pages/auth/Login'
import LogoutPage from 'pages/auth/Logout'
import SelectRolePage from 'pages/auth/SelectRole'
import SelectLoanPage from 'pages/auth/SelectLoan'
import CheckEmailPage from 'pages/auth/CheckEmail'
import RegisterNamePage from 'pages/auth/RegisterName'
import SelectBranchPage from 'pages/auth/SelectBranch'
import RegisterPhonePage from 'pages/auth/RegisterPhone'
import AuthRedirectorPage from 'pages/auth/AuthRedirector'
import RegisterPasswordPage from 'pages/auth/RegisterPassword'
import SelectLoanOfficerPage from 'pages/auth/SelectLoanOfficer'
import WelcomePage from 'pages/auth/Welcome'
import RegisterQuestionsPage from 'pages/auth/RegisterQuestions'
import ForceResetPasswordPage from 'pages/auth/ForceResetPassword'
import DeleteAccountPage from 'pages/auth/Delete'

import { useApplication } from 'store/Application'
import ResetPasswordPage from 'pages/auth/ResetPassword'
import ClaimAppPage from 'pages/auth/ClaimApp'
import LoginUsingTokenPage from 'pages/auth/LoginUsingToken'
import MagicLinkSent from 'pages/auth/MagicLinkSent'
import ExtendTrialPage from 'pages/auth/ExtendTrial'
import VerifyPhonePage from 'pages/auth/VerifyPhonePage'

const AuthRoutes = () => {
  const { isPreview } = useApplication()

  if (isPreview) {
    return <Redirect to="/home" />
  }

  return (
    <>
      <Route exact path="/auth" component={AuthRedirectorPage} />
      <Route exact path="/auth/welcome" component={WelcomePage} />
      <Route exact path="/auth/verify-phone" component={VerifyPhonePage} />
      <Route exact path="/auth/email" component={CheckEmailPage} />
      <Route exact path="/auth/login" component={LoginPage} />
      <Route exact path="/auth/magic-link-sent" component={MagicLinkSent} />
      <Route exact path="/auth/register/name" component={RegisterNamePage} />
      <Route exact path="/auth/register/phone" component={RegisterPhonePage} />
      <Route exact path="/auth/register/password" component={RegisterPasswordPage} />
      <Route exact path="/auth/register/questions" component={RegisterQuestionsPage} />
      <Route exact path="/auth/select-role" component={SelectRolePage} />
      <Route exact path="/auth/select-loan" component={SelectLoanPage} />
      <Route exact path="/auth/select-loan-officer" component={SelectLoanOfficerPage} />
      <Route exact path="/auth/select-branch" component={SelectBranchPage} />
      <Route exact path="/auth/force-password-reset" component={ForceResetPasswordPage} />
      <Route exact path="/auth/reset-password/:token/:email" component={ResetPasswordPage} />
      <Route exact path="/auth/token-login/:token/:email/:broker" component={LoginUsingTokenPage} />
      <Route exact path="/auth/claim" component={ClaimAppPage} />
      <Route exact path="/auth/extend-trial" component={ExtendTrialPage} />
      <PrivateRoute exact path="/auth/logout" component={LogoutPage} />
      <PrivateRoute exact path="/auth/delete" component={DeleteAccountPage} />
    </>
  )
}

export default AuthRoutes
