import React from 'react';

import CalculatorResultPage from 'containers/CalculatorResultPage';

import ResultContent from './components/ResultContent';

const PurchaseResultPage: React.FC = () => {
  return (
    <CalculatorResultPage
      calculator="purchase"
      calculate={window.lhpCalculator.calculate}
      renderContent={result => <ResultContent result={result} onSlideLeft={() => {}} onSlideRight={() => {}} />}
    />
  );
};

export default PurchaseResultPage;
