import React from 'react'
import styled from 'styled-components'
import { IonContent, IonItem, IonLabel, IonList } from '@ionic/react'

import { formatCurrency, formatPercent } from 'utils/formatters'

const ValueLabel = styled(IonLabel)`
  flex: 0 0 auto;
  font-weight: 500;
  text-align: right;
`

const ResultDetails = ({ options, result }) => (
  <IonContent>
    <IonList lines="full">
      <IonItem className="ion-text-center">
        <IonLabel>
          <h1>Calculation Details</h1>
        </IonLabel>
      </IonItem>

      <IonItem>
        <IonLabel>Property Price</IonLabel>
        <ValueLabel>{formatCurrency(options.property_price)}</ValueLabel>
      </IonItem>

      <IonItem>
        <IonLabel>Down Payment</IonLabel>
        <ValueLabel>{formatCurrency(options.down_payment)}</ValueLabel>
      </IonItem>

      <IonItem>
        <IonLabel>Total Loan Amount</IonLabel>
        <ValueLabel>{formatCurrency(options.property_price - options.down_payment)}</ValueLabel>
      </IonItem>

      <IonItem>
        <IonLabel>Interest Rate</IonLabel>
        <ValueLabel>{formatPercent(options.interest_rate, 3)}</ValueLabel>
      </IonItem>

      <IonItem>
        <IonLabel>Principal & Interest</IonLabel>
        <ValueLabel>{formatCurrency(result.principal_interest)}</ValueLabel>
      </IonItem>

      <IonItem>
        <IonLabel>Monthly Taxes</IonLabel>
        <ValueLabel>{formatCurrency(result.taxes)}</ValueLabel>
      </IonItem>

      <IonItem>
        <IonLabel>Monthly Hazard Insurance</IonLabel>
        <ValueLabel>{formatCurrency(result.annual_insurance_rate || result.hazard_insurance)}</ValueLabel>
      </IonItem>

      <IonItem>
        <IonLabel>Monthly Mortgage Insurance</IonLabel>
        <ValueLabel>{formatCurrency(result.mortgage_insurance)}</ValueLabel>
      </IonItem>

      <IonItem>
        <IonLabel>Monthly Total</IonLabel>
        <ValueLabel>{formatCurrency(result.total)}</ValueLabel>
      </IonItem>
    </IonList>
  </IonContent>
)

export default ResultDetails
