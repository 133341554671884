import React from 'react'
import { IonRouterOutlet } from '@ionic/react'

import PrivateRoute from 'layouts/auth/PrivateRoute'

import MilestoneListPage from '../pages/application/Index'
import ApplicationFormPage from '../pages/application/Form'
import NewShortFormPage from '../pages/application/NewShortForm'
import ShortFormSuccessPage from '../pages/application/ShortFormSuccess'

const ApplicationRoutes = () => (
  <IonRouterOutlet>
    <PrivateRoute exact path="/application" component={MilestoneListPage} />
    <PrivateRoute exact path="/application/form" component={ApplicationFormPage} />
    <PrivateRoute exact path="/application/short-form" component={NewShortFormPage} />
    <PrivateRoute exact path="/application/short-form-success" component={ShortFormSuccessPage} />
  </IonRouterOutlet>
)

export default ApplicationRoutes
