import React, { createContext, useContext, useState } from 'react';
import { getLetterParams, storeLetterParams } from '../api/pos';
import { PreApprovalState } from '../types/PreApprovals';
import { Loan } from '../types/Pos';

const PreApprovalContext = createContext({} as PreApprovalState);

type PreApprovalsProviderProps = {
  loan: Loan;
};

export const PreApprovalsProvider = (props: PreApprovalsProviderProps) => {
  const { loan } = props;

  const [letterParams, setLetterParams] = useState({
    maxSalePrice: loan.pre_approval_letter_params?.maxSalePrice,
    maxDownPayment: loan.pre_approval_letter_params?.maxDownPayment,
    minDownPayment: loan.pre_approval_letter_params?.minDownPayment,
  });

  const fetchLetterParams = (loan_id: number) => {
    getLetterParams(loan_id).then(params => setLetterParams(params));
  };

  const saveLetterParams = (loan_id: number, params: any = null) => {
    if (params) {
      setLetterParams(params);
    }

    return storeLetterParams(loan_id, {
      pre_approval_letter_params: params,
    }).then(data => {
      return Promise.resolve(data);
    });
  };

  const contextValue: PreApprovalState = {
    fetchLetterParams,
    letterParams,
    saveLetterParams,
    setLetterParams,
  };

  return <PreApprovalContext.Provider {...props} value={contextValue} />;
};

export const usePreApprovals = () => useContext(PreApprovalContext);
