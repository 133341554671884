import * as React from 'react'
import ModalContent from '../../../components/ModalContent'
import { LoanMilestoneRecipient } from '../../../types/Pos'
import { IonContent } from '@ionic/react'

import RecipientNotificationItem from './RecipientNotificationItem'
import styled from 'styled-components'
import Button from '../../../components/Button'

type Props = {
  close: () => void
  confirm: () => void
  recipientList: LoanMilestoneRecipient[]
}

const MilestoneRecipientNotificationModal: React.FC<Props> = props => {
  const { close, confirm, recipientList } = props

  return (
    <ModalContent dismiss={close} title="Configure Notification Settings" theme={{ bordered: true }}>
      <IonContent>
        {recipientList.map((r, index) => (
          <RecipientNotificationItem key={`${r.name}-${index}`} recipient={r} />
        ))}
        <ActionContainer>
          <Button size="md" color="light" onClick={() => close()}>
            {' '}
            Cancel{' '}
          </Button>
          <Button
            size="md"
            color="primary"
            onClick={() =>
              confirm({
                recipients: recipientList,
              })
            }
          >
            {' '}
            Update & Send{' '}
          </Button>
        </ActionContainer>
      </IonContent>
    </ModalContent>
  )
}

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export default MilestoneRecipientNotificationModal
