import React from 'react'
import styled from 'styled-components/macro'

import Button from 'components/Button'
import Label from 'components/Label'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser'
import TaskCard from 'pages/home/components/TaskCard'
import { BrowserTask } from '../../../types/Pos'

interface Props {
  task: BrowserTask
}

const TaskCardWithBrowser: React.FC<Props> = ({ task }) => {
  const {
    data: { title, description, action, action_text, url },
  } = task
  const doAction = () => {
    if (action === 'in-app-browser') {
      InAppBrowser.create(url)
    }
  }

  return (
    <TaskCard>
      <Container>
        <ContentContainer>
          <Label weight={500} color="gray">
            {title || action_text}
          </Label>
          {description && (
            <LabelContainer>
              <Label size="xs" color="gray-light">
                {description}
              </Label>
            </LabelContainer>
          )}
          <Button size="sm" color="secondary" onClick={() => doAction()} margin="10px 0 0 auto">
            {action_text}
          </Button>
        </ContentContainer>
      </Container>
    </TaskCard>
  )
}
const Container = styled.div`
  display: flex;
  align-items: center;

  .chart-container {
    width: 40%;
  }

  .content-container {
    display: flex;
    flex-direction: column;
  }

  ion-button {
    margin-top: 10px;
  }

  .labels {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .label {
      width: 9px;
      height: 9px;
      border-radius: 99%;
      margin-right: 10px;

      &.primary {
        background: var(--color-primary);
      }

      &.blue-lightest {
        background: var(--color-blue-lightest);
      }
    }

    ion-label {
      margin-right: 10px;
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  ion-label {
    word-wrap: normal;
    white-space: normal;
  }
`

export default TaskCardWithBrowser
