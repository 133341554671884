import React from 'react';
import styled from 'styled-components';
import { IonRouterLink } from '@ionic/react';

import parseISO from 'date-fns/parseISO';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import StyledLabel from 'components/Label';
import ViewDocumentFilesButton from 'pages/documents/components/ViewDocumentFilesButton';
import AcceptOrRejectDocument from './AcceptOrRejectDocument';

const DocumentItemDetails = ({ document, onReviewed }) => (
  <ItemBodyContainer>
    <BodyRow>
      <StyledLabel size="xs" color="gray" weight={600} secondary>
        Category
      </StyledLabel>

      <StyledLabel size="xs" color="gray-light" secondary>
        {document.category}
      </StyledLabel>
    </BodyRow>

    <BodyRow>
      <StyledLabel size="xs" color="gray" weight={600} secondary>
        Last Activity
      </StyledLabel>

      <StyledLabel size="xs" color="gray-light" secondary>
        {formatDistanceToNow(parseISO(document.updated_at))} ago
      </StyledLabel>
    </BodyRow>

    <BodyRow>
      <IonRouterLink routerLink={`/documents/${document.id}/history`}>
        <StyledLabel size="xs" fontStyle="italic" color="primary-light">
          View Doc History
        </StyledLabel>
      </IonRouterLink>

      {onReviewed && (
        <AcceptOrRejectDocument
          document={document}
          onReviewed={onReviewed}
          render={open => (
            <StyledLabel
              style={{ paddingTop: '1px', marginLeft: '15px' }}
              size="xs"
              fontStyle="italic"
              color="primary-light"
              onClick={open}
            >
              Accept or Reject
            </StyledLabel>
          )}
        />
      )}

      <ViewDocumentFilesButton
        document={document}
        render={open => (
          <StyledLabel
            style={{ paddingTop: '1px', marginLeft: '15px' }}
            size="xs"
            fontStyle="italic"
            color="primary-light"
            onClick={() => open(document)}
          >
            View Doc
          </StyledLabel>
        )}
      />
    </BodyRow>
  </ItemBodyContainer>
);

const ItemBodyContainer = styled.div`
  margin-top: 0.5rem;
`;

const BodyRow = styled.div`
  display: flex;
  align-items: center;

  margin-top: 0rem;
  padding: 0.124rem;

  > :first-child {
    width: 30%;
  }
`;

export default DocumentItemDetails;
