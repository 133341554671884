import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getRecentStats } from '../../../../api/loanzify'
import { IonSpinner } from '@ionic/react'
import LineChart from './components/LineChart'

const COLOR_NAMES = ['primary', 'primary-light']

const Wrapper = styled.div`
  text-align: center;
`

const RecentInstallsChart = () => {
  const [loading, setLoading] = useState(true)
  const [installsData, setinstallsData] = useState([])

  const chartLabels = () => installsData.map(data => data.date)
  const chartValues = () => installsData.map(data => data.count)

  useEffect(() => {
    async function loadInitialData() {
      setLoading(true)
      let recentStats = {}
      try {
        const recentStatsData = await getRecentStats(30, 1)
        recentStats = {
          ...recentStatsData,
        }
      } catch (e) {}

      setinstallsData(recentStats.installs_daily || [])
      setLoading(false)
    }

    loadInitialData()
  }, [])

  return (
    <Wrapper>
      {loading && <IonSpinner />}
      {!loading && (
        <LineChart data={chartValues()} labels={chartLabels()} colorNames={COLOR_NAMES} showLegend={false} />
      )}
    </Wrapper>
  )
}

export default RecentInstallsChart
