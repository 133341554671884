import React from 'react'
import styled from 'styled-components'
import { IonGrid, IonRow, IonCol } from '@ionic/react'

import CardIconButton from 'components/CardIconButton'
import { ReactComponent as ShareIcon } from 'legacy-icons/share.svg'
import ShareButton from 'components/ShareButton'

const StyledIonGrid = styled(IonGrid)`
  --ion-grid-column-padding: 0.5rem;
  padding: 1rem 0.5rem;
`

const Share = ({ owner }) => (
  <StyledIonGrid>
    <IonRow>
      <IonCol offset={3} size={6}>
        <ShareButton
          render={(openShare: () => void) => (
            <CardIconButton icon={ShareIcon} title="Share App" className="ion-no-margin" onClick={openShare} />
          )}
        />
      </IonCol>
    </IonRow>
  </StyledIonGrid>
)

export default Share
