import { useCallback } from 'react'
import { initialSession, useSession } from 'store/Session'

const useApplicationSetter = () => {
  const { setSession, application_id } = useSession()

  const setApplication = useCallback(
    id => {
      if (!id) {
        return
      }

      if (id === application_id) {
        return
      }

      setSession(session => ({ ...initialSession, application_id: id }))
    },
    [setSession, application_id]
  )

  return setApplication
}

export default useApplicationSetter
