import React from 'react'
import { IonPage, IonContent } from '@ionic/react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { Browser } from '@capacitor/browser'

import { ReactComponent as DefaultLogo } from 'legacy-icons/logo.svg'

import Button from 'components/Button'

const LogoImageWrapper = styled.div`
  text-align: center;
  padding: 2rem 0;
`

const TextWrapper = styled.div`
  text-align: center;
  padding: 0 2rem;
`

const Title = styled.h6`
  margin-bottom: 1.25rem;
`

const Text = styled.p`
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
`
const ContinueButton = styled(Button)`
  margin-top: 4rem;
`

const TrialWelcomePage = () => {
  const history = useHistory()

  return (
    <IonPage>
      <IonContent>
        <LogoImageWrapper>
          <DefaultLogo />
        </LogoImageWrapper>
        <TextWrapper>
          <Title style={{ marginBottom: '2.5rem' }}>
            Thank you for activating your Loanzify Trial,
            <br />
            the Ultimate Mortgage Calculator App.
          </Title>
          <Title>
            If you are looking for a <br />
            Solution for the Entire Buyer Journey
          </Title>
          <Button
            onClick={() =>
              Browser.open({
                url: 'https://www.loanzify.com/book-a-demo/?utm_source=mobileapp&utm_medium=welcome&utm_campaign=welcome',
              })
            }
            size="normal"
            style={{ margin: '0' }}
          >
            Book a Demo
          </Button>
          <Text>To Upgrade your trial with POS</Text>
          <ContinueButton onClick={() => history.push('/home')} size="md" center>
            Continue with Trial
          </ContinueButton>
        </TextWrapper>
      </IonContent>
    </IonPage>
  )
}

export default TrialWelcomePage
