import React, { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as InfoIcon } from 'legacy-icons/info.svg'
import { ReactComponent as CloseIcon } from 'legacy-icons/close.svg'

const Container = styled.div`
  background: var(--color-gray-lightest);
  border-radius: 8px;
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
`

const IconWrapper = styled.div`
  background: var(--color-gray-lighter);
  padding-left: 12px;
  padding-right: 12px;
  display: flex;

  svg {
    margin: auto 0;
  }
`

const TextWrapper = styled.div`
  padding-right: 20px;
  padding: 13px;
  font-size: 13px;
  font-family: var(--font-secondary);
  color: var(--color-gray);
`

const ToggleContainer = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
`

const ToggleTextWrapper = styled.div`
  display: flex;
  align-self: center;
  font-family: var(--font-secondary);
  color: var(--color-gray-light);
  font-size: 11px;
  margin-bottom: 10px;
`

const ToggleIconWrapper = styled.div`
  padding-left: 6px;
  padding-right: 0;
  display: flex;
  margin-bottom: 10px;

  svg {
    margin: auto 0;
  }
`

const StyledClose = styled(CloseIcon)`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 14px;
`

const InfoBox = ({ text }) => {
  const [show, setShow] = useState(false)

  return (
    <>
      {!show && (
        <ToggleContainer onClick={() => setShow(true)}>
          <ToggleTextWrapper>Help Tips</ToggleTextWrapper>
          <ToggleIconWrapper>
            <InfoIcon width={12} height={12} />
          </ToggleIconWrapper>
        </ToggleContainer>
      )}
      {show && (
        <Container onClick={() => setShow(false)}>
          <StyledClose />
          <IconWrapper>
            <InfoIcon />
          </IconWrapper>
          <TextWrapper>{text}</TextWrapper>
        </Container>
      )}
    </>
  )
}

export default InfoBox
