import * as React from 'react'
import { Channel, ChatContext } from 'stream-chat-react'
import { useContext, useEffect, useState } from 'react'
import { useStreamChat } from 'store/StreamChat'
import { IonContent, IonLoading } from '@ionic/react'
import styled from 'styled-components'
import ChannelInner from './ChannelInner'

const StyledIonContent = styled(IonContent)`
  .str-chat {
    height: 100%;
  }
`

const SingleConversation: React.FC = props => {
  const { conversation } = props
  const { client } = useStreamChat()
  const [loading, setLoading] = useState<boolean>(true)
  const { setActiveChannel } = useContext(ChatContext)

  useEffect(() => {
    let mounted = true

    if (conversation && mounted) {
      client.queryChannels({ type: 'loan', cid: { $in: [`loan:${conversation}`] } }).then(async c => {
        if (c.length > 0) {
          setActiveChannel(c[0])
        }

        setLoading(false)
      })
    }

    return () => {
      mounted = false
    }
  }, [conversation])

  if (loading) {
    return <IonLoading isOpen={loading} />
  }

  return (
    <StyledIonContent>
      <Channel>
        <ChannelInner />
      </Channel>
    </StyledIonContent>
  )
}

export default SingleConversation
