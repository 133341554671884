import React from 'react'
import styled from 'styled-components'

const ListedItem = styled.div`
  padding: 0.75rem 1rem 0.75rem ${props => (props.matchHeaderSpacing ? 'calc(1rem + 10px)' : '1rem')};
  &:nth-child(even) {
    background: var(--color-background);
  }
`

const ListItem = ({ children, matchHeaderSpacing = false }) => {
  return <ListedItem matchHeaderSpacing={matchHeaderSpacing}>{children}</ListedItem>
}

export default ListItem
