import React from 'react'

import TaskCardWithGraph from './TaskCardWithGraph'
import { DocumentTask } from '../../../types/Pos'

interface Props {
  task: DocumentTask
}

const DocumentsTaskCard: React.FC<Props> = ({ task }) => {
  const {
    data: { accepted, pending, rejected, total },
  } = task

  const totalDocsUploaded = accepted + pending

  const progress = totalDocsUploaded === 0 ? 0 : (totalDocsUploaded / total) * 100

  return (
    <TaskCardWithGraph
      title={`${total - totalDocsUploaded} Docs Owed`}
      progress={progress}
      progressText={`${pending} / ${total}`}
      accepted={accepted}
      rejected={rejected}
      submitted={pending}
      actionText="Start Uploading"
      actionRouterLink="/documents"
    />
  )
}

export default DocumentsTaskCard
