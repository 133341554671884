import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LoanCardSection from '../../../../components/loans/LoanCardSection'

import { getLoanIntegrationHistory, postLoanIntegration } from '../../../../api/pos'

import List from '../../../../components/loans/List'
import ListItem from '../../../../components/loans/ListItem'
import Label from '../../../../components/Label'
import Button from '../../../../components/Button'

import { ReactComponent as CreatedSvg } from 'legacy-icons/integrations/created.svg'
import { ReactComponent as ErrorSvg } from 'legacy-icons/integrations/error.svg'
import { Toast } from '@capacitor/toast'

const LeadSourcesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
`

const Table = styled.table``

const TableHeaderCell = styled.td`
  width: 115px;

  vertical-align: middle;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--color-gray);

  padding-bottom: 0.4rem;
`

const TableInfoCell = styled.td`
  font-size: 0.75rem;
  vertical-align: middle;
  padding-bottom: 0.4rem;
  color: var(--color-gray-light);
  font-weight: 500;
`

const TableMinorInfoCell = styled.td`
  font-size: 0.75rem;
  vertical-align: middle;
  padding-bottom: 0.4rem;
  color: var(--color-gray-light);
  font-style: italic;

  svg {
    height: 15px;
    width: 15px;
    display: inline-block;
    vertical-align: text-bottom;
  }
`

const Integrations = ({ loan }) => {
  const [integrationServices, setIntegrationServices] = useState([])
  const [integrationHistory, setIntegrationHistory] = useState([])

  const IconSvg = status => {
    switch (status.toLowerCase()) {
      case 'created':
        return <CreatedSvg />
      case 'error':
        return <ErrorSvg />
      default:
        return
    }
  }

  const refreshIntegrations = () => {
    getLoanIntegrationHistory(loan.id).then(data => {
      const services = (data.dataPostingServices || []).map(service => {
        return {
          id: service.key,
          key: service.key,
          title: service.name,
          enabled: service.loanPostingEnabled,
          loading: false,
        }
      })
      setIntegrationServices(services)

      const history = (data.integrationLogs || []).map(integration => {
        return {
          data: integration.data || {},
          service: integration.service_key.toUpperCase(),
          record: integration.service_id || 'N/A',
          status: integration.status,
          date: integration.created_at,
        }
      })
      setIntegrationHistory(history)
    })
  }

  const setServiceLoading = (service_key, is_loading) => {
    const tempServices = [...integrationServices].map(item => {
      return {
        ...item,
        loading: item.key === service_key ? is_loading : item.loading,
      }
    })

    setIntegrationServices(tempServices)
  }

  const submitLeadData = service_key => {
    setServiceLoading(service_key, true)
    postLoanIntegration(loan.id, service_key)
      .then(response => {
        refreshIntegrations()
      })
      .catch(() => Toast.show({ text: 'There was an error submitting lead. Please try again' }))
      .finally(() => {
        setServiceLoading(service_key, false)
      })
  }

  useEffect(() => {
    refreshIntegrations()
  }, [])

  return (
    <>
      <LoanCardSection title="Manually Submit Lead Data" noBottomPadding noSidePadding showActionsIcon={false}>
        <List>
          {integrationServices.map((source, index) => (
            <ListItem key={`integration-service-${index}`}>
              <LeadSourcesWrapper>
                <Label color="gray-light">{source.title}</Label>
                <Button
                  size="xs"
                  color="secondary"
                  disabled={source.loading}
                  onClick={() => submitLeadData(source.key)}
                >
                  Submit Lead Data
                </Button>
              </LeadSourcesWrapper>
            </ListItem>
          ))}
        </List>
      </LoanCardSection>
      <LoanCardSection title="Integration History" noBottomPadding noSidePadding showActionsIcon={false}>
        <List>
          {integrationHistory.map((history, index) => (
            <ListItem key={`integration-history-${history.record}-${index}`}>
              <Table>
                <tbody>
                  <tr>
                    <TableHeaderCell>Service</TableHeaderCell>
                    <TableInfoCell>{history.service}</TableInfoCell>
                  </tr>

                  <tr>
                    <TableHeaderCell>Record #</TableHeaderCell>
                    <TableInfoCell>{history.record}</TableInfoCell>
                  </tr>

                  <tr>
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableMinorInfoCell>
                      {IconSvg(history.status)} {history.status}
                    </TableMinorInfoCell>
                  </tr>

                  <tr>
                    <TableHeaderCell>Submission Date</TableHeaderCell>
                    <TableMinorInfoCell>{history.date}</TableMinorInfoCell>
                  </tr>
                </tbody>
              </Table>
            </ListItem>
          ))}
        </List>
      </LoanCardSection>
    </>
  )
}

export default Integrations
