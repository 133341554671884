import { useCallback, useState } from 'react'
import set from 'lodash/fp/set'
import get from 'lodash/get'

import { useCalculator } from 'store/Calculator'

// Mapping of fields between calculator defaults and options
const DEFAULTS_CONFIG = [
  {
    optionKey: 'loan_amount',
    getDefaultValue: defaults => {
      const propertyPrice = parseFloat(defaults['property_price'])
      const downPaymentRate = parseFloat(defaults['down_payment'])

      return (propertyPrice * (100 - downPaymentRate)) / 100
    },
  },
  {
    optionKey: 'property_price',
    getDefaultValue: defaults => defaults['property_price'],
  },
  {
    optionKey: 'down_payment_rate',
    getDefaultValue: defaults => defaults['down_payment'],
  },
  {
    optionKey: 'interest_rate',
    getDefaultValue: defaults => defaults['interest_rate_30'],
  },
  {
    optionKey: 'term',
    getDefaultValue: defaults => defaults['mortgage_term'],
  },
  {
    optionKey: 'annual_insurance_rate',
    getDefaultValue: defaults => defaults['annual_insurance_rate'],
  },
  {
    optionKey: 'property_tax',
    getDefaultValue: defaults => defaults['property_tax'],
  },
  {
    optionKey: 'upfront_fee',
    getDefaultValue: defaults => defaults['upfront_fee'],
  },
  {
    optionKey: 'Upfront1stActiveFee5percent',
    getDefaultValue: defaults => defaults['Upfront1stActiveFee5percent'],
  },
  {
    optionKey: 'Upfront1stActiveFee10percent',
    getDefaultValue: defaults => defaults['Upfront1stActiveFee10percent'],
  },
  {
    optionKey: 'Upfront1stActiveFeeNone',
    getDefaultValue: defaults => defaults['Upfront1stActiveFeeNone'],
  },
  {
    optionKey: 'Upfront1stNotActiveFee5percent',
    getDefaultValue: defaults => defaults['Upfront1stNotActiveFee5percent'],
  },
  {
    optionKey: 'Upfront1stNotActiveFee10percent',
    getDefaultValue: defaults => defaults['Upfront1stNotActiveFee10percent'],
  },
  {
    optionKey: 'Upfront1stNotActiveFeeNone',
    getDefaultValue: defaults => defaults['Upfront1stNotActiveFeeNone'],
  },
  {
    optionKey: 'UpfrontActiveFee5percent',
    getDefaultValue: defaults => defaults['UpfrontActiveFee5percent'],
  },
  {
    optionKey: 'UpfrontActiveFee10percent',
    getDefaultValue: defaults => defaults['UpfrontActiveFee10percent'],
  },
  {
    optionKey: 'UpfrontActiveFeeNone',
    getDefaultValue: defaults => defaults['UpfrontActiveFeeNone'],
  },
  {
    optionKey: 'UpfrontNotActiveFee5percent',
    getDefaultValue: defaults => defaults['UpfrontNotActiveFee5percent'],
  },
  {
    optionKey: 'UpfrontNotActiveFee10percent',
    getDefaultValue: defaults => defaults['UpfrontNotActiveFee10percent'],
  },
  {
    optionKey: 'UpfrontNotActiveFeeNone',
    getDefaultValue: defaults => defaults['UpfrontNotActiveFeeNone'],
  },
  {
    optionKey: 'factors',
    getDefaultValue: defaults => {
      return (defaults['factors'] || []).map(item => {
        return {
          fico_max: parseInt(item.fico_max),
          fico_min: parseInt(item.fico_min),
          ltv_max: parseFloat(item.ltv_max),
          ltv_min: parseFloat(item.ltv_min),
          mi: parseFloat(item.mi),
          years: parseInt(item.years),
          loan_min: parseInt(item?.loan_min),
          loan_max: parseInt(item?.loan_max),
        }
      })
    },
  },
]

/**
 * Caches calculator option values per loan type with default values being loaded
 */
const useCalculatorCacheWithDefaults = () => {
  const { options, calculatorDefaultsByType, defaultOptions, setOptions } = useCalculator()
  const [cache, setCache] = useState({})
  const [persistKeys, setPersistKeys] = useState([])

  const cacheKey = (type, optionKey) => {
    if (Array.isArray(persistKeys) && persistKeys.indexOf(optionKey) > -1 && type !== 'jumbo') {
      return [optionKey]
    }
    return [type, optionKey]
  }

  const cacheOptions = useCallback(
    type => {
      setCache(prevCache =>
        DEFAULTS_CONFIG.reduce((newCache, { optionKey }) => {
          return set(cacheKey(type, optionKey), options[optionKey])(newCache)
        }, prevCache)
      )
    },
    [options]
  )

  const restoreOptions = useCallback(
    type => {
      setOptions(prevOptions =>
        DEFAULTS_CONFIG.reduce((newOptions, { optionKey, getDefaultValue }) => {
          const calculationDefaults = get(calculatorDefaultsByType, [type])
          const defaultValue = getDefaultValue(calculationDefaults) || defaultOptions[optionKey]
          let cacheValue

          if (['factors'].indexOf(optionKey) > -1) {
            cacheValue = defaultValue
          } else {
            cacheValue = get(cache, cacheKey(type, optionKey), parseFloat(defaultValue))
          }

          return set([optionKey], cacheValue)(newOptions)
        }, prevOptions)
      )
    },
    [cache, calculatorDefaultsByType, defaultOptions]
  )

  return { cacheOptions, restoreOptions, setPersistKeys }
}

export default useCalculatorCacheWithDefaults
