import React, { useContext, useEffect, useState } from 'react'
import { StepsProps, HintsProps } from 'intro.js-react'
import { availableSteps } from 'utils/steps'
import usePersistentState from '../hooks/usePersistentState'
import { IntroState } from '../types/Intro'
import { useHistory } from 'react-router'
import { useAuth } from './Auth'
import { useSession } from './Session'

const initialState: IntroState = {
  hints: {
    enabled: false,
    hints: [],
  },
  steps: {
    steps: [],
    enabled: false,
    initialStep: 0,
    onExit: () => null,
  },
  guidedTour: {
    seen: false,
  },
}

const IntroContext = React.createContext(initialState)

export const IntroProvider: React.FC = props => {
  const history = useHistory()
  const { isConsumer, isPartner, isUser } = useAuth()
  const { show_selfie_view } = useSession()
  const [guidedTour, setGuidedTour] = usePersistentState('guided_tour', initialState.guidedTour)

  const onExit = () => {
    setSteps({ enabled: false })
  }
  const onHintClicked = () => {}
  const onHintClosed = () => {}
  const onChange = (nextIndex: number) => {
    setSteps({
      initialStep: nextIndex,
    })
  }

  const [hints, setupHints] = useState<HintsProps>({
    ...initialState.hints,
    onClick: onHintClicked,
    onClose: onHintClosed,
  })

  const [steps, setupSteps] = useState<StepsProps>({
    ...initialState.steps,
    onBeforeChange: onChange,
    onExit,
  })

  const resetIntro = () => {
    setGuidedTour(prevState => ({
      ...prevState,
      seen: false,
    }))
  }

  /**
   *
   * @param props
   */
  const setHints = (props: Partial<HintsProps>) => {
    setupHints(previousState => ({ ...previousState, ...props }))
  }

  /**
   *
   * @param props
   */
  const setSteps = (props: Partial<StepsProps>) => {
    if (props.enabled === true) {
      initialize({ pathname: history.location.pathname })
    }

    setupSteps(previousState => ({ ...previousState, ...props }))
  }

  /**
   *

   */
  const initialize = ({ pathname }: { pathname: string }) => {
    setSteps({
      steps: availableSteps.get(pathname, { isPartner, isUser, show_selfie_view }) || [],
    })
  }

  useEffect(() => {
    let mounted = true

    if (mounted && !isConsumer) {
      /**
       * Setup a listener to the history to ensure that when the location changes
       * we re-initialize the steps for a given page (if the page exists)
       */
      history.listen(listener => {
        initialize({ pathname: listener.pathname })
      })
    }

    return () => {
      mounted = false
    }
  }, [history.location])

  const contextValue: IntroState = {
    availableSteps,
    hints,
    resetIntro,
    steps,
    setHints,
    setSteps,
    guidedTour,
    setGuidedTour,
  }

  return <IntroContext.Provider {...props} value={contextValue} />
}

export const useIntro = () => useContext(IntroContext)
