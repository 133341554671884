import React, { useEffect, useState } from 'react'
import { IonContent, IonLoading, IonPage, IonSegment, IonSegmentButton } from '@ionic/react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import List from 'components/List'

import Header from '../../components/Header'
import InstallationTabRoutes from '../../routes/InstallationTabRoutes'
import styled from 'styled-components'
import { getInstallWithDocuments } from '../../utils/shared-services'
import { useApplication } from '../../store/Application'
import usePosOrLoanzifyDocuments from '../../hooks/usePosOrLoanzifyDocuments'
import LoanCard from '../../components/loans/LoanCard'

const StyledIonSegment = styled(IonSegment)`
  margin: 1rem 0;
  padding: 0 1rem;
  margin-bottom: 0;
`

const InstallationDetailsPage = () => {
  const { loading: docBindingsLoading } = usePosOrLoanzifyDocuments()
  const { installation_id } = useParams()
  const { hasPos } = useApplication()
  const history = useHistory()
  const { path, url } = useRouteMatch()
  const [tab, setTab] = useState('calculations')

  const [loading, setLoading] = useState(true)
  const [installation, setInstallation] = useState(null)
  const [mappedInstallationToLoan, setMappedInstallationToLoan] = useState(null)

  useEffect(() => {
    if (!docBindingsLoading) {
      loadDocs()
    }
  }, [docBindingsLoading])

  useEffect(() => {
    history.replace(`${url}/${tab}`)

    if (tab === 'docs') {
      loadDocs()
    }
  }, [tab])

  useEffect(() => {
    if (installation) {
      setMappedInstallationToLoan({
        borrower: {
          full_name: installation.name,
          email: installation?.email,
          tel: installation?.phone,
        },
        type: 'installation',
      })
    }
  }, [installation])

  const loadDocs = () => {
    getInstallWithDocuments(installation_id, hasPos, false)
      .then(install => setInstallation(install))
      .finally(() => setLoading(false))
      .catch(err => console.warn(err))
  }

  return (
    <IonPage>
      <Header title="Installation" defaultHref="/leads/installations" />

      <IonContent>
        <List>
          <IonLoading isOpen={loading} message="Loading..." />

          {mappedInstallationToLoan && <LoanCard loan={mappedInstallationToLoan} />}
          <StyledIonSegment mode="md" value={tab} onIonChange={e => setTab(e.detail.value)}>
            <IonSegmentButton value="calculations">Calculations</IonSegmentButton>
            <IonSegmentButton value="docs">Docs Loaded</IonSegmentButton>
          </StyledIonSegment>
        </List>

        {installation && <InstallationTabRoutes installation={installation} path={path} />}
      </IonContent>
    </IonPage>
  )
}

export default InstallationDetailsPage
