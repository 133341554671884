import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { trashOutline } from 'ionicons/icons'
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react'

import List from 'components/List'
import ListItem from 'components/ListItem'
import StyledButton from 'components/Button'
import Label from 'components/Label'

import { ReactComponent as PlusIcon } from 'legacy-icons/documents/plus.svg'

import usePhotoGetter from 'hooks/usePhotoGetter'
import { Dialog } from '@capacitor/dialog'
import { FilePicker } from '@capawesome/capacitor-file-picker'
import { ReactComponent as IconCamera } from 'icons/Icon-Camera2.svg'
import { ReactComponent as IconFileExplorer } from 'icons/Icon-FileExplorer.svg'

const AttachButtonContainer = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 1rem 3.25rem;
  margin-top: 2rem;
`

const UploadButtonContainer = styled.div`
  text-align: center;
  padding: 3rem 0 1rem 0;
  margin: 2rem 3.25rem 0 3.25rem;

  border-top: 2px solid #ebecef;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 1.875rem;
    height: 1.875rem;
  }
`

const PickerOptionContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: center;
`

const PickerOption = styled(props => {
  const { children, label } = props

  return (
    <div className={'picker-option'} {...props}>
      {children}
      <Label size="sm" color="gray" weight={500} style={{ marginTop: '.675rem' }}>
        {label}
      </Label>
    </div>
  )
})`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 156px;
  height: 149px;
  border-radius: 7px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0, 0.07);
  background: #fff;
  padding: 0.25rem 0.4rem;
  margin: 0 9px;

  svg {
    width: 80px !important;
    height: 80px !important;
  }
`

const FileSelect = ({ title, onFilesSelected, askForDocumentName = true, children }) => {
  const modalEl = useRef(null)
  const getPhoto = usePhotoGetter()

  const [state, setState] = useState({
    files: [],
    showPicker: false,
    isOpen: false,
  })

  const addFiles = files => {
    setState(state => {
      const newFiles = [...state.files, ...files]

      return {
        ...state,
        files: newFiles,
        showPicker: false,
        isOpen: newFiles.length > 0,
      }
    })
  }

  const removeFile = index =>
    setState(state => {
      const newFiles = [...state.files]

      newFiles.splice(index, 1)

      if (newFiles.length === 0) {
        setState(prevState => ({
          ...prevState,
          showPicker: true,
          isOpen: false,
        }))
      }

      return { ...state, files: newFiles }
    })

  const openImagePicker = async () => {
    const file = await getPhoto({
      name: `page-${state.files.length + 1}`,
      cameraOptions: {
        width: undefined,
        height: undefined,
      },
    })

    addFiles([file])
  }

  const openOptionPicker = async () => {
    setState(prevState => ({
      ...prevState,
      showPicker: true,
    }))
  }

  const openFileExplorerPicker = async () => {
    try {
      const result = await FilePicker.pickFiles({
        types: ['application/pdf', 'image/png', 'image/jpeg', 'image/gif', 'image/tiff'],
        multiple: true,
        readData: true,
      })

      console.log(result)

      const blobConversions = result.files.map(async file => {
        let blob = await (await fetch(`data:${file.mimeType};base64,${file.data}`)).blob()
        return new File([blob], `${file.name}`, { type: blob.type })
      })

      Promise.all(blobConversions).then(files => addFiles(files))
    } catch (err) {
      console.warn(`[Loanzify]: Failed to resolve FilePicker! Reason:`)
      console.error(err)
      // fail silently, it was likely closed
    }
  }

  const openModal = async () => {
    if (state.files.length) {
      return setState(state => ({ ...state, isOpen: true }))
    }

    await openOptionPicker()
  }

  const closeModal = () => {
    setState(prevState => ({
      ...state,
      showPicker: false,
      isOpen: false,
    }))
  }

  const select = async () => {
    if (askForDocumentName === false) {
      onFilesSelected(state.files)
      modalEl.current.dismiss()

      return
    }

    const { value, cancelled } = await Dialog.prompt({
      title: 'Upload Document',
      message: 'Enter your document name',
      inputPlaceholder: 'Mobile Upload',
    })

    if (cancelled) {
      return
    }

    onFilesSelected(state.files, value || 'Mobile Upload')
    modalEl.current.dismiss()
  }

  return (
    <>
      {children(() => openModal())}

      <IonModal isOpen={state.isOpen || state.showPicker} ref={modalEl} onDidDismiss={() => closeModal()}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>{title || 'Upload'}</IonTitle>

            <IonButtons slot="end">
              <IonButton onClick={() => modalEl.current.dismiss()}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        {state.showPicker && (
          <IonContent>
            <Label color={'black'} size="lg" center={true}>
              {' '}
              Choose An Option{' '}
            </Label>

            <PickerOptionContainer>
              <PickerOption label={'File Explorer'} onClick={openFileExplorerPicker}>
                <IconFileExplorer />
              </PickerOption>
              <PickerOption label={'Camera / Gallery'} onClick={openImagePicker}>
                <IconCamera />
              </PickerOption>
            </PickerOptionContainer>
          </IonContent>
        )}

        {!state.showPicker && (
          <IonContent>
            <List>
              {state.files.map((file, index) => (
                <ListItem
                  key={`file-${file.name}-${index}`}
                  title={file.name}
                  endIcon={() => <IonIcon size="large" icon={trashOutline} />}
                  onEndIconClick={() => removeFile(index)}
                />
              ))}
            </List>

            <AttachButtonContainer onClick={() => openImagePicker()}>
              <IconContainer>
                <PlusIcon />
              </IconContainer>
              <Label center secondary size="xs" weight={600}>
                Attach More Docs
              </Label>
            </AttachButtonContainer>

            <UploadButtonContainer>
              <StyledButton size="normal" center onClick={select}>
                Upload
              </StyledButton>
            </UploadButtonContainer>
          </IonContent>
        )}
      </IonModal>
    </>
  )
}

export default FileSelect
