import { useEffect, useState } from 'react'

import { associateConsumer, getLoanOfficers, getLoanOfficersWithPartners } from 'api/sso'
import { getUserApplicationId } from 'api/loanzify'

import { useSession } from 'store/Session'
import { useApplication } from 'store/Application'
import { useAuth } from 'store/Auth'

const useLoanOfficerSelect = () => {
  const { setApplicationId } = useSession()
  const { isBranded, ssoOwner, isMainEnterprise, application } = useApplication()
  const { parentSsoUserId, user, setConsumerService } = useAuth()

  const [officers, setOfficers] = useState([])

  const [error, setError] = useState('')
  const [skip, setSkip] = useState(false)
  const [loading, setLoading] = useState(true)

  /**
   * Pick the consumer service for a given ssoUser. If the given
   * ssoUser does not have the loanzify service enabled, pick
   * the consumerService for the given ssoOwner.
   *
   * @param {Object} ssoUser
   * @returns {Object}
   */
  const pickConsumerService = async ssoUser => {
    try {
      return await associateConsumer(ssoUser.id, application.user2 ? application.user1.id : parentSsoUserId)
    } catch (error) {
      if (error?.response?.status === 422) {
        return associateConsumer(ssoOwner.id, application.user2 ? application.user1.id : parentSsoUserId)
      }

      setError(error)
    }
  }

  /**
   * Pick a given ssoUser as a loan officer.
   *
   * @param {Object} ssoUser
   * @param {Boolean} refreshApplication
   */
  const pickLoanOfficer = async (ssoUser, refreshApplication = true) => {
    const consumerService = await pickConsumerService(ssoUser)
    setConsumerService(consumerService)

    if (refreshApplication) {
      await pickApplication(consumerService.userService.loanzify_user_id, consumerService.userService.loanzify_role)
    }

    setSkip(true)
  }

  /**
   * Pick an application by a given loanzify user id.
   *
   * @param {Number} loanzify_user_id
   */
  const pickApplication = async (loanzify_user_id, loanzify_role) => {
    const applicationId = await getUserApplicationId(loanzify_user_id, loanzify_role)
    setApplicationId(applicationId)
  }

  const searchLoanOfficers = async searchTerm => {
    return await getLoanOfficers(isMainEnterprise, { search: searchTerm })
  }

  useEffect(() => {
    const setupLoanOfficer = async () => {
      if (!user.is_consumer) {
        return pickApplication(user.loanzify_user_id, user.loanzify_role).then(() => setSkip(true))
      }

      if (isBranded && !isMainEnterprise) {
        return pickLoanOfficer(ssoOwner, false)
      }

      const officers = await getLoanOfficersWithPartners(isMainEnterprise)

      if (officers.length === 0) {
        return pickLoanOfficer(ssoOwner, false)
      }

      if (officers.length === 1) {
        return pickLoanOfficer(officers[0])
      }

      setOfficers(officers)
      setLoading(false)
    }

    setupLoanOfficer()
  }, [])

  return { officers, loading, skip, error, pickLoanOfficer, searchLoanOfficers }
}

export default useLoanOfficerSelect
