import React, { useEffect, useState } from 'react'
import ModalContent from './ModalContent'
import styled from 'styled-components'
import { IonItem, IonList, useIonActionSheet } from '@ionic/react'
import { ReactComponent as CallIcon } from 'icons/Icon-Call.svg'
import { ReactComponent as EditIcon } from 'legacy-icons/pre-approval/edit.svg'
import { ReactComponent as EmailIcon } from 'icons/Icon-Email.svg'
import { ReactComponent as TextIcon } from 'icons/Icon-Text.svg'
import { ReactComponent as ShareIcon } from 'icons/Icon-Share.svg'
import { callOutline, close, phonePortraitOutline } from 'ionicons/icons'
import { Toast } from '@capacitor/toast'
import { Clipboard } from '@capacitor/clipboard'

interface Props {
  history: any
  initials: string
  partner: Record<string, any>
  dismiss: Function
}

const ModalContentStyling = styled.div`
  font-family: 'Open Sans';
`

const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 83px;
  font-size: 26px;
  width: 83px;
  height: 83px;
  border-radius: 9999px;
  color: #000;

  ${props =>
    props.url
      ? `
    background: url(${props.url});
    background-size: cover;
    `
      : `
    background: var(--color-blue-light);
  `}
`

const PartnerName = styled.div`
  font-size: 30px;
  color: #fba61b;
`

const PartnerTitle = styled.div`
  font-size: 13px;
  color: #8a8f9e;
  letter-spacing: 1px;
`

const PartnerNmls = styled.div`
  font-size: 13px;
  color: #d8dade;
`

const PartnerField = styled.div`
  display: grid;
  grid-template-columns: 116px 1fr;
`

const PartnerLabel = styled.div`
  text-transform: uppercase;
  margin-left: -5px;
  margin-right: 11px;
  color: #000;
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
    fill: #000;

    path {
      fill: #000;
    }
  }
`

const PartnerValue = styled.div`
  display: flex;
  align-items: center;
  color: #8a8f9e;
  font-size: 12px;

  a {
    color: inherit;
    text-decoration: none;
  }
`

const PartnerDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 26px;
`

const PartnerNameAndTitle = styled.div`
  margin-left: 16px;
`

const PartnerDetailsModal: React.FC<Props> = ({ history, initials, partner, dismiss }) => {
  const [present] = useIonActionSheet()
  const [shareText, setShareText] = useState<string>(null)

  useEffect(() => {
    if (partner.share_text) {
      setShareText(partner.share_text)
    }
  }, [partner, setShareText])

  const copyShareText = () => {
    Clipboard.write({ string: shareText })
    Toast.show({ text: 'Share link copied successfully.' })
  }

  const edit = () => {
    history.push(`/leads/partners/${partner.id}/edit`)

    dismiss()
  }

  const startCall = () => {
    if (!partner.phone && !partner.mobile) {
      return
    }

    if (partner.phone && partner.mobile) {
      return present({
        buttons: [
          {
            text: `Mobile Phone - ${partner.mobile}`,
            icon: phonePortraitOutline,
            handler: () => (window.location.href = `tel:${partner.mobile}`),
          },
          {
            text: `Work Phone - ${partner.phone}`,
            icon: callOutline,
            handler: () => (window.location.href = `tel:${partner.phone}`),
          },
          {
            text: 'Cancel',
            icon: close,
            role: 'cancel',
            handler: dismiss,
          },
        ],
      })
    }

    window.location.href = `tel:${partner.mobile || partner.phone}`
  }

  const sendSms = () => {
    const params = new URLSearchParams()
    window.location.href = `sms:;${params.toString()}`
  }

  const sendEmail = () => {
    const params = new URLSearchParams()

    window.location.href = `mailto:?${params.toString()}`
  }

  return (
    <ModalContent className="ion-padding">
      <ModalContentStyling>
        <PartnerDetailsHeader>
          {partner.photo_url ? <Avatar url={partner.photo_url} /> : <Avatar>{initials}</Avatar>}
          <PartnerNameAndTitle>
            <PartnerName>{partner.first_name + ' ' + partner.last_name}</PartnerName>
            {partner.title && <PartnerTitle>{partner.title}</PartnerTitle>}
            {partner.nmls && <PartnerNmls>License: {partner.nmls}</PartnerNmls>}
          </PartnerNameAndTitle>
        </PartnerDetailsHeader>

        <IonList>
          {(partner.phone || partner.mobile) && (
            <React.Fragment>
              <IonItem lines="inset" onClick={startCall}>
                <PartnerField>
                  <PartnerLabel>
                    <CallIcon />
                    CALL
                  </PartnerLabel>
                  <PartnerValue>{partner.mobile}</PartnerValue>
                </PartnerField>
              </IonItem>
            </React.Fragment>
          )}

          {partner.mobile && (
            <React.Fragment>
              <IonItem lines="inset" onClick={sendSms}>
                <PartnerField>
                  <PartnerLabel>
                    <TextIcon />
                    TEXT
                  </PartnerLabel>
                  <PartnerValue>{partner.mobile}</PartnerValue>
                </PartnerField>
              </IonItem>
            </React.Fragment>
          )}

          {partner.email && (
            <React.Fragment>
              <IonItem lines="inset" onClick={sendEmail}>
                <PartnerField>
                  <PartnerLabel>
                    <EmailIcon />
                    EMAIL
                  </PartnerLabel>
                  <PartnerValue>{partner.email}</PartnerValue>
                </PartnerField>
              </IonItem>
            </React.Fragment>
          )}

          {shareText && (
            <React.Fragment>
              <IonItem lines="inset" onClick={copyShareText}>
                <PartnerField>
                  <PartnerLabel>
                    <ShareIcon />
                    SHARE
                  </PartnerLabel>
                  <PartnerValue>
                    <a href={shareText}>{shareText}</a>
                  </PartnerValue>
                </PartnerField>
              </IonItem>
            </React.Fragment>
          )}

          <IonItem lines="inset" onClick={edit}>
            <PartnerField>
              <PartnerLabel>
                <EditIcon
                  style={{ marginLeft: '6px', marginRight: '16px', width: '30px', height: '30px', fill: 'black' }}
                />
                EDIT
              </PartnerLabel>
            </PartnerField>
          </IonItem>
        </IonList>

        {/*<IonItem lines="inset" onClick={destroy}>*/}
        {/*  <PartnerField>*/}
        {/*    <PartnerLabel>*/}
        {/*      <TrashIcon/>*/}
        {/*      DELETE*/}
        {/*    </PartnerLabel>*/}
        {/*  </PartnerField>*/}
        {/*</IonItem>*/}
      </ModalContentStyling>
    </ModalContent>
  )
}

export default PartnerDetailsModal
