import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IonContent, IonLoading, IonPage } from '@ionic/react'

import { useAuth } from 'store/Auth'
import { storeLoanQuickIntake } from 'api/pos'

import Header from 'components/Header'

const QuickIntakePage: React.FC = () => {
  const history = useHistory()
  const { user } = useAuth()

  const [status, setStatus] = useState<'loading' | 'ready'>('loading')

  useEffect(() => {
    if (!user || user?.is_consumer) {
      history.push('/home')

      return
    }

    const mountFormBuilder = async () => {
      const form = await (window as any).LhpForms.create({
        token: '123',
        schema: 'quick',
        account: 'default',
        account_id: user.loanzify_user_id,
      }).mount('#form-builder')

      setStatus('ready')

      form.on('submit', (model: Record<string, string>) => {
        storeLoanQuickIntake(model).then(({ submission_id }) => {
          form.saveSubmission(submission_id)
        })
      })

      form.on('submission-saved', () => {
        history.replace('/leads/loans')
      })
    }

    mountFormBuilder()
  }, [])

  return (
    <IonPage>
      <Header title="Leads" defaultHref="/leads/loans" forceDefaultHref={true} />

      <IonContent>
        <IonLoading isOpen={status === 'loading'} message="Loading..." />

        <div id="form-builder" style={{ width: '100%', height: '100%' }}></div>
      </IonContent>
    </IonPage>
  )
}

export default QuickIntakePage
