import React from 'react'
import { IonActionSheet } from '@ionic/react'
import { closeOutline, copyOutline, mailOutline, phonePortraitOutline } from 'ionicons/icons'
import { Clipboard } from '@capacitor/clipboard'
import { Toast } from '@capacitor/toast'

import { useApplication } from 'store/Application'

interface ShowActionSheetProps {
  showShare: boolean
  handleDismiss: () => void
}
export const ShareActionSheet: React.FC<ShowActionSheetProps> = ({ showShare, handleDismiss }) => {
  const { application } = useApplication()

  const sendSms = () => {
    const params = new URLSearchParams()
    params.set('body', application.share_text)

    window.location.href = `sms:;${params.toString()}`
  }

  const sendEmail = () => {
    const params = new URLSearchParams()
    params.set('subject', 'Check out my cool app!')
    params.set('body', application.share_text)

    window.location.href = `mailto:?${params.toString()}`
  }

  const copyText = () => {
    Clipboard.write({ string: application.share_text })
    Toast.show({ text: 'Share text copied successfully.' })
  }

  return (
    <IonActionSheet
      isOpen={showShare}
      onDidDismiss={handleDismiss}
      buttons={[
        {
          text: 'Email',
          icon: mailOutline,
          handler: sendEmail,
        },
        {
          text: 'SMS',
          icon: phonePortraitOutline,
          handler: sendSms,
        },
        {
          text: 'Copy Text',
          icon: copyOutline,
          handler: copyText,
        },
        {
          text: 'Cancel',
          role: 'destructive',
          icon: closeOutline,
        },
      ]}
    />
  )
}
