import * as React from 'react';
import styled from 'styled-components/macro';

import { Loan, TimelineItem } from 'types/Pos';

import Label from 'components/Label';
import useModal from 'hooks/useModal';

import MilestoneModal from './MilestoneModal';
import MilestoneActionButton from './MilestoneActionButton';
import MilestoneImageOrStatus from './MilestoneImageOrStatus';
import { format, parseISO } from 'date-fns';
import { useAuth } from '../../../store/Auth';
import MilestoneDateTimePicker from './MilestoneDateTimePicker';
import { useEffect, useState } from 'react';

interface Props {
  loan: Loan;
  loanMilestone: TimelineItem;
  isLast?: boolean | undefined;
}

const MilestoneItem: React.FC<Props> = ({ loan, loanMilestone, isLast }) => {
  const { isLoanOfficer, isPartner } = useAuth();

  const [actionDate, setActionDate] = useState('');
  const [dense, setDense] = useState(false);

  const [present] = useModal(MilestoneModal, {
    milestone: loanMilestone.milestone_event,
  });

  const showModal = () => loanMilestone.explanation_1 && present();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (loanMilestone.completed_at) {
        setActionDate(format(parseISO(loanMilestone.completed_at), 'P'));
      }

      if (loanMilestone.is_current || loanMilestone.in_progress) {
        setActionDate(format(parseISO(loanMilestone.updated_at), 'P'));
      }
    }

    return () => {
      mounted = false;
    };
  }, [loanMilestone, setActionDate]);

  useEffect(() => {
    setDense(isLoanOfficer || isPartner);
  }, [setDense]);

  return (
    <Wrapper dense={dense}>
      <MilestoneImageOrStatus loanMilestone={loanMilestone} showModal={showModal} />

      {!dense && <HorizontalSeparator />}

      {!isLast && !dense && <VerticalSeparator />}

      <MilestoneInformationWrapper dense={dense}>
        {!dense && (
          <MilestoneStatusTextWrapper>
            {(loanMilestone.is_current || loanMilestone.in_progress === true) && !loanMilestone.completed_at ? (
              <Label size="xs" weight={700} color="accent">
                IN PROGRESS
              </Label>
            ) : loanMilestone.completed_at ? (
              <Label size="xs" weight={700} color="accent">
                COMPLETED
              </Label>
            ) : (
              <Label size="xs" weight={700} color="gray-lighter">
                NOT STARTED
              </Label>
            )}
            <MilestoneDateWrapper dense={dense}>{actionDate}</MilestoneDateWrapper>
          </MilestoneStatusTextWrapper>
        )}

        <MilestoneActionButtonWrapper dense={dense}>
          <MilestoneActionButton
            loanMilestone={loanMilestone}
            onMoreDetails={showModal}
            render={handle => (
              <Label
                block
                secondary
                weight={dense ? 700 : 400}
                size={dense ? 'xs' : 'normal'}
                color="gray"
                onClick={dense ? null : handle}
                isWrapped={true}
              >
                {loanMilestone.milestone_event?.title}
              </Label>
            )}
          />
        </MilestoneActionButtonWrapper>

        {dense && actionDate && !isLoanOfficer && (
          <MilestoneDateWrapper dense={dense}>{actionDate}</MilestoneDateWrapper>
        )}

        {isLoanOfficer && (
          <MilestoneDateWrapper dense={dense}>
            <MilestoneDateTimePicker milestone={loanMilestone} loan={loan} />
          </MilestoneDateWrapper>
        )}
      </MilestoneInformationWrapper>
    </Wrapper>
  );
};

export const Wrapper = styled.div<{ dense: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${props => (props.dense ? '1px' : '0px')};
  margin-bottom: ${props => (props.dense ? '0px' : '33px')};
  height: ${props => (props.dense ? '48px' : 'auto')};
  &:nth-of-type(odd) {
    background-color: ${props => (props.dense ? '#F5F6F7' : 'transparent')};
  }
`;

const HorizontalSeparator = styled.div`
  width: 19px;
  height: 2px;
  margin: 0 10px;
  background-color: var(--color-gray-lighter);
`;

const VerticalSeparator = styled.div`
  position: absolute;
  top: 50px;
  left: 90px;
  width: 2px;
  height: 51px;
  background-color: var(--color-gray-lighter);
`;

const MilestoneDateWrapper = styled.div<{ dense: boolean }>`
  font-style: italic;
  font-size: 10px;
  font-weight: 400;
  margin-left: auto;
  color: var(--color-gray-light);
`;

const MilestoneStatusTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MilestoneInformationWrapper = styled.div<{ dense: boolean }>`
  width: 100%;
  display: ${props => (props.dense ? 'flex' : 'block')};
  flex-direction: row;
  padding-right: ${props => (props.dense ? '.675rem' : '0')};
`;

const MilestoneActionButtonWrapper = styled.div<{ dense: boolean }>`
  ${props =>
    props.dense
      ? {
          display: 'flex',
          'align-items': 'center',
        }
      : {}}
`;

export default MilestoneItem;
