import React from 'react';
import styled from 'styled-components';

import { ReactComponent as AddIcon } from 'legacy-icons/add.svg';
import Label from './Label';
import { IonRouterLink } from '@ionic/react';
import { useApplication } from 'store/Application';
import { useAuth } from 'store/Auth';
import { useSession } from '../store/Session';
import avatarConfigureIcon from 'legacy-icons/avatar-configure.png';
import { useHistory } from 'react-router-dom';

const SIZES = {
  xs: '2.5rem',
  sm: '4rem',
  normal: '5rem',
  lg: '7.5rem',
};

const BORDER_COLORS = {
  'primary-lighter': 'var(--color-primary-lighter)',
  'gray-lighter': 'var(--color-gray-lighter)',
};

interface AvatarContainerProps {
  size: string;
  borderWidth?: string;
  borderColor?: string;
  url?: string;
}
const AvatarContainer = styled.div<AvatarContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: ${props => getAvatarDimensions(props.size || 'normal')};
  height: ${props => getAvatarDimensions(props.size || 'normal')};

  background: url('${props => props.url}') var(--color-gray-lighter);
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  border-width: ${props => props.borderWidth || '0.25rem'};
  border-style: solid;
  border-color: ${props => getBorderColors(props.borderColor || 'primary-lighter')};
  border-radius: 999px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
`;

const Placeholder = styled.div``;

const getAvatarDimensions = (size: string) => {
  const [index] = Object.keys(SIZES)
    .map((s, idx) => {
      if (s === size) {
        return idx;
      }
    })
    .filter(res => !!res);
  return Object.values(SIZES)[index!];
};
const getBorderColors = (color: string) => {
  const [index] = Object.keys(BORDER_COLORS)
    .map((s, idx) => {
      if (s === color) {
        return idx;
      }
    })
    .filter(res => !!res);
  return Object.values(BORDER_COLORS)[index!];
};

const Avatar = ({ size, borderWidth, borderColor }: AvatarContainerProps) => {
  const history = useHistory();
  const { isUser } = useAuth();
  const { forceConsumerView } = useSession();
  let { photoUrl } = useApplication();
  let goToSettings = false;

  if ((!photoUrl || photoUrl === '/public/images/avatar_default.jpg') && forceConsumerView) {
    photoUrl = avatarConfigureIcon;
    goToSettings = true;
  }

  return (
    <AvatarContainer
      size={size}
      borderWidth={borderWidth}
      borderColor={borderColor}
      onClick={() => goToSettings && history.push('/home/settings')}
      url={photoUrl || 'https://loanzify.com/public/images/avatar_default.jpg'}
    >
      {isUser && !photoUrl && (
        <IonRouterLink routerLink="/home/settings">
          <Placeholder>
            <div style={{ textAlign: 'center' }}>
              <AddIcon />
            </div>
            <Label size="xs">Add Photo</Label>
          </Placeholder>
        </IonRouterLink>
      )}
    </AvatarContainer>
  );
};

export default Avatar;
