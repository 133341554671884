import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { IonSlides, IonContent, IonLoading } from '@ionic/react'

import { useSession } from 'store/Session'

import Introduction from './slides/Introduction'
import UpdateProfileDetails from './slides/UpdateProfileDetails'
import UpdatePhoto from './slides/UpdatePhoto'
import UpdateLogo from './slides/UpdateLogo'
import UpdateBranding from './slides/UpdateBranding'
import LaunchMyApp from './slides/LaunchMyApp'

const Welcome = () => {
  const history = useHistory()
  const slideRef = useRef()
  const [loading, setLoading] = useState(false)
  const { setShowSelfieView } = useSession()

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  }

  const handleNext = async () => {
    const slider = slideRef.current
    await slider.slideNext()
  }

  const handleBack = async () => {
    const slider = slideRef.current
    await slider.slidePrev()
  }

  const handleFinish = async () => {
    setLoading(true)

    setTimeout(() => {
      setShowSelfieView(false)
      history.push('/home/switch-view')
      setLoading(false)
    }, 250)
  }

  return (
    <StyledIonContent>
      <IonLoading isOpen={loading} message="Loading..." />

      <StyledIonSlides pager={false} options={slideOpts} ref={slideRef}>
        <Introduction onNext={handleNext} onPrev={handleBack} />
        <UpdateProfileDetails onNext={handleNext} onPrev={handleBack} progress={1} />
        <UpdatePhoto onNext={handleNext} onPrev={handleBack} progress={2} />
        <UpdateLogo onNext={handleNext} onPrev={handleBack} progress={3} />
        <UpdateBranding onNext={handleNext} onPrev={handleBack} progress={4} />
        <LaunchMyApp onNext={handleFinish} onPrev={handleBack} />
      </StyledIonSlides>
    </StyledIonContent>
  )
}

const StyledIonContent = styled(IonContent)`
  --background: var(--color-background);
`

const StyledIonSlides = styled(IonSlides)`
  height: 100%;
  min-height: 100vh;
  --bullet-background: var(--color-background);
  --bullet-background-active: var(--color-background);
`

export default Welcome
