import * as React from 'react'
import { IonBadge } from '@ionic/react'

const UnreadMessageBadge: React.FC<{ count: number }> = ({ count }) => {
  if (!count) {
    return <React.Fragment />
  }

  return <IonBadge color="primary">{count}</IonBadge>
}

export default UnreadMessageBadge
