import React from 'react'
import { RouteProps } from 'react-router'
import { Route, Redirect } from 'react-router-dom'

import { useAuth } from 'store/Auth'
import { useApplication } from 'store/Application'
import { Loan, TeamMember } from '../../types/Pos'

interface LoanTeamRouteProps extends RouteProps {
  loan: Loan
  exclude?: string
  props?: any
  onTabChange?: Function
}

const LoanTeamRoute: React.FC<LoanTeamRouteProps> = ({ location, component, loan, exclude, ...props }) => {
  const { role, user } = useAuth()
  const { isPreview } = useApplication()

  const isOnLoanTeam = () => {
    return (
      (loan.team?.members || []).findIndex((member: TeamMember) => {
        return member.sso_user_id && member.sso_user_id === user?.id
      }) > -1
    )
  }

  const renderLoanTeamComponent = () => {
    if (isPreview) {
      return <Route component={component} {...props} />
    }

    if (!user) {
      return <Redirect to={{ pathname: '/auth', state: { from: location } }} />
    }

    if (exclude && role && role === exclude) {
      let redirectTo = isOnLoanTeam() ? `/leads/loans/${loan.id}/timeline` : `/leads/tabs/consumers`

      return <Redirect to={{ pathname: redirectTo, state: { from: location } }} />
    }

    return <Route component={component} {...props} />
  }

  return <Route {...props} render={renderLoanTeamComponent} />
}

export default LoanTeamRoute
