import * as React from 'react'
import LoanCardSection from '../../../../components/loans/LoanCardSection'
import MilestoneItem from 'pages/loan/components/MilestoneItem'
import { Loan } from '../../../../types/Pos'
import Milestones from 'components/loans/Milestones/Milestones.tsx'

type MilestoneProps = {
  loan: Loan
}

const Overview: React.FC<MilestoneProps> = ({ isLoanOfficer, isPartner, isUser, loan, reload }) => {
  const props = { isLoanOfficer, isPartner, isUser, loan, reload }

  return (
    <LoanCardSection title="Loan Milestones" showActionsIcon={false} noSidePadding={isPartner || isLoanOfficer}>
      <Milestones {...props}>
        {loan.loan_milestones.map((loanMilestone, index) => (
          <MilestoneItem
            key={loanMilestone.id}
            loan={loan}
            loanMilestone={loanMilestone}
            index={index}
            reload={reload}
          />
        ))}
      </Milestones>
    </LoanCardSection>
  )
}
export default Overview
