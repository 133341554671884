import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { addCircle, funnel } from 'ionicons/icons'
import { IonButton, IonIcon, IonLoading, IonRouterLink, IonSegment, IonSegmentButton, IonToast } from '@ionic/react'
import { format } from 'date-fns'
import debounce from 'lodash/debounce'

import { requestUpgrade } from 'api/loanzify'
import { getPipeline, getLoanOfficers } from 'api/pos'

import Label from 'components/Label'
import Button from 'components/Button'
import Searchbar from 'components/Searchbar'
import LoanCard from 'components/loans/LoanCard'
import FilterModal from './components/FilterModal'

import { useAuth } from 'store/Auth'
import { useApplication } from 'store/Application'

const TABS = [
  {
    value: 'PROSPECT',
    title: 'Prospects',
  },
  {
    value: '',
    title: 'Active',
  },
  {
    value: 'ARCHIVE',
    title: 'Archive',
  },
]

const DEFAULT_FILTERS = {
  searchStartDate: '',
  searchEndDate: '',
  searchDateMode: '',
  searchLoId: '',
}

const ContentHeader = styled.div`
  background: var(--color-gray-lightest);
  padding: 1rem;
`

const QuickInTakeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-bottom: 1rem;
`

const QuickInTakeIcon = styled(IonIcon)`
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
`

const SearchTermContainer = styled.div`
  display: flex;
  align-items: center;
`

const TabsIonSegment = styled(IonSegment)`
  margin: 1rem 0;
`

const LoadButton = styled(IonButton)`
  --background: #fff;
  --color: var(--color-gray-light);

  margin: 1rem auto;
  text-transform: none;
  font-size: 1rem;
  width: 200px;
`

const UpgradeWrapper = styled.div`
  text-align: center;
`

const StyledLabel = styled(Label)`
  margin-top: 2rem;
`

const UpgradeButton = styled(Button).attrs({ size: 'md' })`
  margin-top: 1.5rem;
`

const serializeFilters = filters => {
  const result = {}

  if (filters.searchStartDate) {
    result.searchStartDate = format(new Date(filters.searchStartDate), 'yyyy-MM-dd')
  }

  if (filters.searchEndDate) {
    result.searchEndDate = format(new Date(filters.searchEndDate), 'yyyy-MM-dd')
  }

  if (filters.searchDateMode) {
    result.searchDateMode = filters.searchDateMode
  }

  if (filters.searchLoId) {
    result.searchLoId = filters.searchLoId
  }

  return result
}

const PipelinePage = () => {
  const { hasPos } = useApplication()
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [tab, setTab] = useState('')
  const [loans, setLoans] = useState([])
  const [loanOfficers, setLoanOfficers] = useState([])
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const [upgradeRequest, setUpgradeRequest] = useState(false)
  const [upgradeText, setUpgradeText] = useState('Upgrade to POS')
  const [queryJson, setQueryJson] = useState(
    JSON.stringify({
      status: tab,
      searchTerm,
      page,
      filters,
    })
  )

  const helpUpgrade = () => {
    setUpgradeRequest(true)
    requestUpgrade({
      id: user.id,
      email: user.email,
      loanzify_user_id: user.loanzify_user_id,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
    })
      .then(({ message }) => {
        setUpgradeText(message)
      })
      .catch(error => {
        setUpgradeRequest(false)
        setUpgradeText('Upgrade to POS')
      })
  }

  const renderError = () => {
    if (!hasPos) {
      return (
        <UpgradeWrapper>
          <StyledLabel isWrapped center className="ion-padding-horizontal" color="gray-light">
            You do not have Loanzify POS enabled
          </StyledLabel>
          <UpgradeButton onClick={() => helpUpgrade()} disabled={upgradeRequest}>
            {upgradeText}
          </UpgradeButton>
        </UpgradeWrapper>
      )
    }
    return <IonToast isOpen color="danger" message="Error in loading dashboard data" />
  }

  const dSetSearchTerm = debounce(val => {
    setSearchTerm(val)
  }, 600)

  const renderContent = () => (
    <>
      {loading && <IonLoading isOpen message="Retrieving data..." />}

      <ContentHeader>
        <IonRouterLink routerLink="/leads/quick-intake">
          <QuickInTakeContainer>
            <QuickInTakeIcon icon={addCircle} color="primary" />

            <Label color="gray-light">Quick In-Take</Label>
          </QuickInTakeContainer>
        </IonRouterLink>

        <SearchTermContainer>
          <Searchbar
            placeholder="Search Term"
            value={searchTerm}
            onIonChange={e => dSetSearchTerm(e.detail.value)}
            debounce={500}
          />

          <IonIcon icon={funnel} color="medium" onClick={handleFilterModalOpen} />
        </SearchTermContainer>
      </ContentHeader>

      <TabsIonSegment
        mode="md"
        value={tab}
        className="ion-padding-horizontal"
        onIonChange={e => setTab(e.detail.value)}
      >
        {TABS.map(tab => (
          <IonSegmentButton key={tab.value} value={tab.value}>
            {tab.title}
          </IonSegmentButton>
        ))}
      </TabsIonSegment>

      {loans.map(loan => (
        <LoanCard key={loan.id} loan={loan} />
      ))}

      {hasMore && (
        <LoadButton expand="block" shape="round" onClick={handleLoadMore}>
          Show More Results
        </LoadButton>
      )}

      <FilterModal
        isOpen={showFilterModal}
        defaultFilters={DEFAULT_FILTERS}
        loanOfficers={loanOfficers}
        onSubmit={handleFilterModalSubmit}
        onCancel={handleFilterModalCancel}
      />
    </>
  )

  const handleLoadMore = () => {
    setPage(page + 1)
  }

  const handleFilterModalSubmit = filters => {
    // Close filter modal
    setShowFilterModal(false)

    setFilters(filters)
  }

  const handleFilterModalOpen = () => {
    setShowFilterModal(true)
  }

  const handleFilterModalCancel = () => {
    setShowFilterModal(false)
  }

  // Fetch loans
  useEffect(() => {
    const fetchLoans = async () => {
      setLoading(true)

      try {
        const response = await getPipeline({
          status: tab,
          searchTerm,
          page,
          ...serializeFilters(filters),
        })

        if (response.data.data.length > 0) {
          setLoans(loans => [...loans, ...response.data.data])
        }

        setHasMore(!!response.data.next_page_url)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    if (hasMore) {
      fetchLoans()
    }
  }, [queryJson])

  useEffect(() => {
    try {
      const currentJson = JSON.parse(queryJson)

      const newJson = JSON.stringify({
        status: tab,
        searchTerm,
        page,
        filters,
      })

      if (currentJson === newJson) {
        return
      }

      if (
        JSON.stringify(currentJson.filters) !== JSON.stringify(filters) ||
        currentJson.searchTerm !== searchTerm ||
        currentJson.status !== tab
      ) {
        setLoans([])
        setPage(1)
        setHasMore(true)
      }

      setQueryJson(newJson)
    } catch (e) {}
  }, [tab, searchTerm, filters, page, hasMore])

  // If tab changes, reset data
  useEffect(() => {
    setLoans([])
    setPage(1)
    setHasMore(true)
  }, [tab, filters])

  useEffect(() => {
    const fetchLoanOfficers = async () => {
      try {
        const { data } = await getLoanOfficers()

        setLoanOfficers(data)
      } catch (err) {
        setError(err)
      }
    }

    fetchLoanOfficers()
  }, [])

  return error ? renderError() : renderContent()
}

export default PipelinePage
