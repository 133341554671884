import React from 'react'
import { IonGrid, IonCol, IonRow } from '@ionic/react'
import styled from 'styled-components'

import Badge from 'components/Badge'

const BadgeContainer = styled.div`
  background-color: #fff;
  border-radius: 45px;
  margin: 1rem;
`

const DocumentStatus = ({ owed, submitted, rejected, accepted, loading }) => (
  <BadgeContainer>
    <IonGrid>
      <IonRow>
        <IonCol>
          <Badge title="Owed" color="info" badge={loading ? '-' : owed} />
        </IonCol>

        <IonCol>
          <Badge title="Submitted" color="warning" badge={loading ? '-' : submitted} />
        </IonCol>

        <IonCol>
          <Badge title="Rejected" color="danger" badge={loading ? '-' : rejected} />
        </IonCol>

        <IonCol>
          <Badge title="Accepted" color="success" badge={loading ? '-' : accepted} />
        </IonCol>
      </IonRow>
    </IonGrid>
  </BadgeContainer>
)

export default DocumentStatus
