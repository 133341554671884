import React, { useEffect } from 'react'
import { IonPage, IonLoading } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import ConversationsScreen from './components/ConversationsScreen'
import Header from 'components/Header'
import { useAuth } from 'store/Auth'
import { useStreamChat } from 'store/StreamChat'

const ConversationsPage: React.FC = () => {
  const { posUser } = useAuth() as any
  const history = useHistory()
  const { client, token } = useStreamChat()

  useEffect(() => {
    if (!posUser) {
      history.replace(`/conversations/unavailable`)

      return
    }
  }, [history])

  return (
    <IonPage>
      <Header title="Messages" defaultHref="/" />
      <IonLoading isOpen={!token && !client} />
      {client && token && <ConversationsScreen token={token} user={posUser} />}
    </IonPage>
  )
}

export default ConversationsPage
