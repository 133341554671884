import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { IonLoading, IonRouterOutlet } from '@ionic/react'

import BlogPage from 'pages/content/Blog'
import BlogDetailsPage from 'pages/content/BlogDetails'
import GlossaryPage from 'pages/content/Glossary'
import ExplorePage from 'pages/content/Index'
import LoanProgramDetailsPage from 'pages/content/LoanProgramDetails'
import LoanProgramsPage from 'pages/content/LoanPrograms'
import StepByStepPage from 'pages/content/StepByStep'
import StepByStepDetailsPage from 'pages/content/StepByStepDetails'
import ChecklistPage from 'pages/content/Checklist'

import { useContent } from 'store/Content'

const ContentRoutes = () => {
  const { isLoaded, loadIfNotLoaded } = useContent()

  useEffect(() => {
    loadIfNotLoaded()
  }, [])

  if (!isLoaded) {
    return <IonLoading isOpen={true} message="Loading..." />
  }

  return (
    <IonRouterOutlet>
      <Route exact path="/content" component={ExplorePage} />
      <Route exact path="/content/guide" component={StepByStepPage} />
      <Route exact path="/content/guide/:id" component={StepByStepDetailsPage} />
      <Route exact path="/content/programs" component={LoanProgramsPage} />
      <Route exact path="/content/programs/:id" component={LoanProgramDetailsPage} />
      <Route exact path="/content/glossary" component={GlossaryPage} />
      <Route exact path="/content/checklist" component={ChecklistPage} />
      <Route exact path="/content/blog" component={BlogPage} />
      <Route exact path="/content/blog/:id" component={BlogDetailsPage} />
    </IonRouterOutlet>
  )
}

export default ContentRoutes
