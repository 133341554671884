import React from 'react'
import { IonContent } from '@ionic/react'

import { formatCurrency, formatPercent } from 'utils/formatters'
import ResultCard from '../../../../components/calculators/ResultCard'
import Button from '../../../../components/Button'
import { useCalculations } from '../../../../store/Calculations'
import { handleShare } from '../../../../utils/calculator'

const money = amount => amount && formatCurrency(amount)

const ResultDetails = ({ options, result }) => {
  const { runCalculation } = useCalculations()

  return (
    <IonContent>
      <ResultCard
        title={options.title || result?.title}
        data={[
          { title: 'Property Price', text: formatCurrency(options.property_price) },
          { title: 'Total Loan Amount', text: formatCurrency(options.loan_amount) },
          { title: 'Interest Rate', text: formatPercent(options.interest_rate, 3) },
          { title: 'Principal & Interest', text: money(result?.breakdown?.principal_and_interest) },
          { title: 'Taxes & HOA', text: money(result?.breakdown?.taxes_and_hoa) },
          { title: 'Hazard Insurance', text: money(result?.breakdown?.monthly_insurance) },
          { title: 'Mortgage Insurance', text: money(result?.breakdown?.insurance) },
        ]}
        finalData={{ title: 'Monthly Payment', text: money(result?.total) }}
      />
      <Button center block onClick={() => handleShare(runCalculation?.id || result?.id)}>
        Share This Calculation
      </Button>
    </IonContent>
  )
}
export default ResultDetails
