import React from 'react'

import { useOnboarding } from 'store/Onboarding'

import Form from './components/Form'
import Layout from './components/Layout'
import FormInput from './components/FormInput'
import Button from './components/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ButtonWrapper from './components/ButtonWrapper'
import { useApplication } from '../../store/Application'

const schema = yup.object().shape({
  password: yup.string().required('This field is required').min(8),
})

const RegisterPasswordPage = ({ history }) => {
  const { isDefault } = useApplication()
  const { setPassword } = useOnboarding()

  const { register, handleSubmit, errors, trigger, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      trial: 1,
    },
  })

  const onSubmit = ({ password }) => {
    setPassword(password)

    history.push('/auth/register/questions')
  }

  const handleClick = async () => {
    const result = await trigger()

    if (result) {
      onSubmit(getValues())
    }
  }

  const Footer = (
    <ButtonWrapper>
      <Button onClick={() => handleClick()}>{isDefault ? 'Next' : 'Register'}</Button>
    </ButtonWrapper>
  )

  return (
    <Layout subtitle="Please create a secure password" footer={Footer} backUrl="/auth/register/phone">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Password"
          ref={register}
          error={errors.password?.message}
          name="password"
          type="password"
          placeholder="Enter Your Password"
        />
      </Form>
    </Layout>
  )
}

export default RegisterPasswordPage
