import React from 'react'

import ResourceCard from 'components/ResourceCard'

const InstallationCard = ({ installation, routerLink }) => {
  const tableData = () => {
    return {
      contactInfo: {
        phone: installation?.phone,
        email: installation?.email,
      },
    }
  }

  return (
    <ResourceCard
      name={installation.name || 'Unnamed Installation'}
      date={installation.created_at}
      table={tableData()}
      routerLink={routerLink}
    >
      {/*<BadgeWrapper>*/}
      {/*  <Badge color="yellow-light">*/}
      {/*    <BadgeText>Docs</BadgeText>*/}
      {/*    <BadgeNumber>{installation.docs_uploaded || 0}</BadgeNumber>*/}
      {/*  </Badge>*/}
      {/*  <Badge color="blue">*/}
      {/*    <BadgeText>Calcs</BadgeText>*/}
      {/*    <BadgeNumber>{installation.calculations_count || 0}</BadgeNumber>*/}
      {/*  </Badge>*/}
      {/*</BadgeWrapper>*/}
    </ResourceCard>
  )
}

export default InstallationCard
