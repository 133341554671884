import { useState, useEffect } from 'react'

import { useAuth } from 'store/Auth'
import { createLoanToken } from 'api/pos'
import { client as documentsClient } from 'api/documents'
import { createDocumentsParentToken } from '../api/sso'

const usePosOrLoanzifyDocuments = (loan = {}, install = {}) => {
  const { user } = useAuth()

  const consumer_service_id = (install || {}).sso_consumer_service_id
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const setup = async () => {
      let token = null

      if (loan.id) {
        const bindings = await createLoanToken(loan.id)
        token = bindings.token
        documentsClient.bindings = bindings
        documentsClient.bindings.service = 'pos'
        documentsClient.bindings.consumer_id = user.id
      } else {
        token = (await createDocumentsParentToken('loanzify')).token
        documentsClient.bindings = {
          service: 'loanzify',
          user_id: user.loanzify_user_id,
          consumer_service_id,
        }
      }

      documentsClient.defaults.headers.common['Authorization'] = `Bearer ${token}`

      return setLoading(false)
    }

    setup()
  }, [])

  return { loading }
}

export default usePosOrLoanzifyDocuments
