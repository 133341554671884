import React from 'react'

import {
  IonPage,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
} from '@ionic/react'

import Header from 'components/Header'

const FeatureDisabled = ({ header = 'Disabled' }) => {
  return (
    <IonPage>
      <Header title={header} />
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>Preview Mode</IonCardSubtitle>
            <IonCardTitle>Feature Not Available</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            Sorry, this feature is not availble in the Preview Mode. Please use a real app to access this feature.
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}

export default FeatureDisabled
