import React, { createContext, useState, useContext } from 'react';

type CalculationsContextType = {
  runCalculation: any;
  savedCalculations: any;
  setRunCalculation: (runCalculation: any) => void;
  setSavedCalculations: (savedCalculations: any) => void;
};

const CalculationsContext = createContext<CalculationsContextType>(null!);

export const CalculationsProvider: React.FC = ({ children }) => {
  const [runCalculation, setRunCalculation] = useState(null);
  const [savedCalculations, setSavedCalculations] = useState([]);

  return (
    <CalculationsContext.Provider
      value={{
        runCalculation,
        savedCalculations,
        setRunCalculation,
        setSavedCalculations,
      }}
    >
      {children}
    </CalculationsContext.Provider>
  );
};

export const useCalculations = () => useContext(CalculationsContext);
