import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import identity from 'lodash/identity';

import Label from '../Label';
import AlertInput from '../AlertInput';

const ValueLabel = styled(Label)`
  cursor: pointer;
  color: #000;
  border-bottom: 1px dotted #000;
  margin-left: 1rem;

  &:hover {
    color: var(--color-gray);
  }
`;

const INPUT_NAME = 'input';

const AlertInputNumber = forwardRef(
  ({ title, value, size = 'normal', formatter = identity, onChange }: any, ref: any) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOk = (values: any) => {
      setIsOpen(false);

      onChange(Number(values[INPUT_NAME]));
    };

    return (
      <>
        <div onClick={() => setIsOpen(true)} ref={ref}>
          <ValueLabel size={size}>{formatter(value)}</ValueLabel>
        </div>

        <AlertInput
          isOpen={isOpen}
          title={title}
          inputs={[
            {
              value,
              name: INPUT_NAME,
              type: 'number',
              placeholder: title,
              autofocus: true,
            },
          ]}
          onCancel={() => setIsOpen(false)}
          onOk={handleOk}
        />
      </>
    );
  }
);

AlertInputNumber.displayName = 'AlertInputNumber';

export default AlertInputNumber;
