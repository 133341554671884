import React from 'react';
import styled from 'styled-components';

type IProps = {
  children: JSX.Element;
};

const ReportContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.75rem;
  }
`;

const CreditReportContainer = (props: IProps) => {
  const { children } = props;

  return <ReportContainer>{children}</ReportContainer>;
};

export default CreditReportContainer;
