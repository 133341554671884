import { client as documentsClient, getDocuments, uploadFiles } from 'api/documents'
import { createDocumentsToken } from 'api/sso'
import { sendNotification } from '../../../api/loanzify'

const SsoDocuments = ({ user, consumerService, application }) => ({
  initial: [],

  async setup() {
    if (documentsClient.bindings?.service === 'sso') {
      return
    }

    documentsClient.bindings = {
      service: 'sso',
      token: await createDocumentsToken(consumerService.id),
      user_id: user.id,
      consumer_service_id: consumerService.id,
    }

    documentsClient.defaults.headers.common['Authorization'] = `Bearer ${documentsClient.bindings.token}`
  },

  async load() {
    return await getDocuments()
  },

  async upload(files, name, setProgress) {
    await uploadFiles(
      {
        files,
        title: name,
        consumer_name: `${user.first_name} ${user.last_name}`,
      },
      setProgress
    )

    try {
      await sendNotification({
        to: application.user1.id,
        message: 'New Document Uploaded',
        event: 'sso.document.uploaded',
        data: {
          ...user.id,
          file: {
            name,
            consumer_name: `${user.first_name} ${user.last_name}`,
          },
        },
      })
    } catch (err) {}

    return await this.load()
  },
})

export default SsoDocuments
