import React, { createContext, useContext } from 'react';
import { get } from 'lodash';
import { useAuth } from './Auth';
import { useApplication } from './Application';
import { useSession } from './Session';
import { MenuIconType } from '../types/Application';
import { OptionalExceptFor } from '../types/Utilities';
import { ReactComponent as ConventionalIcon } from '../icons/Icon-Conventional.svg';
import { ReactComponent as DocsOneIcon } from '../icons/Icon-Docs-1.svg';
import { ReactComponent as TeamsIcon } from '../icons/Icon-Teams.svg';
import { ReactComponent as CalculatorIcon } from '../icons/Icon-Calculator.svg';
import { ReactComponent as GlossaryIcon } from '../icons/Icon-Glossary.svg';
import { ReactComponent as ShareIcon } from '../icons/Icon-Share.svg';
import { ReactComponent as CallIcon } from '../icons/Icon-Call.svg';
import { ReactComponent as CloseIcon } from '../icons/Icon-Close.svg';
import { ReactComponent as MessagesIcon } from '../icons/Icon-Messages.svg';
import { ReactComponent as PurchaseIcon } from '../icons/Icon-Purchase.svg';
import { ReactComponent as SettingsIcon } from '../icons/Icon-Settings.svg';
import { ReactComponent as InfoIcon } from '../icons/Icon-Info.svg';
import { ReactComponent as SwitchViewIcon } from '../icons/Icon-SwitchView.svg';

const MenuContext = createContext<MenuContextType>(null!);

type MenuContextType = {
  items: MenuItem[];
  isMenuPreview: boolean;
  isShareMenuItem: (item: MenuItem) => boolean;
};

type MenuItem = {
  title: string;
  externalUrl?: string;
  routerLink?: string;
  icon: React.ComponentType;
};

type ConditionalMenuItemParams = OptionalExceptFor<MenuItem, 'title' | 'icon'>;

/**
 * Get icon component.
 */
const getIcon = (name: MenuIconType): React.ComponentType => {
  const ICON_MAP = {
    'menu-home': ConventionalIcon,
    'menu-documents': DocsOneIcon,
    'menu-leads': TeamsIcon,
    'menu-calculators': CalculatorIcon,
    'menu-guide': GlossaryIcon,
    'menu-share': ShareIcon,
    'menu-about': CallIcon,
    'menu-logout': CloseIcon,
    'menu-chat': MessagesIcon,
    'menu-home-purchase': PurchaseIcon,
    'menu-settings': SettingsIcon,
    'menu-info': InfoIcon,
  };

  return ICON_MAP[name];
};

export const MenuProvider: React.FC = ({ children }) => {
  const { application, isPreview, isMenuPreview, menuPreview, menuPreviewType } = useApplication();
  const { isConsumer, isUser, isPartner } = useAuth();
  const { forceConsumerView } = useSession();

  const isSideMenuPreview = isPreview && isMenuPreview && menuPreviewType === 'menu';
  const menuName = isSideMenuPreview ? menuPreview : (isConsumer || forceConsumerView ? 'consumer' : 'lo') + '-menu';

  const getConditionalMenuItem = (condition: boolean, menuItem: ConditionalMenuItemParams): MenuItem | null => {
    const { title, icon, routerLink, externalUrl } = menuItem;

    return condition
      ? {
          title,
          icon,
          routerLink,
          externalUrl,
        }
      : null;
  };

  const persistentBottomMenu = React.useMemo<MenuItem[]>(() => {
    const items: MenuItem[] = [];

    [
      getConditionalMenuItem(!!application.app_url, {
        title: 'Share App',
        icon: getIcon('menu-share'),
        routerLink: '/share',
        externalUrl: application.app_url,
      }),
      {
        title: 'About',
        icon: getIcon('menu-about'),
        routerLink: '/home/about',
      },
      getConditionalMenuItem(isUser && !isPartner, {
        title: 'Support',
        icon: getIcon('menu-info'),
        routerLink: '/home/support',
      }),
      {
        title: 'Settings',
        icon: getIcon('menu-settings'),
        routerLink: '/home/settings',
      },
      {
        title: 'Logout',
        icon: getIcon('menu-logout'),
        routerLink: '/auth/logout',
      },
    ]
      .filter(Boolean)
      .forEach((item: MenuItem) => {
        items.push(item);
      });

    return items;
  }, [isUser, isPartner]);

  const persistentTopMenu = React.useMemo<MenuItem[]>(() => {
    const items: MenuItem[] = [];

    [
      getConditionalMenuItem(isUser && !isPartner, {
        title: 'Toggle View',
        icon: SwitchViewIcon,
        routerLink: '/home/switch-view',
      }),
    ]
      .filter(Boolean)
      .forEach((item: MenuItem) => {
        items.push(item);
      });

    return items;
  }, [isUser]);

  const items = React.useMemo<MenuItem[]>(() => {
    const menus = application.menus || {};

    /**
     * The shape of our menu items are not correct.
     */
    let menu = get(menus, `${menuName}.items`, []).map(item => {
      const link = item.custom || item.external ? { externalUrl: item.location } : { routerLink: item.location };
      return {
        ...item,
        ...link,
        icon: getIcon(item.icon),
      };
    });

    return [persistentTopMenu, menu, persistentBottomMenu].flat().filter(Boolean);
  }, [menuName, isMenuPreview]);

  const isShareMenuItem = (item: MenuItem): boolean => {
    return item.routerLink === '/share';
  };

  const contextValue: MenuContextType = {
    items,
    isMenuPreview,
    isShareMenuItem,
  };

  return <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>;
};

export const useMenu = () => useContext(MenuContext);
