import * as React from 'react'
import MilestoneImage from './MilestoneImage'
import MilestoneStatus from './MilestoneStatus'
import { LoanMilestone, TimelineItem } from '../../../types/Pos'
import styled from 'styled-components'
import { useAuth } from '../../../store/Auth'
import { useEffect, useState } from 'react'

type MilestoneItemOrImageProps = {
  showModal: Function
  loanMilestone: LoanMilestone | TimelineItem
}

const MilestoneImageOrStatus: React.FC<MilestoneItemOrImageProps> = ({ showModal, loanMilestone }) => {
  const { isLoanOfficer, isPartner } = useAuth()
  const [dense, setDense] = useState(false)

  useEffect(() => {
    setDense(isLoanOfficer || isPartner)
  }, [setDense])

  return (
    <StyledWrapper dense={dense}>
      {((dense && !loanMilestone.completed_at && !loanMilestone.is_current && !loanMilestone.in_progress) ||
        !dense) && (
        <MilestoneImage
          style={{ width: dense ? '26px' : '58px', height: dense ? '26px' : '58px' }}
          milestone={loanMilestone.milestone || loanMilestone.milestone_event}
          dense={dense}
        />
      )}

      {(loanMilestone.completed_at || loanMilestone.is_current || loanMilestone.in_progress) && (
        <MilestoneStatus dense={dense} loanMilestone={loanMilestone} />
      )}

      {!loanMilestone.completed_at && !loanMilestone.is_current && !loanMilestone.in_progress && !dense && (
        <EmptyMilestoneStatus />
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  margin-right: ${props => (props.dense ? '12px' : '0')};
`

const EmptyMilestoneStatus = styled.div`
  border-radius: 9999px;
  height: 22px;
  width: 22px;
  border: 3px solid #d8dade;
  margin: 18px 5px 5px;
`

export default MilestoneImageOrStatus
