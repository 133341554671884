import React from 'react'

import CalculatorResultPage from 'containers/CalculatorResultPage'

import ResultContent from './components/ResultContent'

const ExtraResultPage = ({ path }) => {
  return (
    <CalculatorResultPage
      calculator="extraPayment"
      calculate={window.lhpCalculator.payoff}
      renderContent={result => <ResultContent path={path} result={result} />}
    />
  )
}

export default ExtraResultPage
