export const DATE_FORMAT = 'yyyy-MM-dd'

export const DURATION_OPTIONS = [
  {
    text: 'Last 7 Days',
    value: { days: 7 },
  },
  {
    text: 'Last 15 Days',
    value: { days: 15 },
  },
  {
    text: 'Last 30 Days',
    value: { days: 30 },
  },
  {
    text: 'Last Year',
    value: { days: 365 },
  },
  {
    text: 'All Time',
    value: { all: true, days: 999 },
  },
]
