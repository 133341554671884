import React, { useEffect } from 'react';
import { IonPage, IonContent } from '@ionic/react';

import Profile from 'components/Profile';
import ProfileAlt from 'components/ProfileAlt';
import LogoHeader from 'components/LogoHeader';
import HeaderAdmin from 'components/HeaderAdmin';
import Dashboard from './components/Dashboard';

import { useAuth } from 'store/Auth';
import { useSession } from 'store/Session';
import { useApplication } from 'store/Application';
import ProfilePartnerCoBranding from '../../components/ProfilePartnerCoBranding';
import { Redirect } from 'react-router-dom';

const HomePage = () => {
  const { owner, isDefault, isPartnerApp, license, nmls } = useApplication();
  const { service, user, isConsumer, isUser, isPartner, isPos, posUser } = useAuth();
  let { alternate_profile, setAlternateProfile, forceConsumerView } = useSession();

  useEffect(() => {
    if (isPos) {
      setAlternateProfile(false);
    }

    return () => setAlternateProfile(false);
  }, []);

  const renderProfilePicture = () => {
    if (isUser && !forceConsumerView && !isPartner) {
      return;
    }

    if (isPartnerApp) {
      return <ProfilePartnerCoBranding />;
    }

    if (isUser) {
      return <Profile owner={owner} />;
    }

    if (isPos && posUser && posUser.force_password_reset === true) {
      return <Redirect to="/auth/force-password-reset" />;
    }

    if (alternate_profile && !isDefault) {
      return <ProfileAlt owner={owner} license={license} nmls={nmls} isDefault={isDefault} />;
    }

    return <Profile owner={owner} />;
  };

  return (
    <IonPage>
      {isUser && !forceConsumerView && !isPartner ? <HeaderAdmin /> : <LogoHeader />}

      <IonContent>
        {renderProfilePicture()}

        <Dashboard isPartner={isPartner} isUser={isUser && !forceConsumerView} user={user} owner={owner} />
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
