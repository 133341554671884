import React from 'react'
import { useIonModal } from '@ionic/react'
import { get } from 'lodash'

interface Hook {
  <C extends React.FC<any>>(component: C, componentProps?: React.ComponentProps<C>): [() => void, () => void]
}

const useModal: Hook = (component, componentProps) => {
  const [present, dismiss] = useIonModal(component, componentProps)

  const getClass = () => {
    const classes = ['loanzify-modal']

    classes.push(get(componentProps, 'cssClass', ''))

    if (get(componentProps, 'autoHeight', false) === true) {
      classes.push('auto-height')
    }

    return classes.join(' ')
  }

  return [
    () => {
      present({
        cssClass: getClass(),
        swipeToClose: true,
        backdropDismiss: true,
      })
    },
    dismiss,
  ]
}

export default useModal
