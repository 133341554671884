import React from 'react'
import { IonContent, IonPage } from '@ionic/react'

import { useContent } from 'store/Content'

import Header from 'components/Header'
import ListItem from 'components/ListItem'
import List from 'components/List'

const ExplorePage = () => {
  const { features } = useContent()

  const items = features.mortgageguide.filter(item => item.name !== 'news').sort((a, b) => a.position - b.position)

  return (
    <IonPage>
      <Header title="Explore" defaultHref="/home" />
      <IonContent>
        <List>
          {items.map(({ id, title, name }) => (
            <ListItem
              key={id}
              title={title}
              startIcon={require(`legacy-icons/menu`)[name]}
              routerLink={`/content/${name}`}
            />
          ))}
        </List>
      </IonContent>
    </IonPage>
  )
}

export default ExplorePage
