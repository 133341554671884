import React from 'react';
import { IonPage } from '@ionic/react';

import { CALCULATORS_CONFIG } from 'config/calculators';
import { useCalculator } from 'store/Calculator';
import Header from 'components/Header';

import ResultDetails from './components/ResultDetails';

const PurchaseDetailsPage: React.FC = () => {
  const { options, result } = useCalculator();

  return (
    <IonPage>
      <Header defaultHref="/calculators/purchase/result" title={CALCULATORS_CONFIG.purchase.title} />

      <ResultDetails options={options} result={result} />
    </IonPage>
  );
};

export default PurchaseDetailsPage;
