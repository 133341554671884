import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import ResultChart from './components/ResultChart'
import Label from 'components/Label'
import { getChartColors } from '../../../utils/theme'
import { formatCurrency } from '../../../utils/formatters'

const ChartContainer = styled.div`
  position: relative;
  height: 15rem;
`

const ChartOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 3rem;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
`

const Graph = ({ result }) => {
  const [loaded, setLoaded] = useState(false)

  const chartColors = useMemo(() => {
    const colors = getChartColors()

    return [colors[1], colors[0]]
  }, [])

  useEffect(() => {
    /**
     * This is a workaround to render <Doughnut /> component after 100ms.
     * If we render this cmoponent as soon as the component is ready,
     * for some reason it does not play the animation.
     */
    setTimeout(() => setLoaded(true), 100)
  }, [])

  return (
    <>
      <Label center color="gray">
        Original: {formatCurrency(result.base_total)}
      </Label>

      {loaded && (
        <ChartContainer>
          <ResultChart result={result} colors={chartColors} />

          <ChartOverlay>
            <Label size="sm" color="gray">
              {result.base_duration}
            </Label>

            <Label size="sm" color="gray">
              {result.new_duration}
            </Label>
          </ChartOverlay>
        </ChartContainer>
      )}

      <Label center color="gray">
        With New Payment: {formatCurrency(result.new_total)}
      </Label>
    </>
  )
}

export default Graph
