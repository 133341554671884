import React, { useEffect, useState } from 'react'
import { IonLoading } from '@ionic/react'

import { register as registerSso, login as ssoLogin } from 'api/sso'
import { useApplication } from 'store/Application'
import { useAuth } from 'store/Auth'
import { useOnboarding } from 'store/Onboarding'

import Form from './components/Form'
import Layout from './components/Layout'
import Button from './components/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ButtonWrapper from './components/ButtonWrapper'
import { useSession } from '../../store/Session'
import { registerTrial } from '../../api/loanzify'
import FormSelect from './components/FormSelect'

const schema = yup.object().shape({
  password: yup.string().required('This field is required').min(8),
})

const RegisterQuestionsPage = ({ history }) => {
  const { application, owner, isDefault } = useApplication()
  const { login } = useAuth()
  const { email, firstName, lastName, phone, reset, password } = useOnboarding()
  const [loading, setLoading] = useState(false)
  const { setShowWelcomeScreen, setApplicationId, setShowSelfieView } = useSession()
  const [role, setRole] = useState('')
  const [trial, setTrial] = useState(1)

  const { handleSubmit, setError, trigger, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      trial: 1,
    },
  })

  const onSubmit = () => {
    const handleError = error => {
      setError('password', {
        type: 'manual',
        message: error.response?.data?.errors?.password?.[0] || error.response?.data?.message,
      })
    }

    const handleTrialSuccess = ({ application_id }) => {
      setApplicationId(application_id)

      ssoLogin(email, password)
        .then(authenticatable => {
          login(authenticatable).then(() => {
            reset()
            setShowSelfieView(false)
            history.push('/onboarding')
            // history.push('/home/switch-view')
          })
        })
        .catch(handleError)
        .finally(() => setShowWelcomeScreen(false))
    }

    const handleSuccess = authenticatable => {
      login(authenticatable).then(() => {
        reset()
        history.push('/auth/select-loan-officer')
      })
    }

    setLoading(true)

    const data = {
      service: 'loanzify',
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      phone,
      role,
    }

    if (role === 'mortgage-professional' && trial) {
      registerTrial(data)
        .then(handleTrialSuccess)
        .catch(data => {
          setLoading(false)
          handleError(data)
        })
        .finally(() => setShowWelcomeScreen(false))
    } else {
      registerSso(owner.id, application.is_enterprise && !application.user2_id, data, application.user1_id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setShowWelcomeScreen(false))
    }
  }

  const handleClick = async () => {
    const result = await trigger()

    if (result) {
      onSubmit(getValues())
    }
  }

  useEffect(() => {
    if (!isDefault) {
      handleClick()
    }
  }, [])

  if (!isDefault) {
    return <IonLoading isOpen={loading} />
  }

  const Footer = (
    <ButtonWrapper>
      <Button onClick={() => handleClick()}>Register</Button>
    </ButtonWrapper>
  )

  return (
    <Layout subtitle="Tell us how you will use the app..." footer={Footer} backUrl="/auth/register/password">
      <IonLoading isOpen={loading} />

      <Form onSubmit={handleSubmit(onSubmit)}>
        {isDefault && (
          <FormSelect
            name="role"
            onIonChange={event => setRole(event.detail.value)}
            label={'Are you a Mortgage Professional?'}
            placeholder={'Select'}
            options={[
              { title: 'No', value: 'consumer' },
              { title: 'Yes', value: 'mortgage-professional' },
            ]}
          />
        )}

        {role === 'mortgage-professional' && (
          <FormSelect
            onIonChange={event => setTrial(event.detail.value)}
            name="trial"
            label={
              'Would you like to start a FREE 14-day trial to create your own branded app? (no credit card required)'
            }
            placeholder={'Yes'}
            value={trial}
            options={[
              { title: 'Yes', value: 1 },
              { title: 'No', value: 0 },
            ]}
          />
        )}
      </Form>
    </Layout>
  )
}

export default RegisterQuestionsPage
