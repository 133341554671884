import styled from 'styled-components'
import { IonRange } from '@ionic/react'

const Range = styled(IonRange)`
  --bar-background: var(--color-gray-lightest);
  --bar-background-active: var(--color-primary-light);
  --bar-height: 0.5rem;
  --bar-border-radius: 999px;

  --knob-background: var(--color-primary);
  --knob-size: 20px;
  --knob-box-shadow: 0 0 0 4px var(--color-primary-light);

  margin-top: 0px;

  padding: 0;

  pointer-events: none;

  &::part(knob) {
    pointer-events: auto;
  }
`

export default Range
