import React from 'react'
import styled from 'styled-components'

import Label from 'components/Label'
import AvatarCircle from './AvatarCircle'

import { TeamMember } from 'types/Pos'

interface Props {
  member: TeamMember
  onExpanded: () => void
}

const ShortMemberCard: React.FC<Props> = ({ member, onExpanded }) => (
  <StyledCard onClick={onExpanded}>
    <AvatarCircle
      src={member.src}
      color="gray-lighter"
      width={82}
      height={82}
      borderWidth="3px"
      name={member.full_name}
    />

    <TitleContainer>
      <Label size="lg" color="gray" block align={'left'} wrapped={true}>
        {member.full_name}
      </Label>
      <Label color="gray-light" size="sm" block align={'left'} fontStyle="italic">
        {member.role}
        {member.role === 'Loan Officer' && <>&nbsp; | NMLS# {member.nmls}</>}
        {(member.role === 'Realtor' || member.role === 'Partner') && member.nmls && <>| License: {member.nmls}</>}
      </Label>
    </TitleContainer>
  </StyledCard>
)

const StyledCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const TitleContainer = styled.div`
  flex: 1;
  text-align: center;
  margin: 0.5rem 0 0 1.5rem;
  align-self: stretch;
`

export default ShortMemberCard
