import { useCallback, useEffect, useState } from 'react'

const useDocuments = repository => {
  const [loading, setLoading] = useState(true)
  const [uploading, setUploading] = useState(false)
  const [destroying, setDestroying] = useState(false)
  const [documents, setDocuments] = useState(repository.initial)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const setup = async () => {
      try {
        await repository.setup()
        setDocuments(await repository.load())
        setLoading(false)
      } catch (err) {
        setDocuments({})
        setLoading(false)
      }
    }

    setup()
  }, [repository])

  const refresh = useCallback(async () => {
    setDocuments(await repository.load())
  }, [repository])

  const upload = useCallback(
    async (files, name) => {
      setUploading(true)
      setDocuments(await repository.upload(files, name, setProgress))
      setUploading(false)
    },
    [repository]
  )

  const destroy = useCallback(
    async document => {
      if (!repository.destroy) {
        return
      }

      setDestroying(true)
      setDocuments(await repository.destroy(document))
      setDestroying(false)
    },
    [repository]
  )

  return {
    loading,
    uploading,
    destroying,
    documents,
    progress,
    refresh,
    upload,
    destroy,
  }
}

export default useDocuments
