import React from 'react'
import styled from 'styled-components'

import { ReactComponent as ChevronRightIcon } from 'legacy-icons/chevron-right.svg'

import Card from './Card'
import Label from './Label'

/**
 * @param {{
 * children?: string|string[]|any
 * title?: string
 * endIcon?: any
 * onEndIconClick?: any
 * onClick?: any
 * startIcon?: any
 * startSlot?: any
 * href?: string
 * }} props
 * @returns
 */
const ListItem = ({
  children,
  title,
  endIcon,
  onEndIconClick,
  onClick,
  startIcon: StartIconComponent,
  startSlot,
  ...cardProps
}) => {
  const hasRightIcon = cardProps.routerLink || cardProps.href || endIcon
  const EndIconComponent = endIcon || ChevronRightIcon

  return (
    <Card {...cardProps}>
      <HeaderContainer onClick={onClick}>
        <StartContainer>
          {startSlot}

          {StartIconComponent && <StartIconComponent />}

          <Label color="gray" secondary isWrapped>
            {title}
          </Label>
        </StartContainer>

        {hasRightIcon && (
          <div className="icon-container" onClick={onEndIconClick}>
            <EndIconComponent />
          </div>
        )}
      </HeaderContainer>

      {children}
    </Card>
  )
}

const HeaderContainer = styled.div`
  min-height: 3.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  user-select: none;
`

const StartContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: var(--color-primary);
    fill: currentColor;
    margin-right: 0.75rem;
  }
`

export default ListItem
