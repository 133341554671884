import React, { useState } from 'react';
import styled from 'styled-components';
import { IonButton } from '@ionic/react';

import { ReactComponent as ShareIcon } from 'legacy-icons/share.svg';
import { ReactComponent as ChartIcon } from 'legacy-icons/chart.svg';
import { ReactComponent as CalendarIcon } from 'legacy-icons/calendar.svg';
import { ReactComponent as DownloadIcon } from 'legacy-icons/download.svg';
import { ReactComponent as ArrowUpIcon } from 'legacy-icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'legacy-icons/arrow-down.svg';

import FlexContainer from 'components/FlexContainer';
import Label from 'components/Label';
import Backdrop from 'components/Backdrop';

interface ResultFooterProps {
  canDownload: boolean;
  canSave: boolean;
  onDetails: () => void;
  onAmortisation: () => void;
  onDownload: () => void;
  onSave: () => void;
  onPreQualify: () => void;
  onShare: () => void;
  hideSave: boolean;
  hidePrequalify: boolean;
}
const ResultFooter: React.FC<ResultFooterProps> = ({
  canDownload = true,
  canSave = true,
  onDetails,
  onAmortisation,
  onDownload,
  onSave,
  onPreQualify,
  onShare,
  hideSave = false,
  hidePrequalify = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(open => !open);

  return (
    <React.Fragment>
      {isOpen && <Backdrop />}

      <FooterContainer isOpen={isOpen} small={hideSave && hidePrequalify}>
        <div className="arrow-wrapper"></div>
        <div className="arrow-container" onClick={toggle}>
          {isOpen ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </div>
        <div className="footer-backdrop">
          {isOpen && (
            <React.Fragment>
              <FlexContainer className="ion-justify-content-center">
                <Label size="normal">Calculation Details</Label>
              </FlexContainer>
              <FlexContainer
                className="ion-justify-content-center ion-align-items-center ion-margin-bottom"
                style={{ marginTop: '1rem' }}
              >
                <IconButtonContainer>
                  <IconButton onClick={onShare}>
                    <ShareIcon />
                  </IconButton>

                  <Label secondary color="gray-light" size="xs" fontStyle="italic">
                    Share
                  </Label>
                </IconButtonContainer>

                <IconButtonContainer>
                  <IconButton onClick={onDetails}>
                    <ChartIcon />
                  </IconButton>

                  <Label secondary color="gray-light" size="xs" fontStyle="italic">
                    Details
                  </Label>
                </IconButtonContainer>

                <IconButtonContainer>
                  <IconButton onClick={onAmortisation}>
                    <CalendarIcon />
                  </IconButton>

                  <Label secondary color="gray-light" size="xs" fontStyle="italic">
                    Amortization
                  </Label>
                </IconButtonContainer>

                {canDownload && (
                  <IconButtonContainer>
                    <IconButton onClick={onDownload}>
                      <DownloadIcon />
                    </IconButton>

                    <Label secondary color="gray-light" size="xs" fontStyle="italic">
                      Download
                    </Label>
                  </IconButtonContainer>
                )}
              </FlexContainer>

              <FlexContainer className="ion-justify-content-center ion-align-items-center">
                {!hideSave && (
                  <ChartButton center color="secondary" fill="outline" disabled={!canSave} onClick={onSave}>
                    Save
                  </ChartButton>
                )}
                {!hidePrequalify && (
                  <ChartButton center color="secondary" onClick={onPreQualify}>
                    Pre-Qualify
                  </ChartButton>
                )}
              </FlexContainer>
            </React.Fragment>
          )}
        </div>
      </FooterContainer>
    </React.Fragment>
  );
};

const FooterContainer = styled.div<{ isOpen: boolean; small: boolean }>`
  display: block;
  box-shadow: 0 25px 50px -12px rgb(0 0 0);
  height: ${props => (props.isOpen ? (props.small ? '180px' : '240px') : '34px')};
  transition: height .25s ease;
  position: fixed;
  bottom: -10px;
  left: 0;
  right: 0;
  z-index: 999;

  .footer-backdrop {
    position: relative;
    z-index: 99;
    background: linear-gradient(#FFFFFF 0%, #FCFCFC 21%, #F2F2F2 100%);
    height: 100%;
    width: 100%;
    padding-top: ${props => (props.isOpen ? '30px' : '0')};
  }


  .arrow-wrapper, .arrow-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 9999px;
  }

 .arrow-wrapper {
    left: calc(50% - 25px);
    top: -25px;
    z-index: 9;
    box-shadow: 0px -5px 15px -12px rgb(0 0 0);
    background: transparent;
    border: 5px solid #F5F6F7;
    width: 50px;
    height: 50px;
  }

  .arrow-container {
    top: -20px;
    left: calc(50% - 20px);
    z-index: 999;
    background: #fff;
    width: 40px;
    height: 40px;
  }

    svg {
      width: 50px;
    }
  }
`;

const IconButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 90px;
`;

const IconButton = styled.button`
  background: var(--color-gray-lightest);
  color: var(--color-gray-light);

  border: none;
  border-radius: 999px;

  width: 50px;
  height: 50px;

  margin-bottom: 0.5rem;
  padding: 0;
`;

const ChartButton = styled(IonButton)`
  --border-radius: 999px;
  width: 150px;
  margin: 0 0.5rem;
`;

export default ResultFooter;
