import { useState } from 'react'

import {
  createRunCalculation,
  createSavedCalculation,
  sendPdfDownloadCalculation,
  sendRunCalculationPrequalify,
} from 'api/loanzify'
import { useCalculations } from 'store/Calculations'
import { useCalculator } from 'store/Calculator'
import { retrieveDownPayment } from '../utils/calculator'
import { useApplication } from '../store/Application'

/**
 * Auto tracks run calculation to the database
 *
 * @param {string} calculator Calculator name
 */
const useCalculatorResult = calculator => {
  const { runCalculation, setRunCalculation } = useCalculations()
  const { options } = useCalculator()
  const { application } = useApplication()

  const [calculatorNameOpen, setCalculatorNameOpen] = useState(false)
  const [saved, setSaved] = useState(false)
  const [error, setError] = useState('')
  const [tracking, setTracking] = useState(false)

  const handleSave = () => {
    if (!runCalculation) {
      setError('There is an issue in tracking calculation')
      return
    }

    setCalculatorNameOpen(true)
  }

  const saveCalculator = async ({ name }) => {
    setCalculatorNameOpen(false)

    try {
      await createSavedCalculation(runCalculation.id, name)

      setSaved(true)
    } catch (error) {
      setError(error.message)
    }
  }

  const sendPdfCalculation = () => sendPdfDownloadCalculation(runCalculation.id)

  const sendPreQualify = () => sendRunCalculationPrequalify(runCalculation.id)

  /**
   * Track current calculator result if it is not tracked yet or options are changed
   */
  const saveRunCalculation = result => {
    const { type, ...optionsWithoutType } = options
    let shouldTrack = false

    if (!runCalculation) {
      shouldTrack = true
    } else {
      const { version, ...runCalculationOptions } = JSON.parse(runCalculation.params_json)

      shouldTrack =
        runCalculation.program !== type || JSON.stringify(runCalculationOptions) !== JSON.stringify(optionsWithoutType)
    }

    if (shouldTrack) {
      setTracking(true)

      const paramsBody = { ...optionsWithoutType, result }
      delete paramsBody.mi
      delete paramsBody.factors

      paramsBody.interest_rate =
        paramsBody.interest_rate >= 1 ? paramsBody.interest_rate / 100 : paramsBody.interest_rate

      if (!paramsBody.is_refi) {
        const extraIncrease =
          ['va', 'rural', 'fha'].indexOf(options.type) > -1
            ? result.fha_upfront_mip || result.rural_upfront_mip || result.va_fee
            : 0

        paramsBody.loan_amount = options.property_price - retrieveDownPayment(options) + extraIncrease
      }

      paramsBody.application_id = application.id

      createRunCalculation({
        calculator,
        program: type,
        params: paramsBody,
      })
        .then(({ data }) => {
          setRunCalculation(data)
        })
        .finally(() => {
          setTracking(false)
        })
    }
  }

  return {
    calculatorNameOpen,
    saved,
    error,
    tracking,
    setCalculatorNameOpen,
    handleSave,
    saveCalculator,
    saveRunCalculation,
    sendPdfCalculation,
    sendPreQualify,
    runCalculation,
  }
}

export default useCalculatorResult
