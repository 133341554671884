import List from 'components/List'
import React from 'react'

import InstallationCard from './InstallationCard'

const InstallationList = ({ installations }) => (
  <List>
    {installations.map(installation => (
      <InstallationCard
        key={installation.id}
        installation={installation}
        routerLink={`/installations/${installation.id}`}
      />
    ))}
  </List>
)

export default InstallationList
