import React, { useMemo } from 'react'
import { IonContent, IonLoading, IonPage } from '@ionic/react'

import Header from 'components/Header'
import Placeholder from '../components/Placeholder'
import SsoDocumentList from './components/SsoDocumentList'
import StartUploadingButton from '../components/StartUploadingButton'

import { useAuth } from 'store/Auth'
import useDocuments from '../hooks/useDocuments'
import SsoDocuments from '../repositories/SsoDocuments'
import ProgressBar from 'components/ProgressBar'
import { useApplication } from '../../../store/Application'

const SsoDocumentsPage = () => {
  const { user, consumerService } = useAuth()
  const { application } = useApplication()

  const repository = useMemo(() => {
    return SsoDocuments({ user, consumerService, application })
  }, [])

  const { loading, uploading, documents, refresh, upload, progress } = useDocuments(repository)

  return (
    <IonPage>
      <Header title="Documents" />

      <IonContent className="ion-padding">
        {uploading && <ProgressBar progress={progress} title={`Uploading... (${progress}%)`} />}

        {loading ? (
          <IonLoading isOpen={loading} message="Loading..." />
        ) : documents.length ? (
          <>
            <SsoDocumentList documents={documents} onRefresh={refresh} />
            <StartUploadingButton uploaded={1} onFilesSelected={upload} />
          </>
        ) : (
          <Placeholder onFilesSelected={upload} />
        )}
      </IonContent>
    </IonPage>
  )
}

export default SsoDocumentsPage
