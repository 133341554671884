import { IonLoading } from '@ionic/react';
import { getCalculatorDefaults } from 'api/sso';
import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { useApplication } from './Application';
import { CalculatorDefaults } from 'types/Calculator';

interface Context {
  calculatorDefaults: CalculatorDefaults;
}

const CalculatorDefaultsContext = createContext({} as Context);

const CalculatorDefaultsProvider: React.FC = props => {
  const { ssoOwner } = useApplication();

  const query = useQuery(
    'calculator-defaults',
    async () => {
      return await getCalculatorDefaults(ssoOwner.id);
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour
    }
  );

  if (query.isLoading) {
    return <IonLoading isOpen={true} message="Loading..." />;
  }

  return <CalculatorDefaultsContext.Provider {...props} value={{ calculatorDefaults: query.data }} />;
};

export default CalculatorDefaultsProvider;

export const useCalculatorDefaults = () => useContext(CalculatorDefaultsContext);
