import React from 'react';
import { useQuery } from 'react-query';
import { IonPage, IonContent, IonLoading } from '@ionic/react';
import { getNotifications, markNotificationsAsRead } from 'api/loanzify';

import IconNoAlerts from 'icons/Icon-NoAlerts.svg';
import NotificationsList from './components/NotificationsList';
import { usePushNotifications } from 'store/PushNotifications';
import Header from '../../components/Header';
import NoDataLoaded from '../../components/NoDataLoaded';
import NoNotificationsAnimation from '../../components/animations/NoNotificationsAnimation';

interface Props {
  //
}

const NotificationsPage: React.FC<Props> = () => {
  const { removeAllDeliveredNotifications } = usePushNotifications();

  const query = useQuery(
    'notifications',
    () => {
      return getNotifications()
        .then(paginator => paginator.data)
        .finally(() => {
          markNotificationsAsRead();
          removeAllDeliveredNotifications();
        });
    },
    { retry: false }
  );

  if (query.isLoading) {
    return <IonLoading isOpen={true} message="Loading..." />;
  }

  if (!query.isSuccess) {
    return (
      <IonPage>
        <Header title="Notifications" />

        <IonContent>
          <NoDataLoaded
            icon={IconNoAlerts}
            message="We could not load your notifications. Please try again later."
            color="danger"
          />
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <Header title="Notifications" />

      <IonContent>
        <div style={{ marginTop: '1rem', padding: '0 5px' }}>
          {query.data.length ? (
            <NotificationsList notifications={query.data} />
          ) : (
            <NoDataLoaded icon={false} message="No notifications have been sent to you yet...">
              <NoNotificationsAnimation />
            </NoDataLoaded>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotificationsPage;
