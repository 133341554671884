import * as React from 'react'
import styled from 'styled-components'
import { IonCard, IonCardContent, IonContent, IonInput, IonPage } from '@ionic/react'
import { useEffect, useState } from 'react'
import Header from '../../components/Header'
import ModalContent from '../../components/ModalContent'
import useModal from '../../hooks/useModal'
import { Clipboard } from '@capacitor/clipboard'
import { Toast } from '@capacitor/toast'
import Label from '../../components/Label'

type Icon = {
  icon: string
  legacy: boolean
  name: string
  path: string
}

const iconMapper = (set, legacy = false) => {
  return set.keys().reduce((acc, next) => {
    acc.push({
      icon: set(next),
      legacy,
      name: next.split('/').pop(),
      path: `${legacy ? 'legacy-icons' : 'icons'}/${next.replace('./', '')}`,
    })
    return acc
  }, [])
}

const newIcons = require.context('../../icons', false, /\.(svg)$/)
const legacyIcons = require.context('../../legacy-icons', true, /\.(svg|png)$/)

const icons = [...iconMapper(newIcons), ...iconMapper(legacyIcons, true)]

icons.sort((a: Icon, b: Icon) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1
  }

  if (a.name.toLowerCase() === b.name.toLowerCase()) {
    return 0
  }

  return 1
})

const iconName = (icon: string) => {
  return icon ? icon.replace('.svg', '').replace('.png', '') : null
}

const IconsPage: React.FC = () => {
  const [filter, setFilter] = useState()
  const [selectedIcon, setSelectedIcon] = useState()
  const [modalProps, setModalProps] = useState()
  const [present] = useModal(IconModal, modalProps)

  const filteredIcons = () => {
    return icons.filter((icon: Icon) => (filter ? icon.name.toLowerCase().includes(filter.toLowerCase()) : icon))
  }

  const setFilterValue = e => {
    setFilter(e.target.value)
  }

  const showIcon = (icon: Icon) => {
    console.log(icon)
    setSelectedIcon(icon)
  }

  useEffect(() => {
    if (selectedIcon) {
      setModalProps({
        icon: selectedIcon,
        name: iconName(selectedIcon.name),
      })
      present()
    }
  }, [selectedIcon])

  return (
    <IonPage>
      <Header title="Icons" />
      <IconSearchCard>
        <IonCardContent>
          <IconSearchInput placeholder={`Search ${icons.length} icons for...`} onIonChange={setFilterValue} />
        </IonCardContent>
      </IconSearchCard>

      <IonContent>
        <IonCard>
          <IonCardContent>
            <IconsContainer>
              {filteredIcons().map((icon, index) => (
                <IconWrapper key={`${icon.name}-${index}`} onClick={() => showIcon(icon)}>
                  <img src={icon.icon.default} alt={icon.name} />
                  <IconName>{iconName(icon.name)}</IconName>
                </IconWrapper>
              ))}
            </IconsContainer>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}

const IconModalContainerImage = styled.div`
  margin: 0 auto;
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 1rem;
`

const IconModalImportPathWrapper = styled.div`
  background: #ecebeb;
  border: 1px solid #e5e5e5;
  overflow-x: scroll;
  padding: 0.25rem 0.5rem;
  margin-top: 0.675rem;
`

const IconModalWrapper = styled.div`
  padding: 1.25rem;
`

const IconModal: React.FC = props => {
  const { icon, name } = props

  const ucFirst = str => str.charAt(0).toUpperCase() + str.slice(1, str.length)

  const importPath = `import { ReactComponent as Icon${ucFirst(name)} } from '${icon.path}'`

  const copyPath = () => {
    Clipboard.write({ string: importPath })
    Toast.show({ text: 'Path copied!' })
  }

  return (
    <ModalContent>
      <IconModalWrapper>
        <IconModalContainerImage>
          <img src={icon.icon.default} alt={icon.name} />
          <IconName>{name}</IconName>
        </IconModalContainerImage>
        <Label weight={400}>Import Path: </Label>
        <IconModalImportPathWrapper onClick={copyPath}>{importPath}</IconModalImportPathWrapper>
      </IconModalWrapper>
    </ModalContent>
  )
}

const IconSearchCard = styled(IonCard)`
  margin-bottom: 1.5rem;
`

const IconSearchInput = styled(IonInput)`
  border: 1px solid #ecebeb;
`

const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const IconWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding: 1rem;
  width: calc(100% / 2);

  @media (min-width: 30em) {
    width: calc(100% / 4);
  }

  @media (min-width: 60em) {
    width: calc(100% / 6);
  }

  @media (min-width: 77.5em) {
    width: calc(100% / 9);
  }

  img {
    display: inline;
    max-height: 40px;
    width: auto;
    object-fit: contain;
  }
`

const IconName = styled.div`
  text-align: center;
  margin-top: auto;
`

export default IconsPage
