import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { IonLoading } from '@ionic/react';

import useQueryString from 'hooks/useQueryString';
import useApplicationSetter from 'hooks/useApplicationSetter';
import { useOnboarding } from 'store/Onboarding';
import { Toast } from '@capacitor/toast';
import { BranchDeepLinks } from 'capacitor-branch-deep-links';
import usePersistentState from 'hooks/usePersistentState';
import { InitialSessionState } from 'types/Session';
import { initialSession } from './Session';

const BranchDeepLinksProvider = ({ children }) => {
  const history = useHistory();
  const query = useQueryString();
  const setApplication = useApplicationSetter();
  const { setRedirect } = useOnboarding();
  const [session, setSession] = usePersistentState<InitialSessionState>('session', initialSession);

  const [loading, setLoading] = useState(true);

  const handleLink = ({ appId, claimToken, email, loginToken, broker, extendTrial }) => {
    if (appId) {
      appId = appId.toString().indexOf('/') > -1 ? appId.toString().replace('/', '') : appId;
      setApplication(appId);
      Toast.show({ text: `Application changed to ${appId}` });
    }

    if (extendTrial) {
      setRedirect('/auth/extend-trial');
    }

    if (claimToken) {
      history.push(`/auth/reset-password/${claimToken}/${email}`);
    } else if (loginToken) {
      history.push(`/auth/token-login/${loginToken}/${email}/${broker}`);
    }

    setLoading(false);
  };

  useEffect(() => {
    // Skip the provider if a link is not handled in 1 second.
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    // Set 'app_id' query parameter on web.
    if (!Capacitor.isNativePlatform()) {
      handleLink({
        appId: query.get('app_id') || query.get('loanzify_application_id'),
        claimToken: query.get('claim-token'),
        email: query.get('email'),
        loginToken: query.get('login-token'),
        broker: query.get('broker'),
        extendTrial: Boolean(query.get('extend-trial')),
      });

      return;
    }

    // Branch.io link listener.
    BranchDeepLinks.addListener('init', event => {
      if (!event?.referringParams?.['+clicked_branch_link']) {
        console.log('Branch.io link not detected.');

        return;
      }

      console.log('Branch.io link detected.', { params: event?.referringParams });

      handleLink({
        appId: event?.referringParams?.app_id || event?.referringParams?.loanzify_application_id,
        claimToken: event?.referringParams?.['claim-token'],
        loginToken: event?.referringParams?.['login-token'],
        email: event?.referringParams?.email,
        broker: event?.referringParams?.broker,
        extendTrial: Boolean(event?.referringParams?.['extend-trial']),
      });
    });

    // Branch.io error listener.
    BranchDeepLinks.addListener('initError', error => {
      throw error;
    });
  }, []);

  if (loading) {
    return <IonLoading isOpen={true} message="Loading..." />;
  }

  return children;
};

export default BranchDeepLinksProvider;
