import React from 'react';
import styled from 'styled-components';
import { IonGrid, IonCol, IonRow } from '@ionic/react';
import ConsumerFooter from './ConsumerFooter';
import ShareAppCard from 'components/ShareAppCard';
import CardImgButton from 'components/CardImgButton';
import StartLoanButton from 'components/StartLoanButton';
import contactMeIcon from 'legacy-icons/dashboard/contact-me.png';
import documentsIcon from 'legacy-icons/dashboard/documents.png';
import calculatorIcon from 'legacy-icons/dashboard/calculator.png';
import applicationIcon from 'icons/Icon-ApplyNow.svg';
import applicationInProgressIcon from 'icons/icon-illustration-ApplicationPending.png';
import mortgageLibraryIcon from 'legacy-icons/dashboard/mortgage-library.png';

import { getIcon } from 'utils/menu';
import { useApplication } from 'store/Application';
import { useAuth } from 'store/Auth';
import { useSession } from 'store/Session';
import StepManager from '../../../components/StepManager';

const StyledIonGrid = styled(IonGrid)`
  --ion-grid-column-padding: 0.5rem;
  padding: 0 1rem;
`;

const SsoConsumerDashboard = () => {
  const { alternate_profile, forceConsumerView } = useSession();
  const { isDefault, application } = useApplication();
  const { isPartner, isUser, isConsumer, isPos } = useAuth();

  const menus = application.menus || {};
  const consumerDashboardMenu = menus['consumer-dashboard']?.items || [];

  let shareDescription;

  if (isPartner) {
    shareDescription = 'Share your personal app with your homebuyer prospects';
  } else if (isUser) {
    shareDescription = 'Share your app link with borrowers and realtor partners';
  }

  return (
    <StyledIonGrid>
      {(isUser || (!alternate_profile && !isConsumer)) && !forceConsumerView && (
        <IonRow>
          <IonCol>
            <ShareAppCard
              className="home--share_app_button"
              title={isConsumer ? 'Contact Me' : ''}
              description={shareDescription}
              routerLink={isConsumer ? '/home/profile' : ''}
            />
          </IonCol>
        </IonRow>
      )}

      {consumerDashboardMenu.length === 0 && (
        <IonRow>
          <IonCol size={'6'}>
            <StepManager>
              <CardImgButton
                id="step-sso-consumer-home-calculator"
                src={calculatorIcon}
                title="Calculators"
                className="ion-no-margin"
                routerLink="/calculators"
              />
            </StepManager>
          </IonCol>

          <IonCol size={'6'}>
            <CardImgButton
              id="step-sso-consumer-mortgage-library"
              src={mortgageLibraryIcon}
              title="Mortgage Library"
              className="ion-no-margin"
              routerLink="/content"
            />
          </IonCol>

          <IonCol size={'6'}>
            <StartLoanButton
              id="step-sso-consumer-start-application"
              component={CardImgButton}
              src={applicationIcon}
              title="Start Application"
              className="ion-no-margin"
              height={75}
              width={95}
            />
          </IonCol>

          {!process.env.REACT_APP_DOCUMENTS_DISABLED && (
            <IonCol size={'6'}>
              <CardImgButton
                id="step-sso-consumer-upload-docs"
                src={documentsIcon}
                title="Upload Docs"
                className="ion-no-margin"
                routerLink="/documents"
                routerDirection="root"
              />
            </IonCol>
          )}

          {!isDefault && (
            <IonCol size={'6'}>
              <CardImgButton
                id="step-sso-consumer-contact-me"
                src={contactMeIcon}
                title="Contact Me"
                className="ion-no-margin"
                routerLink="/home/profile"
              />
            </IonCol>
          )}
        </IonRow>
      )}
      {consumerDashboardMenu.length > 0 && (
        <IonRow>
          {consumerDashboardMenu.map((menuItem, index) => (
            <IonCol size={'6'} key={`consumer-dashboard-${index}`}>
              {menuItem.location === '/apply' && (
                <>
                  {!isPos ? (
                    <StartLoanButton
                      component={CardImgButton}
                      src={applicationIcon}
                      title="Start Application"
                      className="ion-no-margin"
                      size={80}
                    />
                  ) : (
                    <CardImgButton
                      id="step-sso-consumer-my-loan"
                      src={applicationInProgressIcon}
                      title="My Loan"
                      className="ion-no-margin"
                      routerLink="/consumer/tasks"
                    ></CardImgButton>
                  )}
                </>
              )}
              {menuItem.location !== '/apply' && (
                <CardImgButton
                  src={getIcon(menuItem.icon)}
                  title={menuItem.title}
                  className="ion-no-margin"
                  {...(!menuItem.external ? { routerLink: menuItem.location } : { href: menuItem.location })}
                />
              )}
            </IonCol>
          ))}
        </IonRow>
      )}
      <IonRow>
        <IonCol size={'12'}>
          <ConsumerFooter />
        </IonCol>
      </IonRow>
    </StyledIonGrid>
  );
};

export default SsoConsumerDashboard;
