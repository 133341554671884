import React, { useState, useEffect } from 'react'
import { IonLoading } from '@ionic/react'
import { Redirect } from 'react-router-dom'

import { useAuth } from 'store/Auth'
import { useStreamChat } from '../../store/StreamChat'

const LogoutPage = () => {
  const { logout } = useAuth()
  const { disconnect } = useStreamChat()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    disconnect()
    logout()
    setLoading(false)
  }, [logout, setLoading])

  if (loading) {
    return <IonLoading isOpen={true} message="Logging out..." />
  }

  return <Redirect to="/auth/email" />
}

export default LogoutPage
