import * as React from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSwipeable } from 'react-swipeable'
import { SwipeEventData } from 'react-swipeable/src/types'
import SwipeDirection from './SwipeDirection'
import { useAuth } from '../../store/Auth'

type TabChangeEvent = {
  detail: {
    value: string | null
  }
} & MouseEvent

type TabsProps = {
  onTabChange?: (e: TabChangeEvent) => void
  value: null | string
}

type TabItemProps = {
  value: string
  onTabChange?: (e: TabChangeEvent) => void
}

type TabContainerProps = {
  activeChild: HTMLElement
  activeChildIndex: number | null
  isPartner: boolean
}

const TabContext = React.createContext({})

const TabContainer: React.FC<TabsProps> = props => {
  const { children, className, onTabChange, value } = props

  const [activeChild, setActiveChild] = useState<any>(null)
  const [activeChildIndex, setActiveChildIndex] = useState<number | null>(null)
  const [selected, setSelected] = useState<number>(0)

  const { isPartner } = useAuth()

  const container = useCallback(
    node => {
      const logDimensions = node => {
        const children = node.querySelectorAll('div[value]')
        if (children.length > 0) {
          children.forEach((child, index) => {
            if (value === child.getAttribute('value')) {
              setActiveChild(child)
              setActiveChildIndex(index)
            }
          })
        }
      }

      if (node) {
        const resizeObserver = new ResizeObserver(entries => {
          if (entries.length) {
            logDimensions(entries[0].target)
          }
        })

        resizeObserver.observe(node)

        return () => {
          resizeObserver.unobserve(node)
          resizeObserver.disconnect()
        }
      }
    },
    [selected]
  )

  const renderedChildren = children => {
    return children && children.filter(c => !!c)
  }

  const swipeHandlers = useSwipeable({
    onSwiped: e => {
      onSwipeLeft(e)
      onSwipeRight(e)
    },
    preventDefaultTouchmoveEvent: true,
  })

  const onSwipeLeft = (e: SwipeEventData) => {
    if (children && e.dir === 'Left' && selected !== children.length - 1) {
      onChange(e.event, (children as ReactElement[]).filter(c => !!c)[selected + 1].props.value)
    }
  }

  const onSwipeRight = (e: SwipeEventData) => {
    if (children && e.dir === 'Right' && selected !== 0) {
      onChange(e.event, (children as ReactElement[]).filter(c => !!c)[selected - 1].props.value)
    }
  }

  useEffect(() => {
    let mounted = true

    if (children && mounted) {
      const activeChild = (children as ReactElement[])
        .filter(c => !!c)
        .findIndex(c => c && c.props && c.props.value === value)

      setSelected(activeChild ? activeChild : 0)
    }

    return () => {
      mounted = false
    }
  }, [setSelected, value])

  const onChange = (e, value: string | null) => {
    if (onTabChange) {
      onTabChange(
        Object.assign({}, e, {
          detail: {
            value,
          },
        })
      )
    }
  }

  return (
    <TabPositionWrapper {...swipeHandlers} className={className}>
      {selected === 0 && !isPartner && <SwipeDirection direction="left" />}
      <TabContainerElement>
        <TabItemContainerElement
          ref={container}
          activeChild={activeChild}
          activeChildIndex={activeChildIndex}
          isPartner={isPartner}
        >
          <TabContext.Provider value={{ value, onTabChange: onChange }}>{children}</TabContext.Provider>
        </TabItemContainerElement>
      </TabContainerElement>
      {activeChildIndex === renderedChildren(children).length - 1 && !isPartner && <SwipeDirection direction="right" />}
      <TabSliderElement activeChild={activeChild} isPartner={isPartner} />
    </TabPositionWrapper>
  )
}

const TabItem: React.FC<TabItemProps> = props => {
  const { children, innerRef, value } = props
  const { value: currentValue, onTabChange } = useContext(TabContext)

  return (
    <TabItemElement
      onClick={e => (value !== currentValue ? onTabChange(e, value) : null)}
      value={value}
      currentValue={currentValue}
      ref={innerRef}
    >
      {children}
    </TabItemElement>
  )
}

const TabPositionWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
`

const TabContainerElement = styled.div`
  position: relative;
  margin-bottom: 1.25rem;
`

const TabItemElement = styled.div`
  font-family: var(--font-primary);
  font-size: 20px;
  font-weight: ${props => (props.value === props.currentValue ? '500' : 'normal')};
  color: ${props => (props.value === props.currentValue ? '#3A455F' : '#969696')};
  margin-right: 11px;
  word-wrap: normal;
  white-space: nowrap;
`

const TabItemContainerElement = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  transition: transform 0.25s ease;
  transform: ${(props: TabContainerProps) => {
    if (props.activeChild && !props.isPartner) {
      return `translateX(calc(50% - ${
        props.activeChildIndex === 0
          ? props.activeChild.clientWidth / 2
          : props.activeChild.offsetLeft + props.activeChild.clientWidth / 2
      }px
      ))`
    }
  }};
  justify-content: ${(props: TabContainerProps) => {
    if (!props.isPartner) {
      return `normal`
    }

    return 'center'
  }};
`

const TabSliderElement = styled.div`
  position: absolute;
  bottom: 1rem;
  left: ${(props: { isPartner }) => {
    if (!props.isPartner) {
      return '50%;'
    }

    return 'auto'
  }};
  display: block;
  margin: 1.25rem auto 0;
  background-color: var(--color-primary);
  border-radius: 5px;
  height: 5px;
  transition: width 0.25s ease, margin-left 0.25s ease;
  margin-left: ${(props: { activeChild: HTMLElement; isPartner: boolean }) => {
    if (props.activeChild && !props.isPartner) {
      return `-${props.activeChild.clientWidth / 2}px`
    }

    if (props.activeChild) {
      return `${props.activeChild.offsetLeft}px`
    }
  }};
  width: ${props => {
    if (props.activeChild) {
      return `${props.activeChild.clientWidth}px`
    }

    return '78px'
  }};
`

export { TabContainer, TabItem, TabContext }

export default TabContainer
