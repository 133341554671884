import React, { useState, useEffect } from 'react'
import { ChannelSort } from 'stream-chat'
import { Chat, ChannelList, CustomClasses } from 'stream-chat-react'
import 'stream-chat-react/dist/css/index.css'
import { User } from 'types/Pos'
import { useStreamChat } from 'store/StreamChat'

import ConversationListItem from './ConversationListItem'

interface Props {
  token: string
  user: User
}

const ConversationsScreen: React.FC<Props> = ({ user }) => {
  const { client, setUnreadCounts } = useStreamChat()
  const [filters, setFilters] = useState({ type: 'loan', members: { $in: [`${user.id}`] } })
  const sort: ChannelSort<any> = { last_message_at: -1 }
  const options = { limit: 10 }

  useEffect(() => {
    setFilters({ type: 'loan', members: { $in: [`${user.id}`] } })
  }, [setFilters])

  useEffect(() => {
    let mounted = true

    if (mounted) {
      setUnreadCounts(prevState => ({
        previous: prevState.current,
        current: 0,
      }))
    }

    return () => {
      mounted = false
    }
  }, [setUnreadCounts])

  const customClasses: CustomClasses = {
    channelList: 'loanzify-conversation-channel-list str-chat-channel-list',
  }

  return (
    <Chat client={client} customClasses={customClasses}>
      <ChannelList
        filters={filters}
        sort={sort}
        options={options}
        Preview={ConversationListItem}
        setActiveChannelOnMount={false}
      />
    </Chat>
  )
}

export default ConversationsScreen
