import React from 'react'
import styled from 'styled-components'

import { chevronForward } from 'ionicons/icons'
import { IonButton, IonIcon } from '@ionic/react'

const ForwardBtn = styled(IonButton)`
  --background: var(--color-gray-lightest);
  --padding-start: 0;
  --padding-end: 0;
  --box-shadow: none;

  color: #fff;
  margin: 0 0 0 0.5rem;
  width: 1.5rem;
  height: 1.5rem;

  ::part(native) {
    border-radius: 999px;
  }

  ion-icon {
    width: 1rem;
    height: 1rem;
  }
`

const ForwardButton = ({ ...props }) => {
  return (
    <ForwardBtn {...props}>
      <IonIcon slot="icon-only" color="#FBFDFF" icon={chevronForward} />
    </ForwardBtn>
  )
}

export default ForwardButton
