import React from 'react'
import styled from 'styled-components'
import { IonCard, IonCardContent } from '@ionic/react'

import Label from './Label'

const CardIconButton = ({ icon: Icon, iconSize = 60, title, ...cardProps }) => (
  <IonCard {...cardProps}>
    <StyledCardContent>
      <IconContainer size={iconSize}>
        <Icon />
      </IconContainer>

      <Label size="sm" color="gray" weight={500}>
        {title}
      </Label>
    </StyledCardContent>
  </IonCard>
)

const StyledCardContent = styled(IonCardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1rem;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-primary);
  background: var(--color-gray-lightest);

  width: ${props => props.size}px;
  height: ${props => props.size}px;

  border-radius: 100%;
  margin-bottom: 1rem;

  svg {
    width: ${props => props.size * 0.875}px;
    height: ${props => props.size * 0.875}px;
  }
`

export default CardIconButton
