import React from 'react'
import styled from 'styled-components'
import { TextNotification } from 'types/Loanzify'
import NotificationListItem from './NotificationListItem'

interface Props {
  notifications: TextNotification[]
}

const NotificationsList: React.FC<Props> = ({ notifications }) => (
  <Container>
    {notifications.map((notification, index) => (
      <NotificationListItem key={notification.id} isHighlighted={index % 2 !== 0} notification={notification} />
    ))}
  </Container>
)

const Container = styled.div`
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
`

export default NotificationsList
