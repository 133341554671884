import React, { useRef } from 'react';
import styled from 'styled-components';
import { IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';

import { useSideMenu } from './SideMenuLayout';
import Label, { LabelProps } from 'components/Label';

import { ReactComponent as MoreIcon } from 'icons/custom/Icon-Ellipsis-H.svg';

import { ReactComponent as UserIcon } from 'icons/Icon-User.svg';
import { ReactComponent as DocumentsIcon } from 'icons/Icon-Docs-1.svg';
import { ReactComponent as HomeIcon } from 'icons/Icon-Conventional.svg';
import { ReactComponent as MessagesIcon } from 'icons/Icon-Messages.svg';
import { ReactComponent as CalculatorIcon } from 'icons/Icon-Calculator.svg';
import { ReactComponent as NotificationsIcon } from 'icons/Icon-Notification.svg';

import { useAuth } from 'store/Auth';
import { usePushNotifications } from 'store/PushNotifications';
import { useStreamChat } from '../store/StreamChat';
import UnreadMessageBadge from '../components/UnreadMessageBadge';
import { useApplication } from '../store/Application';

const TabbedLayout: React.FC = props => {
  const container = useRef();
  const { element } = useSideMenu();
  const { pushNotifications } = usePushNotifications();

  const { documentsEnabled } = useApplication();
  const { isPartner, isPos, isUser } = useAuth();
  const { unreadCounts } = useStreamChat();
  const { children } = props;

  const toggleSideMenu = () => element.current?.toggle();

  return (
    <Container ref={container}>
      <IonTabs>
        <IonRouterOutlet id="tabbed-content">{children}</IonRouterOutlet>

        {isUser ? (
          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/home">
              <HomeIcon />
              <TabLabel>Home</TabLabel>
            </IonTabButton>

            <IonTabButton tab="consumers" href="/leads/tabs/consumers">
              <UserIcon />
              <TabLabel>{isPartner ? 'Clients' : 'Users'}</TabLabel>
            </IonTabButton>

            <IonTabButton tab="conversations" href="/conversations">
              <BadgeWrapper>
                <MessagesIcon />
                <TabLabel>Messages</TabLabel>
                <UnreadMessageBadge
                  count={
                    unreadCounts.current > unreadCounts.previous ? unreadCounts.current - unreadCounts.previous : 0
                  }
                />
              </BadgeWrapper>
            </IonTabButton>

            <IonTabButton tab="notifications" href="/notifications" layout="icon-top">
              <BadgeWrapper>
                <NotificationsIcon />
                <TabLabel>Notifications</TabLabel>
                <UnreadMessageBadge count={pushNotifications.length} />
              </BadgeWrapper>
            </IonTabButton>

            <IonTabButton href={undefined} tab="more">
              <MoreIcon onClick={toggleSideMenu} />
              <TabLabel onClick={toggleSideMenu}>More</TabLabel>
            </IonTabButton>
          </IonTabBar>
        ) : isPos ? (
          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/home">
              <HomeIcon />
              <TabLabel>Home</TabLabel>
            </IonTabButton>

            <IonTabButton tab="team" href="/consumer/team">
              <UserIcon />
              <TabLabel>Loan Team</TabLabel>
            </IonTabButton>

            <IonTabButton tab="chat" href="/chat">
              <BadgeWrapper>
                <MessagesIcon />
                <TabLabel>Messages</TabLabel>
                <UnreadMessageBadge
                  count={
                    unreadCounts.current > unreadCounts.previous ? unreadCounts.current - unreadCounts.previous : 0
                  }
                />
              </BadgeWrapper>
            </IonTabButton>

            <IonTabButton tab="notifications" href="/notifications">
              <NotificationsIcon />
              <TabLabel>Notifications</TabLabel>
            </IonTabButton>

            <IonTabButton href={undefined} tab="more">
              <MoreIcon onClick={toggleSideMenu} />
              <TabLabel onClick={toggleSideMenu}>More</TabLabel>
            </IonTabButton>
          </IonTabBar>
        ) : (
          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/home">
              <HomeIcon />
              <TabLabel>Home</TabLabel>
            </IonTabButton>

            <IonTabButton tab="calculators" href="/calculators">
              <CalculatorIcon />
              <TabLabel>Calculators</TabLabel>
            </IonTabButton>

            {!process.env.REACT_APP_DOCUMENTS_DISABLED && documentsEnabled && (
              <IonTabButton tab="documents" href="/documents">
                <DocumentsIcon />
                <TabLabel>Documents</TabLabel>
              </IonTabButton>
            )}

            <IonTabButton tab="notifications" href="/notifications">
              <NotificationsIcon />
              <TabLabel>Notifications</TabLabel>
            </IonTabButton>

            <IonTabButton href={undefined} tab="more">
              <MoreIcon onClick={toggleSideMenu} />
              <TabLabel onClick={toggleSideMenu}>More</TabLabel>
            </IonTabButton>
          </IonTabBar>
        )}
      </IonTabs>
    </Container>
  );
};

const Container = styled.div`
  ion-tab-bar {
    height: 70px;
    background: #fff;
    align-content: flex-end;
  }

  ion-tab-button {
    border-top: 6px solid var(--color-background);
  }

  ion-tab-button > svg {
    width: 40px;
    height: 40px;
    color: var(--color-gray-lighter);
  }

  ion-tab-button.tab-selected > svg,
  ion-tab-button.tab-selected > div > svg {
    color: var(--color-primary);
  }
`;

const BadgeWrapper = styled.div`
  position: relative;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  display: inline-flex;

  ion-badge {
    margin-left: 42px;
    margin-top: -30px;
    position: absolute;
    font-size: 10px;
    padding: 3px 4px;
  }

  svg {
    width: 40px;
    height: 40px;
    color: var(--color-gray-lighter);
  }
`;

const TabLabel: React.FC<LabelProps> = props => <Label {...props} weight={500} size="2xs" color="gray-light" />;

export default TabbedLayout;
