import React, { useState, useEffect } from 'react'
import { IonPage, IonContent, IonLoading } from '@ionic/react'

import List from 'components/List'
import Header from 'components/Header'
import MemberCard from './components/MemberCard'

import { getCurrentLoanTeam } from 'api/pos'

const LoanTeamPage = () => {
  const [state, setState] = useState({
    loading: true,
    members: [],
    error: '',
  })

  useEffect(() => {
    getCurrentLoanTeam()
      .then(team =>
        team.members.map(member => ({
          email: member.email,
          title: member.full_name,
          subtitle: member.role,
          src: member.src,
          phone: member.phone,
        }))
      )
      .then(members => setState(state => ({ ...state, members, loading: false })))
  }, [])

  return (
    <IonPage>
      <Header title="My Loan Team" />
      <IonContent>
        <IonLoading isOpen={state.loading} message="Loading..." />

        <List>
          {state.members.map((member, index) => (
            <MemberCard key={`item-${index}`} {...member} />
          ))}
        </List>
      </IonContent>
    </IonPage>
  )
}

export default LoanTeamPage
