const getInitials = (name: string) => {
  if (name) {
    const [first, last] = name.split(' ', 2)

    return ((first || '').charAt(0) + (last || '').charAt(0)).toUpperCase()
  }

  return ''
}

export default getInitials
