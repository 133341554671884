import React, { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { IonContent, IonPage } from '@ionic/react';
import List from 'components/List';
import Label from 'components/Label';
import Header from 'components/Header';
import ListItem from 'components/ListItem';
import { useAuth } from 'store/Auth';
import { useApplication } from 'store/Application';
import { Device, DeviceInfo } from '@capacitor/device';
import { App } from '@capacitor/app';

const AboutPage: React.FC = () => {
  const { consumerService } = useAuth();
  const { application } = useApplication();

  const [device, setDevice] = useState<DeviceInfo>();
  const [version, setVersion] = useState('');

  useEffect(() => {
    Device.getInfo().then(setDevice);

    const getAppInfo = async () => {
      if (Capacitor.isNativePlatform()) {
        const { version } = await App.getInfo();
        setVersion(version);
      }
    };

    getAppInfo().catch(console.error);
  }, []);

  return (
    <IonPage>
      <Header title="About" />

      <IonContent>
        <List>
          <ListItem title={`Application ID: ${application.id}`} />

          {consumerService && <ListItem title={`Consumer Service ID: ${consumerService.id}`} />}

          <ListItem title={`App Version`}>
            <Label isWrapped>{version !== '' ? version : 'No version number on web'}</Label>
          </ListItem>
          {device && (
            <ListItem title={`Device`}>
              <Label>
                {device.platform && (
                  <>
                    Platform: {device.platform} <br />
                  </>
                )}
                {device.osVersion && (
                  <>
                    OS Version: <Label size="sm">{device.osVersion}</Label> <br />
                  </>
                )}
                {device.model && (
                  <>
                    Model: <Label size="sm">{device.model}</Label> <br />
                  </>
                )}
              </Label>
            </ListItem>
          )}
        </List>
      </IonContent>
    </IonPage>
  );
};

export default AboutPage;
