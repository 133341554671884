import React, { useState, useMemo, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { IonPage, IonContent, IonSegment, IonSegmentButton } from '@ionic/react'

import LogoHeader from 'components/LogoHeader'
import Profile from 'components/Profile'

import Bio from './tabs/Bio'
import Contact from './tabs/Contact'
import Share from './tabs/Share'
import { useApplication } from 'store/Application'
import { useHistory } from 'react-router'

const TABS = {
  contact: {
    title: 'Contact',
    component: Contact,
  },

  bio: {
    title: 'Bio',
    component: Bio,
  },

  share: {
    title: 'Share',
    component: Share,
  },
}

const ProfilePage = () => {
  let {
    owner,
    ssoOwner,
    application: { user1, user2 },
  } = useApplication()
  const history = useHistory()
  const { url } = useRouteMatch()
  const [defaultHref, setDefaultHref] = useState('/home')

  switch (url) {
    case '/home/profile/partner':
      owner = user2
      break
    case '/home/profile/loan-officer':
      owner = user1
      break
    default:
      break
  }

  const [tab, setTab] = useState('contact')
  const TabComponent = useMemo(() => TABS[tab].component, [tab])

  const onTabChange = event => setTab(event.detail.value)

  useEffect(() => {
    if (history.location.search && history.location.search.indexOf('team')) {
      setDefaultHref('/consumer/team')
      setTab('bio')
    }
  }, [history])

  return (
    <IonPage>
      <LogoHeader defaultHref={defaultHref} />

      <IonContent>
        <Profile owner={owner} showContactLink={false} />

        <IonSegment mode="md" value={tab} className="ion-padding-horizontal" onIonChange={onTabChange}>
          {Object.keys(TABS).map(key => (
            <IonSegmentButton key={key} value={key}>
              {TABS[key].title}
            </IonSegmentButton>
          ))}
        </IonSegment>

        <TabComponent owner={owner} ssoOwner={ssoOwner} />
      </IonContent>
    </IonPage>
  )
}

export default ProfilePage
