import * as React from 'react';
import { IonCard, IonContent, IonIcon, IonPage, IonRippleEffect } from '@ionic/react';
import Header from '../../components/Header';
import PhoneIcon from 'icons/Icon-Phone-Handheld.svg';
import MessageIcon from 'icons/Icon-Message.svg';
import BillingIcon from 'icons/Icon-Billing.svg';
import styled from 'styled-components';
import Label from '../../components/Label';
import { useSession } from '../../store/Session';

const SupportPage: React.FC = () => {
  const { application_id } = useSession();

  const callSales = () => {
    window.open(`tel:(888) 377-1265`, '_system');
  };

  const sendBillingMail = () => {
    window.location.href = `mailto:billing@lenderhomepage.com?subject=Loanzify App Billing: SSO_ID: ${application_id}`;
  };

  const sendSupportMail = () => {
    window.location.href = `mailto:support@lenderhomepage.com?subject=Loanzify App Support: SSO_ID: ${application_id}`;
  };

  return (
    <IonPage>
      <Header title="Support" />

      <IonContent>
        <SupportCard className="ion-activatable" onClick={callSales}>
          <IconContainer>
            <IonIcon src={PhoneIcon} />
          </IconContainer>
          <InfoContainer>
            <Label weight={500} size="sm">
              {' '}
              Talk to Sales{' '}
            </Label>
            <p>
              Interested in LenderHomePage&apos;s services? Just pick up the phone to chat with a member of our sales
              team.
            </p>
          </InfoContainer>
          <IonRippleEffect type="unbounded" />
        </SupportCard>

        <SupportCard className="ion-activatable" onClick={sendSupportMail}>
          <IconContainer>
            <IonIcon src={MessageIcon} />
          </IconContainer>
          <InfoContainer>
            <Label weight={500} size="sm">
              {' '}
              Contact Support{' '}
            </Label>
            <p>
              Our support team has no automated bots, simply professionals who answer your questions and solve your
              issue.
            </p>
          </InfoContainer>
          <IonRippleEffect type="unbounded" />
        </SupportCard>

        <SupportCard className="ion-activatable" onClick={sendBillingMail}>
          <IconContainer>
            <IonIcon src={BillingIcon} />
          </IconContainer>
          <InfoContainer>
            <Label weight={500} size="sm">
              {' '}
              Contact Billing{' '}
            </Label>
            <p>
              Our billing team has no automated bots, simply professionals who answer your questions and solve your
              issue.
            </p>
          </InfoContainer>
          <IonRippleEffect type="unbounded" />
        </SupportCard>
      </IonContent>
    </IonPage>
  );
};

const SupportCard = styled(IonCard)`
  border-radius: 8px;
  background-color: #f5f6f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.25rem 1rem 0.75rem;
  box-shadow: 2.13px 2.13px 12.76px rgb(0 0 0 / 10%);
  margin: 7px 0;
  position: relative;
  overflow: hidden;

  &:first-of-type {
    margin-top: 1.5rem;
  }
`;

const IconContainer = styled.div`
  width: 76px;
  height: 76px;
  border-radius: 9999px;
  background-color: var(--color-gray-lightest);
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-right: 1.5rem;

  ion-icon {
    font-size: 3.35em;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  height: 100%;

  p {
    margin-top: 0.5rem;
  }
`;

export default SupportPage;
