import React from 'react'

import { useCalculator } from 'store/Calculator'

import OptionCard from 'components/calculators/OptionCard'
import AdvancedOption from 'components/calculators/AdvancedOption'

const AdvancedOptions = ({ optionsConfig }) => {
  const { options, defaultOptionsConfig, setOption } = useCalculator()

  return (
    <OptionCard>
      {Object.keys(optionsConfig).map(key => (
        <AdvancedOption
          key={key}
          value={options[key]}
          options={options}
          {...defaultOptionsConfig[key]}
          {...optionsConfig[key]}
          onChange={value => setOption(key, value)}
        />
      ))}
    </OptionCard>
  )
}

export default AdvancedOptions
