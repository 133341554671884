import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { IonSelect, IonSelectOption } from '@ionic/react';
import Label from '../../../components/Label';

const StyledComponentLabel = styled(Label)`
  white-space: pre-wrap;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  .error {
    padding: 5px;
    color: var(--color-red);
  }
`;

const StyledSelectWrapper = styled.div`
  ion-select {
    padding: 1rem 0.5rem;
    margin-top: 0.5rem;

    background: #fff;

    color: var(--color-gray);
    border: ${props => (props.hasError ? '1px solid var(--color-red)' : 'none')};
    border-radius: 0.5rem;

    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: normal;
  }
`;

const FormSelect = forwardRef(({ label, placeholder, error, options, ...props }, ref) => (
  <StyledLabel>
    <StyledComponentLabel secondary color="gray" size="sm">
      {label}
    </StyledComponentLabel>

    <StyledSelectWrapper>
      <IonSelect ref={ref} {...props} mode="ios" placeholder={placeholder} interfaceOptions={{ header: label }}>
        {options.map(({ value, title }) => (
          <IonSelectOption key={value} value={value}>
            {title}
          </IonSelectOption>
        ))}
      </IonSelect>
    </StyledSelectWrapper>
  </StyledLabel>
));

FormSelect.displayName = 'FormSelect';

export default FormSelect;
