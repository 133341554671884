import React from 'react'
import { IonAlert } from '@ionic/react'

const AlertInput = ({ isOpen, title, inputs, okButtonText = 'OK', cancelButtonText = 'Cancel', onCancel, onOk }) => {
  const focusInput = event => {
    setTimeout(() => event.target.getElementsByTagName('input')[0].focus())
  }

  return (
    <IonAlert
      mode="ios"
      isOpen={isOpen}
      header={title}
      onDidPresent={focusInput}
      inputs={inputs}
      buttons={[
        {
          text: cancelButtonText,
          role: 'cancel',
          handler: onCancel,
        },
        {
          text: okButtonText,
          handler: onOk,
        },
      ]}
    />
  )
}

export default AlertInput
