import React, { useMemo } from 'react'
import styled from 'styled-components'
import { IonLoading, IonRefresher, IonRefresherContent, IonSpinner } from '@ionic/react'

import useDocuments from 'pages/documents/hooks/useDocuments'
import PosDocuments from 'pages/documents/repositories/PosDocuments'
import PosDocumentList from 'pages/documents/POS/components/PosDocumentList'

import { useAuth } from 'store/Auth'
import { Loan } from 'types/Pos'
import { RequestsGroupedByStatus } from 'types/Documents'
import NoDocumentsAnimation from 'components/animations/NoDocumentsAnimation'
import NoDataLoaded from '../../../../components/NoDataLoaded'

interface HookResult {
  loading: boolean
  uploading: boolean
  documents: RequestsGroupedByStatus
  refresh: () => Promise<void>
  upload: (files: any, name: string) => Promise<void>
}

interface Props {
  loan: Loan
}

const Documents: React.FC<Props> = ({ loan }) => {
  const { user } = useAuth()

  const repository = useMemo(() => {
    return PosDocuments({ loan, user })
  }, [loan.id])

  const { loading, uploading, documents, refresh } = useDocuments(repository) as HookResult

  const isEmpty =
    !loading &&
    Object.keys(documents).every(key => {
      return documents[key as keyof RequestsGroupedByStatus].length === 0
    })

  const doRefresh = (event: any) => refresh().then(event.detail.complete)

  return (
    <Wrapper>
      <IonLoading isOpen={uploading} message="Uploading..." />

      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>

      {loading ? (
        <StyledLoader />
      ) : isEmpty ? (
        <NoDataLoaded icon={false} message="No documents have been loaded yet...">
          <NoDocumentsAnimation options={{ loop: true }} />
        </NoDataLoaded>
      ) : (
        <PosDocumentList documents={documents} onUploaded={refresh} onReviewed={refresh} isConsumer={false} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0rem;
`
const StyledLoader = styled(IonSpinner)`
  width: 100%;
  text-align: center;
`

export default Documents
