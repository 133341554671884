import useModal from 'hooks/useModal'
import React from 'react'
import AcceptOrRejectDocumentModal from './AcceptOrRejectDocumentModal'

interface Props {
  document: Request
  render: (open: () => void) => React.ReactElement
  onReviewed?: () => void
}

const AcceptOrRejectDocument: React.FC<Props> = ({ render, document, onReviewed }) => {
  const [present, dismiss] = useModal(AcceptOrRejectDocumentModal, {
    document,
    onReviewed: () => {
      dismiss()
      onReviewed && onReviewed()
    },
  })

  const handle = () => {
    present()
  }

  return render(handle)
}

export default AcceptOrRejectDocument
