import React from 'react'
import styled from 'styled-components'
import { IonSelect, IonSelectOption } from '@ionic/react'
import Label from 'components/Label'

const sizes = {
  sm: {
    fontSize: '.75rem',
  },
  normal: {
    fontSize: '14px;',
  },
  lg: {
    fontSize: '1.25rem',
  },
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const StyledLabel = styled(Label)`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`

const StyledIonSelect = styled(IonSelect)`
  padding: 1rem 0.5rem;

  margin-bottom: 0.75rem;
  margin-top: 0.5rem;

  color: var(--color-gray);

  border: 1px solid var(--color-gray-lightest);
  border-radius: 0.5rem;

  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  line-height: normal;

  ::part(text),
  ::part(placeholder),
  ::part(icon) {
    font-size: ${props => sizes[props.size || 'normal'].fontSize};
    color: var(--color-gray);
    font-style: italic;
    opacity: 0.75;
  }

  ::part(text) {
    margin-left: 0.75rem;
    color: var(--color-gray);
  }
`

const FormSelect = ({
  name = null,
  label,
  value,
  placeholder,
  required,
  size,
  options = [],
  multiple = false,
  onIonChange = () => {},
  onNativeChange = e => {},
}) => (
  <InputWrapper>
    <StyledLabel weight="bold" secondary color="gray" size="xs">
      {label}
    </StyledLabel>
    <StyledIonSelect
      name={name}
      mode="ios"
      interfaceOptions={{ header: label }}
      value={value}
      placeholder={placeholder}
      size={size}
      required={required}
      multiple={multiple}
      onIonChange={e => [onIonChange(e.detail.value), onNativeChange(e)]}
    >
      {options.map(({ value, title }) => (
        <IonSelectOption key={value} value={value}>
          {title}
        </IonSelectOption>
      ))}
    </StyledIonSelect>
  </InputWrapper>
)

export default FormSelect
