import React from 'react';
import Label from './Label';
import styled from 'styled-components';
import { CreditScore } from '../types/Credit';

type IProps = {
  score: CreditScore;
  imageSrc: string;
};

const ReportColumn = styled.div`
  border-right: 1px solid var(--color-gray-lightest);
  flex-grow: 1;

  &:last-child {
    border-right: none;
  }
`;

const UpdatedLabel = styled(Label).attrs({
  block: true,
  secondary: true,
  center: true,
  size: '3xs',
  color: 'gray-light',
})`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

const AgencyImage = styled.img`
  margin: 0 auto;
  display: flex;
`;

const CreditBureauScore = (props: IProps) => {
  const { score, imageSrc } = props;

  return (
    <ReportColumn>
      <Label block secondary color="gray" size="2xl" center>
        {score.score}
      </Label>
      <UpdatedLabel>Updated {score.last_updated}</UpdatedLabel>
      <AgencyImage src={imageSrc} />
    </ReportColumn>
  );
};

export default CreditBureauScore;
