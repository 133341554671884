import { IonSearchbar } from '@ionic/react'
import styled from 'styled-components'

const Searchbar = styled(IonSearchbar)`
  --background: white;
  --border-radius: ${props => (props.borderRadius ? props.borderRadius : '2px')};
  --box-shadow: none;
`

export default Searchbar
