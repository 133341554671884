import React from 'react';
import styled from 'styled-components';
import Card from '../Card';

const StyledIonCard = styled(Card)`
  padding: 20px 15px;
  padding: 0;
  margin-top: 25px;
  margin-bottom: 2.5rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid var(--color-gray-lightest);
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
`;

const Title = styled.div`
  color: var(--color-primary);
  font-size: 1rem;
`;

const List = styled.div`
  > div {
    &:nth-child(even) {
      background: var(--color-background);
    }

    &:last-child {
      margin-bottom: 6px;
    }
  }
`;
const ListItem = styled.div`
  display: flex;
  color: var(--color-gray-light);
  font-size: 13px;
  min-height: 48px;
  justify-content: space-between;
  margin-left: 6px;
  margin-right: 6px;

  > div {
    padding: 1rem;
    align-self: center;
  }

  &.final-item {
    .final-item-title {
      color: var(--color-gray);
    }

    .final-item-text {
      color: var(--color-green);
      font-size: 31px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

const ListTitle = styled.div`
  align-self: center;
`;

const ListText = styled.div`
  align-self: center;
`;
interface ResultCardProps {
  title?: string;
  data: { title?: string; text?: string | 0 }[];
  finalData: {
    title?: string;
    text?: string | 0;
  };
}
const ResultCard = ({ title = 'Calculation Details', data, finalData }: ResultCardProps) => {
  return (
    <StyledIonCard>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
      <List>
        {data.map(({ title, text }, index) => (
          <ListItem key={`rc-${index}`}>
            <ListTitle>{title}</ListTitle>
            <ListText>{text}</ListText>
          </ListItem>
        ))}
        {finalData.title && (
          <ListItem className="final-item">
            <ListTitle className="final-item-title">{finalData.title}</ListTitle>
            <ListText className="final-item-text">{finalData.text}</ListText>
          </ListItem>
        )}
      </List>
    </StyledIonCard>
  );
};

export default ResultCard;
