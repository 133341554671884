import React from 'react';
import styled from 'styled-components';
import { IonButton } from '@ionic/react';
import { Override } from 'types/Utilities';

type Size = '2xs' | 'xs' | 'sm' | 'md' | 'normal' | 'lg' | 'auto';

type SizeMap = {
  [key in Size]: {
    height: string;
    fontSize: string;
    paddingX: string;
  };
};

const SIZES: SizeMap = {
  auto: {
    height: 'auto',
    fontSize: 'inherit',
    paddingX: '1px',
  },
  '2xs': {
    height: '20px',
    fontSize: '11px',
    paddingX: '0.75rem',
  },
  xs: {
    height: '23px',
    fontSize: '11px',
    paddingX: '0.8rem',
  },
  sm: {
    height: '29px',
    fontSize: '0.8rem',
    paddingX: '1.25rem',
  },
  md: {
    height: '33px',
    fontSize: '1rem',
    paddingX: '2.5rem',
  },
  normal: {
    height: '47px',
    fontSize: '1.25rem',
    paddingX: '4rem',
  },
  lg: {
    height: '57px',
    fontSize: '1.5rem',
    paddingX: '8rem',
  },
};

export type ButtonProps = Override<
  Parameters<typeof IonButton>[0],
  {
    size?: Size;
    center?: boolean;
    radius?: string;
    margin?: string;
  }
>;

const Button = styled<React.FC<ButtonProps>>(({ size, center, ...props }) => <IonButton {...props} />)`
  ${({ size = 'normal', center = false, ...props }) => `
    --border-radius: ${props.radius ? props.radius : '30px'};
    --padding-start: ${props.expand === 'block' ? 0 : SIZES[size].paddingX};
    --padding-end: ${props.expand === 'block' ? 0 : SIZES[size].paddingX};

    height: ${SIZES[size].height};
    font-size: ${SIZES[size].fontSize};

    margin: ${props.margin ? props.margin : 'inherit'};

    font-style: normal;
    font-weight: normal;
    text-transform: none;
    line-height: 29px;

    ${
      center &&
      `
      display: table;
      margin: 0 auto;
    `
    }

    svg {
      margin-right: 0.5rem;
    }
  `}
`;

export default Button;
