import React from 'react'
import styled from 'styled-components'
import { IonGrid, IonRow, IonCol, useIonActionSheet } from '@ionic/react'
import { callOutline, close, phonePortraitOutline } from 'ionicons/icons'

import SocialLinks from '../components/SocialLinks'
import CardIconButton from 'components/CardIconButton'

import { ReactComponent as MailIcon } from 'legacy-icons/mail.svg'
import { ReactComponent as PhoneIcon } from 'legacy-icons/phone.svg'
import { ReactComponent as WebsiteIcon } from 'legacy-icons/website.svg'
import { ReactComponent as MessageIcon } from 'legacy-icons/messages.svg'

const StyledIonGrid = styled(IonGrid)`
  --ion-grid-column-padding: 0.5rem;
  padding: 1rem 0.5rem;
`

const Contact = ({ owner, ssoOwner }) => {
  const [present, dismiss] = useIonActionSheet()

  const startCall = () => {
    if (!owner.phone && !owner.mobile) {
      return
    }

    if (owner.phone && owner.mobile) {
      return present({
        buttons: [
          {
            text: 'Mobile Phone',
            icon: phonePortraitOutline,
            handler: () => (window.location.href = `tel:${owner.mobile}`),
          },
          {
            text: 'Work Phone',
            icon: callOutline,
            handler: () => (window.location.href = `tel:${owner.phone}`),
          },
          {
            text: 'Cancel',
            icon: close,
            role: 'cancel',
            handler: dismiss,
          },
        ],
      })
    }

    window.location.href = `tel:${owner.mobile || owner.phone}`
  }

  return (
    <StyledIonGrid>
      <IonRow>
        {(owner.phone || owner.mobile) && (
          <IonCol size={6}>
            <CardIconButton
              iconSize={75}
              onClick={startCall}
              icon={PhoneIcon}
              title={owner.phone || owner.mobile ? 'Call' : 'Unknown Phone #'}
              className="ion-no-margin"
            />
          </IonCol>
        )}

        {owner.mobile && (
          <IonCol size={6}>
            <CardIconButton
              iconSize={75}
              icon={MessageIcon}
              title="Text"
              className="ion-no-margin"
              href={`sms:${owner.mobile}`}
            />
          </IonCol>
        )}

        <IonCol size={6}>
          <CardIconButton
            iconSize={75}
            icon={MailIcon}
            title="Email"
            className="ion-no-margin"
            href={`mailto:${owner.email}`}
          />
        </IonCol>

        {owner.website_url && (
          <IonCol size={6}>
            <CardIconButton
              iconSize={75}
              icon={WebsiteIcon}
              title="Website"
              className="ion-no-margin"
              href={owner.website_url}
              target="_blank"
            />
          </IonCol>
        )}
      </IonRow>

      {['facebook_url', 'instagram_url', 'twitter_url', 'youtube_url', 'pinterest_url', 'linkedin_url'].some(
        link => ssoOwner[link]
      ) && (
        <IonRow>
          <IonCol size={12}>
            <SocialLinks
              facebook_url={ssoOwner.facebook_url}
              instagram_url={ssoOwner.instagram_url}
              twitter_url={ssoOwner.twitter_url}
              youtube_url={ssoOwner.youtube_url}
              pinterest_url={ssoOwner.pinterest_url}
              linkedin_url={ssoOwner.linkedin_url}
            />
          </IonCol>
        </IonRow>
      )}
    </StyledIonGrid>
  )
}

export default Contact
