import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Card from 'components/Card'
import Label from 'components/Label'
import AvatarCircle from './AvatarCircle'

import { ReactComponent as MailIcon } from 'legacy-icons/loan-team/mail.svg'
import { ReactComponent as TextIcon } from 'legacy-icons/loan-team/text.svg'
import { ReactComponent as ProfileIcon } from 'legacy-icons/loan-team/profile.svg'
import { ReactComponent as PhoneIcon } from 'legacy-icons/loan-team/phone.svg'
import { ReactComponent as CloseIcon } from 'legacy-icons/loan-team/close.svg'
import { TeamMember } from 'types/Pos'
import { useApplication } from '../../../store/Application'
import { useHistory } from 'react-router-dom'

interface Props {
  member: TeamMember
  onClose: () => void
}

const ExpandedMemberCard: React.FC<Props> = ({ member, onClose }) => {
  const history = useHistory()
  const { application } = useApplication()
  const [canViewProfile, setCanViewProfile] = useState(false)

  const viewMemberProfile = member => {
    if (member.role === 'Loan Officer') {
      history.push(`/home/profile/loan-officer?back=team`)
    } else if (member.role === 'Partner' || member.role === 'Realtor') {
      history.push(`/home/profile/partner?back=team`)
    }
  }

  useEffect(() => {
    setCanViewProfile(
      ((member.role === 'Partner' || member.role === 'Realtor') && application.user2_id !== null) ||
        member.role === 'Loan Officer'
    )
  }, [member])

  return (
    <Card paddingless={true}>
      <CloseIconContainer onClick={onClose}>
        <CloseIcon />
      </CloseIconContainer>

      <HeaderContainer>
        <AvatarCircle name={member.full_name} src={member.src} />
      </HeaderContainer>
      <TitleContainer>
        <Label size="xl" color="primary" weight={600} block>
          {member.full_name}
        </Label>
        <Label color="gray-light" block>
          {member.role}
        </Label>
      </TitleContainer>
      <ToolbarContainer showProfile={canViewProfile}>
        <a href={`mailto:${member.email}`}>
          <ActionWrapper>
            <MailIcon />
            Email
          </ActionWrapper>
        </a>
        <a href={`sms:${member.phone}`}>
          <ActionWrapper>
            <TextIcon />
            Text
          </ActionWrapper>
        </a>
        <a href={`tel:${member.phone}`}>
          <ActionWrapper>
            <PhoneIcon />
            Call
          </ActionWrapper>
        </a>
        {canViewProfile && (
          <button onClick={() => viewMemberProfile(member)}>
            <ActionWrapper>
              <ProfileIcon />
              Profile
            </ActionWrapper>
          </button>
        )}
      </ToolbarContainer>
      <Breaker />
      <DisclaimerContainer>
        <Label size={'sm'} color={'gray'} weight={700} fontStyle={'italic'}>
          {member.company}
        </Label>
        {member.nmls && (
          <Label size={'sm'} color={'gray-light'} fontStyle={'italic'}>
            NMLS#{member.nmls}
          </Label>
        )}
        <br />
        {member.address && member.city && member.state && member.zip && (
          <Label size={'sm'} color={'gray-light'} fontStyle={'italic'}>
            {member.address}, {member.city}, {member.state} {member.zip}
          </Label>
        )}
        <br />
        <Label size={'sm'} color={'gray-light'} fontStyle={'italic'}>
          Phone <DisclaimerPhone href={`tel:${member.phone}`}>{member.phone}</DisclaimerPhone>
        </Label>
        <br />
        {member.disclaimers && (
          <Label size={'sm'} color={'gray-light'} fontStyle={'italic'}>
            {parsesNewLines(member.disclaimers)}
          </Label>
        )}
      </DisclaimerContainer>
    </Card>
  )
}

const parsesNewLines = text => {
  return text.split('\n').map((str, i) => <p key={`${str}-${i}`}>{str}</p>)
}

const DisclaimerPhone = styled.a`
  text-decoration: none;
  color: var(--color-gray-light);
`

const DisclaimerContainer = styled.div`
  margin-top: 29px;
  text-align: center;
  display: flex;
  flex-direction: column;
`

const Breaker = styled.hr`
  margin-top: 1.5rem;
  height: 2px;
  background-color: #d8dade;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;

  svg {
    margin-top: 0.5rem;
  }
`

const TitleContainer = styled.div`
  text-align: center;
  margin-top: 1rem;
`

const ToolbarContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  margin-top: 3rem;
  margin-bottom: 0.5rem;

  border-left-width: 1px;
  border-color: var(--color-gray-lighter);

  > a:not(:last-child) {
    border-right: 1px solid var(--color-gray-lighter);
  }

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1rem;
    padding-left: 1rem;
    text-decoration: none;
    background: transparent;
    color: var(--color-primary);
  }
`
const CloseIconContainer = styled.div`
  cursor: pointer;
  text-align: right;
  margin-bottom: 1.5rem;
`

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

export default ExpandedMemberCard
