import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonItem,
  IonList,
  IonPage,
  IonProgressBar,
  useIonToast,
} from '@ionic/react';
import styled from 'styled-components';

import Button from '../../components/Button';
import Header from '../../components/Header';
import Label from '../../components/Label';

import useModal from 'hooks/useModal';

import { useAuth } from 'store/Auth';
import { deleteConsumer } from 'api/sso';

const DeleteAccountProgressModal: React.FC = () => {
  return <IonProgressBar color="secondary" type="indeterminate" />;
};

const DeleteAccountPage: React.FC = () => {
  const { isPos, logout } = useAuth();

  const [present, dismiss] = useModal(DeleteAccountProgressModal, {
    autoHeight: true,
  });
  const [showToast] = useIonToast();
  const [loading, setLoading] = useState<boolean>(false);

  const deleteAccount = async () => {
    try {
      setLoading(true);
      await deleteConsumer();
      setLoading(false);
      logout();
    } catch (err) {
      showToast({
        header: 'Unable to delete your account at this time.',
        message: 'Please try again later.',
        color: 'danger',
        duration: 8000,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      present();
    } else {
      dismiss();
    }
  }, [loading]);

  return (
    <IonPage>
      <IonContent>
        <Header title={'Delete Your Account'} />
        <IonCard color={'warning'}>
          <IonCardContent>Read through the following carefully before proceeding!</IonCardContent>
        </IonCard>
        <div className="ion-padding">
          <Label> We are sorry to see you go!</Label>
          <p>
            The process of deleting your account will result in all of your data becoming inaccessible permanently. This
            action cannot be undone. Please verify you do not need the services for any of the following:
          </p>
          <IonList>
            {isPos && (
              <IonItem routerLink={'/chat'} button mode={'ios'}>
                {' '}
                Chatting with your Loan Team{' '}
              </IonItem>
            )}
            <IonItem routerLink={'/application/form'} button mode={'ios'}>
              {' '}
              Your Loan Application{' '}
            </IonItem>
            <IonItem routerLink={'/calculators'} button mode={'ios'}>
              {' '}
              Mortgage Calculations
            </IonItem>
          </IonList>
          <p>
            If you have verified you do not need this service any longer, please acknowledge below and your account will
            be permanently deleted.
          </p>
        </div>
        <IonCard color={'light'}>
          <IonCardContent>
            This process may take some time. Please wait until the page has finished loading before exiting out of the
            application.
          </IonCardContent>
        </IonCard>
        <ActionContainer className="ion-padding">
          <Button color={'success'} size={'md'} routerLink={'/'}>
            {' '}
            I&apos;ll keep it!
          </Button>
          <Button color={'danger'} size={'md'} onClick={deleteAccount}>
            {' '}
            Delete it!
          </Button>
        </ActionContainer>
      </IonContent>
    </IonPage>
  );
};

const ActionContainer = styled.div`
  padding-left: unset;
  padding-right: unset;
  padding-inline-start: var(--ion-padding, 16px);
  padding-inline-end: var(--ion-padding, 16px);

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default DeleteAccountPage;
