import { Application, InitialApplicationState, SsoUser } from 'types/Application'
import { unsetPlaceholderUrls } from './unsetPlaceholderUrls'

interface SsoOwnerData {
  user: SsoUser
  parent_user_id?: number
  parent_data: {
    has_pos: boolean
  }
}

const parseApplicationData = (application: Application, ssoOwnerData: SsoOwnerData): InitialApplicationState => {
  const ssoOwner = ssoOwnerData.user
  const cssVariables = application.css_variables

  /**
   * And here we set "photo_url" and "logo_url" to
   * null if it points to the placeholder image.
   */
  unsetPlaceholderUrls(application)

  return {
    application,
    ssoOwner,
    ssoParent: {
      id: ssoOwnerData.parent_user_id || null,
      hasPos: ssoOwnerData?.parent_data?.has_pos || false,
    },
    cssVariables,
  }
}

export default parseApplicationData
