import React, { useMemo } from 'react'
import { HorizontalBar } from 'react-chartjs-2'

const ResultChart = ({ result, colors }) => {
  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: false,
            ticks: {
              beginAtZero: true,
              max: result.base_amortisation.length,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    }),
    [result]
  )

  const data = useMemo(
    () => ({
      labels: ['Original', 'New'],
      datasets: [
        {
          label: 'Payoff Results',
          backgroundColor: colors,
          data: [result.base_amortisation.length, result.new_amortisation.length],
        },
      ],
    }),
    [colors, result]
  )

  return <HorizontalBar options={options} data={data} />
}

export default ResultChart
