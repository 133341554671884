import React, { useState } from 'react'
import styled from 'styled-components'

import Label from 'components/Label'

import { ReactComponent as ChevronDownIcon } from 'legacy-icons/documents/chevron-down.svg'
import { ReactComponent as ChevronRightIcon } from 'legacy-icons/documents/chevron-right.svg'
import StatusIcon from '../POS/components/StatusIcon'

const ItemContainer = styled.div`
  border-radius: 7px;
  padding: 1rem;

  &:nth-child(even) {
    background: var(--color-gray-lightest);
  }
`
const ItemContent = styled.div`
  display: flex;
  align-items: center;
`

const ItemActionColumn = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  min-width: 80px;
  text-align: center;
`

const StyledLabel = styled(Label)`
  flex: 1;
`

const DocumentItem = ({ document, title, action, isConsumer, children }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <ItemContainer>
      <ItemContent onClick={() => setExpanded(!expanded)}>
        <StyledLabel color="gray-light" block isWrapped>
          {title}
        </StyledLabel>

        <ItemActionColumn>{isConsumer ? action : <StatusIcon status={document.status} />}</ItemActionColumn>

        {children && (expanded ? <ChevronDownIcon /> : <ChevronRightIcon />)}
      </ItemContent>

      {expanded && children}
    </ItemContainer>
  )
}

export default DocumentItem
