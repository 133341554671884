import { useEffect } from 'react'

import { client as loanzifyClient } from 'api/loanzify'
import { client as ssoClient } from 'api/sso'
import { client as posClient } from 'api/pos'
import { client as amClient } from 'api/am'

const useClientListeners = (session, auth, setAuth) => {
  useEffect(() => {
    if (!session.token) {
      return
    }

    amClient.defaults.headers.common['Authorization'] = `Bearer ${session.token}`
    posClient.defaults.headers.common['Authorization'] = `Bearer ${session.token}`
    ssoClient.defaults.headers.common['Authorization'] = `Bearer ${session.token}`
    loanzifyClient.defaults.headers.common['Authorization'] = `Bearer ${session.token}`
  }, [session.token])
}

export default useClientListeners
