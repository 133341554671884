import React from 'react';
import styled from 'styled-components';

import ProfileInfo from 'components/ProfileInfo';
import Avatar from 'components/Avatar';

import { useApplication } from 'store/Application';
import Button from './Button';
import { User } from 'types/Application';

const ProfileContainer = styled.div`
  position: relative;
  box-sizing: content-box;
  margin: 0 -1rem;
  padding: 0 1rem;
`;

interface ProfileRedCurveProps {
  isDefault: boolean;
}

const ProfileRedCurve = styled.div<ProfileRedCurveProps>`
  position: absolute;
  z-index: 9;
  height: 100%;
  max-height: 180px;
  width: 100%;
  ${props =>
    props.isDefault
      ? `
    background: linear-gradient(332.19deg,
      #D03555 31.64%,
      #D03555 32.38%,
      #E21B52 63.68%
    );
  `
      : `
    background: var(--color-primary);
  `}
  border-bottom-left-radius: 50% 1rem;
  border-bottom-right-radius: 50% 1rem;
`;

const TopCurve = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  height: 1rem;
  background: white;
  border-bottom-left-radius: 50% 1rem;
  border-bottom-right-radius: 50% 1rem;
  box-sizing: content-box;
  margin: 0 -1rem;
  padding: 0 1rem;
`;

const VerticalProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  padding: 2rem 1.5rem 0;
  margin-bottom: 1rem;
`;

const VerticalAvatarContainer = styled.div`
  position: relative;
  z-index: 99;
`;

const VerticalProfileInfo = styled.div`
  position: relative;
  z-index: 9;
  margin-top: -1rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  background: #fff;
  width: 100%;
  border-radius: 7px;
`;

interface ProfileProps {
  owner: User;
  showContactLink?: boolean;
}
const Profile = ({ owner, showContactLink = true }: ProfileProps) => {
  const { isDefault, license, nmls } = useApplication();
  return (
    <ProfileContainer>
      <TopCurve />
      <ProfileRedCurve isDefault={isDefault}></ProfileRedCurve>
      <VerticalProfileContent>
        <VerticalAvatarContainer>
          <Avatar url={owner.photo_url} size="lg" />
        </VerticalAvatarContainer>
        <VerticalProfileInfo>
          <ProfileInfo color={'#616364'} owner={owner} license={license} nmls={nmls} />
          {showContactLink && (
            <Button
              routerLink={'/home/profile/loan-officer'}
              size="md"
              style={{ margin: '1rem 28% 0' }}
              color="secondary"
            >
              Contact Me
            </Button>
          )}
        </VerticalProfileInfo>
      </VerticalProfileContent>
    </ProfileContainer>
  );
};

export default Profile;
