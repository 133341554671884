import React, { createContext, useState, useMemo, useCallback, useContext, useEffect } from 'react';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

import { getDefaultOptionsConfig, calculateLimits } from 'config/calculators';
import { useApplication } from 'store/Application';
import { retrieveDownPayment } from '../utils/calculator';
import { useCalculatorDefaults } from './CalculatorDefaultsProvider';
import {
  AffordabilityConfig,
  CalculatorDefaultOptions,
  CalculatorDefaultOptionsConfig,
  CalculatorDefaultsByMortgageType,
  CalculatorOptions,
  ConventionalConfig,
  FhaConfig,
  JumboConfig,
  OtherConfig,
  RuralConfig,
  VaConfig,
  CalculatorResult,
} from 'types/Calculator';

type CalculatorContextType = {
  limits: any;
  options: CalculatorOptions | CalculatorDefaultOptions;
  defaultOptions: CalculatorDefaultOptions;
  defaultOptionsConfig: CalculatorDefaultOptionsConfig;
  calculatorDefaultsByType:
    | Record<
        string,
        FhaConfig | VaConfig | JumboConfig | ConventionalConfig | AffordabilityConfig | RuralConfig | OtherConfig
      >
    | CalculatorDefaultsByMortgageType;
  result: CalculatorResult;
  setResult: (result: CalculatorResult) => void;
  setOption: (key: string, value: number | boolean) => void;
  setOptions: (options: CalculatorDefaultOptions) => void;
  resetOptions: () => void;
  currentTypeDefaults: any;
};

const CalculatorContext = createContext<CalculatorContextType>(null!);

export const CalculatorProvider: React.FC = ({ children }) => {
  const { calculatorDefaults } = useCalculatorDefaults();
  const { user1 } = useApplication();

  const [options, setOptions] = useState<CalculatorDefaultOptions>({ ...window.lhpCalculator.defaultOptions });

  const [result, setResult] = useState<CalculatorResult>();
  const [overwriteMaxLimits, setOverwriteMaxLimits] = useState<any>({});

  const calculatorDefaultsByType = Object.entries(calculatorDefaults).reduce((a, [, { config_json, calculator }]) => {
    a[calculator] = config_json;
    return a;
  }, {} as Record<string, FhaConfig | VaConfig | JumboConfig | ConventionalConfig | AffordabilityConfig | RuralConfig | OtherConfig>);

  const currentTypeDefaults = useMemo(
    () => calculatorDefaultsByType[options.type],
    [calculatorDefaultsByType, options.type]
  );

  // Calculate limits based upon options and result
  const limits = useMemo(() => {
    if (options.loan_amount <= 0) {
      options.loan_amount = 1; // required to prevent the slider from exploding
    }

    if (options.property_price <= 0) {
      options.property_price = 1; // required to prevent the slider from exploding
    }

    const newLimits = calculateLimits(
      window.lhpCalculator.limits(
        {
          ...options,
          ...currentTypeDefaults,
        },
        window.lhpCalculator.payoff(options)
      )
    );

    newLimits.loan_amount.min = 1; // required to prevent the slider from exploding

    // TODO is this needed .max_property_price does not exist on defaults??

    // if (currentTypeDefaults.max_property_price) {
    //   newLimits.property_price.max = parseFloat(currentTypeDefaults.max_property_price);
    // } else

    if (options.type === 'jumbo') {
      newLimits.property_price.max = 5000000;
    }

    Object.keys(newLimits).forEach(key => {
      const customKey = `${options.type}_${key}`;
      if (overwriteMaxLimits[customKey]) {
        newLimits[key].max = overwriteMaxLimits[customKey];
      }
    });

    return newLimits;
  }, [options, result, currentTypeDefaults]);

  // State and county
  const states = useMemo(() => window.lhpCalculator.states, []);
  const counties = useMemo(() => window.lhpCalculator.counties(options.state), [options.state]);

  // Options config
  const defaultOptionsConfig = useMemo(() => getDefaultOptionsConfig({ states, counties }), [states, counties]);

  const setOption = (key: string, value: number | boolean) => {
    if (key === 'term') {
      console.log('stopping crash for "term" option changes (Loan Length) select');
      return;
    }
    if (limits[key] && value > limits[key].max) {
      setOverwriteMaxLimits((overwriteMaxLimits: any) => ({
        ...overwriteMaxLimits,
        [`${options.type}_${key}`]: value,
      }));
    }

    if (key === 'property_price') {
      setOption('down_payment', (options.property_price * options.down_payment_rate) / 100);
    }

    if (key === 'down_payment_rate') {
      if (typeof value === 'number') {
        setOption('down_payment', (options.property_price * value) / 100);
      }
    }

    if ((key === 'is_refi' && value === false) || (!options.is_refi && key === 'down_payment')) {
      setOption('loan_amount', options.property_price - retrieveDownPayment(options));
    }

    // Hazard insurance
    if (key === 'annual_insurance_rate') {
      const hazardAnnual = (options.property_price * Number(value)) / 100;
      setOption('annual_insurance', hazardAnnual);
    }

    setOptions((options: any) => ({ ...options, [key]: value }));
  };

  // Reset
  const resetOptions = useCallback(() => {
    let state = window.lhpCalculator.defaultOptions.state;
    let county = window.lhpCalculator.defaultOptions.county;
    const counties = window.stateAndCounties.states[user1.state];

    if (counties) {
      state = user1.state;
      const firstCounty = Object.keys(counties.data)[0];
      const userCounty = (user1.county || user1.city || '').toUpperCase();
      county = counties.data[(user1.county || user1.city || '').toUpperCase()] !== undefined ? userCounty : firstCounty;
    }

    // Hazard Insurance, default rate is 0.55% and $1925 on a $350,000 loan (default values when entering calc)
    // annual_insurance should be 1925 but is 800 on window.lhpCalculator.defaultOptions
    // Change it!

    const defaultCalculatorOptions = { ...window.lhpCalculator.defaultOptions, annual_insurance: 1925 };

    setOptions({
      ...defaultCalculatorOptions,
      state,
      county,
    });
  }, []);

  useEffect(() => {
    setOptions((options: any) => ({
      ...options,
      down_payment: (options.property_price * options.down_payment_rate) / 100,
    }));
  }, [options.down_payment_rate]);

  return (
    <CalculatorContext.Provider
      value={{
        options,
        defaultOptions: window.lhpCalculator.defaultOptions,
        limits,
        defaultOptionsConfig,
        calculatorDefaultsByType,
        result,
        setOption,
        setOptions,
        setResult,
        resetOptions,
        currentTypeDefaults,
      }}
    >
      {children}
    </CalculatorContext.Provider>
  );
};

export const useCalculator = () => useContext(CalculatorContext);
