import * as React from 'react'
import { IonIcon, IonInput } from '@ionic/react'
import styled from 'styled-components/macro'

type InputWithActionProps = {
  action: Action
  disabled?: boolean
  border?: {
    width?: number
    style?: string
    color?: string
  }
  value: string | null
} & HasEvents

type HasEvents = {
  onClick: Function
}

type Action = {
  click: Function
  color?: string
  icon: string
  size?: number
  visible: boolean
}

const InputWithAction: React.FC<InputWithActionProps> = props => {
  const { action, className, disabled, value } = props
  const { click, color, icon, size, visible } = action

  const getSize = () => {
    return size ? size : 22
  }

  const isVisible = () => {
    return action && visible ? visible : true
  }

  return (
    <StyledInputWithActionWrapper className={className}>
      <IonInput value={value} {...props} />
      {!disabled && (
        <StyledIonIcon
          action={action}
          visible={isVisible()}
          onClick={click}
          src={icon}
          size={getSize()}
          color={color}
        />
      )}
    </StyledInputWithActionWrapper>
  )
}

const StyledInputWithActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d8dade;
  height: 32px;
  border-radius: 5px;
  color: #000;
  opacity: 1;
  padding: 0 0.25rem;
`

const StyledIonIcon = styled(IonIcon)`
  cursor: pointer;
  display: ${({ action: { visible } }) => {
    return visible ? 'block' : 'none'
  }};

  font-size: ${props => props.size}px;
`

export default InputWithAction
