import React from 'react'
import { Redirect } from 'react-router'
import { useAuth } from 'store/Auth'
import MilestoneList from 'pages/loan/components/MilestoneList'
import styled from 'styled-components/macro'

interface Props {
  //
}

const PosMilestonesPage: React.FC<Props> = () => {
  const { loan } = useAuth()

  if (!loan) {
    return <Redirect to="/logout" />
  }

  return <WrappedMilestoneList loan={loan} />
}

const WrappedMilestoneList = styled(MilestoneList)`
  padding: 0 18px;
`

export default PosMilestonesPage
