import React from 'react'
import styled from 'styled-components'
import { IonGrid, IonCol, IonRow } from '@ionic/react'

import CardImgButton from 'components/CardImgButton'
import StartLoanButton from 'components/StartLoanButton'

import messagesIcon from 'legacy-icons/dashboard/messages.png'
import calculatorIcon from 'legacy-icons/dashboard/calculator.png'
import applicationIcon from 'icons/Icon-ApplyNow.svg'

import { useApplication } from 'store/Application'

const StyledIonGrid = styled(IonGrid)`
  --ion-grid-column-padding: 0.5rem;
  padding: 0 1rem;
`

const EmptyConsumerDashboard = () => {
  const { owner } = useApplication()

  return (
    <StyledIonGrid>
      <IonRow>
        <IonCol size={6}>
          <CardImgButton src={calculatorIcon} title="Calculators" className="ion-no-margin" routerLink="/calculators" />
        </IonCol>

        <IonCol size={6}>
          <CardImgButton src={messagesIcon} title="Messages" className="ion-no-margin" href={`sms:${owner.mobile}`} />
        </IonCol>

        <IonCol size={6}>
          <StartLoanButton
            component={CardImgButton}
            src={applicationIcon}
            title="Start Application"
            className="ion-no-margin"
            height={75}
            width={95}
          />
        </IonCol>
      </IonRow>
    </StyledIonGrid>
  )
}

export default EmptyConsumerDashboard
