import get from 'lodash/get';
import last from 'lodash/last';
import startCase from 'lodash/startCase';

import { formatCurrency, formatPercent } from 'utils/formatters';

export const getDefaultOptionsConfig = ({ states, counties }) => ({
  annual_insurance_rate: {
    formatter: formatPercent,
  },

  auto_payment: {
    formatter: number => formatCurrency(number, 0),
  },

  county: {
    type: 'select',
    selectOptions: counties.map(county => ({
      value: county,
      title: startCase(county.toLowerCase()),
    })),
  },

  credit_payment: {
    formatter: number => formatCurrency(number, 0),
  },

  down_payment: {
    formatter: number => formatCurrency(number, 0),
  },

  down_payment_rate: {
    formatter: number => formatPercent(number, 1),
  },

  fico: {
    type: 'select',
    selectOptions: [
      { value: 850, title: '760-850 (Excellent)' },
      { value: 759, title: '720-759 (Very Good)' },
      { value: 719, title: '680-719 (Good)' },
      { value: 679, title: '620-679 (Fair)' },
      { value: 620, title: '<620 (Poor)' },
    ],
  },

  installment_payment: {
    formatter: number => formatCurrency(number, 0),
  },

  interest_rate: {
    formatter: number => formatPercent(number, 3),
  },

  loan_amount: {
    formatter: number => formatCurrency(number, 0),
  },

  monthly_hoa: {
    formatter: number => formatCurrency(number, 0),
  },

  monthly_income: {
    formatter: number => formatCurrency(number, 0),
  },

  other_payment: {
    formatter: number => formatCurrency(number, 0),
  },

  payment_considered: {
    formatter: number => formatCurrency(number, 0),
  },

  payments_made: {},

  property_price: {
    formatter: number => formatCurrency(number, 0),
  },

  property_tax: {
    formatter: formatPercent,
  },

  state: {
    type: 'select',
    selectOptions: states.map(({ value, name }) => ({
      value,
      title: value,
    })),
  },

  student_payment: {
    formatter: number => formatCurrency(number, 0),
  },

  term: {
    type: 'select',
    selectOptions: [
      { value: 30, title: '30 Years' },
      { value: 25, title: '25 Years' },
      { value: 20, title: '20 Years' },
      { value: 15, title: '15 Years' },
      { value: 10, title: '10 Years' },
      { value: 5, title: '5 Years' },
    ],
  },
});

/**
 * Update minimum of payment_considered based on base_amortisation
 *
 * @param {any} limits
 * @param {any} result
 *
 * @returns New limits
 */
export const calculateLimits = (limits, result = {}) => {
  const paymentConsideredMin = get(last(result.base_amortisation), 'monthlyPayment');

  if (!paymentConsideredMin) {
    return limits;
  }

  return {
    ...limits,
    payment_considered: {
      ...limits.payment_considered,
      min: paymentConsideredMin,
    },
  };
};

export const CALCULATORS_CONFIG = {
  purchase: {
    title: 'Purchase Calculator',
    canDownload: true,
    mainOptionsConfig: {
      property_price: {
        title: 'Property Price',
      },
      down_payment_rate: {
        title: 'Down Pmt.',
        computedField: {
          title: 'Down Pmt.',
          get: (srcValue, options) => (srcValue * options.property_price) / 100,
          set: (dstValue, options) => (dstValue / options.property_price) * 100,
          formatter: number => formatCurrency(number, 0),
        },
      },
      interest_rate: {
        title: 'Interest Rate',
      },
    },

    advancedOptionsConfig: {
      fico: {
        title: 'FICO Score',
      },
      term: {
        title: 'Term',
      },
      monthly_hoa: {
        title: 'Monthly HOA',
      },
      annual_insurance_rate: {
        title: 'Hazard Insurance',
        computedField: {
          get: (srcValue, options) => (srcValue * options.property_price) / 100,
          set: (dstValue, options) => (dstValue / options.property_price) * 100,
          formatter: number => formatCurrency(number, 0),
        },
      },
      property_tax: {
        title: 'Property Tax',
        computedField: {
          title: 'Property Tax',
          get: (srcValue, options) => (srcValue * options.property_price) / 100,
          set: (dstValue, options) => (dstValue / options.property_price) * 100,
          formatter: number => formatCurrency(number, 0),
        },
      },
      state: {
        title: 'State',
      },
      county: {
        title: 'County',
      },
    },
    resultTitles: {
      principal_and_interest: 'Principal & Interest',
      insurance: 'Mortgage Insurance',
      taxes_and_hoa: 'Taxes & HOA',
      monthly_insurance: 'Hazard Insurance',
    },
  },

  refi: {
    title: 'Refinance Calculator',
    canDownload: true,
    mainOptionsConfig: {
      property_price: {
        title: 'Property Value',
      },
      loan_amount: {
        title: 'Loan Amount',
      },
      interest_rate: {
        title: 'Interest Rate',
      },
    },

    advancedOptionsConfig: {
      fico: {
        title: 'FICO Score',
      },
      term: {
        title: 'Term',
      },
      monthly_hoa: {
        title: 'Monthly HOA',
      },
      annual_insurance_rate: {
        title: 'Hazard Insurance',
      },
      property_tax: {
        title: 'Property Tax',
        computedField: {
          title: 'Property Tax',
          get: (srcValue, options) => (srcValue * options.property_price) / 100,
          set: (dstValue, options) => (dstValue / options.property_price) * 100,
          formatter: number => formatCurrency(number, 0),
        },
      },
      state: {
        title: 'State',
      },
      county: {
        title: 'County',
      },
    },
    resultTitles: {
      principal_and_interest: 'Principal & Interest',
      insurance: 'Mortage Insurance',
      taxes_and_hoa: 'Taxes & HOA',
      monthly_insurance: 'Hazard Insurance',
    },
  },

  extraPayment: {
    title: 'Extra Payment Calculator',
    canDownload: false,
    mainOptionsConfig: {
      loan_amount: {
        title: 'Loan Amount',
      },
      interest_rate: {
        title: 'Interest Rate',
      },
      payment_considered: {
        title: 'Pmt. Considered',
      },
      term: {
        title: 'Loan Length',
      },
    },
  },

  principal: {
    title: 'Principal Calculator',
    canDownload: false,
    mainOptionsConfig: {
      loan_amount: {
        title: 'Loan Amount',
      },
      interest_rate: {
        title: 'Interest Rate',
      },
      payments_made: {
        title: 'Payments Made (Months)',
      },
      term: {
        title: 'Loan Length',
      },
    },
    resultTitles: {
      balance: 'Principal Balance',
      paid: 'Principal Paid',
    },
  },

  affordability: {
    title: 'Affordability Calculator',
    canDownload: false,
    mainOptionsConfig: {
      monthly_income: {
        title: 'Monthly Income',
      },
      monthly_expenses_label: {
        title: 'Monthly Expenses',
        label: true,
      },
      auto_payment: {
        title: 'Auto Loans',
      },
      student_payment: {
        title: 'Student Loans',
      },
      credit_payment: {
        title: 'Credit Card Loans',
      },
      other_payment: {
        title: 'Other Debts',
      },
      loan_info_label: {
        title: 'Loan Info',
        label: true,
      },
      down_payment: {
        title: 'Down Pmt.',
      },
      interest_rate: {
        title: 'Interest Rate',
      },
      term: {
        title: 'Loan Length',
      },
    },
    resultTitles: {
      principal_interest: 'Principal & Interest',
      taxes: 'Monthly Taxes',
      hazard_insurance: 'Monthly Hazard Insurance',
      mortgage_insurance: 'Monthly Mortgage Insurance',
    },
  },
};
