import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { IonLoading } from '@ionic/react'
import { Dialog } from '@capacitor/dialog'

import Label from 'components/Label'
import Layout from './components/Layout'
import Button from './components/Button'
import ButtonWrapper from './components/ButtonWrapper'

import useApplicationSetter from 'hooks/useApplicationSetter'
import { searchApplications, sendClaimLink } from 'api/loanzify'

import { useOnboarding } from 'store/Onboarding'
import { useApplication } from 'store/Application'

const SubtitleText = styled(Label)`
  font-size: 15px;
  line-height: 1.2;
  margin: 1rem 0 2rem 0;
`

const ClaimAppPage = () => {
  const { application } = useApplication()
  const { email } = useOnboarding()
  const setApplication = useApplicationSetter()
  const history = useHistory()

  const [sent, setSent] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    if (!email) {
      history.push('/auth')

      return
    }

    searchApplications(email).then(applications => {
      if (applications.length !== 1) {
        return Dialog.alert({
          message: 'We could not find your Application. Please contact support.',
        }).then(() => history.push('/auth'))
      }

      setApplication(applications[0].id)
      setLoading(false)
    })
  }, [])

  const onSubmit = async channels => {
    setProcessing(true)
    await sendClaimLink(application.id, channels)
    setProcessing(false)
    setSent(true)
  }

  if (loading) {
    return <IonLoading isOpen={loading} message="Loading your branded application..." />
  }

  return (
    <Layout title="Claim Your App">
      <IonLoading isOpen={processing} message="Sending a confirmation link..." />

      {sent ? (
        <>
          <SubtitleText secondary block center isWrapped fontStyle="italic" color="gray">
            We successfully sent you the confirmation link. <br /> <br />
            Tap on it to claim your app.
          </SubtitleText>

          <ButtonWrapper>
            <Button onClick={() => setSent(false)}>Re-Send</Button>
          </ButtonWrapper>
        </>
      ) : (
        <>
          <SubtitleText secondary block center isWrapped fontStyle="italic" color="gray">
            Your app is almost ready for download and viral sharing. <br /> <br />
            Send a confirmation link to your mobile phone or email to claim your app.
          </SubtitleText>

          <ButtonWrapper>
            <Button onClick={() => onSubmit(['sms'])}>Send to Phone</Button>
          </ButtonWrapper>

          <ButtonWrapper>
            <Button onClick={() => onSubmit(['mail'])}>Send to E-Mail</Button>
          </ButtonWrapper>
        </>
      )}
    </Layout>
  )
}

export default ClaimAppPage
