import Bugsnag from '@bugsnag/js'

const retryCatch = (callback: any, sleep: number) => (error: any) =>
  new Promise(resolve => {
    Bugsnag.notify(error)

    setTimeout(() => resolve(callback()), sleep)
  })

export default retryCatch
