import { useEffect, useState } from 'react'

const useApiLoader = (callback, initialState) => {
  const [data, setData] = useState(initialState)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const resolveCallback = async () => {
      setData(await callback())
      setLoading(false)
    }

    resolveCallback()
  }, [])

  return [data, loading]
}

export default useApiLoader
