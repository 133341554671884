import React, { PropsWithChildren, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Consumer } from 'types/Sso'
import useAlert from 'hooks/useAlert'

import { getUserInfoByConsumerService } from 'api/pos'
import ResourceCard from '../../../../components/ResourceCard'

interface Props {
  consumer: Consumer
  flat?: boolean
  tall?: boolean
  intro?: boolean
}

const ConsumerListItem: React.FC<PropsWithChildren<Props>> = ({ consumer, flat, tall, ...props }) => {
  const history = useHistory()
  const { error } = useAlert()

  const [isOpening, setIsOpening] = useState(false)

  const posConsumerId = consumer.pos_consumer_id

  const handleError = (message: string) => {
    error(message)
    setIsOpening(false)
  }

  const open = async () => {
    if (isOpening) {
      return
    }

    setIsOpening(true)

    if (posConsumerId) {
      const info = await getUserInfoByConsumerService(posConsumerId)

      if (!info) {
        return handleError('Could not retrieve loan. Please contact support.')
      }

      return history.push(`/leads/loans/${info.latest_loan_id}/overview`)
    }

    if (!consumer.install) {
      return handleError('The user has not installed the app yet.')
    }

    return history.push(`/installations/${consumer.install.id}/calculations`)
  }

  return (
    <ResourceCard
      {...props}
      consumer={consumer}
      name={consumer.name}
      date={consumer.created_at}
      loading={isOpening}
      open={open}
      list={true}
      flat={flat}
      status={consumer.loan_info && consumer.loan_info.loan_status}
      tall={tall}
      confirmed={consumer.install}
    />
  )
}

export default ConsumerListItem
