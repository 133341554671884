import styled from 'styled-components'
import { IonContent } from '@ionic/react'

const Content = styled(IonContent)`
  ${props =>
    props.horizontalPadding &&
    `
    --padding-start: 1.5rem;
    --padding-end: 1.5rem;
  `}

  ${props =>
    props.verticalPadding &&
    `
    --padding-top: 1.5rem;
    --padding-bottom: 1.5rem;
  `}
`

Content.defaultProps = {
  horizontalPadding: true,
  verticalPadding: true,
}

export default Content
