import React from 'react'
import { IonLoading } from '@ionic/react'

import Label from 'components/Label'
import MembersList from 'pages/loan/components/MembersList'

import useLoanTeam from 'queries/useLoanTeam'
import Button from 'components/Button'
import { ReactComponent as IconMailPlane } from 'icons/Icon-MailPlane.svg'
import { TeamMember } from '../../types/Pos'

interface Props {
  //
}

const PosLoanTeamPage: React.FC<Props> = () => {
  const query = useLoanTeam()

  if (query.isLoading) {
    return <IonLoading isOpen={true} message="Loading..." />
  }

  if (query.isError) {
    return <Label center>There was an error loading your loan team. Please try again later.</Label>
  }

  if (!query.isSuccess) {
    return null
  }

  const loanTeamMembers = () => {
    return query.data.members
      .filter((member: TeamMember) => member.role !== 'Partner' && member.role !== 'Realtor')
      .sort((a: TeamMember, b: TeamMember) => {
        if (a.role === 'Loan Officer') {
          return -1
        }

        return 0
      })
  }

  return (
    <>
      <Button center routerLink="/chat" style={{ marginTop: '2rem' }} color="secondary">
        <IconMailPlane /> Send Group Message
      </Button>

      <MembersList members={loanTeamMembers()} title={'Loan Team'} />
      <MembersList
        members={query.data.members.filter(
          (member: TeamMember) => member.role === 'Partner' || member.role === 'Realtor'
        )}
        title={'Partners'}
      />
    </>
  )
}

export default PosLoanTeamPage
