import React from 'react'
import styled from 'styled-components'
import { add, format } from 'date-fns'

import { formatCurrency } from 'utils/formatters'
import { IonCard } from '@ionic/react'

const TODAY = new Date()

const TableWrapper = styled(IonCard)`
  background: #fff;
  overflow-x: auto;
`

const StyledTable = styled.table`
  margin: 1rem 0.25rem;
  font-size: 0.85rem;
  min-width: 100%;

  th {
    padding: 0 0.25rem;
    min-width: 5rem;
    font-size: 0.75rem;
  }

  td {
    text-align: center;
    padding: 0.1rem 0.15rem;
    font-family: var(--font-secondary);
  }

  tr {
    &:nth-child(even) {
      background: var(--color-background);
    }
  }
`

const ResultAmortisationTable = ({ amortisation }) => {
  return (
    <TableWrapper>
      <StyledTable>
        <thead>
          <tr>
            <th>Month</th>
            <th>Principal Paid</th>
            <th>Interest Paid</th>
            <th>Tax, Insurance, PMI</th>
            <th>Total Payment</th>
            <th>Balance</th>
          </tr>
        </thead>

        <tbody>
          {amortisation.map((item, index) => (
            <tr key={index}>
              <td>{format(add(TODAY, { months: index + 1 }), 'MM/yy')}</td>
              <td>{formatCurrency(item.totalPrincipal)}</td>
              <td>{formatCurrency(item.totalInterest)}</td>
              <td>{formatCurrency(item.taxesAndFees)}</td>
              <td>{formatCurrency(item.monthlyPayment)}</td>
              <td>{formatCurrency(item.balance)}</td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </TableWrapper>
  )
}

export default ResultAmortisationTable
