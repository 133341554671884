import React from 'react'
import styled from 'styled-components'
import { IonRouterLink } from '@ionic/react'

import Label from 'components/Label'

import { ReactComponent as EqualHousingLender } from 'legacy-icons/equal-housing-lender.svg'
import { ReactComponent as EqualHousingOpportunity } from 'legacy-icons/equal-housing-opportunity.svg'

const ConsumerFooter = () => (
  <FooterWrapper>
    <IconWrapper href="http://www.nmlsconsumeraccess.org/" target="_blank">
      <EqualHousingLender width={25} height={23} />
    </IconWrapper>

    <FooterLink href="https://nmlsconsumeraccess.org/" target="_blank">
      <Label size="2xs" color="gray-light">
        NMLS Consumer Access
      </Label>
    </FooterLink>

    <Divider />

    <IonRouterLink routerLink="/legal/privacy">
      <Label size="2xs" color="gray-light">
        Privacy Policy
      </Label>
    </IonRouterLink>

    <IconWrapper href="http://www.nmlsconsumeraccess.org/" target="_blank">
      <EqualHousingOpportunity width={25} height={25} />
    </IconWrapper>
  </FooterWrapper>
)

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 0.5rem;
  margin-top: 1.75rem;
  font-size: 0.75rem;
`

const IconWrapper = styled.a`
  text-decoration: none;

  svg {
    fill: var(--color-gray-light);
  }
`

const Divider = styled.div`
  height: 15px;
  width: 1px;
  background-color: var(--color-gray-light);
`

const FooterLink = styled.a`
  text-decoration: none;
`
export default ConsumerFooter
