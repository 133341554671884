import React, { FC, useState } from 'react'
import { IonContent, IonLoading, IonPage } from '@ionic/react'
import styled from 'styled-components'

import { uploadProfilePicture } from 'api/sso'

import Avatar from 'components/Avatar'
import Button from 'components/Button'
import Header from 'components/Header'

import usePhotoGetter from 'hooks/usePhotoGetter'
import { useApplication } from 'store/Application'
import { Dialog } from '@capacitor/dialog'

const SettingsPhotoPage: FC = () => {
  const getPhoto = usePhotoGetter()
  const [loading, setLoading] = useState(false)
  const { ssoOwner, photoUrl, owner, setOwner } = useApplication()

  const changePhoto = async () => {
    const photo = await getPhoto()

    setLoading(true)

    uploadProfilePicture(ssoOwner.id, photo)
      .then(photo_url => setOwner({ ...owner, photo_url }))
      .catch(error => {
        Dialog.alert({
          title: 'Error',
          message: 'It looks like something went wrong. Please try again later or contact support.',
        })

        throw error
      })
      .finally(() => setLoading(false))
  }

  return (
    <IonPage>
      <Header title="Upload Photo" defaultHref="/home/settings" />

      <IonContent>
        <IonLoading isOpen={loading} message="Uploading..." />

        <AvatarContainer>
          <StyledAvatar size="lg" />
        </AvatarContainer>

        <Button size="md" center onClick={changePhoto}>
          {photoUrl ? 'Replace Image' : 'Upload Image'}
        </Button>
      </IonContent>
    </IonPage>
  )
}

const AvatarContainer = styled.div`
  padding-top: 2rem;
`

const StyledAvatar = styled(Avatar)`
  margin: 0 auto 1rem auto;
`

export default SettingsPhotoPage
