import React, { useState } from 'react'
import { IonLoading } from '@ionic/react'
import { Browser } from '@capacitor/browser'

import { register } from 'api/pos'
import { getConsumerService, getCurrentUser } from 'api/sso'
import { getCurrentUser as getCurrentPosUser } from 'api/pos'

import { useAuth } from 'store/Auth'
import { useOnboarding } from 'store/Onboarding'
import { useApplication } from 'store/Application'
import useAlert from 'hooks/useAlert'
import { useSession } from '../store/Session'
import { useStreamChat } from '../store/StreamChat'

const StartLoanButton = ({ component: Component, linkAttribute = 'routerLink', ...props }) => {
  const alert = useAlert()
  const { show_selfie_view } = useSession()
  const { setRedirect } = useOnboarding()
  const { owner, ssoOwner, hasPos, user1, user2 } = useApplication()
  const { setUser, setConsumerService, isPartner, isPos, parentSsoUserId, setPosUser } = useAuth()
  const { connect: connectUserToChat, createToken: createTokenForStreamChat } = useStreamChat()

  const [loading, setLoading] = useState(false)

  const startPosApplication = async () => {
    setLoading(true)
    setRedirect('/application/form')

    if (!show_selfie_view) {
      try {
        /**
         * There may be instances where we have a user who has the 'loanzify' service detected
         * so the fetch to the POS user never happens. Adding the shim below on the error response
         * from POS will allow us to fetch the user if they already exist.
         *
         * Otherwise, we will reject the call and default back to the error screen.
         * @type {unknown}
         */
        const posUser = await new Promise(async (resolve, reject) => {
          try {
            resolve(await register(ssoOwner.id, user2 ? user1.id : parentSsoUserId))
          } catch (err) {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.errors &&
              err.response.data.errors.email &&
              err.response.data.errors.email.length > 0 &&
              err.response.data.errors.email[0] === 'Borrower already exists.'
            ) {
              console.log('This is the problem child')
              resolve(getCurrentPosUser())
            }

            reject()
          }
        })

        // 3. Sync posUser
        setPosUser(posUser)

        // 3. Load & sync SSO User
        const user = await getCurrentUser()
        setUser(user)

        // 4. Load new [consumerService]
        const consumerService = await getConsumerService(posUser.sso_consumer_service_id)
        await setConsumerService(consumerService)

        // 5. invoke users stream chat instance
        await createTokenForStreamChat()
        await connectUserToChat(posUser)
      } catch (error) {
        alert.error('There was an error starting your loan. Please contact support!')
        setLoading(false)

        throw error
      }
    }
  }

  // User has a custom "application_url" link.
  if (owner.application_url && !isPartner && !show_selfie_view) {
    return <Component {...props} onClick={() => Browser.open({ url: owner.application_url })} />
  }

  // Consumer is logged-in to a POS service.
  if (isPos || show_selfie_view || isPartner) {
    return <Component {...props} {...{ [linkAttribute]: '/application/form' }} />
  }

  // User does not have a POS subscription.
  if (!hasPos) {
    return <Component {...props} routerLink="/application/short-form" />
  }

  return (
    <>
      <IonLoading isOpen={loading} message="Starting Your Loan..." />
      <Component {...props} onClick={startPosApplication} />
    </>
  )
}

export default StartLoanButton
