import { Step } from 'intro.js-react'
import { has } from 'lodash'

const conditionalStep = (condition: boolean, step: Step, callback: Function) => {
  if (condition && (!step.element || (step.element && document.querySelectorAll(step.element).length > 0))) {
    return callback(step)
  }
}

export const availableSteps = {
  get: (route: string, conditions: Record<string, boolean>) => {
    if (has(availableSteps, route)) {
      return availableSteps[route](conditions)
    }
  },
  '/home': conditions => {
    const { isConsumer, isDefault, isPartner, isUser, show_selfie_view } = conditions

    const steps: Step[] = []

    conditionalStep(
      isUser || isPartner,
      {
        element: '.home--share_app_button',
        intro: 'By clicking here a link will be copied that you can share.',
        position: 'right',
      },
      step => steps.push(step)
    )

    conditionalStep(
      isUser && !isPartner,
      {
        element: '.home--switch_view_button',
        intro: 'Want to see what your borrower sees? Click here. You can always go back later.',
      },
      step => steps.push(step)
    )

    conditionalStep(
      isUser && !isPartner,
      {
        element: '.home--recent_activity_widget',
        intro: 'Here you can view your activity at a glance!',
      },
      step => steps.push(step)
    )

    conditionalStep(
      isUser && !isPartner,
      {
        element: '.home--recent_users_widget',
        intro:
          'A quick view of your borrower activity at a glance. Those who appear with a "!" have not yet installed your app. Share it with them!',
      },
      step => steps.push(step)
    )

    conditionalStep(
      isUser && !isPartner,
      {
        element: '.consumer-list-item-0',
        intro:
          'An indicator here having an orange badge with an ! means that the borrower has NOT installed your app yet. Share it with them!',
      },
      step => steps.push(step)
    )

    conditionalStep(
      isConsumer || isPartner || show_selfie_view,
      {
        element: '#step-sso-consumer-home-calculator',
        intro: `Access multiple loan calculators`,
      },
      step => steps.push(step)
    )

    conditionalStep(
      isConsumer || isPartner || show_selfie_view,
      {
        element: '#step-sso-consumer-mortgage-library',
        intro: `View documents, reviews, papers and more full of helpful mortgage information`,
      },
      step => steps.push(step)
    )

    conditionalStep(
      isConsumer || isPartner || show_selfie_view,
      {
        element: '#step-sso-consumer-start-application',
        intro: `Start your no-hassle loan application today. A step-by-step guide will walk you through the process, making it as simple as possible!`,
      },
      step => steps.push(step)
    )

    conditionalStep(
      !process.env.REACT_APP_DOCUMENTS_DISABLED && (isConsumer || isPartner),
      {
        element: '#step-sso-consumer-upload-docs',
        intro: `When required, you may upload documents here for your loan application`,
      },
      step => steps.push(step)
    )

    conditionalStep(
      !isDefault && (isConsumer || isPartner),
      {
        element: '#step-sso-consumer-contact-me',
        intro: `Contact your ${isPartner ? 'Loan Team' : 'Loan Officer'} today!`,
      },
      step => steps.push(step)
    )

    conditionalStep(
      isUser || isPartner,
      {
        element: '#tab-button-home',
        intro: 'Quickly return to the home page of your application.',
      },
      step => steps.push(step)
    )

    conditionalStep(
      isUser || isPartner,
      {
        element: '#tab-button-consumers',
        intro: `View your ${isPartner ? 'Borrower' : 'Borrower & Partner'} activity at a glance.`,
      },
      step => steps.push(step)
    )

    conditionalStep(
      isUser || isPartner,
      {
        element: '#tab-button-conversations',
        intro: 'Keep up with your borrowers. You can direct message them here!',
      },
      step => steps.push(step)
    )

    conditionalStep(
      isUser || isPartner,
      {
        element: '#tab-button-notifications',
        intro: 'View important notifications',
      },
      step => steps.push(step)
    )

    return steps
  },
  '/side-menu': ({ isUser, isPartner }) => {
    const steps = []

    conditionalStep(
      isUser && !isPartner,
      {
        element: 'ion-router-link[href="/home/switch-view"] .list-item',
        intro: `Switch between what your borrower sees and what you see!`,
      },
      step => steps.push(step)
    )

    conditionalStep(
      isUser,
      {
        element: 'ion-router-link[href="/home/about"] .list-item',
        intro: `Get information about this application`,
      },
      step => steps.push(step)
    )

    conditionalStep(
      isUser && !isPartner,
      {
        element: 'ion-router-link[href="/home/support"] .list-item',
        intro: `Get technical help, speak with our dedicated sales team, or discussion billing inquiries`,
      },
      step => steps.push(step)
    )

    conditionalStep(
      isUser && !isPartner,
      {
        element: 'ion-router-link[href="/home/settings"] .list-item',
        intro: `Update your branded application settings`,
      },
      step => steps.push(step)
    )

    return steps
  },
}
