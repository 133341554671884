import React from 'react'
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react'
import Header from 'components/Header'
import { useApplication } from '../../store/Application'

const PrivacyPage = () => {
  const { application } = useApplication()

  return (
    <IonPage>
      <Header title="Privacy Policy" defaultHref="/home" />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: application.privacy_text.replace(/(<? *script)/gi, 'illegalscript'),
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default PrivacyPage
