import React from 'react';
import styled from 'styled-components';
import { IonCard, IonCardContent, IonRouterLink, IonSpinner } from '@ionic/react';

import Label from './Label';

interface Props {
  title: string;
  routerLink?: string;
  isLoading?: boolean;
}

const CollectionCard: React.FC<Props> = ({ title, routerLink, children, isLoading = false }) => (
  <IonCard className="ion-no-margin">
    <StyledIonCardContent>
      <Header>
        <Label color="gray" size="xs" weight={600}>
          {title}
        </Label>

        {routerLink && (
          <IonRouterLink routerLink={routerLink}>
            <Label secondary size="2xs" color="gray-light" fontStyle="italic">
              View All
            </Label>
          </IonRouterLink>
        )}
      </Header>

      {isLoading ? <Spinner name="dots" /> : children}
    </StyledIonCardContent>
  </IonCard>
);

const StyledIonCardContent = styled(IonCardContent)`
  padding: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-lightest);
  padding: 0 0.7rem 0.25rem 0.7rem;
  margin-bottom: 0.5rem;
`;

const Spinner = styled(IonSpinner)`
  display: block;
  margin: 0 auto;
`;

export default CollectionCard;
