import React, { useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'

import Form from './components/Form'
import Button from './components/Button'
import Layout from './components/Layout'
import FormInput from './components/FormInput'
import ButtonWrapper from './components/ButtonWrapper'

import { resetPassword } from 'api/sso'
import { useOnboarding } from 'store/Onboarding'
import { markAsClaimed } from 'api/loanzify'
import { useApplication } from 'store/Application'
import { Dialog } from '@capacitor/dialog'

const schema = yup.object().shape({
  password: yup.string().required('This field is required').min(8),
  password_confirmation: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    })
    .required('This field is required')
    .min(8),
})

const ResetPasswordPage = () => {
  const history = useHistory()
  const { token, email } = useParams()
  const { setEmail } = useOnboarding()
  const { application } = useApplication()

  const [xhrLoading, setXhrLoading] = useState(false)

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async ({ password, password_confirmation }) => {
    try {
      setXhrLoading(true)
      await resetPassword({
        broker: 'users',
        token,
        email,
        password,
        password_confirmation,
      })

      await markAsClaimed(application.id)

      await Dialog.alert({
        title: 'Password set successfully',
        message: 'You may now login with your new password.',
      })

      setEmail(email)
      setXhrLoading(false)
      history.push('/auth/login')
    } catch (err) {
      if (err?.response?.data?.errors?.email) {
        setError('password', {
          message: err.response.data.errors.email[0],
        })
      } else {
        setError('password', {
          message: 'We are unable to set your password at this time. Please try again later.',
        })
      }

      setXhrLoading(false)
    }
  }

  return (
    <Layout subtitle="Set Your Password">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="token" value={token} ref={register} />
        <input type="hidden" name="email" value={email} ref={register} />

        <FormInput
          ref={register}
          label="New Password"
          error={errors.password?.message}
          name="password"
          type="password"
          placeholder="Enter Your New Password"
        />

        <FormInput
          ref={register}
          label="Confirm New Password"
          error={errors.password_confirmation?.message}
          name="password_confirmation"
          type="password"
          placeholder="Confirm Your New Password"
        />

        <ButtonWrapper>
          <Button disabled={xhrLoading} loading={xhrLoading}>
            Set Password
          </Button>
        </ButtonWrapper>
      </Form>
    </Layout>
  )
}

export default ResetPasswordPage
