import React from 'react'
import styled from 'styled-components'

import { ReactComponent as FacebookIcon } from 'legacy-icons/social/facebook.svg'
import { ReactComponent as InstagramIcon } from 'legacy-icons/social/instagram.svg'
import { ReactComponent as TwitterIcon } from 'legacy-icons/social/twitter.svg'
import { ReactComponent as YoutubeIcon } from 'legacy-icons/social/youtube.svg'
import { ReactComponent as PinterestIcon } from 'legacy-icons/social/pinterest.svg'
import { ReactComponent as LinkedInIcon } from 'legacy-icons/social/linkedin.svg'

import { IonCard } from '@ionic/react'
import Label from 'components/Label'

interface Props {
  facebook_url?: string
  instagram_url?: string
  twitter_url?: string
  youtube_url?: string
  linkedin_url?: string
  pinterest_url?: string
}

type Entry = [keyof Props, React.FC]

const iconMap = {
  facebook_url: FacebookIcon,
  instagram_url: InstagramIcon,
  twitter_url: TwitterIcon,
  youtube_url: YoutubeIcon,
  linkedin_url: LinkedInIcon,
  pinterest_url: PinterestIcon,
}

const SocialLinks: React.FC<Props> = props => (
  <StyledCard>
    <div className="icons-container">
      {(Object.entries(iconMap) as Entry[]).map(
        ([key, Icon]) =>
          props[key] && (
            <a key={key} href={props[key]} target="_blank" rel="noopener noreferrer">
              <Icon />
            </a>
          )
      )}
    </div>

    <Label center size="sm">
      Social Media Sites
    </Label>
  </StyledCard>
)

const StyledCard = styled(IonCard)`
  margin: 0;
  padding: 1rem;

  .icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;

    a {
      width: 52px;
      height: 52px;
      margin: 0.5rem;
    }
  }
`

export default SocialLinks
