import React, { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as CheckmarkIcon } from 'legacy-icons/alert-checkmark.svg'

import useAlert from 'hooks/useAlert'

import Label from './Label'
import Button from './Button'
import ModalContent from './ModalContent'
import { sendError } from 'api/loanzify'

interface Props {
  context: Record<string, any>
}

const SendFeedbackModal: React.FC<Props> = ({ context, dismiss = undefined }) => {
  const showAlert = useAlert()
  const [status, setStatus] = useState<'ready' | 'sending' | 'sent'>('ready')
  const [message, setMessage] = useState('')

  const send = () => {
    setStatus('sending')

    sendError({ level: 'info', message, context })
      .then(() => setStatus('sent'))
      .catch(() => showAlert.error('We are experiencing downtime. Please try again later.'))
  }

  if (status === 'sent') {
    return <FeedbackSentModal />
  }

  return (
    <ModalContent title="Send Feedback" className="ion-padding" dismiss={dismiss}>
      <Label size="sm" color="gray" secondary>
        Message:
      </Label>

      <TextArea rows={8} style={{ width: '100%' }} onChange={e => setMessage(e.target.value)} />

      <Button expand="block" onClick={send} disabled={status !== 'ready'}>
        Send
      </Button>
    </ModalContent>
  )
}

export default SendFeedbackModal

const FeedbackSentModal: React.FC = () => (
  <ModalContent title="Feedback Sent" className="ion-padding">
    <div style={{ textAlign: 'center' }}>
      <CheckmarkIcon
        style={{
          color: 'var(--color-success)',
          width: '110px',
          height: '110px',
          margin: '1.5rem auto',
        }}
      />
    </div>

    <Label isWrapped center>
      We have received your feedback. Thank-you!
    </Label>
  </ModalContent>
)

const TextArea = styled.textarea`
  padding: 1rem 0.5rem;
  margin-top: 0.5rem;

  background: #fff;

  color: var(--color-gray);
  border: var(--border);
  border-radius: 0.5rem;

  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;

  ::placeholder {
    color: var(--color-gray-lighter);
    opacity: 1;
  }
`
