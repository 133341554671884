import React from 'react'

import Letters from './pre-approval/Letters'
import { PreApprovalsProvider } from '../../../../store/usePreApprovals'

const PreApproval = ({ loan }) => {
  return (
    <PreApprovalsProvider loan={loan}>
      <Letters loan={loan} />
    </PreApprovalsProvider>
  )
}

export default PreApproval
