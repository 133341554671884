import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-bottom: env(safe-area-inset-bottom);
`

const ButtonWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default ButtonWrapper
