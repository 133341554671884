import React from 'react'
import ReactDOM from 'react-dom'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import * as serviceWorker from './serviceWorker'
import App from './layouts/App'

import './theme/app.sass'
import './utils/statesAndCounties'

Bugsnag.start({
  apiKey: '75336b5f364569519cdf912aaa8498c7',
  plugins: [new BugsnagPluginReact()],
  /**
   * Prevent BugSnag from firing during local development.
   */
  enabledReleaseStages: ['production', 'staging'],
  onError: event =>
    event.addMetadata('Application', {
      initial_application_id: Number(process.env.REACT_APP_INITIAL_APP_ID),
      default_application_id: Number(process.env.REACT_APP_DEFAULT_APP_ID),
    }),
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
)

defineCustomElements(window)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
