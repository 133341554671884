import React, { useState, useEffect } from 'react'
import { IonLoading } from '@ionic/react'
import styled from 'styled-components'

import { ReactComponent as CheckMark } from 'legacy-icons/onboarding/checkmark.svg'
import { ReactComponent as ErrorIcon } from 'legacy-icons/onboarding/alert.svg'

import { extendTrial } from 'api/sso'

import Label from 'components/Label'
import Button from 'components/Button'

import Layout from './components/Layout'
import ButtonWrapper from './components/ButtonWrapper'

const ExtendTrialPage: React.FC = () => {
  const [status, setStatus] = useState<'LODING' | 'SUCCESS' | 'EXPIRED'>('LODING')
  const [message, setMessage] = useState<string>()

  useEffect(() => {
    extendTrial()
      .then(() => setStatus('SUCCESS'))
      .catch((error: any) => {
        setMessage(error?.response?.data?.message)

        setStatus('EXPIRED')
      })
  }, [])

  if (status === 'LODING') {
    return <IonLoading isOpen={true} message="Extending your trial..." />
  }

  if (status === 'EXPIRED') {
    return (
      <Layout title={message ? '' : 'Link Expired'}>
        <Container>
          <ErrorIcon />

          <Label secondary block center isWrapped fontStyle="italic" color="gray">
            {message ||
              `
              It looks like you've already extended your trial.
              You may only extend your trial once.
            `}
          </Label>

          <ButtonWrapper>
            <Button routerLink="/home">Go to Dashboard</Button>
          </ButtonWrapper>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout title={status === 'SUCCESS' ? 'Trial Extended' : 'LINK EXPIRED'}>
      <Container>
        <CheckMark />

        <Label secondary block center isWrapped fontStyle="italic" color="gray">
          Your trial has been successfully extended. <br /> <br />
          You may now setup your personal branded app.
        </Label>

        <ButtonWrapper>
          <Button routerLink="/onboarding">Setup App</Button>
        </ButtonWrapper>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  text-align: center;

  svg {
    margin-top: 1rem;
  }

  ion-label {
    margin: 2rem 0;
  }
`

export default ExtendTrialPage
