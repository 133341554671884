import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { IonCol, IonGrid, IonPage, IonRow, useIonToast } from '@ionic/react';

import { CALCULATORS_CONFIG } from 'config/calculators';
import { useCalculator } from 'store/Calculator';
import useCalculatorCacheWithDefaults from 'hooks/useCalculatorCacheWithDefaults';

import Label from 'components/Label';
import Header from 'components/Header';
import Content from 'components/Content';
import InfoBox from 'components/InfoBox';
import TypeSelect from 'components/calculators/TypeSelect';
import ToggleAdvancedButton from 'components/calculators/ToggleAdvancedButton';
import CalculatorResultPage from 'containers/CalculatorResultPage';

import MainOptions from '../components/MainOptions';
import AdvancedOptions from '../components/AdvancedOptions';
import ResultContent from './components/ResultContent';

import useAlert from 'hooks/useAlert';
import useSlider from '../hooks/useSlider';

import { getChartColors } from 'utils/theme';
import { formatCurrency } from 'utils/formatters';
import { PurchaseCalculatorConfig } from 'types/Calculator';

const calculatorConfig: PurchaseCalculatorConfig = CALCULATORS_CONFIG.purchase;

const PurchaseOptionsPage: React.FC = () => {
  const showAlert = useAlert();
  const { options, setOption, resetOptions, currentTypeDefaults } = useCalculator();
  const { cacheOptions, restoreOptions, setPersistKeys } = useCalculatorCacheWithDefaults();
  const [present, dismiss] = useIonToast();
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);

  const [isAdvancedVisible, setIsAdvancedVisible] = useState(false);

  const [slide, slideTo] = useSlider(['graph', 'details']);

  const calculatorDescription = currentTypeDefaults.description || '';

  const handleResetError = (message: string, field: string) => {
    if (isErrorToastVisible) {
      dismiss();
      setIsErrorToastVisible(false);
    }

    presentErrorToast(message, field);
  };

  const presentErrorToast = (message: string, field: string) => {
    const header = field === 'down_payment_rate' ? 'Down Payment Warning' : 'Loan Limit Exceeded';

    present({
      header,
      duration: 5000,
      keyboardClose: true,
      color: 'danger',
      // icon: 'alert-circle-outline',
      buttons: [
        {
          side: 'end',
          text: 'More Info',
          handler: () => {
            showAlert.error(message);
          },
        },
      ],
      onDidDismiss: () => {
        setIsErrorToastVisible(false);
      },
      onDidPresent: () => {
        setIsErrorToastVisible(true);
      },
    });
  };

  const handleTypeChange = (type: number) => {
    // Before type changes, cache the options of current loan type
    cacheOptions(options.type);

    setOption('type', type);
  };

  // When mounted
  useEffect(() => {
    // Reset options
    resetOptions();
    setOption('is_refi', false);
  }, []);

  // After type changes, restore options
  useEffect(() => {
    restoreOptions(options.type);
  }, [options.type]);

  const chartColors = useMemo(getChartColors, []);

  return (
    <IonPage>
      <Header title={calculatorConfig.title} defaultHref="/calculators" />

      <Content style={{ '--padding-top': '10px' }}>
        {calculatorDescription && <InfoBox text={calculatorDescription} />}
        <TypeSelect value={options.type} onChange={handleTypeChange} />

        <CalculatorResultPage
          calculator="purchase"
          calculate={window.lhpCalculator.calculate}
          renderContent={(result: any) => (
            <React.Fragment>
              <ResultContent
                result={result}
                onSlideLeft={() => slideTo('LEFT')}
                onSlideRight={() => slideTo('RIGHT')}
              />

              {slide === 'graph' && (
                <React.Fragment>
                  <MainOptions
                    onResetError={handleResetError}
                    checkLoanLimits
                    loanType={options.type}
                    optionsConfig={calculatorConfig.mainOptionsConfig}
                    onPersistChanges={(keys: any) => setPersistKeys(keys)}
                  />

                  <ToggleAdvancedButton
                    isOpen={isAdvancedVisible}
                    onClick={() => setIsAdvancedVisible(!isAdvancedVisible)}
                  />

                  {isAdvancedVisible && <AdvancedOptions optionsConfig={calculatorConfig.advancedOptionsConfig} />}
                </React.Fragment>
              )}

              {slide === 'details' && (
                <IonGrid className="ion-margin-top">
                  <IonRow>
                    {Object.keys(calculatorConfig.resultTitles).map((key, index) => (
                      <IonCol key={key} size={'6'}>
                        <ChartLegendBar loaded={true} color={chartColors[index]} />

                        <Label block secondary color="gray" size="xs" weight={600}>
                          {formatCurrency(result?.breakdown?.[key])}
                        </Label>

                        <Label block secondary color="gray" size="xs">
                          {calculatorConfig.resultTitles[key as keyof typeof calculatorConfig.resultTitles]}
                        </Label>
                      </IonCol>
                    ))}
                  </IonRow>
                </IonGrid>
              )}
            </React.Fragment>
          )}
        />
      </Content>
    </IonPage>
  );
};

const ChartLegendBar = styled.div<{ loaded: boolean }>`
  background: ${props => props.color};
  border-radius: 0.75rem;
  height: 0.75rem;
  margin-bottom: 0.5rem;

  transition: width 1s;
  width: ${props => (props.loaded ? '100%' : 0)};
`;

export default PurchaseOptionsPage;
