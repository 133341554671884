import React, { useState, useEffect } from 'react';
import { ActionSheet } from '@capacitor/action-sheet';
import { Toast } from '@capacitor/toast';
import styled from 'styled-components';
import { IonSpinner } from '@ionic/react';
import { titleCase } from 'utils/formatters';

import Label from 'components/Label';
import Button from 'components/Button';
import List from 'components/loans/List';
import ListItem from 'components/loans/ListItem';
import LoanCardSection from 'components/loans/LoanCardSection';

import {
  getLoanCreditRequests,
  createLoanCreditRequest,
  refreshLoanCreditRequest,
  archiveLoanCreditRequest,
} from 'api/pos';
import { formatCurrency } from 'utils/formatters';
import ThreeBureauReport from '../../../../components/ThreeBureauReport';

const CreditHistoryMapping = [
  {
    title: 'Institution',
    properties: ['agency', 'institution'],
  },
  {
    title: 'Revolving Payments',
    properties: ['payment_amount'],
  },
  {
    title: 'Running Balance',
    properties: ['balance'],
  },
  {
    title: 'Status',
    properties: ['status'],
  },
];

const FicoScoreWrapper = styled.div`
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: var(--color-background);
  margin-bottom: 0.75rem;
  border-radius: 7px;
`;

const Table = styled.table``;

const TableHeaderCell = styled.td`
  padding-bottom: 0.75rem;
  padding-top: 0;
  vertical-align: top;
  width: 125px;
  font-size: 0.75rem;
`;

const TableInfoCell = styled.td`
  padding-bottom: 0.75rem;
  padding-top: 0;
`;

const StyledLoader = styled(IonSpinner)`
  width: 100%;
  text-align: center;
`;

const StyledRequestButton = styled(Button)`
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
`;

const StyledErrorContainer = styled.div`
  margin-top: 1rem;
  border-top: 1px solid red;
  padding-top: 1rem;
`;

const CreditReport = ({ loan }) => {
  const [requestingReport, setRequestingReport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [creditRequestPending, setCreditRequestPending] = useState(false);
  const [activeCreditRequest, setActiveCreditRequest] = useState({ id: null });
  const [creditHistory, setCreditHistory] = useState([]);
  const [creditRequestError, setCreditRequestError] = useState(null);
  const [creditScores, setCreditScores] = useState({
    experian: {
      score: null,
      last_updated: null,
    },
    equifax: {
      score: null,
      last_updated: null,
    },
    transunion: {
      score: null,
      last_updated: null,
    },
    fico: {
      score: null,
    },
  });

  const fetchWhilePending = () => {
    setTimeout(() => {
      refreshCreditRequest();
    }, 500);
  };
  const refreshCreditRequest = async () => {
    const { currentCreditRequest } = await getLoanCreditRequests(loan.id);

    const creditRequest = currentCreditRequest || { id: null };

    if (creditRequest.id !== null) {
      const scores = {};

      (currentCreditRequest.scores || []).forEach(score => {
        scores[score.service.key] = {
          score: score.score,
          last_updated: score.date,
        };
      });

      const liabilities = Object.values(currentCreditRequest.activeLiabilities).map(liability => {
        const unpaidBalance = liability.detail.CreditLiabilityUnpaidBalanceAmount;
        const monthlyPayment = liability.detail.CreditLiabilityMonthlyPaymentAmount;

        return {
          agency: liability.commentSourceType,
          institution: liability.creditor.NAME.FullName,
          type: liability.detail.CreditLoanType || 'N/A',
          balance: unpaidBalance ? formatCurrency(unpaidBalance, 2) : 'N/A',
          payment_amount: monthlyPayment ? formatCurrency(monthlyPayment, 2) : 'N/A',
          status: liability.detail.CreditLiabilityAccountStatusType || 'N/A',
        };
      });

      switch (creditRequest.status.toLowerCase()) {
        case 'completed':
          setCreditRequestPending(false);
          setCreditScores(scores);
          setCreditHistory(liabilities);
          break;
        default:
          setCreditRequestPending(true);
          fetchWhilePending();
          break;
      }
    }

    setActiveCreditRequest(currentCreditRequest || { id: null });
  };
  const requestCreditReport = () => {
    setRequestingReport(true);
    createLoanCreditRequest(loan.id)
      .then(data => {
        setCreditRequestPending(true);
        fetchWhilePending();
      })
      .catch(error => {
        Toast.show({ text: 'There was an error requesting a credit report. Please try again' });
        try {
          const e = JSON.parse(error?.response?.data?.message);
          setCreditRequestError(e);
        } catch (e) {
          console.log(e);
          setCreditRequestError(null);
        }
      })
      .finally(() => {
        setRequestingReport(false);
      });
  };
  const promptCreditReportModal = () =>
    ActionSheet.showActions({
      title: 'Credit Report',
      message: 'What would you like to do?',
      options: [
        {
          title: 'Download',
        },
        {
          title: 'Refresh',
        },
        {
          title: 'Archive',
        },
        {
          title: 'Cancel',
        },
      ],
    }).then(({ index }) => {
      switch (index) {
        case 0:
          break;
        case 1:
          // Refresh
          refreshLoanCreditRequest(loan.id, activeCreditRequest.id).then(() => {
            refreshCreditRequest().then(r => r);
          });
          break;
        case 2:
          // Archive
          archiveLoanCreditRequest(loan.id, activeCreditRequest.id).then(() => {
            refreshCreditRequest().then(r => r);
          });
          break;
        default:
          break;
      }
    });

  const DisplayErrorMessage = () => {
    if (creditRequestError && typeof creditRequestError === 'object' && Object.keys(creditRequestError).length > 0) {
      return (
        <StyledErrorContainer>
          <h3> An error was encountered: </h3>
          <ul>
            {Object.keys(creditRequestError).map(key => {
              return <li key={key}> {formatMissingFormField(creditRequestError[key][0])}</li>;
            })}
          </ul>
          <p> Please instruct the borrower to complete their application before requesting a credit report. </p>
        </StyledErrorContainer>
      );
    }

    return (
      <StyledErrorContainer>
        <h3> An error was encountered: </h3>
        <span dangerouslySetInnerHTML={{ __html: creditRequestError }}></span>
      </StyledErrorContainer>
    );
  };

  const formatMissingFormField = field => {
    return titleCase(field.replace('.', ' '));
  };

  useEffect(() => {
    async function loadInitialData() {
      await refreshCreditRequest();
      setLoading(false);
    }

    loadInitialData();

    return () => {
      setCreditRequestError('');
    };
  }, []);

  return (
    <>
      {loading && <StyledLoader />}
      {!loading && activeCreditRequest.id === null && !creditRequestPending && (
        <LoanCardSection title="Credit Report" showActionsIcon={false}>
          <Label center>No Credit Report has been pulled for this Borrower yet.</Label>
          <StyledRequestButton disabled={requestingReport} onClick={() => requestCreditReport()} size="sm" block center>
            Request a Credit Report
          </StyledRequestButton>

          {creditRequestError && <DisplayErrorMessage />}
        </LoanCardSection>
      )}
      {!loading && creditRequestPending && (
        <LoanCardSection title="Gathering Report" showActionsIcon={false}>
          <StyledLoader />
        </LoanCardSection>
      )}
      {!loading && activeCreditRequest.id !== null && !creditRequestPending && (
        <>
          <LoanCardSection
            title="Credit Scores"
            showActionsIcon={true}
            onActionsClick={() => promptCreditReportModal()}
          >
            {(creditScores.fico || {}).score && (
              <FicoScoreWrapper>
                <Label block secondary color="gray" size="lg" center weight="bold">
                  {creditScores.fico.score} FICO Score
                </Label>
              </FicoScoreWrapper>
            )}
            <ThreeBureauReport creditScores={creditScores} />
          </LoanCardSection>

          <LoanCardSection title="Credit History" noSidePadding noBottomPadding showActionsIcon={false}>
            <List>
              {creditHistory.map((history, index) => (
                <ListItem key={`${history.agency}-${index}`}>
                  <Table>
                    <tbody>
                      {CreditHistoryMapping.map(map => (
                        <tr key={`history-mapping-${map.title}`}>
                          <TableHeaderCell>{map.title}</TableHeaderCell>

                          <TableInfoCell>
                            {map.properties.map(prop => (
                              <React.Fragment key={`${history[prop]}-${index}`}>
                                {history[prop] ?? 'N/A'} <br />
                              </React.Fragment>
                            ))}
                          </TableInfoCell>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </ListItem>
              ))}
            </List>
          </LoanCardSection>
        </>
      )}
    </>
  );
};

export default CreditReport;
