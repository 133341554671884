import React, { useRef, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'

type Props = {
  content: string
  onChange?: Function
  plugins?: string[]
  toolbar?: string
}

const RichTextEditor: React.FC<Props> = props => {
  const editorRef = useRef(null)

  const [initialValue] = useState(props.content)

  const [plugins] = useState(
    props.plugins
      ? props.plugins
      : [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'help',
          'wordcount',
        ]
  )

  const [toolbar] = useState(
    props.toolbar
      ? props.toolbar
      : 'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help'
  )

  const onInput = (value: string) => {
    if (editorRef.current && props.onChange) {
      props.onChange(value)
    }
  }

  return (
    <>
      <Editor
        apiKey="e21yptpnud3vptejn8ubm6985i739gk6motec9zkl8zb1lv8"
        onInit={(evt, editor) => (editorRef.current = editor)}
        onEditorChange={(newValue, _) => onInput(newValue)}
        initialValue={initialValue}
        init={{
          height: 500,
          menubar: false,
          plugins: plugins,
          toolbar: toolbar,
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </>
  )
}

export default RichTextEditor
