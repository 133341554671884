import React from 'react'
import { useMutation } from 'react-query'

import { sendPhoneVerificationNotification } from 'api/sso'

interface RenderOptions {
  send: () => Promise<void>
  status: 'sending' | 'sent' | 'ready'
}

interface Props {
  render: (options: RenderOptions) => React.ReactElement
  onSent: () => void
}

const ResendActivationCodeButton: React.FC<Props> = ({ render, onSent }) => {
  const mutation = useMutation(() => {
    return sendPhoneVerificationNotification()
  })

  const status = React.useMemo(() => {
    if (mutation.isLoading) {
      return 'sending'
    }

    if (mutation.isSuccess) {
      return 'sent'
    }

    return 'ready'
  }, [mutation])

  const send = React.useCallback(async () => {
    await mutation.mutateAsync()
    setTimeout(mutation.reset, 2000)
    onSent()
  }, [])

  return render({ send, status })
}

export default ResendActivationCodeButton
