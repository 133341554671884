import * as React from 'react'
import PartnerHeader from '../../pages/auth/components/PartnerHeader'
import Avatar from '../Avatar'
import ProfileInfo from '../ProfileInfo'
import ContentHeader from './ContentHeader'
import { useApplication } from '../../store/Application'
import TitleLabel from '../Labels/TitleLabel'
import SubtitleLabel from '../Labels/SubtitleLabel'

interface Props {
  title?: string
  subtitle?: string
  titleLarge?: any
}

const LayoutHeader: React.FC<Props> = ({ title, subtitle, titleLarge }) => {
  let {
    owner,
    isDefault,
    photoUrl,
    license,
    nmls,
    isPartnerApp,
    isMainEnterprise,
    application: { user1, user2, dual_branding },
  } = useApplication()

  if (isDefault) {
    return (
      <ContentHeader>
        {title && (
          <TitleLabel lg={titleLarge} subtitle={!!subtitle} color="primary" block center isWrapped>
            {title}
          </TitleLabel>
        )}

        {subtitle && (
          <SubtitleLabel secondary block center isWrapped fontStyle="italic" color="gray">
            {subtitle}
          </SubtitleLabel>
        )}
      </ContentHeader>
    )
  }

  if (isPartnerApp && user1 && user2) {
    return (
      <ContentHeader>
        <PartnerHeader user1={user1} user2={user2} dual_branding={dual_branding} />
      </ContentHeader>
    )
  }

  if (photoUrl && !isMainEnterprise) {
    return (
      <ContentHeader>
        <Avatar size="lg" borderColor="gray-lighter" url={photoUrl} />
        <ProfileInfo owner={owner} license={license} nmls={nmls} color="gray-light" />
      </ContentHeader>
    )
  }

  return (
    <ContentHeader>
      {title && (
        <TitleLabel lg={titleLarge} color="warning" block center isWrapped>
          {title}
        </TitleLabel>
      )}

      {subtitle && (
        <SubtitleLabel lg secondary block center isWrapped fontStyle="italic" color="gray">
          {subtitle}
        </SubtitleLabel>
      )}
    </ContentHeader>
  )
}

export default LayoutHeader
