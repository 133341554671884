import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as changeCase from 'change-case';
import { getCurrentUser as getCurrentPosUser } from 'api/pos';
import { IonContent, IonPage } from '@ionic/react';
import { TabContainer, TabItem } from 'components/Tabs/TabContainer';
import { useHistory } from 'react-router';
import ConsumerTabRoutes from 'routes/ConsumerTabRoutes';

import Header from '../../components/Header';
import { useAuth } from '../../store/Auth';
import { getLoan } from '../../api/pos';

const ConsumerPage: React.FC = () => {
  const history = useHistory();
  const { loan, setLoan, setPosUser } = useAuth();
  const [tab, setTab] = useState(history.location.pathname.split('/').pop() || null);
  const [viewCreditScore, setViewCreditScore] = useState(false);

  useEffect(() => {
    if (history.location.pathname.indexOf('consumer') > -1) {
      setTab(history.location.pathname.split('/').pop() || null);
    }
  }, [history.location]);

  useEffect(() => {
    history.replace(`/consumer/${tab}`);

    getLoan(loan?.id).then(setLoan);
    getCurrentPosUser().then(setPosUser);
  }, [tab]);

  useEffect(() => {
    if (loan && loan.formData?.borrower_credit_score_view_authorization) {
      setViewCreditScore(true);
    }
  }, [setLoan]);

  return (
    <IonPage>
      <IonContent>
        <Header title={tab ? changeCase.headerCase(tab) : 'Loading'} />

        {tab !== 'team' && (
          <StyledTabContainer value={tab} onTabChange={e => setTab(e.detail.value)}>
            <TabItem value="tasks"> My Tasks </TabItem>
            <TabItem value="milestones"> Loan Status </TabItem>
            {viewCreditScore && <TabItem value="credit"> Credit Score </TabItem>}
          </StyledTabContainer>
        )}

        <ConsumerTabRoutes />
      </IonContent>
    </IonPage>
  );
};

const StyledTabContainer = styled(TabContainer)`
  padding: 1.25rem 0;
`;

export default ConsumerPage;
