import Axios from 'axios';
import { PageError } from 'components/ErrorPage';
import { default as useBaseSWR } from 'swr';
import parseApplicationData from 'utils/parseApplicationData';

import { getUserByLoanzifyId } from './sso';
import { Application } from 'types/Application';

/**
 * POS Http client.
 *
 * @type {Axios}
 */
export const client = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    'X-Mobile-Version': '4',
  },
});

/**
 * POS data fetcher.
 *
 * @type {Function}
 */
export const fetcher = (url: string) => client.get<any>(url).then(response => response.data);

/**
 * SWR Wrapper for API
 *
 * @type {Function}
 */
export const useSWR = (key: string, customFetcher = null) => {
  return useBaseSWR(key, customFetcher || fetcher, {
    revalidateOnFocus: false,
  });
};

/**
 * Get application by id.
 *
 * @param {String} id

 */
export const getApplication = async (id: number): Promise<Application> => {
  try {
    const res = await client.get<any>(`v4/applications/${id}`);
    const { data } = res.data;
    return data as Application;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

/**
 * Get application features.
 *
 * @param {Number} id
 * @returns {Features}
 */
export const getApplicationFeatures = (id: number) => {
  return fetcher(`v3/applications/${id}/features`).then(response => response.data);
};

/**
 * Get application content.
 *
 * @param {Number} id
 * @returns {Content}
 */
export const getApplicationContent = (id: number) => {
  return fetcher(`v3/applications/${id}/content`);
};

/**
 * Get application calculator default options.
 *
 * @param {String} id
 * @returns {Object}
 */
export const getApplicationCalculatorDefauls = (id: number) => {
  return fetcher(`v3/applications/${id}/calculator-defaults`).then(response => response.data);
};

/**
 * Get full application data.
 *
 * @returns {Promise}
 */
export const getFullApplicationData = async (id: number) => {
  const application = await getApplication(id);

  const ssoOwnerData: any = await getUserByLoanzifyId(
    application.user2_id || application.user1_id, // userId
    application.is_enterprise && !application.user2_id, // isLoanOfficer (bool)
    application.user1_id // userId (parentId)
  ).catch(error => {
    if (!Axios.isAxiosError(error) || error.response?.status !== 404) {
      throw error;
    }

    throw new PageError({
      message: 'User not found.',
      title: 'The owner of this app cannot be found in our system.',
      description: 'Please use "Submit Feedback".  Our support team will contact you soon after feedback.',
      context: {
        application_id: application.id,
        email: application.user1.email,
      },
    });
  });


  return parseApplicationData(application, ssoOwnerData);
};

/**
 * Get Loan officer by ID.
 *
 * @param {Number} id
 */
export const getLoanOfficer = (id: number) =>
  client.get<any>(`v4/loan-officers/${id}`).then(response => response.data.data);

/**
 * Get Loan officer by ID.
 *
 * @param {Number} id
 */
export const getUser = (id: number) => client.get<any>(`v4/users/${id}`).then(response => response.data.data);

/**
 * Get Loan officer by ID.
 *
 * @param {Number} id
 */
export const searchApplications = (search: string) =>
  client
    .get<any>(`v4/search/applications`, {
      params: { search },
    })
    .then(response => response.data.data);

/**
 * Get application id by user_id.
 *
 * @param {Number} user
 */
export const getUserApplicationId = (user_id: number, role: string) =>
  client.get<any>(`v4/user-applications/${user_id}/${role}`).then(response => response.data.id);

/**
 * Create an application id.
 *
 * @param {Number} application_id
 * @param {Object} data
 */
export const createInstallation = (application_id: number, data: any) => {
  return client.post(`v4/applications/${application_id}/installations`, data).then(response => response.data);
};

/**
 * Create run calculation
 *
 * @param {String} name
 * @param {Object} payload
 */
export const createRunCalculation = (payload: any) =>
  client.post(`v4/run-calculations`, payload).then(response => response.data);

/**
 * Get saved calculations
 */
export const getSavedCalculations = () => client.get(`v4/saved-calculations`).then(response => response.data);

/**
 * Create saved calculation
 *
 * @param {Number} calculation_id
 * @param {String} name
 */
export const createSavedCalculation = (calc_id: number, name: string) =>
  client.post(`v4/saved-calculations`, { calc_id, name }).then(response => response.data);

/**
 * Send the pdf to email.
 *
 * @param {Number} calculation
 */
export const sendPdfDownloadCalculation = (calculation: number) =>
  client.post(`v4/run-calculations/${calculation}/sent-emails`).then(response => response);

/**
 * Send run calculation pre qualify
 *
 * @param calculation
 * @returns {*}
 */
export const sendRunCalculationPrequalify = (calculation: number) =>
  client.post(`v4/run-calculations/${calculation}/pre-qualify`).then(response => response);
/**
 * Store short form.
 *
 * @param {Object} data
 */
export const storeShortForm = (data: any) => client.post(`v4/short-forms`, data).then(response => response.data);

/**
 * Get a list of installations.
 *
 * @param {{
 * ids: number[]
 *   page?: number
 *   search?: string
 * }} options
 */
export const getInstallations = ({ ids, page, search }: { ids: any; page: any; search: string }) => {
  const params = new URLSearchParams();

  if (ids) {
    params.append('ids', ids);
  }

  if (page) {
    params.append('page', page);
  }

  if (search) {
    params.append('search', search);
  }

  return client.get<any>(`v4/installations?${params.toString()}`).then(response => response.data.data);
};

/**
 * Get an installation.
 *
 * @param {Number} installation
 */
export const getInstallation = (installation: number) =>
  client.get<any>(`v4/installations/${installation}`).then(response => response.data.data);

/**
 * Get calculations of a given installation.
 *
 * @param {Number} installation
 */
export const getCalculations = (installation: number) =>
  client.get<any>(`v4/installations/${installation}/calculations`).then(response => response.data.data);

export const getRecentStats = (from = 7, showPerDay = 0) =>
  client.get<any>(`v4/recent-stats`, { params: { from, per_day: showPerDay } }).then(response => response.data);

export const getApplicationCssVariables = (id: number) =>
  client.get<any>(`v4/applications/${id}/css-variables`).then(response => response.data.data);

export const storeLead = (form: any) => client.post<any>(`v4/leads`, { form }).then(response => response.data.data);

export const getBranches = (app_id: number) =>
  client.get<any>(`v4/applications/${app_id}/branches`).then(response => response.data.data);

export const requestUpgrade = (data: any) => client.post(`v4/help/upgrade`, data).then(response => response.data);

export const getPartnersApplications = (ids: any) =>
  client.get(`v4/partner-applications`, { params: { ids } }).then(response => response.data);

/**
 * @param {{ token: string }} data
 */
export const updatePushNotificationToken = (data: any) => client.put(`v4/push-notification-tokens`, data);

export const getPushNotifications = () => client.get<any>(`v4/push-notifications`).then(response => response.data.data);

export const sendClaimLink = (app_id: number, channels: any) =>
  client.post(`v4/applications/${app_id}/claims`, { channels });

export const updateProfile = (data: any) => client.put(`v4/profile`, data).then(response => response.data);

export const registerTrial = (data: any) => client.post(`v4/register-trial`, data).then(response => response.data);

export const updateCssVariables = (app_id: number, values: any) =>
  client.post<any>(`v4/applications/${app_id}/css-variables`, { values }).then(response => response.data.data);

export const sendMagicLink = (data: any) =>
  client.post(`v4/send-magic-link`, data).then(response => response.data.data);

/**
 * @param {number} app_id
 * @returns {Promise<void>}
 */
export const markAsClaimed = (app_id: number) =>
  client.post<any>(`v4/claimed-applications`, { app_id }).then(response => response.data.data);

/**
 * @param {number} consumer_service_id
 * @returns {Promise<Installation>}
 */
export const getInstallationByConsumerService = (consumer_service_id: number) =>
  client.get<any>(`v4/sso-installations/${consumer_service_id}`).then(response => response.data.data);

/**
 * @param {{
 *   level: 'info'|'error'
 *   message: string
 *   context?: Record<string, any>
 * }} data
 * @returns {Promise<void>}
 */
interface ErrorInterface {
  level: 'info' | 'error';
  message: string;
  context?: Record<string, any>;
}
export const sendError = (data: ErrorInterface) => client.post(`v4/errors`, data);

/**
 * @param {Record<string, string>|undefined} query
 * @returns {Promise<{ data: import('types/Loanzify').TextNotification[] }>}
 */

export const getNotifications = () => client.get<any>(`v4/notifications`).then(response => response.data);

export const sendNotification = ({ to, message, event, data }: any) =>
  client.post(`v4/notifications`, { to, message, event, data }).then(response => response.data);

/**
 * @returns {Promise<void>}
 */
export const markNotificationsAsRead = () => client.post(`v4/read-notifications`);

export const getConsumersV2 = () => client.get(`v4/consumers`).then(response => response.data);
