import React, { useEffect, useState } from 'react'

import Label from './Label'
import { User } from 'types/Application'

interface ProfileInfoProps {
  owner: User
  license: string | null
  nmls: string | null
  color: string
}

const ProfileInfo = ({ owner, license, nmls, color = 'white' }: ProfileInfoProps) => {
  const [showLicense, setShowLicense] = useState(true)

  useEffect(() => {
    if (license === null && owner.nmls === null) {
      // Loan Officer (no partner)
      setShowLicense(false)
    } else if (owner.nmls === license) {
      // Partners
      setShowLicense(true)
    } else if (owner.nmls === nmls) {
      // Loan Officer with partner
      setShowLicense(false)
    }
  }, [])

  return (
    <>
      <Label block color="gray-alt" weight={500} center isWrapped>
        {owner.first_name} {owner.last_name}
      </Label>

      <Label block secondary color="gray-light" size="xs" center isWrapped>
        {owner.title}
      </Label>

      {(nmls || license) && (
        <Label block secondary color="gray-lighter" size="xs" center isWrapped>
          {showLicense ? `License: #${license}` : nmls ? `NMLS: #${nmls}` : ''}
        </Label>
      )}
    </>
  )
}

export default ProfileInfo
