import React from 'react'
import { useParams } from 'react-router-dom'
import { IonPage, IonContent } from '@ionic/react'
import styled from 'styled-components'

import Header from 'components/Header'
import { useContent } from 'store/Content'

const ExternalHtml = styled.div`
  .slider-slide-image {
    div {
      padding: 1rem;
    }
  }
`

const StepByStepDetailsPage = () => {
  const { id } = useParams()
  const { content } = useContent()
  const item = content.stepByStep.find(item => item.id === Number(id))

  return (
    <IonPage>
      <Header title={item.name} back defaultHref="/content/guide" />

      <IonContent>
        <ExternalHtml dangerouslySetInnerHTML={{ __html: item.content }} />
      </IonContent>
    </IonPage>
  )
}

export default StepByStepDetailsPage
