import React, { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'

const CHART_OPTIONS = {
  cutoutPercentage: 80,
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
}

const ResultChart = ({ result, resultTitles, colors }) => {
  const data = useMemo(
    () => ({
      labels: Object.keys(resultTitles).map(key => resultTitles[key]),
      datasets: [
        {
          data: Object.keys(resultTitles).map(key => result.breakdown[key]),
          backgroundColor: colors,
        },
      ],
    }),
    [colors, resultTitles, result.breakdown]
  )

  return <Doughnut options={CHART_OPTIONS} data={data} />
}

export default ResultChart
