import React, { useState, useEffect } from 'react';
import { IonPage, useIonToast } from '@ionic/react';

import { CALCULATORS_CONFIG } from 'config/calculators';
import { useCalculator } from 'store/Calculator';
import useCalculatorCacheWithDefaults from 'hooks/useCalculatorCacheWithDefaults';

import Header from 'components/Header';
import Content from 'components/Content';
import InfoBox from 'components/InfoBox';
import TypeSelect from 'components/calculators/TypeSelect';
import ToggleAdvancedButton from 'components/calculators/ToggleAdvancedButton';

import MainOptions from '../components/MainOptions';
import AdvancedOptions from '../components/AdvancedOptions';

import useAlert from 'hooks/useAlert';
import CalculatorResultPage from 'containers/CalculatorResultPage';
import ResultContent from './components/ResultContent';

const calculatorConfig = CALCULATORS_CONFIG.refi;

const RefiOptionsPage = () => {
  const showAlert = useAlert();
  const { options, setOption, resetOptions, currentTypeDefaults } = useCalculator();
  const { cacheOptions, restoreOptions, setPersistKeys } = useCalculatorCacheWithDefaults();
  const [isAdvancedVisible, setIsAdvancedVisible] = useState(false);
  const [present, dismiss] = useIonToast();

  const calculatorDescription = currentTypeDefaults.refi_description || '';

  const handleTypeChange = type => {
    // Before type changes, cache the options of current loan type
    cacheOptions(options.type);

    setOption('type', type);
  };

  const handleResetError = message => {
    dismiss();

    present({
      header: 'Loan Limit Exceeded',
      duration: 5000,
      animated: true,
      keyboardClose: true,
      color: 'danger',
      icon: 'alert-circle-outline',
      buttons: [
        {
          side: 'end',
          text: 'More Info',
          handler: () => {
            showAlert.error(message);
          },
        },
      ],
    });
  };

  // When mounted
  useEffect(() => {
    // Reset options
    resetOptions();
    setOption('is_refi', true);
  }, []);

  // After type changes, restore options
  useEffect(() => {
    restoreOptions(options.type);
  }, [options.type]);

  return (
    <IonPage>
      <Header back title={calculatorConfig.title} defaultHref="/calculators" />

      <Content>
        {calculatorDescription && <InfoBox text={calculatorDescription} />}
        <TypeSelect value={options.type} onChange={handleTypeChange} />

        <CalculatorResultPage
          calculator="refi"
          calculate={options => window.lhpCalculator.calculate({ ...options, is_refi: true })}
          renderContent={result => <ResultContent result={result} />}
        />

        <MainOptions
          loanType={options.type}
          onResetError={handleResetError}
          isRefi
          optionsConfig={calculatorConfig.mainOptionsConfig}
          onPersistChanges={keys => setPersistKeys(keys)}
        />

        <ToggleAdvancedButton isOpen={isAdvancedVisible} onClick={() => setIsAdvancedVisible(!isAdvancedVisible)} />

        {isAdvancedVisible && <AdvancedOptions optionsConfig={calculatorConfig.advancedOptionsConfig} />}
      </Content>
    </IonPage>
  );
};

export default RefiOptionsPage;
