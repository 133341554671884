import React from 'react'
import { Route } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'

import CalculatorsPage from 'pages/calculators/Index'

import PurchaseAmortisationPage from 'pages/calculators/purchase/Amortisation';
import PurchaseDetailsPage from 'pages/calculators/purchase/Details'
import PurchaseOptionsPage from 'pages/calculators/purchase/Options'
import PurchaseResultPage from 'pages/calculators/purchase/Result'

import RefiOptionsPage from 'pages/calculators/refi/Options'
import RefiResultPage from 'pages/calculators/refi/Result'
import RefiDetailsPage from 'pages/calculators/refi/Details'
import RefiAmortisationPage from 'pages/calculators/refi/Amortisation'

import ExtraOptionsPage from 'pages/calculators/extraPayment/Options'
import ExtraResultPage from 'pages/calculators/extraPayment/Result'

import PrincipalOptionsPage from 'pages/calculators/principal/Options'
import PrincipalResultPage from 'pages/calculators/principal/Result'

import AffordabilityOptionsPage from 'pages/calculators/affordability/Options'
import AffordabilityResultPage from 'pages/calculators/affordability/Result'
import AffordabilityDetailsPage from 'pages/calculators/affordability/Details'

import SavedCalculationsPage from 'pages/calculators/saved/Index'

const CalculatorRoutes = () => {
  return (
    <IonRouterOutlet>
      <Route exact path="/calculators" component={CalculatorsPage} />

      <Route exact path="/calculators/purchase" component={PurchaseOptionsPage} />
      <Route exact path="/calculators/purchase/result" component={PurchaseResultPage} />
      <Route exact path="/calculators/purchase/details" component={PurchaseDetailsPage} />
      <Route exact path="/calculators/purchase/amortisation" component={PurchaseAmortisationPage} />

      <Route exact path="/calculators/refi" component={RefiOptionsPage} />
      <Route exact path="/calculators/refi/result" component={RefiResultPage} />
      <Route exact path="/calculators/refi/details" component={RefiDetailsPage} />
      <Route exact path="/calculators/refi/amortisation" component={RefiAmortisationPage} />

      <Route exact path="/calculators/extra-payment" component={ExtraOptionsPage} />
      <Route path="/calculators/extra-payment/result" component={ExtraResultPage} />

      <Route exact path="/calculators/principal" component={PrincipalOptionsPage} />
      <Route exact path="/calculators/principal/result" component={PrincipalResultPage} />

      <Route exact path="/calculators/affordability" component={AffordabilityOptionsPage} />
      <Route exact path="/calculators/affordability/result" component={AffordabilityResultPage} />
      <Route exact path="/calculators/affordability/details" component={AffordabilityDetailsPage} />

      <Route exact path="/calculators/saved" component={SavedCalculationsPage} />
    </IonRouterOutlet>
  );
}

export default CalculatorRoutes
