import React from 'react';

import CalculatorResultPage from 'containers/CalculatorResultPage';

import ResultContent from './components/ResultContent';

const RefiResultPage = () => {
  return (
    <CalculatorResultPage
      calculator="refi"
      calculate={options => window.lhpCalculator.calculate({ ...options, is_refi: true })}
      renderContent={result => <ResultContent result={result} />}
    />
  );
};

export default RefiResultPage;
