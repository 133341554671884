import * as React from 'react';
import { User } from '../types/Application';

import SlideOne from './../images/homepage/PHONE-1.png';
import SlideOneBranded from './../components/Slides/Homepage/SlideOneBranded';
import SlideOneCoBranded from './../components/Slides/Homepage/SlideOneCoBranded';
import styled from 'styled-components';

type BrandedWelcomeSlideProps = {
  photoUrl: string;
  owner?: User;
  license?: string;
  nmls?: string;
  user1: User;
  user2?: User;
};

const BrandedWelcomeSlideContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const ImageConstraintContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;

  @media (min-width: 768px) {
    justify-content: center;
  }
`;

const BrandableSlide: React.FC<BrandedWelcomeSlideProps> = props => {
  const { user2 } = props;

  const TheSlide = user2 ? SlideOneCoBranded : SlideOneBranded;

  return <TheSlide {...props} partnerPhotoUrl={user2 ? user2.photo_url : null} />;
};

const BrandableImage = styled(BrandableSlide)`
  position: absolute;
  top: -30px;
  width: 105%;
  height: 105%;
  max-width: 508px;
  max-height: 105%;
  right: -50px;

  @media (min-width: 768px) {
    position: relative;
    top: 0;
    right: 0;
  }

  #slide-one--first_name {
    fill: var(--color-primary) !important;
  }

  #slide-one--title {
    fill: var(--color-gray) !important;
  }

  #slide-one--cobranded_title {
    font-weight: 100;
  }

  #slide-one--nmls {
    fill: var(--color-gray-lighter) !important;
  }

  #slide-one--cobranded_nmls {
    font-weight: 100;
    fill: var(--color-gray-lighter) !important;
  }

  #slide-one--cobranded_share_app_name {
    fill: var(--color-gray) !important;
  }
`;

const DefaultBrandedImage = styled.img`
  position: absolute;
  top: 30px;
  height: 90% !important;
  width: auto !important;
  max-width: none !important;
  max-height: 90% !important;
  right: -50px;

  @media (min-width: 768px) {
    position: relative;
    top: 0;
    right: 0;
  }
`;

const BrandedWelcomeSlide: React.FC<BrandedWelcomeSlideProps> = ({ photoUrl, owner, license, nmls, user1, user2 }) => {
  return (
    <BrandedWelcomeSlideContainer className={'branded-welcome-slide-container'}>
      <ImageConstraintContainer className={'branded-welcome-slide-constraint'}>
        {photoUrl ? (
          <BrandableImage {...{ owner, photoUrl, license, nmls, user1, user2 }} />
        ) : (
          <DefaultBrandedImage src={SlideOne} alt={user1.first_name + ' ' + user1.last_name + "'s Mobile App"} />
        )}
      </ImageConstraintContainer>
    </BrandedWelcomeSlideContainer>
  );
};

export default BrandedWelcomeSlide;
