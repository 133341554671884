import React from 'react'
import { IonContent, IonPage } from '@ionic/react'

import Header from 'components/Header'
import Label from 'components/Label'
import Button from 'components/Button'

const ShortFormSuccessPage = () => (
  <IonPage>
    <Header title="Thank You" />
    <IonContent>
      <div style={{ marginBottom: '2rem', marginTop: '4rem' }}>
        <Label size="xl" color="primary" center>
          Thank You!
        </Label>
      </div>

      <div style={{ marginBottom: '3rem', padding: '0 3rem' }}>
        <Label center isWrapped>
          We have received your request and will get back to you shortly
        </Label>
      </div>

      <Button center routerLink="/home">
        Return to Dashboard
      </Button>
    </IonContent>
  </IonPage>
)

export default ShortFormSuccessPage
