import React from 'react'
import { Route } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'

import ChatPage from 'pages/chat/Index'

const ChatRoutes = () => (
  <IonRouterOutlet>
    <Route exact path="/chat" component={ChatPage} />
  </IonRouterOutlet>
)

export default ChatRoutes
