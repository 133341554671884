import React, { useEffect, useState } from 'react'
import Header from 'components/Header'
import { getPushNotifications } from 'api/loanzify'
import { IonContent, IonLoading, IonPage } from '@ionic/react'

const PushNotificationsPage = () => {
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getPushNotifications()
      .then(setNotifications)
      .finally(() => setLoading(false))
  }, [])

  return (
    <IonPage>
      <Header title="Notifications" />
      <IonContent>
        <IonLoading isOpen={loading} message="Loading..." />

        {JSON.stringify(notifications)}
      </IonContent>
    </IonPage>
  )
}

export default PushNotificationsPage
