import React from 'react'
import styled from 'styled-components'

import Card from '../Card'
import Label from '../Label'
import Button from '../Button'
import { ReactComponent as DotsIcon } from 'legacy-icons/options.svg'
import { usePreApprovals } from '../../store/usePreApprovals'

const LoanCardSectionContainer = styled(Card)`
  padding-left: ${props => (props.noSidePadding ? '0' : '1rem')};
  padding-right: ${props => (props.noSidePadding ? '0' : '1rem')};
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  margin-bottom: 1.5rem;
  ${props =>
    props.noBottomPadding &&
    `
  padding-bottom: 0;
    `}
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${props => (props.noHeaderBorder ? 'none' : '1px solid var(--color-gray-lightest)')};
  padding-bottom: 0.25rem;
  margin-bottom: 0.5rem;
  margin-left: ${props => (props.noSidePadding ? '1rem' : '0')};
  margin-right: ${props => (props.noSidePadding ? '1rem' : '0')};
`

const StyledLabel = styled(Label)`
  margin-left: 10px;
`

const ClickableActionsButton = styled.div`
  width: 30px;
  min-height: 20px;
  text-align: right;

  svg {
    vertical-align: middle;
  }
`

const StyledMaxSalePrice = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;

  span {
    padding-left: 0.5rem;
    color: #38a169;
    font-size: 1.125rem;
  }
`

const LoanCardSection = ({
  title,
  showActionsIcon = true,
  children,
  noSidePadding = false,
  noBottomPadding = false,
  noHeaderBorder = false,
  alertText = null,
  onActionsClick = () => {},
  CustomAction = null,
  CustomActionBefore = null,
}) => {
  const { letterParams } = usePreApprovals()

  return (
    <LoanCardSectionContainer noSidePadding={noSidePadding} noBottomPadding={noBottomPadding}>
      {CustomActionBefore !== null && <CustomActionBefore />}
      <Header noSidePadding={noSidePadding} noHeaderBorder={noHeaderBorder}>
        <StyledLabel color="primary-light" size="sm">
          {title}
        </StyledLabel>
        {letterParams && Object.keys(letterParams).length > 0 && (
          <StyledMaxSalePrice>
            Max Sale Price
            <span> ${new Intl.NumberFormat('en-US').format(letterParams.maxSalePrice)}</span>
          </StyledMaxSalePrice>
        )}
        {showActionsIcon && !alertText && (
          <ClickableActionsButton onClick={() => onActionsClick()}>
            <DotsIcon />
          </ClickableActionsButton>
        )}
        {CustomAction !== null && <CustomAction />}
        {alertText && <Button size="2xs">{alertText}</Button>}
      </Header>
      {children}
    </LoanCardSectionContainer>
  )
}

export default LoanCardSection
