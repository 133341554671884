import React, { createContext, useContext } from 'react';
import { IonLoading } from '@ionic/react';

import usePersistentState from 'hooks/usePersistentState';
import { InitialSessionState, SessionState } from 'types/Session';

export const initialSession: InitialSessionState = {
  token: '',
  role: '',
  application_id: Number(process.env.REACT_APP_INITIAL_APP_ID),
  alternate_profile: true,
  recent_activity_from: 30,
  show_welcome_screen: true,
  show_selfie_view: false,
};

export const SessionContext = createContext({} as SessionState);

export const SessionProvider: React.FC = props => {
  const [session, setSession, loading] = usePersistentState<InitialSessionState>('session', initialSession);

  if (loading) {
    return <IonLoading isOpen={true} message="Loading..." />;
  }

  const value: SessionState = {
    ...session,
    setSession,
    logout: () =>
      setSession(session => ({
        ...initialSession,
        application_id: session.application_id,
        show_welcome_screen: false,
        show_selfie_view: false,
      })),
    forceConsumerView: session.show_selfie_view && !session.installation_id,
    setRole: role => setSession(session => ({ ...session, role })),
    setToken: token => setSession(session => ({ ...session, token })),
    setLoanId: loan_id => setSession(session => ({ ...session, loan_id })),
    setApplicationId: application_id => setSession(session => ({ ...session, application_id })),
    setInstallationId: installation_id => setSession(session => ({ ...session, installation_id })),
    setConsumerServiceId: consumer_service_id => setSession(session => ({ ...session, consumer_service_id })),
    setAlternateProfile: alternate_profile => setSession(session => ({ ...session, alternate_profile })),
    setRecentActivityFrom: recent_activity_from => setSession(session => ({ ...session, recent_activity_from })),
    setShowWelcomeScreen: show_welcome_screen => setSession(session => ({ ...session, show_welcome_screen })),
    setShowSelfieView: show_selfie_view => setSession(session => ({ ...session, show_selfie_view })),
  };

  return <SessionContext.Provider {...props} value={value} />;
};

export const useSession = () => useContext(SessionContext);
