import React from 'react';

import SsoConsumerDashboard from './SsoConsumerDashboard';
import EmptyConsumerDashboard from './EmptyConsumerDashboard';

import { useAuth } from 'store/Auth';
import { useSession } from '../../../store/Session';
import { Redirect } from 'react-router-dom';

const ConsumerDashboard: React.FC = () => {
  const { forceConsumerView } = useSession();
  const { service, isPartner } = useAuth();

  // In iOS, users may skip the login/register step and they
  // are not associated with any of our services. That's
  // where we use the "EmptyConsumerDashboard" view.
  if (!forceConsumerView && service === undefined && !isPartner) {
    return <EmptyConsumerDashboard />;
  }

  return <SsoConsumerDashboard />;
};

export default ConsumerDashboard;
