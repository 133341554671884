import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IonSpinner } from '@ionic/react'
import LoanCardSection from '../../../../../components/loans/LoanCardSection'
import ListItem from '../../../../../components/loans/ListItem'
import List from '../../../../../components/loans/List'

import { ReactComponent as DownloadIcon } from 'legacy-icons/pre-approval/download.svg'
import { ReactComponent as EmailIcon } from 'legacy-icons/pre-approval/email.svg'
import { ReactComponent as TrashIcon } from 'legacy-icons/pre-approval/trash.svg'

import {
  deleteLoanPreApprovalLetter,
  downloadPreApprovalLetter,
  getLoanPreApprovalLetters,
} from '../../../../../api/pos'
import { formatCurrency } from '../../../../../utils/formatters'

import CreatePreApprovalLetter from '../../../../../components/loans/CreatePreApprovalLetter'
import CreateOrEditLetterParams from '../../../../../components/loans/CreateOrEditLetterParams'
import EmailPreApprovalLetter from '../../../../../components/loans/EmailPreApprovalLetter'
import { Toast } from '@capacitor/toast'
import { format } from 'date-fns'
import { DATE_FORMAT } from '../../../../../config/dates'

import { usePreApprovals } from '../../../../../store/usePreApprovals'
import { useAuth } from '../../../../../store/Auth'
import Button from '../../../../../components/Button'

const StyledPlusSign = styled.div`
  font-size: 2rem;
  margin-top: -5px;
  margin-right: 5px;
`

const StyledLetterEditor = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 1.5rem;
  margin-bottom: 1rem;

  svg {
    margin-left: 0.25rem;
    vertical-align: middle;
  }
`

const ActionsWrapper = styled.div`
  display: flex;
  border-top: 1px solid var(--color-gray-lightest);
  margin-top: 0.5rem;
`

const ActionIcon = styled.div`
  margin-top: 10px;
  padding-right: 1rem;
  margin-left: 1rem;
  display: flex;
  border-right: 1px solid var(--color-gray-lighter);

  svg {
    vertical-align: middle;
    align-self: center;
  }
  &:last-child {
    border-right: none;
  }
`

const Table = styled.table``

const TableHeaderCell = styled.td`
  width: 135px;

  vertical-align: middle;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--color-gray);

  padding-bottom: 0.4rem;
`

const TableInfoCell = styled.td`
  font-size: 0.75rem;
  vertical-align: middle;
  padding-bottom: 0.4rem;
  color: var(--color-gray-light);
  font-weight: 500;
`

const StyledIonSpinner = styled(IonSpinner)`
  width: 100%;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`
const StyledActionContainer = styled.div``

const Letters = ({ loan, params }) => {
  const [loading, setLoading] = useState(true)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showLetterParamsModal, setShowLetterParamsModal] = useState(false)
  const [letters, setLetters] = useState([])
  const [letterToEmail, setLetterToEmail] = useState({ id: null })
  const { isLoanOfficer } = useAuth()
  const { fetchLetterParams, letterParams } = usePreApprovals()

  const userEmail = (loan.borrower.user || {}).email || ''

  useEffect(() => {
    /**
     * @DEPRECATED
     *
     * We are fetching the JSON object containing the letter params directly from the
     * LOAN object being supplied to the PreApprovalsProvider.
     *
     * This exists for regression testing only.
     */
    if (isLoanOfficer) {
      fetchLetterParams(loan.id)
    }
  }, [])

  const promptCreateLetter = () => {
    setShowCreateModal(true)
  }

  const promptSetParams = () => {
    setShowLetterParamsModal(true)
  }

  const downloadLetter = letter => {
    downloadPreApprovalLetter(loan.id, letter)
  }

  const emailLetter = letter_id => {
    const letter = letters.find(l => l.id === letter_id)

    if (letter === undefined || letter_id === null) {
      setLetterToEmail({ id: null })
    } else {
      setLetterToEmail(letter)
    }
  }
  const deleteLetter = letter_id => {
    deleteLoanPreApprovalLetter(loan.id, letter_id)
      .then(response => {
        Toast.show({ text: 'Letter deleted' })
      })
      .catch(error => {
        Toast.show({ text: 'There was an error. Please try again.' })
      })
      .finally(() => {
        refreshLetters()
      })
  }

  const refreshLetters = () => {
    setLoading(true)
    return getLoanPreApprovalLetters(loan.id)
      .then(({ loanPreApprovalOptions }) => {
        setLetters(loanPreApprovalOptions)

        return Promise.resolve()
      })
      .finally(() => setLoading(false))
  }

  const onCreate = async () => {
    setShowCreateModal(false)
    refreshLetters().then(() => {
      if (letters.length) {
        emailLetter(letters[0].id)
      }
    })
  }

  const letterDate = created_at => {
    return format(new Date(created_at), DATE_FORMAT)
  }

  useEffect(() => {
    refreshLetters()
  }, [])

  const letterParamsAction = () => {
    return (
      <StyledActionContainer>
        <StyledLetterEditor>
          {isLoanOfficer && (
            <Button size="sm" color="secondary" fill="outline" onClick={() => promptSetParams()}>
              {letterParams && Object.keys(letterParams).length > 0 ? 'Edit' : 'Set'} Parameters
            </Button>
          )}

          {Object.keys(letterParams).length > 0 && (
            <Button size="sm" color="secondary" onClick={() => promptCreateLetter()}>
              <StyledPlusSign>+</StyledPlusSign> New Pre-Approval Letter
            </Button>
          )}
        </StyledLetterEditor>
      </StyledActionContainer>
    )
  }

  return (
    <>
      <LoanCardSection
        title="Pre-Approval Letters"
        noSidePadding={true}
        noBottomPadding={true}
        showActionsIcon={false}
        CustomActionBefore={letterParamsAction}
        noHeaderBorder={Object.keys(letterParams).length === 0}
      >
        {loading && <StyledIonSpinner />}

        {!loading && letters.length > 0 && (
          <>
            <List>
              {letters.map(letter => {
                return (
                  <ListItem key={`pre-approval-letter-${letter.id}`}>
                    <Table>
                      <tbody>
                        <tr>
                          <TableHeaderCell>Requested Amount</TableHeaderCell>
                          <TableInfoCell>{formatCurrency(letter.data.requested_amount, 2)}</TableInfoCell>
                        </tr>
                        <tr>
                          <TableHeaderCell>Address</TableHeaderCell>
                          <TableInfoCell>
                            {letter.data.address_street_1 && (
                              <>
                                {letter.data.address_street_1}
                                <br />
                              </>
                            )}
                            {letter.data.address_street_2 && (
                              <>
                                {letter.data.address_street_2}
                                <br />
                              </>
                            )}
                            {letter.data.address_city && letter.data.address_city + ','} {letter.data.address_state}{' '}
                            {letter.data.address_zipcode}
                          </TableInfoCell>
                        </tr>
                        <tr>
                          <TableHeaderCell>Generated By</TableHeaderCell>
                          <TableInfoCell>{letter.created_by_account_user_type}</TableInfoCell>
                        </tr>
                        <tr>
                          <TableHeaderCell>Date Generated</TableHeaderCell>
                          <TableInfoCell>{letterDate(letter.created_at)}</TableInfoCell>
                        </tr>
                      </tbody>
                    </Table>
                    <ActionsWrapper>
                      <ActionIcon>
                        <DownloadIcon onClick={() => downloadLetter(letter)} />
                      </ActionIcon>
                      <ActionIcon>
                        <EmailIcon onClick={() => emailLetter(letter.id)} />
                      </ActionIcon>
                      <ActionIcon>
                        <TrashIcon onClick={() => deleteLetter(letter.id)} />
                      </ActionIcon>
                    </ActionsWrapper>
                  </ListItem>
                )
              })}
            </List>
          </>
        )}
      </LoanCardSection>

      <EmailPreApprovalLetter
        loan={loan}
        letter={letterToEmail}
        defaultEmail={userEmail}
        defaultFilename={letterToEmail.id ? `${letterToEmail.file_name}.pdf` : ''}
        defaultBody="Hi, please view your attached Pre-Approval Letter"
        isOpen={letterToEmail.id !== null}
        onCloseClick={() => emailLetter(null)}
        onSent={() => emailLetter(null)}
      />
      <CreatePreApprovalLetter
        loan={loan}
        isOpen={showCreateModal}
        onCloseClick={() => setShowCreateModal(false)}
        onCreate={onCreate}
      />
      <CreateOrEditLetterParams
        loan={loan}
        isOpen={showLetterParamsModal}
        onCloseClick={() => setShowLetterParamsModal(false)}
        onChange={() => {}}
      ></CreateOrEditLetterParams>
    </>
  )
}

export default Letters
