import React from 'react'
import styled from 'styled-components'
import { IonToolbar, IonButtons, IonHeader } from '@ionic/react'

import MenuButton from './MenuButton'
import ExpirationWarning from './ExpirationWarning'

import { useApplication } from 'store/Application'

import { ReactComponent as DefaultLogo } from 'legacy-icons/logo.svg'
import { ReactComponent as BottomLip } from 'legacy-icons/bottom-curve.svg'

// import HeaderQuickMenu from './HeaderQuickMenu'
// import { useAuth } from '../store/Auth'
// import { useIntro } from '../store/Intro'
// import Button from './Button'
// import { ReactComponent as IconSettings } from '../icons/Icon-Settings.svg'
// import { useHistory } from 'react-router-dom'

const StyledIonHeader = styled(IonHeader)`
  ion-toolbar {
    --min-height: 4.5rem;
    --border-width: 0 !important;
  }
`

const StyledBrandedLogo = styled.img`
  max-width: 70%;
  max-height: 60px;
`

const LogoWrapper = styled.div`
  text-align: center;
  margin-right: -56px;
  padding-right: 28px;
  padding-left: 28px;
  padding-top: 10px;
`

const BottomCurve = styled.div`
  margin-top: 0.15rem;
  svg {
    width: 100%;
    max-height: 100%;
    fill: var(--color-primary);
  }
`

const HeaderAdmin = ({ defaultHref = null, forceDefaultHref = false }) => {
  const { logoUrl } = useApplication()
  // const { isLoanOfficer, isPartner } = useAuth()
  // const { steps, setSteps } = useIntro()
  // const history = useHistory()

  // const [present, dismiss] = useIonPopover(HeaderQuickMenu, {
  //   onHide: () => dismiss(),
  //   isLoanOfficer,
  //   isPartner,
  //   steps,
  //   setSteps,
  //   history,
  // })

  return (
    <StyledIonHeader className="ion-no-border">
      <ExpirationWarning />

      <IonToolbar color="light">
        <IonButtons slot="start">
          <MenuButton defaultHref={defaultHref} forceDefaultHref={forceDefaultHref} />
        </IonButtons>

        {/*{(isLoanOfficer || isPartner) && (*/}
        {/*  <IonButtons slot="end">*/}
        {/*    <Button onClick={e => present({ event: e.nativeEvent })}>*/}
        {/*      {' '}*/}
        {/*      <IconSettings width={25} height={25} color="accent" />{' '}*/}
        {/*    </Button>*/}
        {/*  </IonButtons>*/}
        {/*)}*/}

        <LogoWrapper>
          {logoUrl && <StyledBrandedLogo src={logoUrl} />}
          {!logoUrl && <DefaultLogo width="100%" height="60" />}
        </LogoWrapper>
      </IonToolbar>
      <BottomCurve>
        <BottomLip preserveAspectRatio="none" width="100%" />
      </BottomCurve>
    </StyledIonHeader>
  )
}

export default HeaderAdmin
