import React from 'react'

import { ReactComponent as DocumentSubmittedIcon } from 'legacy-icons/documents/waiting.svg'
import { ReactComponent as DocumentAcceptedIcon } from 'legacy-icons/documents/success.svg'

import FileSelect from '../../components/FileSelect'
import StyledButton from 'components/Button'

const DocumentItemAction = ({ document, onFilesSelected }) => {
  if (document.status === 'accepted') {
    return <DocumentAcceptedIcon />
  }

  if (document.status === 'submitted') {
    return <DocumentSubmittedIcon />
  }

  return (
    <FileSelect title={document.name} onFilesSelected={onFilesSelected} askForDocumentName={false}>
      {open => (
        <StyledButton size="sm" color={buttonColor(document.status)} onClick={open}>
          {buttonText(document.status)}
        </StyledButton>
      )}
    </FileSelect>
  )
}

/**
 * @param {string} status
 */
const buttonColor = status => (status === 'rejected' ? 'danger' : 'blue')

/**
 * @param {string} status
 */
const buttonText = status => (status === 'rejected' ? 'Re-Upload' : 'Upload')

export default DocumentItemAction
