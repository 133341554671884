import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useSession } from '../../store/Session'
import { IonLoading, IonPage } from '@ionic/react'
import { useApplication } from '../../store/Application'

const SwitchViewPage = () => {
  const { refreshApplication } = useApplication()
  const { setShowSelfieView, show_selfie_view } = useSession()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    refreshApplication().then(() => {
      setTimeout(() => setShowSelfieView(!show_selfie_view), 100)
      setTimeout(() => setLoading(false), 400)
    })
  }, [])

  if (loading) {
    return (
      <IonPage>
        <IonLoading isOpen={loading} message="Switching..." />
      </IonPage>
    )
  }

  return <Redirect to="/home" />
}

export default SwitchViewPage
