import React, { useRef } from 'react';
import styled from 'styled-components';

import Label from '../Label';
import AlertInputNumber from './AlertInputNumber';
import Select from './Select';
import { ReactComponent as PencilIcon } from 'legacy-icons/calculators/pencil.svg';
import ComputedField from './ComputedField';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  :not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 3px solid var(--color-gray-lightest);
    margin-bottom: 1rem;
  }
`;

const StyledPencilIcon = styled(PencilIcon)`
  margin-left: 5px;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  svg {
    display: inline-block;
    align-self: center;
  }
`;

const AdvancedOption = ({
  title,
  value,
  type = 'input',
  selectOptions,
  formatter,
  onChange,
  computedField,
  options,
}) => {
  const inputEl = useRef();

  return (
    <Container>
      <Label color="black" size="sm">
        {title}
      </Label>

      {type === 'select' ? (
        <Select size="normal" title={title} value={value} options={selectOptions} onChange={onChange} />
      ) : (
        <StyledInputWrapper>
          <AlertInputNumber
            title={title}
            value={value}
            size="sm"
            formatter={formatter}
            onChange={onChange}
            ref={inputEl}
          />
          {computedField && (
            <ComputedField
              title={title}
              value={value}
              options={options}
              computedField={computedField}
              onChange={onChange}
              ref={inputEl}
            />
          )}
          <StyledPencilIcon onClick={() => inputEl.current.click()} />
        </StyledInputWrapper>
      )}
    </Container>
  );
};

export default AdvancedOption;
