import React from 'react'
import styled from 'styled-components'

import Label from 'components/Label'
import { DocumentStatus } from 'types/Documents'

import { ReactComponent as RejectedIcon } from 'legacy-icons/alert-error.svg'
import { ReactComponent as AcceptedIcon } from 'legacy-icons/alert-checkmark.svg'
import { ReactComponent as WaitingIcon } from 'legacy-icons/alert-waiting.svg'

interface Props {
  status: DocumentStatus
}

const StatusIcon: React.FC<Props> = ({ status }) => {
  const { icon: Icon, title, style } = STATUS_MAP[status]

  return (
    <Wrapper>
      <Icon style={style} />

      <Label color="gray-light" fontStyle="italic" size="2xs">
        {title}
      </Label>
    </Wrapper>
  )
}

const STATUS_MAP = {
  accepted: {
    icon: AcceptedIcon,
    title: 'Accepted',
    style: {
      color: 'var(--color-success)',
    },
  },

  owed: {
    icon: RejectedIcon,
    title: 'Requested',
    style: {
      color: 'var(--color-info)',
    },
  },

  rejected: {
    icon: RejectedIcon,
    title: 'Rejected',
    style: {
      color: 'var(--color-danger)',
    },
  },

  submitted: {
    icon: WaitingIcon,
    title: 'Submitted',
    style: {
      color: 'var(--color-warning)',
    },
  },
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default StatusIcon
