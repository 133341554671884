import React, { useRef } from 'react';
import styled from 'styled-components/macro';

import FlexContainer from '../FlexContainer';
import Label from '../Label';

import Range from './Range';
import Select from './Select';
import AlertInputNumber from './AlertInputNumber';
import ComputedField from './ComputedField';

import { ReactComponent as PencilIcon } from 'legacy-icons/calculators/pencil.svg';
import { CalculatorDefaultOptionsConfig, CalculatorOptions, CalculatorsConfig } from 'types/Calculator';

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPencilIcon = styled(PencilIcon)`
  margin-left: 5px;
`;

interface MainOptionProps {
  value: number;
  limit: any;
  options: CalculatorOptions;
  config: CalculatorDefaultOptionsConfig;
  onChange: () => void;
  onFocus: () => void;
  loanType: string | null;
}
const MainOption: React.FC<MainOptionProps> = ({
  value,
  limit,
  options,
  onChange,
  onFocus = () => {},
  config: { title, formatter, type, selectOptions, computedField },
  loanType = null,
}: any) => {
  const inputEl = useRef<any>();
  const computedEl = useRef<any>();

  if (type === 'select' && inputEl && inputEl.current) {
    inputEl.current.value = value;
  }

  return (
    <div className="ion-margin-vertical">
      <CardHeader>
        <Label color="black" size="sm">
          {title}
        </Label>

        <FlexContainer>
          {type === 'select' ? (
            <Select size="lg" title={title} value={value} options={selectOptions} onChange={onChange} ref={inputEl} />
          ) : (
            <AlertInputNumber
              title={title}
              size="sm"
              value={value}
              formatter={formatter}
              onChange={onChange}
              ref={inputEl}
            />
          )}

          {computedField && (
            <ComputedField
              title={title}
              value={value}
              options={options}
              computedField={computedField}
              onChange={onChange}
              ref={computedEl}
            />
          )}

          <StyledPencilIcon
            onClick={() =>
              computedEl && computedEl.current !== undefined ? computedEl.current.click() : inputEl.current.click()
            }
          />
        </FlexContainer>
      </CardHeader>

      {limit && (
        <Range
          {...limit}
          mode="ios"
          value={value}
          className="ion-margin-top"
          onIonChange={event => onChange(event.detail.value, event)}
          onIonFocus={event => onFocus((event?.target as any).value, event)}
        />
      )}
    </div>
  );
};

export default MainOption;
