import { Share } from '@capacitor/share'
import { Toast } from '@capacitor/toast'

export const handleShare = async id => {
  try {
    await Share.share({
      title: 'Mortgage Calculation Details',
      text: `Tap Here: https://www.loanzify.com/calc/${id} to view mortgage calculated details produced by my mobile app`,
      dialogTitle: 'Share',
    })
  } catch (e) {
    Toast.show({
      text: 'Link copied.',
    })
  }
}

/**
 * THIS IS A HACK!
 * This method extracts the real down payment value from the given options.
 *
 * Computed options do not work correctly.
 * • If down payment changes, the down payment rate is not updated.
 * • If down payment rate changes, the down payment is not updated.
 *
 * @param {Object} options
 */
export const retrieveDownPayment = options => {
  if (options.down_payment !== 25000) {
    return options.down_payment
  }

  if (options.down_payment_rate !== 10) {
    return (options.property_price * options.down_payment_rate) / 100
  }

  return options.down_payment
}
