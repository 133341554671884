import React, { useEffect, useState } from 'react'

import { loadLhpFormsConfig } from 'api/pos'
import { IonSpinner } from '@ionic/react'
import styled from 'styled-components'

const StyledLoader = styled(IonSpinner)`
  width: 100%;
  text-align: center;
`

interface AdminApplicationProps {
  loan: any
}
const AdminApplication = ({ loan }: AdminApplicationProps) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadLhpFormsConfig(loan.id).then(options => {
      options.schema = 'admin'

      window.LhpForms.create(options)
        .mount('#form-builder')
        .then(() => setLoading(false))
    })
  }, [])

  return (
    <>
      {loading && <StyledLoader />}
      <div id="form-builder" frameBorder="0" style={{ width: '100%', height: '100%' }}></div>
    </>
  )
}

export default AdminApplication
