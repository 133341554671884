import React from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Form from './components/Form'
import Button from './components/Button'
import Layout from './components/Layout'
import FormInput from './components/FormInput'
import ButtonWrapper from './components/ButtonWrapper'

import { resetPassword } from 'api/pos'
import { Dialog } from '@capacitor/dialog'

const schema = yup.object().shape({
  password: yup.string().required('This field is required').min(8),
})

const ResetPasswordPage = () => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async ({ password, password_confirmation }) => {
    await resetPassword({
      password,
      password_confirmation,
    })

    await Dialog.alert({
      title: 'Password set successfully',
      message: 'You may now login with your new password.',
    })
  }

  return (
    <Layout subtitle="Set Your Password">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          ref={register}
          label="New Password"
          error={errors.password?.message}
          name="password"
          type="password"
          placeholder="Enter Your New Password"
        />

        <FormInput
          ref={register}
          label="Confirm New Password"
          error={errors.password_confirmation?.message}
          name="password_confirmation"
          type="password"
          placeholder="Confirm Your New Password"
        />

        <ButtonWrapper>
          <Button>Set Password</Button>
        </ButtonWrapper>
      </Form>
    </Layout>
  )
}

export default ResetPasswordPage
