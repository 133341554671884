import React, { createContext, useContext, useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { IonLoading } from '@ionic/react';

import { useSession } from './Session';
import { useApplication } from './Application';

import useClientListeners from 'hooks/useClientListeners';
import useInstallationListener from 'hooks/useInstallationListener';

import { fakeAuth } from 'utils/fakeAuth';
import { recoverAuthFromSession } from 'utils/recoverAuthFromSession';

import { AuthState, InitialAuthState, LoginData, Role } from 'types/Auth';
import ErrorPage, { ErrorPageProps } from 'components/ErrorPage';
import { Authenticatable, ConsumerService } from '../types/Sso';
import { Loan, User } from '../types/Pos';
import { useStreamChat } from './StreamChat';
import { get } from 'lodash';
// import { useIntro } from './Intro'

export const AuthContext = createContext({} as AuthState);

export const AuthProvider: React.FC = props => {
  const session = useSession();
  const [error, setError] = useState<ErrorPageProps>();
  // const { resetIntro } = useIntro()
  const { hasPos, isPreview } = useApplication();
  const { client, token: streamToken, setChatUser, createToken: createTokenForChat } = useStreamChat();
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState<InitialAuthState>({});

  useClientListeners(session, auth, setAuth);
  useInstallationListener(session, auth, setAuth);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const setupAuth = async () => {
        if (isPreview) {
          return fakeAuth(setAuth);
        }

        return recoverAuthFromSession(session, setAuth, hasPos)
          .then(() => setLoading(false))
          .catch(error =>
            setError({
              message: 'Authentication error.',
              context: { error },
            })
          );
      };

      setAuth({});
      setLoading(true);
      setupAuth().then(() => setLoading(false));
    }

    return () => {
      mounted = false;
    };
  }, [hasPos, session.token]);

  useEffect(() => {
    if (auth.user) {
      Bugsnag.setUser(String(auth.user.id), auth.user.email);
    }
  }, [auth.user]);

  useEffect(() => {
    if (auth && get(auth, 'posUser.id', false) && !streamToken && !client) {
      createTokenForChat().then(() => setChatUser(auth.posUser));
    }
  }, [auth.posUser]);

  if (error) {
    return <ErrorPage {...error} />;
  }

  if (loading) {
    return <IonLoading isOpen={true} message="Loading..." />;
  }

  const contextValue: AuthState = {
    ...auth,

    service: auth.consumerService?.userService?.service,
    isPos: auth.consumerService?.userService?.service === 'pos',
    isSso: auth.consumerService?.userService?.service === 'loanzify',
    parentSsoUserId: auth.consumerService?.userService?.parent_user_id,
    isConsumer: Boolean(auth.user?.is_consumer),
    isLoanOfficer: auth.user && auth.user.roles ? Boolean(auth.user?.roles.includes('Loan Officer')) : false,
    isUser: Boolean(!auth.user?.is_consumer),
    isPartner: Boolean(auth.user?.roles?.includes('Partner')),

    logout: () => {
      session.logout();
      //temp fix
      // resetIntro()
      setAuth({});
    },

    login: async ({ user, token }: LoginData) => {
      session.setToken(token);
      setAuth(auth => ({ ...auth, user }));
    },

    setUser: (user: Authenticatable) => {
      setAuth(auth => ({ ...auth, user }));
    },

    setPosUser: (posUser: User) => {
      setAuth(auth => ({ ...auth, posUser }));
    },

    setRole: (role: Role) => {
      session.setRole(role);
      setAuth(auth => ({ ...auth, role }));
    },

    setLoan: async (loan: Loan) => {
      session.setLoanId(loan?.id);
      setAuth(auth => ({ ...auth, loan }));
    },

    setConsumerService: async (consumerService: ConsumerService) => {
      session.setConsumerServiceId(consumerService?.id);
      setAuth(auth => ({ ...auth, consumerService }));

      // if (consumerService.userService.service === 'pos') {
      //   const posUser = await getCurrentPosUser()
      //   setAuth(state => ({ ...state, posUser }))
      // }
    },
  };

  return <AuthContext.Provider {...props} value={contextValue} />;
};

export const useAuth = () => useContext(AuthContext);
