import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LoanCardSection from '../../../../components/loans/LoanCardSection'
import { IonSpinner, IonTextarea } from '@ionic/react'
import Button from 'components/Button'
import { getLoanNotes, createLoanNote } from 'api/pos'

import { ReactComponent as EmojiIcon } from 'legacy-icons/emoji.svg'
import { ReactComponent as AttachmentIcon } from 'legacy-icons/attachment.svg'

import ListItem from '../../../../components/loans/ListItem'
import List from '../../../../components/loans/List'
import { Toast } from '@capacitor/toast'

const StyledTextarea = styled(IonTextarea)`
  border: 1px solid var(--color-gray-lightest);
  border-radius: 7px;
  min-height: 140px;
  padding: 0.75rem;

  textarea,
  .native-textarea {
    padding: 0 !important;
  }
`

const StyledActionsWrapper = styled.div`
  margin-top: 1rem;
  text-align: right;
  vertical-align: middle;

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.75rem;
  }
`

const StyledIonSpinner = styled(IonSpinner)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
`

const StyledLoadingIonSpinner = styled(IonSpinner)`
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 0.75rem;
`

const StyledButton = styled(Button)`
  vertical-align: middle;
`

const NoteContent = styled.p`
  margin-bottom: 0.25rem;
  margin-top: 0.75rem;
  font-family: var(--font-secondary);
`
const NoteHeader = styled.div`
  font-family: var(--font-secondary);
  position: relative;
`

const NoteTime = styled.span`
  font-weight: bold;
  font-size: 0.75rem;
`

const NoteAuthor = styled.span`
  margin-left: 3px;
  font-size: 11px;
  font-style: italic;
`

const Notes = ({ loan }) => {
  const [loadingNotes, setLoadingNotes] = useState(true)
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [notes, setNotes] = useState([])
  const [message, setMessage] = useState('')

  const refreshNotes = () =>
    getLoanNotes(loan.id).then(data => {
      setNotes(data.notes || [])
      setLoadingNotes(false)
    })

  const addNote = () => {
    if (message.trim().length === 0) {
      return
    }

    setLoadingCreate(true)
    createLoanNote(loan.id, message)
      .then(data => {
        refreshNotes()
        Toast.show({ text: 'Note created!' })
        setMessage('')
      })
      .catch(error => {
        Toast.show({ text: 'There was an error. Please try again' })
      })
      .finally(() => {
        setLoadingCreate(false)
      })
  }
  useEffect(() => {
    refreshNotes()
  }, [])

  return (
    <>
      <LoanCardSection title="Add a Note" noHeaderBorder showActionsIcon={false}>
        <StyledTextarea
          value={message}
          onIonChange={e => setMessage(e.detail.value)}
          placeholder="Type your note here"
        />
        <StyledActionsWrapper>
          {loadingCreate && <StyledIonSpinner />}
          <AttachmentIcon />
          <EmojiIcon />
          <StyledButton color="secondary" size="sm" onClick={addNote}>
            Post
          </StyledButton>
        </StyledActionsWrapper>
      </LoanCardSection>
      <LoanCardSection title="Notes" noSidePadding noBottomPadding showActionsIcon={false}>
        {loadingNotes && <StyledLoadingIonSpinner />}
        {!loadingNotes && (
          <List>
            {notes.map((note, index) => (
              <ListItem matchHeaderSpacing key={`note-${note.id}-${index}`}>
                <NoteHeader>
                  <NoteTime>{note.created_at}</NoteTime>
                  <NoteAuthor>- from {note.user_name}</NoteAuthor>
                </NoteHeader>
                <NoteContent>{note.body}</NoteContent>
              </ListItem>
            ))}
          </List>
        )}
      </LoanCardSection>
    </>
  )
}

export default Notes
