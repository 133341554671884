import * as React from 'react'
import { MessageInput, MessageList, Thread, useChannelActionContext, Window } from 'stream-chat-react'
import ConversationHeader from './ConversationHeader'
import { MessageToSend } from 'stream-chat-react/dist/context/ChannelActionContext'
import { storeUnreadMessage } from '../../../api/pos'
import { useChatContext } from 'stream-chat-react/dist/context/ChatContext'
import { useAuth } from '../../../store/Auth'

const ChannelInner: React.FC = () => {
  const { sendMessage } = useChannelActionContext()
  const { channel } = useChatContext()
  const { posUser } = useAuth()

  /**
   * @see https://getstream.io/chat/docs/sdk/react/custom-code-examples/override_submit_handler/
   * @param message MessageToSend
   * @unused cid string
   */
  const overrideSubmitHandler = async (message: MessageToSend) => {
    await sendMessage(message)

    try {
      await storeUnreadMessage(posUser?.id, parseInt(channel.id), message.text)
    } catch (err) {
      console.warn(`Unable to store unread message`)
    }
  }

  return (
    <>
      <Window>
        <ConversationHeader live={true} />
        <MessageList />
        <MessageInput overrideSubmitHandler={overrideSubmitHandler} />
      </Window>
      <Thread />
    </>
  )
}

export default ChannelInner
