import React from 'react';
import styled from 'styled-components';
import { IonToolbar, IonButtons, IonHeader } from '@ionic/react';

import Label from './Label';
import MenuButton from './MenuButton';
import ExpirationWarning from './ExpirationWarning';

interface Props {
  defaultHref?: string;
  title: string;
}

const Header: React.FC<Props> = props => {
  const { defaultHref, title } = props;

  return (
    <StyledIonHeader className="ion-no-border">
      <ExpirationWarning />

      <IonToolbar color="primary">
        <IonButtons slot="start">
          <MenuButton click defaultHref={defaultHref} />
        </IonButtons>

        {title && (
          <Title>
            <Label color="white" center size="sm" weight={500}>
              {title}
            </Label>
          </Title>
        )}
      </IonToolbar>
    </StyledIonHeader>
  );
};

const StyledIonHeader = styled(IonHeader)`
  ion-toolbar {
    --min-height: 4.5rem;
    --border-width: 0 !important;
  }
`;

const Title = styled.div`
  position: absolute;
  top: 28px;
  left: 4rem;
  right: 4rem;
  bottom: 0;

  background: var(--color-primary-light);
  border-top-left-radius: 999px;
  border-top-right-radius: 999px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Header;
