import React from 'react'
import { IonPage } from '@ionic/react'

import { CALCULATORS_CONFIG } from 'config/calculators'
import { useCalculator } from 'store/Calculator'
import Header from 'components/Header'

import ResultDetails from './components/ResultDetails'

const RefiDetailsPage = () => {
  const { options, result } = useCalculator()

  return (
    <IonPage>
      <Header defaultHref="/calculators/refi/result" title={CALCULATORS_CONFIG.refi.title} />

      <ResultDetails options={options} result={result} />
    </IonPage>
  )
}

export default RefiDetailsPage
