import React from 'react'
import { IonPage, IonContent } from '@ionic/react'

import List from 'components/List'
import Header from 'components/Header'
import ListItem from 'components/ListItem'

import { useContent } from 'store/Content'

const BlogPage = () => {
  const { content } = useContent()

  const items = content.blog || []

  return (
    <IonPage>
      <Header title="Mobile Blog" defaultHref="/content" />

      <IonContent>
        <List>
          {items.map(item => (
            <ListItem key={item.id} title={item.name} routerLink={`/content/blog/${item.id}`} />
          ))}
        </List>
      </IonContent>
    </IonPage>
  )
}

export default BlogPage
