import React from 'react'
import styled from 'styled-components'
import { IonSegment, IonSegmentButton } from '@ionic/react'

import Label from '../Label'

const StyledIonSegment = styled(IonSegment)`
  margin-bottom: 0;
`

const TYPES = [
  {
    value: 'fha',
    title: 'FHA',
  },
  {
    value: 'conventional',
    title: 'Conv',
  },
  {
    value: 'jumbo',
    title: 'Jumbo',
  },
  {
    value: 'va',
    title: 'VA',
  },
  {
    value: 'rural',
    title: 'USDA',
  },
]

const TypeSelect = ({ value, onChange }) => (
  <StyledIonSegment mode="ios" value={value} onIonChange={event => onChange(event.detail.value)}>
    {TYPES.map(({ value, title }) => (
      <IonSegmentButton key={value} value={value}>
        <Label color="gray" size="xs">
          {title}
        </Label>
      </IonSegmentButton>
    ))}
  </StyledIonSegment>
)

export default TypeSelect
