import Axios from 'axios'

/**
 * SSO Http client.
 *
 * @type {Axios}
 */
export const client = Axios.create({
  baseURL: `${process.env.REACT_APP_AM_URL}/api/v1/external`,
})

export const deleteAccount = () => client.delete('/account')
