import React from 'react'

import { LoanMilestone, TimelineItem } from 'types/Pos'

import { ReactComponent as CurrentIcon } from 'legacy-icons/loans/Notification/Waiting.svg'
import { ReactComponent as LockedIcon } from 'legacy-icons/loans/Notification/Completed/Gray.svg'
import { ReactComponent as PreviousIcon } from 'legacy-icons/loans/Notification/Success/NoStroke.svg'

interface Props {
  loanMilestone: LoanMilestone | TimelineItem
}

const MilestoneStatusIcon: React.FC<Props> = ({ loanMilestone }) => {
  if (loanMilestone.completed_at_date || loanMilestone.completed_at) {
    return <PreviousIcon />
  }

  if (loanMilestone.is_current || loanMilestone.in_progress) {
    return <CurrentIcon />
  }

  return <LockedIcon />
}

export default MilestoneStatusIcon
