import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';

import { CALCULATORS_CONFIG } from 'config/calculators';
import { formatCurrency } from 'utils/formatters';
import { getChartColors } from 'utils/theme';

import Label from 'components/Label';

import ResultChart from './ResultChart';

import { ReactComponent as ArrowLeftIcon } from 'icons/Icon-ArrowLeft-Naked.svg';
import { ReactComponent as ArrowRightIcon } from 'icons/Icon-ArrowRight-Naked.svg';

const { resultTitles } = CALCULATORS_CONFIG.purchase;

interface Props {
  result: any;
  onSlideLeft: () => void;
  onSlideRight: () => void;
}

const ResultContent: React.FC<Props> = ({ result, onSlideLeft, onSlideRight }) => {
  const [loaded, setLoaded] = useState(false);
  const chartColors = useMemo(getChartColors, []);

  useEffect(() => {
    /**
     * This is a workaround to render <Doughnut /> component after 100ms.
     * If we render this cmoponent as soon as the component is ready,
     * for some reason it does not play the  animation.
     */
    setTimeout(() => setLoaded(true), 100);
  }, []);

  return (
    <div className="ion-margin-vertical">
      <ChartContainer>
        <ChartTitleContainer>
          <Label block secondary color="gray">
            Your Payment
          </Label>

          <Label block size="xl" color="gray">
            {formatCurrency(result.total)}
          </Label>
        </ChartTitleContainer>

        {loaded && <ResultChart result={result} resultTitles={resultTitles} colors={chartColors} />}

        <ArrowLeftIcon className="arrow-left" onClick={onSlideLeft} />
        <ArrowRightIcon className="arrow-right" onClick={onSlideRight} />
      </ChartContainer>
    </div>
  );
};

const ChartContainer = styled.div`
  position: relative;
  height: 15rem;

  .arrow-left {
    position: absolute;
    left: 5px;
    top: calc(50% - 5px);
  }

  .arrow-right {
    position: absolute;
    right: 5px;
    top: calc(50% - 5px);
  }
`;

const ChartTitleContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default ResultContent;
