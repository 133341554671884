import Storage from 'store/Storage'

const LocalDocuments = () => ({
  initial: [],
  documents: [],

  async setup() {
    this.documents = await Storage.get('documents', [])
  },

  load() {
    return this.delayedDocuments(1000)
  },

  async upload(files, name) {
    this.documents.push(name)

    Storage.set('documents', this.documents)

    return this.delayedDocuments()
  },

  destroy(document) {
    this.documents = this.documents.filter(name => {
      return name !== document
    })

    Storage.set('documents', this.documents)

    return this.delayedDocuments(500)
  },

  delayedDocuments(sleep = 1500) {
    return new Promise(resolve => {
      setTimeout(() => resolve(this.documents), sleep)
    })
  },
})

export default LocalDocuments
