import React from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';

import ListItem from 'components/ListItem';
import { HistoryItem } from 'types/Documents';

interface Props {
  item: HistoryItem;
}

const HistoryListItem: React.FC<Props> = ({ item }) => (
  <ListItem title={title(item)}>{updatedAtForHumans(item.updated_at)}</ListItem>
);

const title = (item: HistoryItem) => {
  switch (item.event) {
    case 'DOC_ADDED':
      return `Document "${item.document.title}" added`;
    case 'DOC_REJECTED':
      return `Document "${item.document.title}" rejected`;
    case 'DOC_ACCEPTED':
      return `Document "${item.document.title}" accepted`;
    case 'DOC_COMMENT_UPDATED':
      return `Document "${item.document.title}" commented: ${item.comments}`;
    case 'REQUEST_ACCEPTED':
      return `All documents accepted`;
    case 'REQUEST_ADDED':
      return `Document requested`;
    case 'REQUEST_REJECTED':
      return `All documents rejected`;
    default:
      return `Unknown action`;
  }
};

const updatedAtForHumans = (updated_at: string): string => {
  return formatDistanceToNow(parseISO(updated_at)) + ' ago';
};

export default HistoryListItem;
