import React, { useState } from 'react'
import { Toast } from '@capacitor/toast'

import { uploadRequestedFiles } from 'api/documents'
import DocumentItem from 'pages/documents/components/DocumentItem'

import DocumentItemAction from './DocumentItemAction'
import DocumentItemDetails from './DocumentItemDetails'
import ProgressBar from 'components/ProgressBar'

const PosDocumentItem = ({ document, onUploaded, isConsumer = true, onReviewed }) => {
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)

  const upload = async files => {
    setUploading(true)

    await uploadRequestedFiles(
      {
        files,
        request_id: document.id,
      },
      setProgress
    )

    setUploading(false)

    Toast.show({ text: 'Files uploaded successfully.' })
    onUploaded()
  }

  return (
    <>
      {uploading && <ProgressBar progress={progress} title={`Uploading... (${progress}%)`} />}

      <DocumentItem
        title={document.name}
        document={document}
        isConsumer={isConsumer}
        action={<DocumentItemAction document={document} onFilesSelected={upload} />}
      >
        {document.status !== 'owed' && <DocumentItemDetails document={document} onReviewed={onReviewed} />}
      </DocumentItem>
    </>
  )
}

export default PosDocumentItem
