import React, { useEffect, useState } from 'react'
import { IonInput } from '@ionic/react'
import { formatCurrency, formatPercent } from '../../../utils/formatters'
import styled from 'styled-components'

const IonInputWrapper = styled.div`
  display: flex;
  gap: ${props => (props.ResultDisplay !== null ? '1.5rem' : 0)};

  ion-input {
    flex-grow: 1;
  }
`

const StyledIonInput = styled(IonInput)`
  input {
    border-top-right-radius: ${props => (props.AfterAction !== null ? '0 !important' : '0.5rem')};
    border-bottom-right-radius: ${props => (props.AfterAction !== null ? '0 !important' : '0.5rem')};
  }
`

const AfterActionWrapper = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
  margin-top: 0.5rem;
`

const InputColumnWrapper = styled.div`
  display: flex;
  ${props => {
    if (props.ResultDisplay === null) {
      return 'flex-grow: 1'
    }
  }}
`

const ResultColumnWrapper = styled.div`
  display: flex;
`

const FormattedInput = ({
  ref = null,
  onBlur = () => {},
  onIonBlur = () => {},
  onIonChange = () => {},
  onValChange,
  currency = false,
  percent = false,
  required = false,
  value,
  AfterAction = null,
  ResultDisplay = null,
  ...props
}) => {
  const [rawVal, setRawVal] = useState(value || '')
  const [isEditing, setIsEditing] = useState(false)

  const manageValChange = val => {
    setRawVal(val)
    setIsEditing(false)
    onValChange(currency ? parseFloat(val) : val)
  }

  useEffect(() => {
    setRawVal(value)
  }, [value])

  const prettyVal = () => {
    if (currency) {
      return formatCurrency(rawVal)
    }

    if (percent) {
      return formatPercent(rawVal)
    }

    return rawVal
  }

  return (
    <IonInputWrapper {...props}>
      <InputColumnWrapper ResultDisplay={ResultDisplay}>
        <StyledIonInput
          onIonBlur={e => [manageValChange(e.target.value), onIonBlur(e)]}
          onIonChange={onIonChange}
          onIonFocus={() => setIsEditing(true)}
          ref={ref}
          required={required}
          value={isEditing ? rawVal : prettyVal()}
          {...props}
        />
        {AfterAction !== null && (
          <AfterActionWrapper>
            <AfterAction />
          </AfterActionWrapper>
        )}
      </InputColumnWrapper>

      {ResultDisplay !== null && (
        <ResultColumnWrapper>
          <ResultDisplay />
        </ResultColumnWrapper>
      )}
    </IonInputWrapper>
  )
}

export default FormattedInput
