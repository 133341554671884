import React from 'react'

import PrivateRoute from 'layouts/auth/PrivateRoute'
import WelcomePage from 'pages/onboarding/Welcome'

const OnboardingRoutes = () => (
  <>
    <PrivateRoute exact path="/onboarding" component={WelcomePage} />
  </>
)

export default OnboardingRoutes
