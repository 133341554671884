import React from 'react'

import { useOnboarding } from 'store/Onboarding'

import Form from './components/Form'
import Layout from './components/Layout'
import FormInput from './components/FormInput'
import Button from './components/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ButtonWrapper from './components/ButtonWrapper'
const schema = yup.object().shape({
  phone: yup
    .string()
    .required('This field is required.')
    .test('phone', 'A valid phone number is required.', value => {
      return value.replace(/[^0-9.]+/g, '').length === 10
    }),
})

const RegisterPhonePage = ({ history }) => {
  const { setPhone } = useOnboarding()

  const { register, handleSubmit, errors, trigger, getValues } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = ({ phone }) => {
    setPhone(phone)

    history.push('/auth/register/password')
  }

  const handleClick = async () => {
    const result = await trigger()

    if (result) {
      onSubmit(getValues())
    }
  }

  const Footer = (
    <ButtonWrapper>
      <Button onClick={() => handleClick()}>Next</Button>
    </ButtonWrapper>
  )

  return (
    <Layout subtitle="Please provide your mobile number..." footer={Footer} backUrl="/auth/register/name">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Mobile Number"
          ref={register}
          error={errors.phone?.message}
          name="phone"
          type="tel"
          placeholder="Enter Your Mobile #"
        />
      </Form>
    </Layout>
  )
}

export default RegisterPhonePage
