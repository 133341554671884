import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Label from './Label';

interface Props {
  title?: string;
  value: number;
  max: number;
}

const range = (items: number): number[] => {
  let result = [];

  for (let i = 1; i <= items; i++) {
    result.push(i);
  }

  return result;
};

const Stepper: React.FC<Props> = ({ value, max, title }) => (
  <Wrapper>
    {title && (
      <Label block center size="xsplus" color="gray" weight={500} fontStyle="italic" style={{ marginBottom: '1.5rem' }}>
        {title}
      </Label>
    )}

    <ProgressBarWrapper>
      {range(max + 1).map(index => (
        <Tick
          style={{ '--width': (value / max) * 100 } as React.CSSProperties}
          key={index}
          className={classNames({
            first: index === 1,
            fill: index <= value,
            next: index > value,
          })}
        >
          {index >= value && index !== max + 1 && <div className="tick" />}
          {index === max + 1 && <div className="last" />}
        </Tick>
      ))}
    </ProgressBarWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 1rem 2rem 0 2rem;
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const Tick = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  height: 8px;
  flex-grow: 1;
  background: #d8dade;

  .tick {
    position: absolute;
    right: 0;
    top: -8px;

    width: 25px;
    height: 25px;
    border: 5px solid #ad1934;
    border-radius: 9999px;
    background: #d03555;
  }

  &.next {
    .tick {
      position: absolute;
      right: 0;
      top: -6px;

      width: 20px;
      height: 20px;
      border: 3px solid #d8dade;
      background: white;
    }
  }

  &.fill {
    background: #ad1934;
  }

  &.first {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .last {
    position: absolute;
    right: 0;
    top: -13px;

    width: 4px;
    height: 36px;
    background: #d8dade;
  }
`;

export default Stepper;
