import styled from 'styled-components/macro'

const TaskCard = styled.div`
  background: #ffffff;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  margin: 9px 0;
  padding: 1.2rem 1.5rem;
`

export default TaskCard
