import React from 'react'
import styled from 'styled-components'

import DocumentItem from '../components/DocumentItem'
import DocumentList from '../components/DocumentList'

import useDocuments from '../hooks/useDocuments'
import { ReactComponent as CloseIcon } from 'legacy-icons/close.svg'
import { IonContent, IonLoading, IonPage } from '@ionic/react'
import Header from 'components/Header'
import StartUploadingButton from '../components/StartUploadingButton'
import Placeholder from '../components/Placeholder'
import LocalDocuments from '../repositories/LocalDocuments'

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const IconWrapper = styled.div`
  align-self: center;
  margin-left: 1.25rem;
  color: red;

  &:first-child {
    margin-left: 0;
  }

  svg {
    fill: var(--color-gray-light);

    path {
      fill: var(--color-gray-light);
    }
  }
`

const LocalDocumentsPage = () => {
  const { loading, uploading, destroying, documents, upload, destroy } = useDocuments(LocalDocuments())

  return (
    <IonPage>
      <Header title="Documents" />

      <IonContent className="ion-padding">
        <IonLoading isOpen={uploading} message="Uploading..." />
        <IonLoading isOpen={destroying} message="Removing document..." />

        {loading ? (
          <IonLoading isOpen={loading} message="Loading..." />
        ) : documents.length ? (
          <>
            <DocumentList>
              {documents.map((document, index) => (
                <DocumentItem
                  key={index}
                  title={document}
                  action={() => (
                    <ActionWrapper>
                      <IconWrapper onClick={() => destroy(document)}>
                        <CloseIcon />
                      </IconWrapper>
                    </ActionWrapper>
                  )}
                />
              ))}
            </DocumentList>
            <StartUploadingButton uploaded={1} onFilesSelected={upload} />
          </>
        ) : (
          <Placeholder onFilesSelected={upload} />
        )}
      </IonContent>
    </IonPage>
  )
}

export default LocalDocumentsPage
