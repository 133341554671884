import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useMutation } from 'react-query';
import { IonContent, IonPage, IonSpinner } from '@ionic/react';

import Label from 'components/Label';
import AuthButton from './components/Button';
import FormInput from './components/FormInput';
import ModalContent from 'components/ModalContent';
import ResendActivationCodeButton from './components/ResendActivationCodeButton';

import useModal from 'hooks/useModal';
import useAlert from 'hooks/useAlert';

import { sendPhoneVerificationNotification, verifyPhone } from 'api/sso';
import UpdatePhoneNumberPage from '../../layouts/auth/UpdatePhoneNumberPage';
import styled from 'styled-components';
import { useAuth } from '../../store/Auth';
import { useApplication } from '../../store/Application';
import ApplicationLogo from '../../components/ApplicationLogo';

interface Props {
  //
}

const VerifyPhonePage: React.FC<Props> = () => {
  const { isDefault } = useApplication();
  const { user, setUser } = useAuth();
  const alert = useAlert();

  const [code, setCode] = useState('');

  const mutation = useMutation(async () => {
    return await verifyPhone(code);
  });

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate();
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      user?.is_consumer && setUser({ ...user, is_verified: true });
    }

    if (mutation.isError) {
      alert.error('Invalid verification code.');
    }
  }, [mutation.isSuccess, mutation.isError]);

  const [present, dismiss] = useModal(ChangePhoneModal, {
    isIdle: mutation.isIdle,
    isLoading: mutation.isLoading,
    onChange: async phone => {
      try {
        await sendPhoneVerificationNotification({ phone });
      } catch (error) {
        //
      }

      alert.success('Verification code successfully sent to the new phone number');
      user && setUser({ ...user, phone });
      dismiss();
    },
  });

  if (!user) {
    return <Redirect to="/auth/login" />;
  }

  if (!user.phone) {
    return <UpdatePhoneNumberPage />;
  }

  return (
    <IonPage>
      <LogoHeader isDefault={isDefault}>
        <ApplicationLogo />
      </LogoHeader>

      <IonContent>
        <Wrapper>
          <VerifyLabel block>Verify your account...</VerifyLabel>
          <OneTimeLabel block>
            A one-time password was sent to <br />
            {user.phone}
          </OneTimeLabel>
          <Label
            fontStyle="italic"
            size="xsplus"
            center
            block
            color="blue"
            onClick={present}
            style={{ marginTop: '8px' }}
          >
            Change Phone Number
          </Label>
          <VerifyForm onSubmit={submit}>
            <FormInput
              type="number"
              min={100000}
              max={999999}
              label="Enter Verification Code"
              placeholder="Enter Verification Code"
              value={code}
              onChange={e => setCode(e.target.value)}
            />

            <AuthButton margin="1rem 0 !important">
              {mutation.isLoading ? <IonSpinner name="dots" /> : 'Verify Me'}
            </AuthButton>
          </VerifyForm>

          <ResentWrapper>
            <Label size="sm" center block color="gray" isWrapped className="resend-code-label">
              <span style={{ paddingRight: '.25rem' }}>Didn&apos;t receive code?</span>
              <ResendActivationCodeButton
                onSent={() => alert.success('The new code was successfully sent.')}
                render={({ status, send }) => (
                  <>
                    {status === 'sending' && (
                      <IonSpinner style={{ display: 'block', margin: '0px auto' }} name="dots" />
                    )}
                    {status === 'sent' && (
                      <Label block color="blue" fontStyle="italic" isWrapped>
                        Code resent successfully
                      </Label>
                    )}
                    {status === 'ready' && (
                      <Label weight={300} secondary color="blue" fontStyle="italic" onClick={send} size="sm">
                        Resend Code
                      </Label>
                    )}
                  </>
                )}
              />
            </Label>
          </ResentWrapper>
        </Wrapper>
      </IonContent>
    </IonPage>
  );
};

interface ChangePhoneModalProps {
  isIdle: boolean;
  isLoading: boolean;
  onChange: (phone: string) => void;
}

const ChangePhoneModal: React.FC<ChangePhoneModalProps> = ({ isIdle, isLoading, onChange }) => {
  const [phone, setPhone] = useState('');

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onChange(phone);
  };

  return (
    <ModalContent title="Want to change your number?" className="ion-padding">
      <Label size="xs" isWrapped center>
        During the application intake we’ll build your borrower profile with basic info like your name, address and
        social security number.
      </Label>
      <br />
      <br />
      <Label size="xs" isWrapped center>
        We’ll also collect details about your assets, income and real estate owned. Call or message us with any
        questions. We’re here to help.
      </Label>
      <br />
      <br />
      <form onSubmit={submit}>
        <FormInput
          type="tel"
          label="Phone Number"
          placeholder="Enter Phone Number"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />

        <AuthButton>{isLoading ? <IonSpinner name="dots" /> : 'Submit New Number'}</AuthButton>
      </form>
    </ModalContent>
  );
};

const LogoHeader = styled.div<{ isDefault: boolean }>`
  position: relative;
  background: #fff;
  padding: ${props => (props.isDefault ? '1rem 0 0.5rem' : '1rem 0')};
  text-align: center;
  border-bottom-left-radius: 50% 1.5rem;
  border-bottom-right-radius: 50% 1.5rem;
  border-bottom: 2px solid var(--color-gray-lightest);

  .logo {
    height: 5.5rem;
  }
`;

const Wrapper = styled.div`
  padding: 49px 18px 0 15px;
`;

const VerifyLabel = styled(Label)`
  font-size: 27px !important;
  font-weight: 400;
  font-style: italic;
  font-family: var(--font-secondary);
  color: #3a455f !important;
  text-align: center;
`;

const OneTimeLabel = styled(Label)`
  font-weight: 400;
  font-size: 18px !important;
  font-style: italic;
  text-align: center;
  margin-top: 10px;
  color: #3a455f !important;
  line-height: 30px !important;
`;

const VerifyForm = styled.form`
  margin: 49px 14px 27px;
`;

const ResentWrapper = styled.div``;

export default VerifyPhonePage;
