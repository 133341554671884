import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Bar } from 'react-chartjs-2'

import { formatCurrency } from 'utils/formatters'

const CURRENT_YEAR = new Date().getFullYear()

const ChartContainer = styled.div`
  position: relative;
  height: 300px;
`

const ResultAmortisationChart = ({ propertyPrice, amortisation }) => {
  const chartOptions = useMemo(
    () => ({
      tooltips: {
        callbacks: {
          label: (item, data) => {
            const value = formatCurrency(item.value)

            return `${data.datasets[item.datasetIndex].label}: ${value}`
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
      borderDash: [1],
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            id: 'balance',
            gridLines: {
              display: false,
              drawTicks: false,
            },
            ticks: {
              beginAtZero: true,
              suggestedMax: propertyPrice,
              callback: value => formatCurrency(value, 0),
              padding: 5,
            },
          },
          {
            id: 'amortisation',
            position: 'right',
            stacked: true,
            gridLines: {
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              beginAtZero: true,
              callback: value => formatCurrency(value, 0),
              padding: 5,
            },
          },
        ],
      },
    }),
    [propertyPrice]
  )

  const chartData = useMemo(() => {
    const annualAmortisation = amortisation.filter((_, index) => index % 12 === 0)
    const labels = annualAmortisation.map((_, index) => CURRENT_YEAR + index)
    const datasets = [
      {
        type: 'line',
        label: 'Balance',
        backgroundColor: 'transparent',
        borderColor: '#D05273',
        data: annualAmortisation.map(item => item.balance),
        yAxisID: 'balance',
      },
      {
        label: 'Principal',
        backgroundColor: '#15AF85',
        data: annualAmortisation.map(item => item.principal),
        yAxisID: 'amortisation',
      },
      {
        label: 'Interest',
        backgroundColor: '#2AC7F2',
        data: annualAmortisation.map(item => item.interest),
        yAxisID: 'amortisation',
      },
      {
        label: 'Tax, Insurance, PMI',
        backgroundColor: '#EAC826',
        data: annualAmortisation.map(item => item.taxesAndFees),
        yAxisID: 'amortisation',
      },
    ]

    return {
      labels,
      datasets,
    }
  }, [amortisation])

  return (
    <ChartContainer>
      <Bar options={chartOptions} data={chartData} />
    </ChartContainer>
  )
}

export default ResultAmortisationChart
