import React from 'react'
import styled from 'styled-components'
import { IonIcon, IonItem } from '@ionic/react'
import { alertCircle, chevronForward } from 'ionicons/icons'

import Label from './Label'
import { useAuth } from 'store/Auth'
import { useApplication } from 'store/Application'

const ExpirationWarning: React.FC = () => {
  const { owner } = useApplication()
  const { isConsumer } = useAuth() as any

  if (!owner || owner.is_active === true) {
    return null
  }

  return (
    <StyledIonItem lines="none" color="warning" href="https://www.loanzify.com/pricing/">
      <IonIcon slot="start" color="danger" icon={alertCircle} />
      <Label center weight={700}>
        {isConsumer ? 'The' : 'Your'} app is expired <br />
        Fix this
      </Label>
      <IonIcon slot="end" icon={chevronForward} />
    </StyledIonItem>
  )
}

const StyledIonItem = styled(IonItem)`
  position: relative;
  display: flex;
  align-items: center;

  min-height: 100px;
  background-color: var(--ion-color-warning);

  ion-label {
    margin-left: 0.5rem;
  }

  ion-icon {
    font-size: 50px;
  }
`

export default ExpirationWarning
