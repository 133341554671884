export const appends = loan => {
  loan.helper_data = {}

  try {
    const jsonData = JSON.parse(loan.lhp_forms_data || '{}')
    loan.helper_data = {
      is_purchase: !!jsonData.is_purchasing_new_property,
      is_refinance: !jsonData.is_purchasing_new_property,
      property_use: jsonData.property_use,
    }
  } catch (e) {}

  return loan
}
