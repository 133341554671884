import React from 'react';
import LoanTeamRoute from '../layouts/auth/LoanTeamRoute';

import Documents from 'pages/leads/pipeline/sections/Documents';
import Application from 'pages/leads/pipeline/sections/Application';
import CreditReport from 'pages/leads/pipeline/sections/CreditReport';
import PreApproval from 'pages/leads/pipeline/sections/PreApproval';
import Integrations from 'pages/leads/pipeline/sections/Integrations';
import Export from 'pages/leads/pipeline/sections/Export';
import Notes from 'pages/leads/pipeline/sections/Notes';

import Overview from '../pages/leads/pipeline/sections/Overview';

const LoanTabRoutes = ({ isPartner, isUser, loan, path, onTabChange, reload }) => {
  // eslint-disable-next-line react/display-name
  const injectPageProps = Component => props => {
    const mergedProps = { ...props, isPartner, isUser, loan, path, reload };
    return <Component {...mergedProps} />;
  };

  return (
    <>
      <LoanTeamRoute
        exact
        path={`${path}/overview`}
        loan={loan}
        render={injectPageProps(Overview)}
        onTabChange={onTabChange}
        reload={reload}
      />
      <LoanTeamRoute
        exclude={'Partner'}
        exact
        path={`${path}/application`}
        loan={loan}
        render={injectPageProps(Application)}
      />
      <LoanTeamRoute
        exclude={'Partner'}
        exact
        path={`${path}/documents`}
        loan={loan}
        render={injectPageProps(Documents)}
      />
      <LoanTeamRoute
        exclude={'Partner'}
        exact
        path={`${path}/credit-report`}
        loan={loan}
        render={injectPageProps(CreditReport)}
      />
      <LoanTeamRoute exact path={`${path}/pre-approval`} loan={loan} render={injectPageProps(PreApproval)} />
      <LoanTeamRoute
        exclude={'Partner'}
        exact
        path={`${path}/integrations`}
        loan={loan}
        render={injectPageProps(Integrations)}
      />
      <LoanTeamRoute exclude={'Partner'} exact path={`${path}/export`} loan={loan} render={injectPageProps(Export)} />
      <LoanTeamRoute exclude={'Partner'} exact path={`${path}/notes`} loan={loan} render={injectPageProps(Notes)} />
      {/*<LoanTeamRoute*/}
      {/*  exclude={'Partner'}*/}
      {/*  exact*/}
      {/*  path={`${path}/loan-team`}*/}
      {/*  loan={loan}*/}
      {/*  render={injectPageProps(LoanTeam)}*/}
      {/*/>*/}
    </>
  );
};

export default LoanTabRoutes;
