import React from 'react';
import styled from 'styled-components/macro';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';

import { TextNotification } from 'types/Loanzify';

import Label from 'components/Label';
import AvatarInitials from 'components/AvatarInitials';
import { useHistory } from 'react-router-dom';

interface Props {
  notification: TextNotification;
  isHighlighted: boolean;
}

const NotificationListItem: React.FC<Props> = ({ notification, isHighlighted }) => {
  const history = useHistory();

  const navigateIfRouterLink = () => {
    if (notification.data.routerLink) {
      history.push(notification.data.routerLink);
    }

    return;
  };

  return (
    <Container
      style={{ background: isHighlighted ? '#FFFFFF' : 'var(--color-background)' }}
      onClick={navigateIfRouterLink}
    >
      {notification.data.avatarInitials && (
        <LogoContainer>
          {notification.data.avatarInitials && <AvatarInitials name={notification.data.avatarInitials} size="large" />}
        </LogoContainer>
      )}

      <ContentContainer>
        <DataContainer>
          {notification.data.title && (
            <Label color="gray" weight={600} isWrapped>
              {notification.data.title}
            </Label>
          )}

          <Label secondary color="gray" size="sm" weight={400} isWrapped>
            {notification.data.text}
          </Label>
        </DataContainer>
        <DateContainer>
          <Label secondary color="gray-light" size="2xs" weight={400} isWrapped>
            {formatDistanceToNowStrict(parseISO(notification.created_at))}
          </Label>
        </DateContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 15px 10px;
  min-height: 74px;
`;

const ContentContainer = styled.div`
  flex: 1 1 0%;
  display: flex;
  flex-direction: row;
`;

const LogoContainer = styled.div`
  padding-right: 1rem;
`;

const DataContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: auto;
`;

const DateContainer = styled.div`
  flex: 1 0 auto;
`;

export default NotificationListItem;
