import { sendMagicLink } from 'api/loanzify'
import { useState } from 'react'
import { useHistory } from 'react-router'

interface Options {
  email: string
  broker: 'users' | 'consumers'
}

type Result = [() => void, boolean]

export const useMagicLinkSender = ({ email, broker }: Options): Result => {
  const history = useHistory()
  const [isSendingMagicLink, setIsSendingMagicLink] = useState(false)

  const sendLink = async () => {
    setIsSendingMagicLink(true)

    try {
      await sendMagicLink({ email, broker })
    } finally {
      setIsSendingMagicLink(false)
    }

    history.push('/auth/magic-link-sent')
  }

  return [sendLink, isSendingMagicLink]
}
