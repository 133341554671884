import React from 'react';
import CreditReportContainer from './CreditReportContainer';
import CreditBureauScore from './CreditBureauScore';
import ExperianLogo from '../legacy-icons/loans/Experian_Logo.png';
import EquifaxLogo from '../legacy-icons/loans/Equifax_Logo.png';
import TransUnionLogo from '../legacy-icons/loans/TransUnion_Logo.png';
import { CreditScore } from '../types/Credit';

type IProps = {
  creditScores: {
    experian?: CreditScore;
    equifax?: CreditScore;
    transunion?: CreditScore;
  };
};

const ThreeBureauReport = (props: IProps) => {
  const { creditScores } = props;

  return (
    <CreditReportContainer>
      <>
        {creditScores.experian?.score && <CreditBureauScore score={creditScores.experian} imageSrc={ExperianLogo} />}

        {creditScores.equifax?.score && <CreditBureauScore score={creditScores.equifax} imageSrc={EquifaxLogo} />}

        {creditScores.transunion?.score && (
          <CreditBureauScore score={creditScores.transunion} imageSrc={TransUnionLogo} />
        )}
      </>
    </CreditReportContainer>
  );
};

export default ThreeBureauReport;
