import React from 'react'
import { IonCheckbox, IonItem } from '@ionic/react'

import Label from './Label'
import styled from 'styled-components'
import AccordionItem from './AccordionItem'

const CheckboxContainer = styled(IonItem)`
  --background-activated: #fff;

  display: flex;
  align-items: center;

  ion-checkbox {
    margin-right: 1rem;
  }
`

const AccordionChecklistItem = ({ onChecked, items, ...props }) => (
  <AccordionItem {...props}>
    {items.map(({ title, checked }) => (
      <CheckboxContainer key={title}>
        <IonCheckbox checked={checked} onClick={onChecked} />
        <Label isWrapped>{title}</Label>
      </CheckboxContainer>
    ))}
  </AccordionItem>
)

export default AccordionChecklistItem
