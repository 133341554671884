import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IonCard, IonCardContent } from '@ionic/react'
import numeral from 'numeral'

import { ReactComponent as PhoneIcon } from 'legacy-icons/phone.svg'
import { ReactComponent as EmailIcon } from 'legacy-icons/email.svg'
import { ReactComponent as MessageIcon } from 'legacy-icons/messages.svg'

import Label from '../Label'
import LoanMilestone from './LoanMilestone'
import AvatarInitials from 'components/AvatarInitials'
import { getInstallationByConsumerService } from '../../api/loanzify'
import Button from '../Button'
import { useAuth } from '../../store/Auth'

const StyledActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LoanCard = ({ loan, showForward = true, showActions = false, style, onTabChange }) => {
  const isPurchase = loan.type === 'purchase'
  const { has_credit_report } = loan
  const [install, setInstall] = useState(null)
  const { isLoanOfficer } = useAuth()

  const loanzifyConsumerServiceId = loan.borrower.sso_consumer_service_id

  useEffect(() => {
    let mounted = true

    const fetchLoanzifyConsumerService = async () => {
      if (loanzifyConsumerServiceId) {
        const install = await getInstallationByConsumerService(loanzifyConsumerServiceId).catch(error => {
          if (error?.response?.status === 404) {
            return false
          }

          throw error
        })

        if (install.sso_consumer_id === loan.borrower.sso_consumer_id) {
          setInstall(install)
        }
      }
    }

    if (mounted) {
      fetchLoanzifyConsumerService()
    }

    return () => {
      mounted = false
    }
  }, [loanzifyConsumerServiceId])

  return (
    <StyledIonCard style={style ? style : {}}>
      <StyledIonCardContent>
        <AvatarContainer>
          <AvatarInitials name={loan.borrower.full_name} size="large" confirmed={install} />
        </AvatarContainer>

        <FullNameContainer>
          <Label color="primary" size="lg">
            {loan.borrower.full_name}
          </Label>
        </FullNameContainer>

        <ContactContainer>
          <IconContainer>
            {loan.borrower.email ? (
              <a className="icon-item" href={`mailto:${loan.borrower.email}`}>
                <EmailIcon />
              </a>
            ) : (
              <div className="icon-item">
                <EmailIcon className="disabled" />
              </div>
            )}

            {loan.borrower.tel ? (
              <a className="icon-item" href={`sms:${loan.borrower.tel}`}>
                <MessageIcon />
              </a>
            ) : (
              <div className="icon-item">
                <MessageIcon className="disabled" />
              </div>
            )}

            {loan.borrower.tel ? (
              <a className="icon-item" href={`tel:${loan.borrower.tel}`}>
                <PhoneIcon />
              </a>
            ) : (
              <div className="icon-item">
                <PhoneIcon className="disabled" />
              </div>
            )}
          </IconContainer>
        </ContactContainer>

        <Divider />

        <Footer>
          {loan.loan_amount && (
            <FooterItem>
              <Label secondary size="xs" color="black" weight={600}>
                Loan Purpose
              </Label>

              <Label secondary size="xs" color="gray-light" weight={600}>
                {isPurchase ? 'Purchase' : 'Refinance'} / {loan.loan_amount && numeral(loan.loan_amount).format('$0,0')}
              </Label>
            </FooterItem>
          )}
          {loan.current_milestone && loan.current_milestone.sequence >= 4 ? (
            <StyledActionButtonContainer>
              <Button
                size="sm"
                color="secondary"
                onClick={() => {
                  onTabChange({
                    detail: {
                      value: 'pre-approval',
                    },
                  })
                }}
              >
                Pre-Approval Letter
              </Button>
              {isLoanOfficer && (
                <Button
                  size="sm"
                  color="secondary"
                  onClick={() => {
                    onTabChange({
                      detail: {
                        value: 'credit-report',
                      },
                    })
                  }}
                >
                  {has_credit_report ? 'Credit Report' : 'Pull Credit'}
                </Button>
              )}
            </StyledActionButtonContainer>
          ) : (
            <FooterItem>
              <Label secondary size="xs" color="black" weight={700}>
                {' '}
                Loan Status{' '}
              </Label>
              <LoanMilestone loan={loan} display="inline-block" onTabChange={onTabChange} />
            </FooterItem>
          )}
        </Footer>
      </StyledIonCardContent>
    </StyledIonCard>
  )
}

const StyledIonCard = styled(IonCard)`
  --background: #fff;
`

const StyledIonCardContent = styled(IonCardContent)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: repeat(5, auto);
  grid-template-areas:
    'avatar full-name purchase-refinance'
    'avatar contact-container empty'
    'divider divider divider'
    'footer footer footer'
    'footer footer footer';
  align-items: center;
  column-gap: 0.5rem;

  padding: 1rem;
`

const AvatarContainer = styled.div`
  grid-area: avatar;
  align-self: start;
`

const FullNameContainer = styled.div`
  grid-area: full-name;
  padding-left: 0.5rem;
`

const Divider = styled.div`
  grid-area: divider;
  height: 0.25rem;
`

const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;

  margin-top: 10px;

  svg.disabled {
    opacity: 0.2;
  }

  .icon-item {
    flex: 1 1 0%;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: 1px;
    border-right: 1px solid var(--color-gray-lighter);

    &:last-child {
      border-right: none;
    }

    &:first-child {
      padding-left: 0;
    }

    svg {
      width: 45px;
      height: 45px;
      color: var(--color-gray);
    }
  }
`

const ContactContainer = styled.div`
  grid-area: contact-container;
`

const Footer = styled.div`
  grid-area: footer;
  display: grid;
  grid-template-rows: repeat(1fr);
`

const FooterItem = styled.div`
  display: grid;
  grid-template-columns: 100px auto;

  &:not(:first-child) {
    margin-top: 4px;
  }
`

export default LoanCard
