import React, { useEffect, useState } from 'react'
import { IonLoading } from '@ionic/react'
import { Capacitor } from '@capacitor/core'
import { Deploy } from 'cordova-plugin-ionic'

import UpdateInProgress from 'components/UpdateInProgress'

const LiveUpdateProvider: React.FC = ({ children }) => {
  const [status, setStatus] = useState<'loading' | 'updating' | 'completed'>('loading')
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      setStatus('completed')

      return
    }

    setProgress(100)
    setStatus('completed')
  }, [])

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return
    }

    const deletePreviousVersions = async () => {
      const currentVersion = await Deploy.getCurrentVersion()

      if (!currentVersion) {
        return
      }

      const versions = await Deploy.getAvailableVersions()

      versions.forEach(version => {
        if (version.versionId !== currentVersion.versionId) {
          Deploy.deleteVersionById(version.versionId)
        }
      })
    }

    deletePreviousVersions()
  }, [])

  if (status === 'loading') {
    return <IonLoading isOpen={true} message="Checking for updates..." />
  }

  if (status === 'updating') {
    return <UpdateInProgress progress={progress} />
  }

  return children as React.ReactElement
}

export default LiveUpdateProvider
