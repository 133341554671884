import * as React from 'react'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'

import { format } from 'date-fns'

import { Calendar, DayValue } from 'react-modern-calendar-datepicker'
import styled from 'styled-components/macro'
import Button from './Button'
import { useEffect, useState } from 'react'

export type DateTimePickerModalProps = {
  value: DayValue
  close: (reset?: boolean) => void
  onChange?: Function
}

const DateTimePickerModal: React.FC<DateTimePickerModalProps> = props => {
  const { close, value, onChange } = props

  const [pickerValue, setPickerValue] = useState<string | null>(null)

  const toFormat = () => {
    return 'yyyy/MM/dd'
  }

  const setSelectedValue = (value: DayValue) => {
    if (value && typeof onChange === 'function') {
      setPickerValue(format(new Date(`${value.year}/${value.month}/${value.day}`), toFormat()))
    }
  }

  const finish = () => {
    if (value && onChange) {
      onChange(pickerValue)
    }

    close()
  }

  useEffect(() => {
    if (value) {
      console.log(value)
    }
  }, [value])

  return (
    <StyledCalendarWrapper>
      <Calendar onChange={setSelectedValue} value={value} />

      <StyledActionButtonWrapper>
        <Button size="md" color="gray" fill="outline" onClick={close}>
          {' '}
          Cancel{' '}
        </Button>
        <Button size="md" color="secondary" disabled={!pickerValue} onClick={finish}>
          {' '}
          Done{' '}
        </Button>
      </StyledActionButtonWrapper>
    </StyledCalendarWrapper>
  )
}

const StyledCalendarWrapper = styled.div`
  margin: 1rem auto 0;
`

const StyledActionButtonWrapper = styled.div`
  padding: 1.25rem 0.675rem;
  display: flex;
  justify-content: space-between;
`

export default DateTimePickerModal
