import * as React from 'react'
import { IonIcon } from '@ionic/react'
import Label from './Label'
import styled from 'styled-components'

interface Props {
  icon: string | boolean
  message: string | JSX.Element
  color?: string
}

const NoDataLoaded: React.FC<Props> = props => {
  const { children, color, icon, message } = props

  return (
    <NoDataContainer>
      <IconContainer>
        {typeof icon === 'string' && <IonIcon src={icon} />}
        {icon === false && <React.Fragment>{children}</React.Fragment>}
      </IconContainer>
      <LabelContainer>
        {typeof message === 'string' ? (
          <Label color={color ? color : 'gray-light'} fontStyle="italic">
            {message}
          </Label>
        ) : (
          <div>{message}</div>
        )}
      </LabelContainer>
    </NoDataContainer>
  )
}

const NoDataContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

const IconContainer = styled.div`
  background: #fff;
  border-radius: 9999px;
  display: inline-block;
  margin: 1rem auto 3rem;
  padding: 1rem;

  ion-icon {
    font-size: 10em;
  }
`

const LabelContainer = styled.div`
  margin: 0 42px;

  ion-label {
    white-space: initial;
  }
`

export default NoDataLoaded
