import React from 'react'
import styled from 'styled-components'

import Label from 'components/Label'
import FormattedInput from './FormattedInput'

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ion-input {
    input,
    > input,
    > input.native-input,
    .native-input {
      padding: 1rem 0.5rem;
      margin-bottom: 0.75rem;
      margin-top: 0.5rem;
      background: #fff;
      color: var(--color-gray);
      border: 1px solid var(--color-gray-lightest);
      border-radius: 0.5rem;

      font-family: var(--font-secondary);
      font-style: normal;
      font-weight: normal;
      padding-left: 0.75rem;

      &::placeholder {
        font-style: italic;
      }
    }
  }
`

const StyledLabel = styled(Label)`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`

const StyledError = styled(Label)`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 0.25rem;
`

const FormInput = ({
  label,
  ref = null,
  onIonChange = () => {},
  onValChange = () => {},
  onIonBlur = () => {},
  error = null,
  currency = false,
  percent = false,
  required = false,
  value,
  ...props
}) => {
  return (
    <InputWrapper>
      <StyledLabel weight="bold" secondary color="gray" size="xs">
        {label} {required && <>*</>}
      </StyledLabel>
      <FormattedInput
        onIonBlur={onIonBlur}
        onIonChange={onIonChange}
        onValChange={fmv => onValChange(fmv)}
        value={value}
        currency={currency}
        percent={percent}
        required={required}
        ref={ref}
        {...props}
      />
      {error && (
        <StyledError color="error" size="2xs">
          {error}
        </StyledError>
      )}
    </InputWrapper>
  )
}

export default FormInput
