import React from 'react'
import styled from 'styled-components'
import { IonTextarea } from '@ionic/react'
import Label from 'components/Label'

const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.675rem;
`

const StyledTextarea = styled(IonTextarea)`
  border: 1px solid var(--color-gray-lightest);
  border-radius: 7px;
  min-height: 140px;
  padding: 0.75rem;

  textarea,
  .native-textarea {
    padding: 0 !important;
  }
`

const StyledLabel = styled(Label)`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`

const StyledError = styled(Label)`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 0.25rem;
`

const TextField = ({ label, onValChange, error = null, required = false, value, ...props }) => {
  return (
    <TextFieldWrapper>
      <StyledLabel weight="bold" secondary color="gray" size="xs">
        {label} {required && <>*</>}
      </StyledLabel>
      <StyledTextarea onValChange={fmv => onValChange(fmv)} value={value} required={required} {...props} />
      {error && (
        <StyledError color="error" size="2xs">
          {error}
        </StyledError>
      )}
    </TextFieldWrapper>
  )
}

export default TextField
