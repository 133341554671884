import React from 'react'
import styled from 'styled-components'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { useApplication } from '../store/Application'

import { ReactComponent as PlusIcon } from 'legacy-icons/plus-partners.svg'
import { ReactComponent as ArrowLeftIcon } from 'legacy-icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from 'legacy-icons/arrow-right.svg'

import { useHistory } from 'react-router-dom'
import { BRANDING_DUAL_ME_PARTNER, BRANDING_DUAL_PARTNER_ME, BRANDING_PARTNER_ONLY } from '../utils/general'

const TopCurve = styled.div`
  height: 1rem;
  background: white;
  border-bottom-left-radius: 50% 1rem;
  border-bottom-right-radius: 50% 1rem;

  box-sizing: content-box;
  margin: 0 -1rem;
  padding: 0 1rem;
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`
const ContentCurve = styled.div<{ isDefault?: boolean }>`
  ${props =>
    props.isDefault
      ? `
    background: linear-gradient(332.19deg,
      #D03555 31.64%,
      #D03555 32.38%,
      #E21B52 63.68%
    );
  `
      : `
    background: var(--color-primary);
  `}
  border-bottom-left-radius: 50% 1rem;
  border-bottom-right-radius: 50% 1rem;
  --ion-grid-column-padding: 0.5rem;
  height: 210px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`
const StyledIonGrid = styled(IonGrid)`
  padding: 0;
  position: relative;
  height: 180px;
`

const StyledIonRow = styled(IonRow)`
  padding: 0 1rem;
  position: relative;
`

const StyledIonCol = styled(IonCol)`
  margin-top: 28px;
`

const ProfileImageRounded = styled.div<{ url: string }>`
  height: 65px;
  width: 65px;
  border-radius: 50%;

  background: url('${props => props.url}') var(--color-gray-lighter);
  background-repeat: no-repeat;
  background-size: cover;

  border: 3px solid var(--color-primary-lighter);

  margin: 0 auto 1rem auto;
`

const ProfileWrapper = styled.div`
  text-align: center;
`

const ProfileName = styled.h5`
  margin: 0;
  color: var(--ion-color-primary-contrast);
`

const ProfileTitle = styled.p`
  font-size: 0.75rem;
  font-family: var(--font-secondary);
  font-weight: bold;
  margin-top: 2px;
  margin-bottom: 0;
  color: var(--ion-color-primary-contrast);
`

const ProfileLicense = styled.p`
  font-size: 0.75rem;
  font-family: var(--font-secondary);
  font-weight: light;
  margin-top: 2px;
  margin-bottom: 0;
  color: var(--ion-color-primary-contrast);
`

const LoanOfficerClickArea = styled.div`
  position: absolute;
  left: 0.75rem;
  top: 0;
  bottom: 0;
  width: 35px;
  opacity: 0.75;

  svg {
    margin: auto 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
`

const PartnerClickArea = styled.div`
  position: absolute;
  right: 0.75rem;
  top: 0;
  bottom: 0;
  width: 35px;
  opacity: 0.75;

  svg {
    margin: auto 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
`

const StyledPlusWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;

  svg {
    margin-top: 46px;

    circle {
      fill: var(--color-primary-lighter);
    }

    path {
      fill: var(--color-primary-lighter);
    }
  }
`

const ProfilePartnerCoBranding = () => {
  const {
    nmls,
    license,
    application: { user1, user2, dual_branding },
  } = useApplication()

  console.log('dual_branding')
  console.log(dual_branding)

  const history = useHistory()

  const goLoanOfficer = () => {
    history.push('/home/profile/loan-officer')
  }

  const goPartner = () => {
    history.push('/home/profile/partner')
  }

  const LoanOfficerWrapper = () => (
    <ProfileWrapper onClick={() => goLoanOfficer()}>
      <ProfileImageRounded url={user1.photo_url || 'https://loanzify.com/public/images/avatar_default.jpg'} />
      <ProfileName>
        {user1.first_name} {user1.last_name}
      </ProfileName>
      <ProfileTitle>{user1.title}</ProfileTitle>
      <ProfileLicense>NMLS: {user1.nmls}</ProfileLicense>
    </ProfileWrapper>
  )

  const PartnerWrapper = () => (
    <ProfileWrapper onClick={() => goPartner()}>
      <ProfileImageRounded url={user2?.photo_url || 'https://loanzify.com/public/images/avatar_default.jpg'} />
      <ProfileName>
        {user2?.first_name} {user2?.last_name}
      </ProfileName>
      <ProfileTitle>{user2?.title}</ProfileTitle>
      <ProfileLicense>{license ? `License: ${license}` : nmls ? `NMLS: #${nmls}` : ''}</ProfileLicense>
    </ProfileWrapper>
  )

  return (
    <StyledIonGrid>
      <TopCurve />
      <ContentCurve />

      {dual_branding === BRANDING_PARTNER_ONLY && (
        <StyledIonRow>
          <StyledIonCol size={'12'}>{PartnerWrapper()}</StyledIonCol>
        </StyledIonRow>
      )}

      {dual_branding === BRANDING_DUAL_PARTNER_ME && (
        <StyledIonRow>
          <StyledPlusWrapper>
            <PlusIcon />
          </StyledPlusWrapper>
          <LoanOfficerClickArea onClick={() => goPartner()}>
            <ArrowLeftIcon fill="#FFF" />
          </LoanOfficerClickArea>

          <StyledIonCol size={'6'}>{PartnerWrapper()}</StyledIonCol>

          <StyledIonCol size={'6'}>{LoanOfficerWrapper()}</StyledIonCol>

          <PartnerClickArea onClick={() => goLoanOfficer()}>
            <ArrowRightIcon fill="#FFF" />
          </PartnerClickArea>
        </StyledIonRow>
      )}

      {dual_branding === BRANDING_DUAL_ME_PARTNER && (
        <StyledIonRow>
          <StyledPlusWrapper>
            <PlusIcon />
          </StyledPlusWrapper>
          <LoanOfficerClickArea onClick={() => goLoanOfficer()}>
            <ArrowLeftIcon fill="#FFF" />
          </LoanOfficerClickArea>

          <StyledIonCol size={'6'}>{LoanOfficerWrapper()}</StyledIonCol>

          <StyledIonCol size={'6'}>{PartnerWrapper()}</StyledIonCol>

          <PartnerClickArea onClick={() => goPartner()}>
            <ArrowRightIcon fill="#FFF" />
          </PartnerClickArea>
        </StyledIonRow>
      )}
    </StyledIonGrid>
  )
}

export default ProfilePartnerCoBranding
