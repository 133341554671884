import React from 'react'
import styled from 'styled-components'
import { IonLabel } from '@ionic/react'

export type LabelSize = '3xs' | '2xs' | 'xs' | 'xsplus' | 'sm' | 'normal' | 'lg' | 'xl' | '2xl' | '3xl'

type SizeMap = {
  [key in LabelSize]: {
    fontSize: string
    lineHeight: string
  }
}

const SIZES: SizeMap = {
  '3xs': {
    fontSize: '9px',
    lineHeight: '11px',
  },
  '2xs': {
    fontSize: '11px',
    lineHeight: '13px',
  },
  xs: {
    fontSize: '13px',
    lineHeight: '18px',
  },
  xsplus: {
    fontSize: '14px',
    lineHeight: '18px',
  },
  sm: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  normal: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
  },
  lg: {
    fontSize: '1.5rem',
    lineHeight: '1.875rem',
  },
  xl: {
    fontSize: '2rem',
    lineHeight: '2.75rem',
  },
  '2xl': {
    fontSize: '2.875rem',
    lineHeight: '3.5rem',
  },
  '3xl': {
    fontSize: '4.25rem',
    lineHeight: '5.25rem',
  },
}

type Color =
  | 'black'
  | 'white'
  | 'primary'
  | 'primary-light'
  | 'primary-lighter'
  | 'secondary'
  | 'gray'
  | 'gray-alt'
  | 'gray-light'
  | 'gray-lighter'
  | 'info'
  | 'warning'
  | 'green'
  | 'green-light'
  | 'danger'
  | 'error'
  | 'accent'

const COLORS = {
  black: '#000',
  white: '#fff',
  primary: 'var(--color-primary)',
  'primary-light': 'var(--color-primary-light)',
  'primary-lighter': 'var(--color-primary-lighter)',
  secondary: 'var(--color-secondary)',
  gray: 'var(--color-gray)',
  'gray-alt': 'var(--color-gray-alt)',
  'gray-light': 'var(--color-gray-light)',
  'gray-lighter': 'var(--color-gray-lighter)',
  'gray-lighter-alt': 'var(--color-gray-lighter-alt)',
  info: 'var(--color-info)',
  warning: 'var(--color-warning)',
  green: 'var(--color-green)',
  'green-light': 'var(--color-green-light)',
  danger: 'var(--color-danger)',
  error: 'var(--color-danger)',
  accent: 'var(--color-accent)',
}

export interface LabelProps extends React.HTMLAttributes<HTMLIonLabelElement> {
  secondary?: boolean
  fontStyle?: string
  size?: LabelSize
  color?: Color
  block?: boolean
  center?: boolean
  justify?: boolean
  isWrapped?: boolean
  weight?: number
  refs?: any
  align?: string
}

const UpackedLabel: React.FC<LabelProps> = ({
  secondary,
  fontStyle,
  size,
  color,
  block,
  center,
  isWrapped,
  weight,
  ...rest
}) => <IonLabel {...rest} color={color} />

const Label = styled(UpackedLabel)`
  ${props => props.block && 'display: block;'}

  ${props =>
    props.align &&
    `
      text-align: ${props.align};
    `}

  ${props =>
    props.center &&
    `
      display: block;
      text-align: center;
    `}

  ${props =>
    props.justify &&
    `
      display: block;
      text-align: justify;
    `}

  font-family: var(--font-${props => (props.secondary ? 'secondary' : 'primary')});
  font-style: ${props => props.fontStyle || 'normal'};
  font-weight: ${props => props.weight || 400};
  font-size: ${props => SIZES[props.size || 'normal'].fontSize} !important;
  line-height: ${props => SIZES[props.size || 'normal'].lineHeight} !important;
  white-space: ${props => (props.isWrapped ? 'normal !important' : 'nowrap')};
  word-wrap: break-word;

  &.ion-color-${props => props.color} {
    color: ${props => COLORS[props.color || 'black']};
  }
`

Label.defaultProps = {
  secondary: false,
  fontStyle: 'normal',
  size: 'normal',
  color: 'black',
  block: false,
}

export default Label
