import React from 'react'
import styled from 'styled-components'
import { IonCard, IonCardHeader, IonCardContent } from '@ionic/react'

import Label from 'components/Label'

const StyledIonCardHeader = styled(IonCardHeader)`
  background-color: #fff;
`

const StyledIonCardContent = styled(IonCardContent)`
  background-color: #fff;
`

const Bio = ({ owner }) => {
  return (
    <IonCard>
      <StyledIonCardHeader>
        <Label size="sm" weight={500} color="gray">
          A little about me...
        </Label>
      </StyledIonCardHeader>

      <StyledIonCardContent>
        {owner.bio ? <div dangerouslySetInnerHTML={{ __html: owner.bio }}></div> : <div></div>}
      </StyledIonCardContent>
    </IonCard>
  )
}

export default Bio
