import * as React from 'react'
import styled from 'styled-components'
import Label from '../Label'

const TitleLabel: React.FC = styled(Label)<{ subtitle?: boolean; lg: boolean }>`
  font-size: ${props => (props.lg ? '66px' : '43px')} !important;
  line-height: 1.1 !important;
  margin-top: 0.25rem;
  margin-bottom: ${props => (props.subtitle ? '0.25rem' : '0.75rem')};
`

export default TitleLabel
