import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import HomeRoutes from './HomeRoutes';
import CalculatorRoutes from './CalculatorRoutes';
import ChatRoutes from './ChatRoutes';
import ContentRoutes from './ContentRoutes';
import LoanRoutes from './LoanRoutes';
import ApplicationRoutes from './ApplicationRoutes';
import InstallationRoutes from './InstallationRoutes';
import DocumentRoutes from './DocumentRoutes';
import LeadRoutes from './LeadRoutes';
import LegalRoutes from './LegalRoutes';
import HelpRoutes from './HelpRoutes';
import NotificationsPage from 'pages/notifications/Index';
import ConversationRoutes from './ConversationRoutes';
import DevRoutes from './DevRoutes';
import ConsumerRoutes from './ConsumerRoutes';

const Routes = () => (
  <Switch>
    <Route path="/home" component={HomeRoutes} />
    <Route path="/installations" component={InstallationRoutes} />
    <Route path="/leads" component={LeadRoutes} />
    <Route path="/application" component={ApplicationRoutes} />
    <Route path="/calculators" component={CalculatorRoutes} />
    <Route path="/chat" component={ChatRoutes} />
    <Route path="/content" component={ContentRoutes} />
    <Route path="/legal" component={LegalRoutes} />
    <Route path="/loans" component={LoanRoutes} />
    <Route path="/documents" component={DocumentRoutes} />
    <Route path="/help" component={HelpRoutes} />
    <Route path="/notifications" component={NotificationsPage} />
    <Route path="/conversations" component={ConversationRoutes} />
    <Route path="/consumer" component={ConsumerRoutes} />
    {process.env.NODE_ENV !== 'production' && <Route path="/icons" component={DevRoutes} />}
    <Route component={() => <Redirect to="/home" />} />
  </Switch>
);

export default Routes;
