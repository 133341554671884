import React from 'react';
import { Route } from 'react-router-dom';
import CalculationList from '../pages/Installations/components/CalculationList';
import DocumentsList from '../pages/Installations/components/DocumentsList';

const InstallationTabRoutes = ({ installation, path }) => {
  // eslint-disable-next-line react/display-name
  const injectInstall = Component => props => <Component {...props} installation={installation} />;

  return (
    <>
      <Route path={`${path}/calculations`} render={injectInstall(CalculationList)} />
      <Route path={`${path}/docs`} render={injectInstall(DocumentsList)} />
    </>
  );
};

export default InstallationTabRoutes;
