import React, { useEffect, useState } from 'react'
import { formatDistanceToNow } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import styled from 'styled-components'

import Label from './Label'

import { IonCard, IonSpinner } from '@ionic/react'

import AvatarInitials from './AvatarInitials'
import LoanMilestone from './loans/LoanMilestone'

const ResourceCard = ({ consumer, name, date, loading, open, list, flat, status, tall, confirmed, ...props }) => {
  const [humanReadableDuration, setHumanReadableDuration] = useState(null)

  useEffect(() => {
    if (date) {
      setHumanReadableDuration(
        formatDistanceToNow(
          utcToZonedTime(
            date.replace(' ', 'T') + 'Z',
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            "yyyy-MM-dd'T'HH:mm:ss'Z'"
          )
        ).replace('about', '')
      )
    }
  }, [date])

  return (
    <Wrapper {...props} onClick={open} list={list} flat={flat} status={status} tall={tall}>
      <InformationWrapper>
        {loading && (
          <OpeningSpinner>
            <IonSpinner name="dots" />
          </OpeningSpinner>
        )}

        <NameWrapper>
          <AvatarInitials name={name} confirmed={confirmed} />
          <Label className="name" color="gray" size="sm">
            {name}
          </Label>
        </NameWrapper>
      </InformationWrapper>

      <ServiceWrapper>
        <Label block secondary size="3xs" fontStyle="italic" weight={300} color="gray-light" noWrap={true}>
          {humanReadableDuration} ago
        </Label>
        {status && consumer && consumer.loan_info && consumer.loan_info && consumer.loan_info.current_milestone && (
          <LoanStatusWrapper>
            <LoanMilestone loan={consumer.loan_info} showCounts={false} />
          </LoanStatusWrapper>
        )}
      </ServiceWrapper>
    </Wrapper>
  )
}

const LoanStatusWrapper = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  color: var(--color-gray);
  margin-top: 10px;
`

const InformationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Wrapper = styled(IonCard)`
  flex-direction: row !important;
  position: relative;
  padding: ${props => (props.status ? '0.35rem 0.5rem' : '0 0.5rem')};
  min-height: 48px;
  height: ${props => (props.tall ? '66px' : 'auto')};
  margin-top: 0;
  margin-bottom: ${props => {
    return props.list ? 0 : '0.25rem'
  }};
  border-radius: ${props => {
    if (props.list) {
      return `
          0;

          &:first-of-type {
            border-radius: 6px 6px 0 0;
          }

          &:last-of-type {
            border-radius: 0 0 6px 6px;
          }
      `
    }
  }};

  ${props => {
    if (props.flat) {
      return `
          box-shadow: none;
          margin-left: 0;
          margin-right: 0;
        `
    }
  }};

  ${props => {
    return `
      display: flex;
      flex-direction: ${props.status ? 'column' : 'row'};
      justify-content: space-between;
    `
  }}

  &:nth-of-type(even) {
    background: #fff;
  }

  &:nth-of-type(odd) {
    background: var(--color-background);
  }
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;

  .name {
    margin-left: 1rem;
  }
`

const OpeningSpinner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`

const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100%;
  margin: auto 0;
`

export default ResourceCard
