import React from 'react'

import { useOnboarding } from 'store/Onboarding'

import Form from './components/Form'
import Layout from './components/Layout'
import FormInput from './components/FormInput'
import Button from './components/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import ButtonWrapper from './components/ButtonWrapper'

const schema = yup.object().shape({
  firstName: yup.string().required('This field is required.').min(2, 'First name must contain at least 2 characters.'),
  lastName: yup.string().required('This field is required.').min(2, 'Last name must contain at least 2 characters.'),
})

const RegisterNamePage = ({ history }) => {
  const { setFirstName, setLastName } = useOnboarding()

  const { register, handleSubmit, errors, trigger, getValues } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = ({ firstName, lastName }) => {
    setFirstName(firstName)
    setLastName(lastName)

    history.push('/auth/register/phone')
  }

  const handleClick = async () => {
    const result = await trigger()

    if (result) {
      onSubmit(getValues())
    }
  }

  const Footer = (
    <ButtonWrapper>
      <Button onClick={() => handleClick()}>Next</Button>
    </ButtonWrapper>
  )

  return (
    <Layout backUrl="/auth/email" footer={Footer} subtitle="Please introduce yourself...">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          ref={register}
          label="First Name"
          error={errors.firstName?.message}
          name="firstName"
          type="text"
          placeholder="Enter Your First Name"
        />

        <FormInput
          ref={register}
          label="Last Name"
          error={errors.lastName?.message}
          name="lastName"
          type="text"
          placeholder="Enter Your Last Name"
        />
      </Form>
    </Layout>
  )
}

export default RegisterNamePage
