import React from 'react'
import { IonRouterOutlet } from '@ionic/react'

import PrivateRoute from '../layouts/auth/PrivateRoute'
import ConversationsPage from 'pages/conversations/Index'
import ShowConversation from 'pages/conversations/Show'
import Unavailable from '../pages/conversations/Unavailable'

const ConversationRoutes = () => (
  <IonRouterOutlet>
    <PrivateRoute exact path="/conversations" component={ConversationsPage} />
    <PrivateRoute exact path="/conversations/:conversation" component={ShowConversation} />
    <PrivateRoute exact path="/conversations/unavailable" component={Unavailable} />
  </IonRouterOutlet>
)

export default ConversationRoutes
