import { IonIcon, IonLoading, IonSlide } from '@ionic/react'
import { imageOutline } from 'ionicons/icons'
import React, { useState } from 'react'
import ActionButtons from '../components/ActionButtons'
import DescriptionLabel from '../components/DescriptionLabel'
import Layout from '../components/Layout'

import { ReactComponent as AddLogoIcon } from 'legacy-icons/add-logo.svg'
import styled from 'styled-components'
import usePhotoGetter from 'hooks/usePhotoGetter'
import { useApplication } from 'store/Application'
import { uploadLogoUrl } from 'api/sso'
import { Dialog } from '@capacitor/dialog'

interface Props {
  onNext: () => Promise<void>
  onPrev: () => Promise<void>
  progress: number
}

const UpdateLogo: React.FC<Props> = ({ progress, onNext, onPrev }) => {
  const getPhoto = usePhotoGetter()
  const [loading, setLoading] = useState(false)
  let { ssoOwner, owner, setOwner } = useApplication()

  const changePhoto = async () => {
    const photo = await getPhoto()

    setLoading(true)

    uploadLogoUrl(ssoOwner.id, photo)
      .then(logo_url => {
        setOwner({ ...owner, logo_url })
        onNext()
      })
      .catch(error => {
        Dialog.alert({
          title: 'Error',
          message: 'It looks like something went wrong. Please try again later or contact support.',
        })

        throw error
      })
      .finally(() => setLoading(false))
  }

  return (
    <IonSlide>
      <Layout
        progress={progress}
        onPrev={onPrev}
        content={
          <>
            <IonLoading isOpen={loading} message="Uploading..." />

            <DescriptionLabel style={{ marginTop: '2rem' }}>Add your logo</DescriptionLabel>

            <AddLogoWrapper onClick={changePhoto}>
              <AddLogoIcon />
            </AddLogoWrapper>
          </>
        }
        footer={
          <ActionButtons onSubmit={changePhoto}>
            <IonIcon slot="start" icon={imageOutline} /> Upload your Logo
          </ActionButtons>
        }
      />
    </IonSlide>
  )
}

const AddLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem auto 0 auto;

  width: 312px;
  height: 180px;
  border: 5px dashed #c6cbcf;
  border-radius: 12px 16px 12px 12px;
`

export default UpdateLogo
