import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import Header from '../../components/Header'
import NoDataLoaded from '../../components/NoDataLoaded'
import NoMessagesAnimation from '../../components/animations/NoMessagesAnimation'

const Unavailable: React.FC = () => {
  return (
    <IonPage>
      <Header title="Messages" defaultHRef="/" />

      <IonContent>
        <div style={{ marginTop: '1rem', padding: '0 5px' }}>
          <NoDataLoaded icon={false} message="This feature is only available to subscribers of the Loanzify POS">
            <NoMessagesAnimation />
          </NoDataLoaded>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Unavailable
