import React from 'react'
import { Browser } from '@capacitor/browser'
import { ActionSheetButton } from '@ionic/core'
import { useIonActionSheet } from '@ionic/react'

import { Request } from 'types/Documents'
import { getDocumentLink } from 'api/documents'

interface Props {
  document: Request
  render: (open: () => void) => React.ReactNode
}

const ViewDocumentFilesButton = ({ document, render }: Props): React.ReactNode => {
  const [present] = useIonActionSheet()

  const view = () => {
    const files = document.current_response.items.filter(file => {
      return file.type === 'SINGLE'
    })

    const buttons: ActionSheetButton[] = files.map(file => ({
      text: file.title,
      handler: () => {
        getDocumentLink(null, file.id).then(({ preview_url }) => {
          Browser.open({
            url: `http://docs.google.com/gview?embedded=true&url=${encodeURIComponent(preview_url)}`,
          })
        })
      },
    }))

    buttons.push({
      text: 'Cancel',
      role: 'cancel',
    })

    present(buttons, 'Files')
  }

  return render(view)
}

export default ViewDocumentFilesButton
