import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IonCard, IonCardContent, IonSpinner, IonPicker, IonIcon } from '@ionic/react'
import Label from '../../../../components/Label'
import { useHistory } from 'react-router-dom'
import { getRecentStats } from '../../../../api/loanzify'
import { getRecentPosStats } from '../../../../api/pos'
import { DURATION_OPTIONS } from '../../../../config/duration'
import { ellipsisHorizontal } from 'ionicons/icons'
import { useSession } from '../../../../store/Session'
import { useApplication } from '../../../../store/Application'
import { getPartners } from '../../../../api/sso'

const StyledIonCard = styled(IonCard)`
  --background: #fff;
  margin: 0;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-lightest);
  padding-bottom: 0.25rem;
  margin-bottom: 0.5rem;
`

const LabelText = styled.span`
  font-weight: normal;
  font-family: var(--font-secondary);
  color: var(--color-gray-light);
  margin-left: 5px;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;

  margin-top: 15px;
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Item = styled.div`
  border-right: 1px solid var(--color-gray-lighter);
  padding: 0 1.25rem;
  text-align: center;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  &:last-child {
    border-right: none;
  }
`

const ItemNumber = styled.div`
  display: flex;
  flex-direction: column;
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled(Label).attrs({ color: 'gray-light', size: 'normal' })``

const Divider = styled.div`
  height: 2px;
  width: 100%;
  display: block;
  margin: 6px auto;
  background: ${props => `var(${props.color})` || 'red'};
`

const Number = styled(Label).attrs({ color: 'gray-light', size: 'xl' })``

const RecentActivity = () => {
  const history = useHistory()
  const { hasPos } = useApplication()
  const { recent_activity_from, setRecentActivityFrom } = useSession()
  const [durationPickerIsOpen, setDurationPickerIsOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [daysBack, setDaysBack] = useState(recent_activity_from)
  const [stats, setStats] = useState({
    installs: 0,
    loans: 0,
    partners: 0,
  })

  const handleToggleDurationPicker = () => {
    setDurationPickerIsOpen(!durationPickerIsOpen)
  }

  const daysBackText = (DURATION_OPTIONS.find(o => o.value.days === daysBack) || { text: '' }).text
  const formatNumber = number => (number === undefined ? '0' : number < 10 ? `0${number}` : number)

  const installMetrics = () => {
    return (stats.loans ? stats.loans : 0) + (stats.installs ? stats.installs : 0)
  }

  useEffect(() => {
    async function loadInitialData() {
      setLoading(true)
      let recentStats = {}
      try {
        const recentStatsData = await getRecentStats(daysBack)
        recentStats = {
          ...recentStatsData,
        }
      } catch (e) {}

      if (hasPos) {
        try {
          const recentStatsPos = await getRecentPosStats(daysBack)
          recentStats = {
            ...recentStats,
            ...recentStatsPos,
          }
        } catch (e) {}
      } else {
        try {
          const { partners_count } = await getPartners(daysBack)
          recentStats = {
            ...recentStats,
            partners: partners_count,
          }
        } catch (e) {}
      }

      setStats(recentStats)
      setLoading(false)
    }

    loadInitialData()
  }, [daysBack])
  return (
    <StyledIonCard>
      <IonCardContent>
        <Header>
          <Label color="gray" size="xs" weight={600}>
            Activity: <LabelText>{daysBackText}</LabelText>
          </Label>
          <IonIcon icon={ellipsisHorizontal} onClick={handleToggleDurationPicker} />
          <IonPicker
            isOpen={durationPickerIsOpen}
            mode="ios"
            cssClass="duration-picker"
            columns={[
              {
                name: 'duration',
                options: DURATION_OPTIONS,
              },
            ]}
            buttons={[
              {
                text: 'Activity View Option',
                cssClass: 'title-button',
                role: 'cancel',
                handler: e => {
                  handleToggleDurationPicker()
                },
              },
              {
                text: 'Done',
                handler: ({ duration: { value } }) => {
                  handleToggleDurationPicker()
                  let days = 7

                  if (value.all) {
                    days = 999
                  } else if (value.days) {
                    days = value.days
                  }

                  setDaysBack(days)
                  setRecentActivityFrom(days)
                },
              },
            ]}
          />
        </Header>
        <ItemWrapper>
          <Wrapper>
            <Item onClick={() => history.push('/leads/tabs/consumers')}>
              <LabelWrapper>
                {loading && <IonSpinner />}
                {!loading && (
                  <ItemNumber>
                    <Number>{formatNumber(installMetrics())}</Number>{' '}
                  </ItemNumber>
                )}
              </LabelWrapper>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <Title>Borrowers</Title>
              </div>
              <Divider color="--color-blue" />
            </Item>

            <Item onClick={() => history.push('/leads/tabs/partners')}>
              <LabelWrapper>
                {loading && <IonSpinner />}
                {!loading && (
                  <ItemNumber>
                    <Number>{formatNumber(stats.partners ? stats.partners : 0)}</Number>{' '}
                  </ItemNumber>
                )}
              </LabelWrapper>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <Title>Partners</Title>
              </div>
              <Divider color="--color-yellow" />
            </Item>
          </Wrapper>{' '}
        </ItemWrapper>
      </IonCardContent>
    </StyledIonCard>
  )
}

export default RecentActivity
