import React, { FC, useState } from 'react';
import { IonContent, IonLoading, IonPage } from '@ionic/react';
import styled from 'styled-components';

import { uploadLogoUrl } from 'api/sso';

import Button from 'components/Button';
import Header from 'components/Header';

import usePhotoGetter from 'hooks/usePhotoGetter';
import { useApplication } from 'store/Application';
import Label from 'components/Label';
import { Dialog } from '@capacitor/dialog';

const SettingsLogoPage: FC = () => {
  const getPhoto = usePhotoGetter();
  const [loading, setLoading] = useState(false);
  const { ssoOwner, logoUrl, owner, setOwner }: any = useApplication();

  const changeLogo = async () => {
    const photo = await getPhoto();

    setLoading(true);

    uploadLogoUrl(ssoOwner.id, photo)
      .then(logo_url => setOwner({ ...owner, logo_url }))
      .catch(error => {
        Dialog.alert({
          title: 'Error',
          message: 'It looks like something went wrong. Please try again later or contact support.',
        });

        throw error;
      })
      .finally(() => setLoading(false));
  };

  return (
    <IonPage>
      <Header title="Upload Logo" defaultHref="/home/settings" />

      <IonContent>
        <IonLoading isOpen={loading} message="Uploading..." />

        <LogoContainer>
          {logoUrl ? (
            <StyledImg src={logoUrl} alt="Logo" style={{ textAlign: 'center' }} />
          ) : (
            <Label center>You haven&apos;t uploaded a logo yet.</Label>
          )}
        </LogoContainer>

        <Button size="md" center onClick={changeLogo}>
          {logoUrl ? 'Replace Image' : 'Upload Image'}
        </Button>
      </IonContent>
    </IonPage>
  );
};

const LogoContainer = styled.div`
  padding: 2rem 0;
`;

const StyledImg = styled.img`
  display: block;
  margin: 0 auto;
`;

export default SettingsLogoPage;
