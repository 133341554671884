import React, { useEffect } from 'react';
import { get } from 'lodash';
import { Hints, Steps } from 'intro.js-react';

import UserDashboard from './UserDashboard/Index';
import ConsumerDashboard from './ConsumerDashboard';
import { useIntro } from '../../../store/Intro';
import useModal from '../../../hooks/useModal';
import { useAuth } from '../../../store/Auth';
import { Authenticatable } from 'types/Sso';
import GuidedTourModal from './GuidedTour/GuidedTour';
import { User } from 'types/Application';

interface DashboardProps {
  isPartner: boolean;
  isUser: boolean;
  user?: Authenticatable;
  owner: User;
}
const Dashboard: React.FC<DashboardProps> = props => {
  const { isConsumer } = useAuth();
  const { steps, hints, guidedTour, setGuidedTour, setSteps } = useIntro();

  const [present, dismiss] = useModal(GuidedTourModal, {
    autoHeight: true,
    setGuidedTour,
    setSteps,
    dismiss: () => closeModal(),
  });

  const closeModal = () => {
    dismiss();
  };

  useEffect(() => {
    if (!get(guidedTour, 'seen', false) && !isConsumer) {
      present();
    }
  }, [guidedTour, isConsumer, present]);

  return (
    <>
      <Steps
        enabled={steps.enabled as boolean}
        initialStep={steps.initialStep}
        steps={steps.steps}
        onExit={steps.onExit}
        options={{
          tooltipClass: 'loanzify-intro-tooltip',
          highlightClass: 'loanzify-intro-helper',
          skipLabel: 'Skip',
          scrollPadding: 5,
          scrollToElement: true,
        }}
      />
      <Hints enabled={hints.enabled} hints={hints.hints} />

      {props.isUser && !props.isPartner ? <UserDashboard /> : <ConsumerDashboard />}
    </>
  );
};

export default Dashboard;
