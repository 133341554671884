import React from 'react'
import { IonSpinner } from '@ionic/react'

import { useAuth } from 'store/Auth'
import useLoanCompletedFields from 'queries/useLoanCompletedFields'

import TaskCardWithGraph from './TaskCardWithGraph'

interface Props {
  //
}

const ApplicationTaskCard: React.FC<Props> = () => {
  const { loan, posUser } = useAuth()

  const query = useLoanCompletedFields(loan)

  if (posUser && posUser.is_co_borrower === true) {
    return null
  }

  if (query.isLoading) {
    return <IonSpinner name="lines-small" />
  }

  if (!query.isSuccess || !query.data) {
    return null
  }

  if (query.data.percentage === 100) {
    return null
  }

  return (
    <TaskCardWithGraph
      title="Application"
      progress={query.data.percentage}
      progressText={`${query.data.percentage}%`}
      actionText="Complete Application"
      actionRouterLink="/application/form"
      documentCounts={false}
    />
  )
}

export default ApplicationTaskCard
