import React, { useEffect } from 'react'
import { Toast } from '@capacitor/toast'
import { IonPage, IonContent } from '@ionic/react'
import { useHistory } from 'react-router-dom'

import Header from 'components/Header'
import FeatureDisabled from 'components/FeatureDisabled'

import { useAuth } from 'store/Auth'
import { useApplication } from 'store/Application'

import { storeLead } from 'api/loanzify'
import { storeQuickIntake } from 'api/pos'
import { useSession } from '../../store/Session'

const NewShortFormPage = () => {
  const { user, consumerService, isUser, isConsumer, isPartner } = useAuth()
  const { ssoOwner, ssoParent, isPreview, isParentWithPos } = useApplication()
  const { application_id } = useSession()

  const history = useHistory()

  useEffect(() => {
    if (isPreview) {
      return
    }

    const mountFormBuilder = async () => {
      const form = await window.LhpForms.create({
        account: isParentWithPos ? 'mobile-intake' : 'mobile-app',
        account_id: isConsumer ? consumerService.userService.parent_user_id : user.loanzify_user_id,
      }).mount('#form-builder')

      form.setModel({
        borrower_firstname: user.first_name,
        borrower_lastname: user.last_name,
        borrower_phone_cell: user.phone,
        borrower_email: isPartner ? `demo+${application_id}+${new Date().valueOf()}@example.com` : user.email,
      })

      form.on('submitted', model => {
        if (isUser) {
          Toast.show({ text: 'Prospects are not stored while previewing as a borrower' })

          return
        }

        storeLead(model)

        isParentWithPos &&
          storeQuickIntake(
            {
              ...model,
              submission_type: 'prospect',
            },
            ssoParent.id,
            ssoOwner.id
          )
      })

      form.on('completed', () => {
        history.push(`/home`)
      })

      form.on('finish-later', () => {
        console.log('finish-later')
      })
    }

    mountFormBuilder()
  }, [])

  if (isPreview) {
    return <FeatureDisabled header="Application" />
  }

  return (
    <IonPage>
      <Header title="Application" />
      <IonContent>
        <div id="form-builder" frameBorder="0" style={{ width: '100%', height: '100%' }}></div>
      </IonContent>
    </IonPage>
  )
}

export default NewShortFormPage
