import React from 'react';
import Lottie from 'react-lottie-wrapper';
import { LottieProps, Options } from 'react-lottie';

export interface AnimationProps extends Omit<LottieProps, 'options'> {
  options?: Omit<Options, 'animationData'>;
}
interface Props extends AnimationProps {
  data: any;
}

const Animation: React.FC<Props> = ({ data, options, ...props }) => (
  <Lottie {...props} options={{ ...options, animationData: data }} />
);

export default Animation;
