import React, { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { IonAvatar, IonItem, IonLabel, IonProgressBar } from '@ionic/react'

import Layout from './components/Layout'
import AccordionItem from 'components/AccordionItem'

import { getBranches } from 'api/loanzify'
import { useApplication } from 'store/Application'
import useApplicationSetter from 'hooks/useApplicationSetter'

const SelectBranchPage = () => {
  const history = useHistory()

  const { isMainEnterprise, application } = useApplication()
  const setApplication = useApplicationSetter()

  const [branches, setBranches] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isMainEnterprise) {
      getBranches(application.id)
        .then(setBranches)
        .finally(() => setLoading(false))
    }
  }, [])

  const selectApplication = id => {
    setApplication(id)
    history.push(`/auth/email`)
  }

  if (!isMainEnterprise) {
    return <Redirect to="/auth/email" />
  }

  return (
    <Layout title="Hello!" titleLarge subtitle="Please select a branch">
      {loading && <IonProgressBar type="indeterminate" style={{ marginBottom: '20px' }}></IonProgressBar>}

      <div>
        {branches.map(branch => (
          <AccordionItem key={branch.id} title={`${branch.name} (${branch.state})`}>
            {branch.loan_officers.map(loanOfficer => (
              <IonItem key={loanOfficer.id} onClick={() => selectApplication(loanOfficer.main_application.id)}>
                <IonAvatar slot="start">
                  <img src={loanOfficer.photo_url} alt="Avatar" />
                </IonAvatar>
                <IonLabel>
                  {loanOfficer.first_name} {loanOfficer.last_name}
                </IonLabel>
              </IonItem>
            ))}
          </AccordionItem>
        ))}
      </div>
    </Layout>
  )
}

export default SelectBranchPage
