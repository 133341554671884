import * as React from 'react'
import { IonPage, IonContent, IonLoading } from '@ionic/react'
import styled from 'styled-components'

import ChatScreen from './components/ChatScreen'

import { useStreamChat } from 'store/StreamChat'
import Header from '../../components/Header'

const StyledIonContent = styled(IonContent)`
  .str-chat {
    height: 100%;
  }
`

const ChatPage = () => {
  const { client, token } = useStreamChat()

  return (
    <IonPage>
      <Header title="Messaging" />
      <StyledIonContent>
        <IonLoading isOpen={!token && !client} />
        {token && client && <ChatScreen token={token} />}
      </StyledIonContent>
    </IonPage>
  )
}

export default ChatPage
