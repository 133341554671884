import React, { createContext, useContext, useRef } from 'react'

import SideMenu from './partials/SideMenu'

interface Context {
  element: React.MutableRefObject<HTMLIonMenuElement | undefined>
}

const SideMenuContext = createContext<Context>({} as Context)

const SideMenuLayout: React.FC = ({ children }) => {
  const sideMenuEl = useRef<HTMLIonMenuElement>()

  return (
    <SideMenuContext.Provider value={{ element: sideMenuEl }}>
      <SideMenu menuRef={sideMenuEl} />

      {children}
    </SideMenuContext.Provider>
  )
}

export const useSideMenu = () => useContext(SideMenuContext)

export default SideMenuLayout
