import { useEffect } from 'react'
import { Device } from '@capacitor/device'
import FakeUuid from '../utils/fakeUuid'

import { createInstallation } from 'api/loanzify'
import { Capacitor } from '@capacitor/core'

const useInstallationListener = (session, auth, setAuth) =>
  useEffect(() => {
    const shouldSkipInstallation = () => {
      // prettier-ignore
      return session.installation_id
        || ! session.application_id
        || ! auth.user
        || ! auth.user.is_consumer
        || ! auth.consumerService
    }

    if (shouldSkipInstallation()) {
      return
    }

    const setupInstallation = async () => {
      const installation = await createInstallation(session.application_id, {
        sso_consumer_id: auth.user.id,
        sso_consumer_service_id: auth.consumerService.id,
        device_uuid: Capacitor.isNativePlatform() ? (await Device.getId()).uuid : FakeUuid(),
        name: `${auth.user.first_name} ${auth.user.last_name}`,
        email: auth.user.email,
        phone: auth.user.phone,
      })

      session.setInstallationId(installation.id)
      setAuth(auth => ({ ...auth, installation }))
    }

    setupInstallation()
  }, [session.application_id, auth.user?.id, auth.consumerService?.id])

export default useInstallationListener
