import React, { useMemo, useCallback, forwardRef } from 'react';

import AlertInputNumber from './AlertInputNumber';

const ComputedField = forwardRef(
  ({ title, value, options, onChange, computedField: { get, set, formatter } }: any, ref: any) => {
    const computedValue = useMemo(() => get(value, options), [value, options, get]);

    const handleChange = useCallback(
      computedValue => {
        onChange(set(computedValue, options));
      },
      [options, set, onChange]
    );

    return (
      <div>
        <AlertInputNumber
          size="sm"
          title={title}
          value={computedValue}
          formatter={formatter}
          onChange={handleChange}
          ref={ref}
        />
      </div>
    );
  }
);

ComputedField.displayName = 'ComputedField';

export default ComputedField;
