import React from 'react'

import { RenderableMenu } from './SideMenu'

interface Props extends React.ComponentProps<'span'> {
  item: RenderableMenu
}

const SideMenuItemIcon: React.FC<Props> = ({ item, ...props }) => {
  const IconComponent = item.icon

  if (IconComponent) {
    return <IconComponent />
  }

  /**
   * Handles cases where an Icon may be improperly provided and results in a DOM error
   */
  return <></>
}

export default SideMenuItemIcon
