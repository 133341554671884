import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { IonContent, IonPage, IonSpinner } from '@ionic/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { createPartner } from 'api/sso'

import Header from 'components/Header'
import FormInput from 'pages/auth/components/FormInput'
import AuthButton from 'pages/auth/components/Button'
import { useHistory } from 'react-router-dom'
import { convertToErrorBag, isValidationError } from 'utils/Validation'

interface Schema {
  email: string
  first_name: string
  last_name: string
  mobile: string
}

const schema = yup.object().shape({
  email: yup.string(),
  first_name: yup.string(),
  last_name: yup.string(),
  mobile: yup.string(),
})

const CreatePartner: React.FC = () => {
  const history = useHistory()

  const { register, errors, handleSubmit, setError } = useForm({
    resolver: yupResolver(schema),
  })

  const [status, setStatus] = useState<'ready' | 'loading'>('ready')

  const submit = async (data: Schema) => {
    setStatus('loading')

    createPartner(data)
      .then(() => {
        history.push('/leads/tabs/partners')
      })
      .catch(error => {
        if (!isValidationError(error)) {
          return
        }

        const errors = convertToErrorBag(error)

        setError('email', { type: 'manual', message: errors.first('email') })
        setError('first_name', { type: 'manual', message: errors.first('first_name') })
        setError('last_name', { type: 'manual', message: errors.first('last_name') })
        setError('mobile', { type: 'manual', message: errors.first('mobile') })

        setStatus('ready')
      })
  }

  return (
    <IonPage>
      <Header title="Create Partner" defaultHref="/leads/partners" />

      <IonContent className="ion-padding">
        <form onSubmit={handleSubmit(submit)}>
          <FormInput
            ref={register}
            error={errors.email?.message}
            label="Email *"
            name="email"
            type="email"
            placeholder="john.doe@example.com"
            labelProps={{ style: { textAlign: 'left' } }}
            required
          />

          <FormInput
            ref={register}
            error={errors.first_name?.message}
            label="First Name"
            name="first_name"
            type="text"
            placeholder="John"
            labelProps={{ style: { textAlign: 'left' } }}
          />

          <FormInput
            ref={register}
            error={errors.last_name?.message}
            label="Last Name"
            name="last_name"
            type="text"
            placeholder="Doe"
            labelProps={{ style: { textAlign: 'left' } }}
          />

          <FormInput
            ref={register}
            error={errors.last_name?.mobile}
            label="Mobile"
            name="mobile"
            type="text"
            placeholder="999-999-9999"
            labelProps={{ style: { textAlign: 'left' } }}
          />

          <AuthButton disabled={status === 'loading'}>
            {status === 'loading' ? <IonSpinner name="dots" /> : <span>Add Partner</span>}
          </AuthButton>
        </form>
      </IonContent>
    </IonPage>
  )
}

export default CreatePartner
