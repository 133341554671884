import * as React from 'react'
import styled from 'styled-components/macro'
import TaskCard from 'pages/home/components/TaskCard'
import Label from '../../../components/Label'
import IconAlert from 'legacy-icons/onboarding/alert.svg'
import { IonIcon, IonRouterLink } from '@ionic/react'
import Button from '../../../components/Button'
import { CreditTask } from '../../../types/Pos'

interface Props {
  task: CreditTask
}

const CreditTaskCard: React.FC<Props> = props => {
  const {
    task: {
      data: { accepted, description },
    },
  } = props

  return (
    <TaskCard>
      <Container>
        <IconContainer>
          <IonIcon src={IconAlert} />
          {!accepted && (
            <React.Fragment>
              <StatusText>Needed</StatusText>
              <IonRouterLink routerLink="/application/form?authorization_mode">
                <Button color="secondary" size="sm">
                  {' '}
                  Authorize{' '}
                </Button>
              </IonRouterLink>
            </React.Fragment>
          )}
        </IconContainer>
        <ContentContainer>
          <Label weight={400} color="gray">
            Authorizations
          </Label>
          <DescriptionContainer>{description}</DescriptionContainer>
        </ContentContainer>
      </Container>
    </TaskCard>
  )
}

const IconContainer = styled.div`
  flex: 0 0 35%;
  text-align: center;

  ion-icon {
    font-size: 4rem;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

const DescriptionContainer = styled.div`
  color: gray;
  font-size: 11px;
  font-family: cabin;
  margin-top: 0.675rem;
`

const StatusText = styled.div`
  text-align: center;
  font-size: 11px;
  font-family: Cabin;
  margin-bottom: 0.675rem;
`

export default CreditTaskCard
