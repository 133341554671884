import React, { useState } from 'react'
import LoanCardModal from './LoanCardModal'
import FormInput from './form/Input'
import FormSelect from './form/Select'
import styled from 'styled-components'
import TextField from './form/TextField'
import Button from '../Button'
import * as yup from 'yup'
import { Formik, Form, useFormikContext } from 'formik'
import { usePreApprovals } from '../../store/usePreApprovals'

const FormFieldIdentifierWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.375rem 0.875rem;
  margin-top: ${props => (props.selfPadded ? '0.5rem' : 0)};
  margin-bottom: ${props => (props.selfPadded ? '0.75rem' : 0)};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.57142857;
  color: #76838f;
  text-align: center;
  white-space: nowrap;
  background-color: #e4eaec;
  border: 1px solid #e4eaec;
`

const FormFieldPercentToRawWrapper = styled.div`
  display: flex;
`

const FormFieldPercentRawValue = styled.div`
  ion-input {
    input {
      background-color: #f3f7f9 !important;
    }
  }
`

const StyledHeadline = styled.div`
  padding: 1rem 0.5rem;
`

const schema = yup.object().shape({
  max_sale_price: yup.number().required('The Max Sale Price field is required!'),
  min_down_payment: yup.number().required('The Min Down Payment field is required!'),
  max_down_payment: yup.number().required('The Max Down Payment field is required!'),
})

const CreateOrEditLetterParams = ({ loan, isOpen, onCloseClick, onChange = () => {} }) => {
  const [downPaymentExpressedAsWholeNumber, setDownPaymentExpressedAsWholeNumber] = useState(null)
  const { letterParams, saveLetterParams } = usePreApprovals()

  /**
   * Watch for changes in the Formik state and respond to the changes to perform
   * our computations against the provided values.
   *
   * @returns {null}
   * @constructor
   */
  const CalculateDownPaymentAsWholeNumber = () => {
    const { values } = useFormikContext()

    React.useEffect(() => {
      const { max_sale_price, min_down_payment } = values

      if (!max_sale_price || !min_down_payment || parseInt(max_sale_price) <= parseInt(min_down_payment)) {
        setDownPaymentExpressedAsWholeNumber(null)
        return
      }

      setDownPaymentExpressedAsWholeNumber(((min_down_payment / 100) * max_sale_price).toFixed(2))
    }, [values])

    return null
  }

  const formFieldIdentifier = (identifier, ...props) => {
    return <FormFieldIdentifierWrapper {...props}>{identifier}</FormFieldIdentifierWrapper>
  }

  const formFieldPercentToRaw = val => {
    return (
      <FormFieldPercentToRawWrapper>
        <FormFieldIdentifierWrapper selfPadded={true}>$</FormFieldIdentifierWrapper>
        <FormFieldPercentRawValue>
          <FormInput disabled={true} value={downPaymentExpressedAsWholeNumber} />
        </FormFieldPercentRawValue>
      </FormFieldPercentToRawWrapper>
    )
  }

  const loanTypes = () => {
    return [
      {
        value: 'conventional',
        title: 'Conventional',
      },
      {
        value: 'conventional-non-qm',
        title: 'Conventional (Non-QM)',
      },
      {
        value: 'fha',
        title: 'FHA',
      },
      {
        value: 'va',
        title: 'VA',
      },
      {
        value: 'rural',
        title: 'USDA',
      },
      {
        value: 'jumbo',
        title: 'Jumbo',
      },
      {
        value: 'Other',
        title: 'Other',
      },
    ]
  }

  const onSubmit = async (values, setSubmitting) => {
    try {
      const { conditions, max_down_payment, max_piti, max_sale_price, min_down_payment } = values

      setSubmitting(true)

      await saveLetterParams(loan.id, {
        conditions,
        maxDownPayment: max_down_payment,
        maxPITI: max_piti,
        maxSalePrice: max_sale_price,
        minDownPayment: min_down_payment,
      })

      setSubmitting(false)

      onCloseClick()
    } catch (err) {
      setSubmitting(false)
      console.log(err)
    }
  }

  return (
    <LoanCardModal isOpen={isOpen} onCloseClick={() => onCloseClick()} title={'Letter Parameters'}>
      <StyledHeadline> Enter actual values from your AUS or underwriting decision</StyledHeadline>
      <Formik
        initialValues={{
          max_sale_price: letterParams?.maxSalePrice,
          max_down_payment: letterParams?.maxDownPayment,
          max_piti: letterParams?.maxPITI,
          min_down_payment: letterParams?.minDownPayment,
          loanType: letterParams?.loanType,
          conditions: letterParams?.conditions,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values, setSubmitting)
        }}
        validationSchema={schema}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormInput
              name={'max_sale_price'}
              error={errors.max_sale_price && touched.max_sale_price && errors.max_sale_price}
              value={values.max_sale_price}
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              required
              disabled={isSubmitting}
              label={'Maximum Sale Price'}
              placeholder={'$0,000,000.00'}
            />
            <FormSelect
              name={'loan_type'}
              error={errors.loan_type && touched.loan_type && errors.loan_type}
              value={values.loan_type}
              onNativeChange={handleChange}
              disabled={isSubmitting}
              options={loanTypes()}
              label="Loan Type (FHA, VA, etc.)"
              placeholder="Choose Loan Type"
            />
            <FormInput
              name={'min_down_payment'}
              error={errors.min_down_payment && touched.min_down_payment && errors.min_down_payment}
              value={values.min_down_payment}
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              required
              disabled={isSubmitting}
              label={'Min Down Pmt (%)'}
              placeholder={'3.5%'}
              AfterAction={() => formFieldIdentifier('%')}
              ResultDisplay={() => formFieldPercentToRaw(values.min_down_payment)}
            />
            <FormInput
              name={'max_down_payment'}
              error={errors.max_down_payment && touched.max_down_payment && errors.max_down_payment}
              value={values.max_down_payment}
              required
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              disabled={isSubmitting}
              label={'Max Down Pmt (in $)'}
              placeholder={'$00,000.00'}
              AfterAction={() => formFieldIdentifier('$')}
            />
            <FormInput
              name={'max_piti'}
              error={errors.max_piti && touched.max_piti && errors.max_piti}
              value={values.max_piti}
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              disabled={isSubmitting}
              label={'Maximum PITI'}
              placeholder={'36.0'}
            />
            <TextField
              name={'conditions'}
              disabled={isSubmitting}
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              value={values.conditions}
              label={'Conditions'}
            />
            <Button disabled={isSubmitting} center block color="secondary" type={'submit'}>
              Save
            </Button>
            <CalculateDownPaymentAsWholeNumber />
          </Form>
        )}
      </Formik>
    </LoanCardModal>
  )
}

export default CreateOrEditLetterParams
