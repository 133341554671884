import React from 'react'
import styled from 'styled-components'
import { IonIcon } from '@ionic/react'
import { addCircle, removeCircle } from 'ionicons/icons'

const StyledButton = styled.button`
  background: transparent;
  color: var(--color-gray-light);

  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 1rem;

  font-size: 1rem;
  outline: none;

  ion-icon {
    margin-right: 0.5rem;
  }
`

const ToggleAdvancedButton = ({ isOpen, ...props }) => (
  <StyledButton type="button" {...props}>
    <IonIcon icon={isOpen ? removeCircle : addCircle} color="primary" size="large" />

    {isOpen ? 'Simple' : 'Advanced'}
  </StyledButton>
)

export default ToggleAdvancedButton
