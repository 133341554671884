const glossaryData = [
  {
    name: 'A  D LOAN',
    content: ' Acquisition and development loan- a loan for the purchase of raw land for the purpose of development.',
  },
  {
    name: 'Abstract Title',
    content: ' A written history of the ownership of a parcel of land.',
  },
  {
    name: 'Acceleration Clause',
    content:
      ' Allows the lender to speed up the rate at which your loan comes due or even to demand immediate payment of the entire outstanding balance of the loan should you default on your loan.',
  },
  {
    name: 'Acknowledgment',
    content:
      ' A declaration by a notary, certifying, by way of personal knowledge or written identification, the identity of the signer.',
  },
  {
    name: 'Adjustable Rate Mortgage (ARM)',
    content:
      ' Is a mortgage in which the interest rate is adjusted periodically based on a pre-selected index. Also sometimes known as the renegotiable rate mortgage, the variable rate mortgage or the Canadian rollover mortgage. (ARM)',
  },
  {
    name: 'Adjustment Interval',
    content:
      ' On an adjustable rate mortgage, the time between changes in the interest rate and/or monthly payment, typically one, three or five years, depending on the index.',
  },
  {
    name: 'Affidavit',
    content: ' A sworn statement in writing.',
  },
  {
    name: 'American Land Title Association (ALTA)',
    content:
      ' An organization of title companies specializing in Real Property Law which has standardized forms and coverage on a national basis. This is standardized coverage.',
  },
  {
    name: 'Amortized / Amortization',
    content:
      ' Amortization refers to the principal portion of the loan payment and is the loan payment by equal periodic payments calculated to pay off the debt at the end of a fixed period, including accrued interest on the outstanding balance. A fully amortized loan will be completely paid off at the end of the loan term.',
  },
  {
    name: 'Annual Percentage Rate (APR)',
    content:
      ' An interest rate reflecting the cost of a mortgage as a yearly rate. This rate is likely to be higher than the stated note rate or advertised rate on the mortgage, because it takes into account points and other credit costs. The APR allows homebuyers to compare different types of mortgages based on the annual cost for each loan.',
  },
  {
    name: 'Appraisal',
    content:
      ' An estimate of the value of real property, made by a qualified professional called an "appraiser." An appraisal will be needed to determine the value of your property.',
  },
  {
    name: 'Assumption',
    content:
      ' The agreement between buyer and seller where the buyer takes over the payments on an existing mortgage from the seller. This must be approved by the lender and be allowed by the note, which was originally signed by the seller.',
  },

  {
    name: 'Back End',
    content:
      ' This refers to the debt-to-income ratio calculated using principal, interest, taxes, insurance and consumer credit obligations divided by gross monthly income. It is expressed as a percentage.',
  },
  {
    name: 'Balloon',
    content:
      ' Usually a short-term fixed-rate loan which involves small payments for a certain period of time and one large payment for the remaining amount of the principal at a time specified in the contract.',
  },
  {
    name: 'Beneficiary',
    content: ' The entity funding the loan. This is the entity to which the loan is owed.',
  },
  {
    name: 'BK / Bankruptcy',
    content: ' A reorganization or discharge of debts. Could also be referred to as Chapter 7, 11 or 13.',
  },
  {
    name: 'Broker',
    content:
      ' An individual in the business of assisting in arranging funding or negotiating contracts for a client but who does not loan the money himself. Brokers usually charge a fee or receive a commission for their services.',
  },
  {
    name: 'Buy Down',
    content:
      ' When the lender and/or the home builder subsidizes the mortgage by lowering the interest rate during the first few years of the loan. While the payments are initially low, they will increase when the subsidy expires.',
  },

  {
    name: 'Cap',
    content:
      ' The highest rate that an adjustable rate mortgage may reach. It can be expressed as the actual rate or as the amount of change allowed above the start rate. For example, a 7.99 % start rate with a 6% rate change cap would have a maximum interest rate cap of 13.99%.',
  },
  {
    name: 'Cash Out',
    content: ' Any funds disbursed directly to the borrower.',
  },
  {
    name: 'Certificate of Occupancy',
    content:
      ' A certificate issued by local city government to a builder, stating that the building is in proper condition to be occupied.',
  },
  {
    name: 'Certified Copy',
    content:
      ' A true copy, attested to be true by the officer holding the original. It should have a stamp and signature stating that it is a true copy.',
  },
  {
    name: 'Clear-to-close',
    content: ' Loan is ready to be closed with no additional conditions.',
  },
  {
    name: 'Closing',
    content:
      ' The meeting between the buyer, seller and lender or their agents where the property and funds legally change hands. Also called settlement.',
  },
  {
    name: 'Closing Costs',
    content:
      ' Usually include an origination fee, discount points, appraisal fee, title search and insurance, survey, taxes, deed recording fee, credit report charge and other costs assessed at settlement. The costs of closing usually are about 3 percent to 6 percent of the total mortgage amount. Or any costs being charged to facilitate granting of the credit request.',
  },
  {
    name: 'Commitment',
    content:
      ' An agreement, often in writing, between a lender and a borrower to loan money at a future date subject to the completion of paperwork or compliance with stated conditions.',
  },
  {
    name: 'Community Property',
    content:
      ' Property owned in common by a husband and wife, which was not acquired as separate property. A classification of property peculiar to certain states. In community property states, assets may be owned in part by a spouse even if their name does not appear on the title.',
  },
  {
    name: 'Comp. / Comparable',
    content:
      ' A property with the same basic characteristics as the property you are attempting to find the value of (usually a real estate appraisal.) It should have been sold recently and be as similar as possible.',
  },
  {
    name: 'Condominium',
    content:
      ' A property owned as a group, with rights to occupy specific units of the structure. An overseeing board, often referred to as a Homeowners Association, governs the property.',
  },
  {
    name: 'Construction Loan',
    content:
      ' A short term interim loan for financing the cost of construction. The lender advances funds to the builder at periodic intervals as the work progresses.',
  },
  {
    name: 'Consumer Credit',
    content: ' Credit owed by the individual, not secured by real estate.',
  },
  {
    name: 'Conventional Loan',
    content: ' A mortgage not insured by FHA or guarantee by the VA or Farmers Home Administration (FMHA).',
  },
  {
    name: 'Conversion Clause ',
    content:
      'A provision in some ARMS, (Adjustable Rate Mortgage) that allows you to change the ARM to a fixed-rate loan at some point during the loan term.',
  },
  {
    name: 'Credit Ratio',
    content:
      " The ratio, expressed as a percentage, which results when a borrower's monthly payment obligation on long-term debts is divided by his or her net effective income (FHA/VA loans) or gross monthly income (Conventional loans).",
  },
  {
    name: 'Credit Report',
    content: ' History of buyers past credit performance.',
  },
  {
    name: 'Credit Score',
    content:
      ' The score given to an individual to determine the credit worthiness. These scores come from TRW, Equifax and Trans Union.',
  },

  {
    name: 'D.R. / Debt Ratio',
    content: " The customer's monthly obligations divided by their monthly gross income. See also Back End.",
  },
  {
    name: 'Deed',
    content: ' Legal document which conveys the title to a property.',
  },
  {
    name: 'Deed of Trust',
    content:
      ' A document used which pledges real property to secure a debt. In some cases a deed of trust can replace a mortgage.',
  },
  {
    name: 'Default',
    content:
      ' Failure to meet legal obligations in a contract, specifically, failure to make the monthly payments on a mortgage.',
  },
  {
    name: 'Deferred Interest',
    content: ' See Negative Amortization',
  },
  {
    name: 'Delinquency',
    content: ' Failure to make payments on time. This can lead to foreclosure.',
  },
  {
    name: 'Department of Veterans Affairs (VA)',
    content:
      ' An independent agency of the federal government which guarantees long-term, low- or no-down payment mortgages to eligible veterans.',
  },
  {
    name: 'Derog Letter',
    content: ' A letter written by the borrower giving an explanation for any derogatory credit.',
  },
  {
    name: 'Derog',
    content: ' This is short for derogatory and refers to negative credit items.',
  },
  {
    name: 'Discharge',
    content:
      ' Following a completed bankruptcy proceeding, discharged debts are no longer owed or collectable. Lenders will require copies of the discharge papers on any prior bankruptcy filings.',
  },
  {
    name: 'Discount Points',
    content:
      ' Prepaid interest assessed at closing by the lender. Each point is equal to 1 percent of the loan amount (e.g. two points on a $100,000 mortgage would cost $2,000).',
  },
  {
    name: 'Dismissal',
    content:
      ' If a bankruptcy is dropped without being completed, a Bankruptcy Dismissal document will be needed to proceed with the loan. Either the court or the debtor can prompt the dismissal.',
  },
  {
    name: 'Down Payment',
    content:
      ' Money paid to make up the difference between the purchase price and mortgage amount. Down payments usually are 10 percent to 20 percent of the sales price on Conventional loans, and no money down up to 5 percent on FHA and VA loans.',
  },
  {
    name: 'Due-On-Sale Clause',
    content:
      ' A provision in a mortgage or deed of trust that allows the lender to demand immediate payment of the balance of the mortgage if the mortgage holder sells the home.',
  },

  {
    name: 'Earnest Money',
    content:
      ' Money given by a buyer to a seller as part of the purchase price to bind a transaction or assure payment.',
  },
  {
    name: 'Easements',
    content:
      ' An interest in property, owned by another that entitles the holder to a specific limited use or privilege, such as the right to cross or to build adjoining structures on the property.',
  },
  {
    name: 'Encroachment',
    content: " A fixture of a piece of property which intrudes on another's property.",
  },
  {
    name: 'Equal Credit Opportunity Act (ECOA)',
    content:
      ' Is a federal law that requires lenders and other creditors to make credit equally available without discrimination based on race, color, religion, national origin, age, sex, marital status or receipt of income from public assistance programs.',
  },
  {
    name: 'Equity',
    content:
      " The difference between the fair market value and current indebtedness, also referred to as the owner's interest.",
  },
  {
    name: 'Escrow Instructions',
    content:
      ' Instructions to the escrow agent giving the parameters and contingencies involved in the transaction and agreed upon by both parties.',
  },
  {
    name: 'Escrow Waiver',
    content:
      ' Request for a borrower to pay their own taxes and insurance. Escrow wavers are rarely granted with less than a 25% equity position (',
  },
  {
    name: 'Escrow',
    content:
      ' Refers to a neutral third party who carries out the instructions of both the buyer and seller to handle all the paperwork of settlement or "closing." Escrow may also refer to an account held by the lender into which the homebuyer pays money for tax or insurance payments.',
  },

  {
    name: 'Farmers Home Administration (FMHA)',
    content: ' Provides financing to farmers and other qualified borrowers who are unable to obtain loans elsewhere.',
  },
  {
    name: 'Freddie Mac',
    content:
      ', is a quasi-governmental agency that purchases conventional mortgages from insured depository institutions and HUD-approved mortgage bankers.',
  },
  {
    name: 'Federal Housing Administration (FHA)',
    content:
      ' A division of the Department of Housing and Urban Development. Its main activity is the insuring of residential mortgage loans made by private lenders. FHA also sets standard for underwriting mortgages.',
  },
  {
    name: 'Fannie Mae',
    content:
      '. A tax-paying corporation created by Congress that purchases and sells conventional residential mortgages as well as those insured by FHA or guaranteed by VA. This institution, which provides funds for one in seven mortgages, makes mortgage money more available and more affordable.',
  },
  {
    name: 'Fee Simple',
    content: ' The most common form of ownership where the vestee owns both the land and the structures.',
  },
  {
    name: 'FHA Loan',
    content:
      ' A loan insured by the Federal Housing Administration open to all qualified home purchasers. While there are limits to the size of FHA loans, they are generous enough to handle moderate-priced homes almost anywhere in the country.',
  },
  {
    name: 'FHA Mortgage Insurance',
    content:
      ' Requires a small fee (up to 3 percent of the loan amount) paid at closing or a portion of this fee added to each monthly payment of an FHA loan to insure the loan with FHA. On a 9.5 percent $75,000 30-year fixed-rate FHA loan, this fee would amount to either $2,250 at closing or an extra $31 a month for the life of the loan. In addition, FHA mortgage insurance requires an annual fee of 0.5 percent of the current loan amount.',
  },
  {
    name: 'Fixed-Rate Mortgage',
    content: ' A mortgage on which the interest rate is set for the term of the loan.',
  },
  {
    name: 'Flood Insurance',
    content: ' A mandatory insurance for some homeowners whose property is built in a designated flood zone.',
  },
  {
    name: 'Foreclosure',
    content:
      " A legal procedure in which property securing debt is sold by the lender to pay a defaulting borrower's debt.",
  },
  {
    name: 'Free and Clear',
    content: ' This means the property is completely paid for and has no liens attached.',
  },
  {
    name: 'Functional Obsolescence',
    content: ' A detraction from the property value due to the design or material being less functional than the norm.',
  },

  {
    name: 'GFE',
    content: ' Good Faith Estimate of Buyers Loan Charges.',
  },
  {
    name: 'Ginnie Mae',
    content: ', provides sources of funds for residential mortgages, insured or guaranteed by FHA or VA.',
  },
  {
    name: 'Graduated Payment Mortgage (GPM)',
    content:
      ' A type of flexible-payment mortgage where the payments increase for a specified period of time and then level off. This type of mortgage has negative amortization built into it.',
  },
  {
    name: 'Grant Deed',
    content:
      ' A Grant Deed is the most common form of title transfer deed. A Grant Deed contains warranties against prior conveyances or encumbrances.',
  },
  {
    name: 'Gross Monthly Income',
    content: ' The total amount the borrower earns per month, before any expenses are deducted.',
  },
  {
    name: 'Guarantee',
    content:
      ' A promise by one party to pay a debt or perform an obligation contracted by another if the original party fails to pay or perform according to a contract.',
  },

  {
    name: 'Hazard Insurance',
    content:
      ' A form of insurance in which the insurance company protects the insured from specified losses, such as fire, windstorm and the like, it would not cover earthquake, riot, or flood damage.',
  },
  {
    name: 'Homestead ',
    content:
      'The dwelling (house and contiguous land) of the head of the family. Some states grant statutory exemptions, protecting homestead property (usually to a set maximum amount) against the rights of the creditors. Property tax exemptions are also available in some states.',
  },
  {
    name: 'Housing Expenses-to-Income Ratio',
    content:
      " The ratio, expressed as a percentage, which results when a borrower's housing expenses are divided by his/her net effective income (FHA/VA loans) or gross monthly income (Conventional loans).",
  },

  {
    name: 'Impound',
    content:
      " That portion of a borrower's monthly payments held by the lender or servicer to pay for taxes, hazard insurance, mortgage insurance, lease payments, and other items as they become due. Also known as reserves.",
  },
  {
    name: 'Index',
    content:
      ' A published interest rate against which lenders measure the difference between the current interest rate on an adjustable rate mortgage and that earned by other investments (such as one- three-, and five-year U.S. Treasury Security yields, the monthly average interest rate on loans closed by savings and loan institutions, and the monthly average Costs-of-Funds incurred by savings and loans), which is then used to adjust the interest rate on an adjustable mortgage up or down.',
  },
  {
    name: 'Interest Bearing',
    content:
      ' A form of interest calculation where the loan is charged at a daily or monthly rate (1/365 or 1/12 of the annual interest rate) on the current outstanding balance.',
  },
  {
    name: 'Investor',
    content: ' Money source for a lender.',
  },

  {
    name: 'Joint Tenants',
    content: ' A form of holding title where the owners have 100% rights of survivorship unless redirected by a will.',
  },
  {
    name: 'Jumbo Loan',
    content:
      ' A loan which is larger (more than $417,000) than the limits set by the Federal National Mortgage Association and the Federal Home Loan Mortgage Corporation. Because jumbo loans cannot be funded by these two agencies, they usually carry a higher interest rate.',
  },

  {
    name: 'Land Contract',
    content:
      ' An agreement between the seller and the buyer where the title is withheld until a time where the required payments have been completed.',
  },
  {
    name: 'Leasehold Estate',
    content:
      ' A kind of real estate ownership where the lessor does not hold title to the property but has use of the property subject to the terms of the lease.',
  },
  {
    name: 'Legal Description',
    content: ' A method of geographically locating a piece or parcel of land, which is acceptable in a court of law.',
  },
  {
    name: 'LIBOR',
    content:
      ' London InterBank Offered Rate. LIBOR is the base interest rate paid on deposits between banks in the Eurodollar market.',
  },
  {
    name: 'Lien',
    content: ' A claim upon a piece of property for the payment or satisfaction of a debt or obligation.',
  },
  {
    name: 'Loan Committee',
    content: ' Generally the Underwriting process.',
  },
  {
    name: 'Loan Risk',
    content:
      ' The rate category assigned to the loan, which estimates the probable risk of delinquency and loss in the future.',
  },
  {
    name: 'Loan-To-Value Ratio (LTV)',
    content:
      ' The relationship between the amount of the mortgage loan and the appraised value of the property expressed as a percentage.',
  },

  {
    name: 'Margin',
    content:
      ' The number of percentage points the lender adds to the index rate to calculate the ARM interest rate at each adjustment.',
  },
  {
    name: 'Market Value',
    content:
      ' The highest price that a buyer would pay and the lowest price a seller would accept on a property. Market value may be different from the price a property could actually be sold for at a given time.',
  },
  {
    name: 'Mortgage Escrow Accounts',
    content: ' The account set by the Lender to pay Taxes and Insurance on behalf of the Borrower.',
  },
  {
    name: 'Mortgage Insurance',
    content:
      ' Money paid to insure the mortgage when the down payment is less than 20 percent. See Private Mortgage Insurance or FHA Mortgage Insurance.',
  },
  {
    name: 'Mortgagee',
    content: ' The lender.',
  },
  {
    name: 'Mortgagor',
    content: ' The borrower or homeowner.',
  },

  {
    name: 'Negative Amortization',
    content:
      " Amortization means that monthly payments are large enough to pay the interest and reduce the principal on a mortgage. Negative amortization occurs when the monthly payments do not cover all of the interest cost. The interest cost that isn't covered is added to the unpaid principal balance. This means that even after making many payments, a borrower may owe more than was owed at the beginning of the loan.",
  },
  {
    name: 'Net Effective Income ',
    content: "The borrower's gross income minus federal income tax.",
  },
  {
    name: 'Non-Assumption Clause',
    content:
      ' Statements in the mortgage contract forbidding the assumption of the mortgage without the prior approval of the lender.',
  },
  {
    name: 'Non-Owner Occupied',
    content: ' A property not used as a residence by the owner of the property.',
  },
  {
    name: 'Notary Public',
    content:
      ' A person, designated by the state, which can certify the identity of a person when signing various documents.',
  },
  {
    name: 'Note',
    content:
      ' Short for promissory note. This document gives the parameters of the loan and legally obligates the borrower to pay back the debt.',
  },

  {
    name: 'Obligations',
    content: ' Any debt, or recurring payment the borrower is obligated to pay, including mortgage payments.',
  },
  {
    name: 'Origination Fee',
    content:
      ' The fee charged by a lender to prepare loan documents, make credit checks, inspect and sometimes appraise a property; usually computed as a percentage of face value of the loan.',
  },
  {
    name: 'Owner Occupied',
    content: " Designation given to property used as the owner's residence.",
  },
  {
    name: 'Owners Policy',
    content: ' A policy of the title insurance which protects the buyer against problems with the title.',
  },

  {
    name: 'P  I ',
    content:
      'Principal and Interest. This refers to the principal and interest portions of the monthly mortgage payment.',
  },
  {
    name: 'P  L / Profit and Loss',
    content: ' A statement of a businesses gross income, cost of goods, operating costs and net profit or loss.',
  },
  {
    name: 'P.I.T.I.',
    content:
      ' Principal, interest, taxes and insurance. The complete monthly cost associated with financing a property.',
  },
  {
    name: 'P.U.D.',
    content:
      ' Planned Unit Development. Property owned as a group, where individuals own the specific piece of land and structure they occupy, but also have a divided interest in a common area. A board, often referred to as a Homeowners Association, will govern the development.',
  },
  {
    name: 'Piggy Back Loan',
    content:
      ' Financing obtained, subordinate to the first mortgage, to facilitate closing the first mortgage. Also known as a Secondary Financing.',
  },
  {
    name: 'Points',
    content: ' A point is equal to one percent of the principal amount of a mortgage, see also Discount Points.',
  },
  {
    name: 'Power of Attorney',
    content:
      ' An authority by which one person enables another to act on his or her behalf. Power of attorney can be limited to specific areas or be general in some cases.',
  },
  {
    name: 'Pre-Approval',
    content:
      ' The Buyer has actually begun the application process and an underwriter has approved their income, funds and credit. Beware of any conditions on the approval.',
  },
  {
    name: 'Prelim. / Preliminary Title Report',
    content:
      ' The title report generated at the beginning of the application process. It tells the mortgage company what liens are on the property and gives advice as to what will need to be done to gain clear title prior to recording the trust deed.',
  },
  {
    name: 'Prepaid Interest',
    content:
      ' The portion of interest, collected at loan closing, which covers the time period between funding and the beginning of the first 30-day period covered by the first payment. For example, if the loan closed on 2/15, the first payment due on 4/1 would pay interest from 3/1 to 4/1. The prepaid interest would cover the period from 2/15 to 2/28.',
  },
  {
    name: 'Prepaids',
    content:
      " Expenses necessary to create an escrow account or to adjust the seller's existing escrow account. Can include taxes, hazard insurance, private mortgage insurance and special assessments.",
  },
  {
    name: 'Prepayment Penalty',
    content:
      ' Money charged for an early repayment of debt. Prepayment penalties are allowed in some form (but not necessarily imposed) in 36 states and the District of Columbia.',
  },
  {
    name: 'Prepayment',
    content: ' A privilege in a mortgage permitting the borrower to make payments in advance of their due date.',
  },
  {
    name: 'Pre-Qualified',
    content:
      ' Buyer has discussed their financial situation with a loan expert. No attempt has been made to verify the validity of any of the borrowers information. PRE-Qualification is only an indication of what the buyer should qualify for.',
  },
  {
    name: 'Principal',
    content: ' The amount of debt, not counting interest, left on a loan.',
  },
  {
    name: 'Private Mortgage Insurance (PMI)',
    content:
      " In the event that you do not have a 20 percent down payments, lenders will allow a smaller down payment, as low as 5 percent in some cases. With the smaller down payments loans, however, borrowers are usually required to carry private mortgage insurance. Private mortgage insurance will require an initial premium payment of 1.0 percent to 5.0 percent of your mortgage amount and may require an additional monthly fee depending on your loan's structure. On a $75,000 house with a 10 percent down payments, this would mean either an initial premium payment of $2,025 to $3,375, or an initial premium of $675 to $1,130 combined with a monthly payment of $25 to $30.",
  },
  {
    name: 'Purchase Agreement',
    content:
      ' The agreement made between the buyer and seller of a property, containing the purchase price and contingencies of the sale.',
  },

  {
    name: 'Quit Claim',
    content:
      ' A deed operating as a release; intended to pass any title, interest or claim, which the grantor may have in the property, but not containing any warranty of a valid interest or title in the grantor.',
  },

  {
    name: 'Rate Float',
    content: ' Assuming market risk on an interest rate in the hopes that it will go lower prior to closing.',
  },
  {
    name: 'Rate Lock',
    content: ' Choosing to have no change to a rate for a specific length of time.',
  },
  {
    name: 'Ratios',
    content: ' How a buyers housing expense and debt picture relates to their income.',
  },
  {
    name: 'Real Estate Settlement Procedures Act (RESPA)',
    content:
      ' RESPA is a federal law that allows consumers to review information on known or estimated settlement costs once after application and once prior to or at settlement. The law requires lenders to furnish information after application only.',
  },
  {
    name: 'Realtor',
    content:
      ' A real estate broker or an associate holding active membership in a local real estate board affiliated with the National Association of Realtors.',
  },
  {
    name: 'Rescission',
    content:
      ' The cancellation of a contract. With respect to mortgage refinancing, the law that gives the homeowner three days to cancel a contract in some cases once it is signed if the transaction uses equity in the home as security.',
  },
  {
    name: 'Recon / Reconveyance',
    content: ' A release of lien filed with the county recorder by the trustee.',
  },
  {
    name: 'Recording Fees',
    content:
      ' Money paid to the lender for recording a home sale with the local authorities, thereby making it part of the public records.',
  },
  {
    name: 'REFI',
    content: ' Slang for refinance, or a new mortgage on a property that does not change ownership.',
  },
  {
    name: 'Request for Reconveyance',
    content:
      ' Verification given by the beneficiary to the trustee that the conditions of the lien have been fulfilled and request that the lien be canceled.',
  },
  {
    name: 'Reverse Annuity Mortgage (RAM)',
    content:
      " A form of mortgage in which the lender makes periodic payments to the borrower using the borrower's equity in the home as security.",
  },

  {
    name: 'S.I. / Statement of Information ',
    content:
      'The form the customer fills out for the title company giving further identification of the customer. This allows the title company to eliminate debts and liens owed by people with similar names.',
  },
  {
    name: 'Second Mortgage',
    content:
      ' A mortgage which is entered after the primary loan. Called a second due to it being in second lien position to the first mortgage. See also Secondary Financing.',
  },
  {
    name: 'Secondary Financing ',
    content:
      'Financing obtained, subordinate to the first mortgage, to facilitate closing the first mortgage. Also known as a "piggyback" loan.',
  },
  {
    name: 'Servicing',
    content:
      ' All the steps and operations a lender perform to keep a loan in good standing, such as collection of payments, payment of taxes, insurance, property inspections and the like.',
  },
  {
    name: 'Settlement Costs',
    content: ' See Closing Costs.',
  },
  {
    name: 'Settlement ',
    content: 'See Closing.',
  },
  {
    name: 'Shared Appreciation Mortgage (SAM)',
    content:
      ' A mortgage in which a borrower receives a below-market interest rate in return for which a lender (or another investor such as a family member or other partner) receives a portion of the future appreciation in the value of the property. May also apply to mortgages where the borrower shares the monthly principal and interest payments with another party in exchange for a part of the appreciation.',
  },
  {
    name: 'Submission',
    content:
      ' This refers to a complete loan application package submitted for approval to the underwriting department.',
  },
  {
    name: 'Subordination Agreement',
    content:
      ' The agreement detailing the contingencies of subordination, filed with the county recorder. If a lien holder agrees to accept a lien position after that of a later recorded lien.',
  },
  {
    name: 'Substitution of Trustee',
    content: ' A document, filed by the beneficiary, which changes the trustee on a particular trust deed.',
  },
  {
    name: 'Surety Bond',
    content:
      ' A bond which insures against harm to a party (usually the lender or owner) by a lien still attached to the property. This is usually used when the original deed was lost or the beneficiary cannot be located.',
  },
  {
    name: 'Survey',
    content:
      ' A measurement of land prepared by a registered land surveyor showing the location of the land with reference to known points, its dimensions, and the location and dimensions of any building.',
  },
  {
    name: 'Suspended',
    content: ' The underwriter cannot yet approve or deny the loan. More information is required.',
  },

  {
    name: 'Tenants in Common',
    content: ' A percentage interest in a property by two or more individuals without rights of survivorship.',
  },
  {
    name: 'Term Mortgage',
    content: ' See Balloon Payment Mortgage.',
  },
  {
    name: 'Title Insurance',
    content:
      ' The insurance policy insuring the lender and/or the buyer that the liens are as stated in the title report. Any claim arising from a lien other than that disclosed is payable by the title insurance company.',
  },
  {
    name: 'Title Search',
    content:
      ' An examination of municipal records to determine the legal ownership of property. Usually is performed by a title company.',
  },
  {
    name: 'Title',
    content: " A document that gives evidence of an individual's ownership of property.",
  },
  {
    name: 'Trust Deed',
    content:
      ' The Trust Deed attaches the note as a lien on the property. This is the document which conveys the ability to collect from the proceeds of the property.',
  },
  {
    name: 'Truth-in-Lending',
    content:
      ' A federal law requiring disclosure of the Annual Percentage Rate to homebuyers shortly after they apply for the loan. Also known as a TIL.',
  },
  {
    name: 'Two-Step Mortgage',
    content:
      ' A mortgage in which the borrower receives a below-market interest rate for a specified number of years (most often seven or 10 years), and then receives a new interest rate adjusted (within certain limits) to market conditions at that time. The lender sometimes has the option to call the loan, due within 30 days notice at the end of seven or 10 years. Also called "Super Seven" or "Premier" mortgage.',
  },

  {
    name: 'Underwriting',
    content:
      ' The decision whether to make a loan to a potential homebuyers based on credit, employment, assets, and other factors and the matching of this risk to an appropriate rate and term or loan amount.',
  },

  {
    name: 'VA',
    content: ' VETERANS ADMINISTRATION',
  },
  {
    name: 'VA Loan',
    content:
      ' A long-term, low-or no-down payment loan guaranteed by the Department of Veterans Affairs. Restricted to individuals qualified by military service or other entitlements.',
  },
  {
    name: 'VA Mortgage Funding Fee',
    content:
      ' A premium, typically 2.15% based on first time use and no money down, is charged on a VA-backed loan. The VA Funding Fee may be financed into the loan and the amount of the funding fee may vary based on down payment and eligibility requirements. A veteran may have the VA Funding Fee waived if they carry a 10% or more disability with the VA. ',
  },
  {
    name: 'Variable Rate Mortgage (VRM)',
    content: ' See Adjustable Rate Mortgage.',
  },
  {
    name: 'Verification of Deposit (VOD)',
    content:
      " A document signed by the borrower's financial institution verifying the status and balance of his/her financial accounts.",
  },
  {
    name: 'Verification of Employment (VOE)',
    content: " A document signed by the borrower's employer verifying his/her position and salary.",
  },

  {
    name: 'Wraparound',
    content:
      ' Results when an existing assumable loan is combined with a new loan, resulting in an interest rate somewhere between the old rate and the current market rate. The payments are made to a second lender or the previous homeowner, who then forwards the payments to the first lender after taking the additional amount off the top.',
  },

  {
    name: 'Zoning',
    content:
      ' The division of a city or county into areas (zones) specifying the uses allowable for the real property in these areas.',
  },
]

export default glossaryData
