import Axios from 'axios'
import { default as useBaseSWR } from 'swr'
import Vapor from 'laravel-vapor'

/**
 * POS Http client.
 *
 * @type {Axios & {bindings: Record<string, any>}}
 */
export const client = Axios.create({
  baseURL: `${process.env.REACT_APP_DOCUMENTS_URL}/api`,
})

/**
 * POS data fetcher.
 *
 * @type {Function}
 */
export const fetcher = url => client.get(url).then(response => response.data)

/**
 * SWR hook wrapper for POS
 *
 * @type {Function}
 */
export const useSWR = data => useBaseSWR(data, fetcher)

/**
 * Get documents by a given status.
 *
 * @param {Number} status
 */
export const getDocumentsByStatus = status => {
  const statusMap = {
    owed: 1,
    submitted: 2,
    rejected: 3,
    accepted: 4,
  }

  return client
    .get(`v1/consumerService/${client.bindings.consumer_service_id}/request?status_id=${statusMap[status]}`)
    .then(response => response.data.data.requests)
    .then(requests => requests.map(request => ({ ...request, status })))
}

/**
 * Get owed documents.
 */
export const getOwed = () => getDocumentsByStatus('owed')

/**
 * Get submitted documents.
 */
export const getSubmitted = () => getDocumentsByStatus('submitted')

/**
 * Get rejected documents.
 */
export const getRejected = () => getDocumentsByStatus('rejected')

/**
 * Get accepted documents.
 */
export const getAccepted = () => getDocumentsByStatus('accepted')

/**
 * @param {{
 * request_id?: number
 * smartapp_application_id?: string
 * files: any[]
 * document_name?: string
 * }}
 * @param {(file:progress: number) => void|undefined} setProgress
 */
export const uploadRequestedFiles = async (
  { request_id, smartapp_application_id, files, document_name = 'Uploaded from the mobile app.' },
  setProgress
) => {
  const uploadedFiles = await uploadWithProgress(files, setProgress)
  const createdFiles = await createFiles(uploadedFiles)

  return createResponse({
    request_id,
    smartapp_application_id,
    document_ids: createdFiles.map(file => file.id),
    document_name,
  })
}

/**
 *
 * @param {any[]} files
 * @param {(file:progress: number) => void|undefined} setProgress
 * @returns {Promise<any[]>}
 */
const uploadWithProgress = async (files, setProgress) => {
  let progressTracker = files.map(() => 0)

  const updateProgress = () => {
    const sum = progressTracker.reduce((carry, sum) => carry + sum, 0)

    setProgress(Math.round((sum * 100) / files.length))
  }

  return await Promise.all(
    files.map((file, index) => {
      return upload(file, progress => {
        progressTracker[index] = progress
        updateProgress()
      })
    })
  )
}

/**
 * Create files.
 *
 * @param {any[]} files
 */
export const createFiles = files =>
  client
    .post(`v1/consumer/${client.bindings.consumer_id}/document`, {
      files,
      service: 'pos',
      consumer_services_id: client.bindings.consumer_service_id,
    })
    .then(response => response.data.files)

/**
 * @param {{
 * request_id?: number
 * smartapp_application_id?: string
 * document_ids: number[]
 * document_name: string
 * }} options
 */
export const createResponse = options => {
  const data = {
    ...options,
    service: 'pos',
    message: '',
  }

  return client
    .post(`v1/consumerService/${client.bindings.consumer_service_id}/response`, data)
    .then(response => response.data.data)
}

/**
 * Upload a given file.
 *
 * @param {Object} file
 * @param {(progress: number) => any|undefined} progress
 */
export const upload = (file, progress) => {
  return Vapor.store(file, {
    baseURL: process.env.REACT_APP_DOCUMENTS_URL,
    progress,
  }).then(vaporFile => ({
    ...vaporFile,
    title: file.name,
    mime: file.type || file.mimeType,
  }))
}

/**
 * Get document history.
 *
 * @param {Number} document_id
 */
export const getHistory = document_id =>
  client
    .get(`v1/consumerService/${client.bindings.consumer_service_id}/request/${document_id}/history`)
    .then(response => response.data.data.histories)

/**
 * Get documents.
 */
export const getDocuments = () =>
  client.get(`v1/consumer/${client.bindings.user_id}/document?service=loanzify`).then(response => response.data.files)

/**
 * Upload documents for the current consumer.
 *
 * @param {{
 * files: any[]
 * title: string
 * consumer_name: string
 * }}
 * @param {(file:progress: number) => void|undefined} setProgress
 */
export const uploadFiles = async ({ files, title = 'mobile-app-upload.pdf', consumer_name }, setProgress) => {
  const vaporFiles = await uploadWithProgress(files, setProgress)

  return await client
    .post(`v1/consumer/${client.bindings.user_id}/combined-documents`, {
      consumer_services_id: client.bindings.consumer_service_id,
      service: 'loanzify',
      title,
      consumer_name,
      paths: vaporFiles.map(file => file.key),
    })
    .then(response => response.data.files)
}

/**
 * Gets link to view document
 */
export const getDocumentLink = (consumer_id, file_id) =>
  client.get(`v1/document/${file_id}`).then(response => response.data)

export const deleteDocument = file_id => client.delete(`v1/document/${file_id}`).then(response => response.data)

/**
 * Gets document for a non-pos user as an admin
 */
export const getDocumentsAsParent = (service = 'loanzify', consumerServiceId = null, consumerId = null) =>
  client
    .get(`v1/parent/${client.bindings.user_id}/document`, {
      params: {
        service,
        page: 1,
        perPage: 100,
        sortDesc: 1,
        consumerServiceId,
        consumerId,
      },
    })
    .then(response => response.data)

/**
 * @param {import('types/Documents').Request} document
 * @param {{
 *     id: number
 *     review_comments?: string
 *     status?: 'APPROVED' | 'REJECTED'
 *   }} item
 * @returns Promise<void>
 */
export const updateFileReview = (document, item) => {
  return client.put(`v1/consumerService/${client.bindings.consumer_service_id}/request-review/${document.id}`, {
    document_replacements: {},
    response: { review_message: null },
    items_single_pdf: [
      item,
      ...document.current_response.items
        .filter(previousItem => {
          return previousItem.id !== item.id && previousItem.type !== 'COMBINED'
        })
        .map(item => ({
          id: item.id,
          status: item.status,
          review_comments: item.review_comments,
        })),
    ],
  })
}
