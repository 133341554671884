import React from 'react';
import RichTextEditor from './Forms/RichTextEditor';
import styled from 'styled-components';
import { ReactNode, useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

export interface TextEditorModalProps {
  dismiss: (data?: string | null | undefined | number, role?: string) => void;
  value: string;
  onChange?(value: string): void;
  onSave?(value: string): void;
  title: string;
  render: (opener: () => void) => ReactNode;
}

const TextEditorModal: React.FC<TextEditorModalProps> = ({ dismiss, value, onChange, onSave, title }) => {
  const [localValue, setLocalValue] = useState<string>(value);

  const onEditorChanged = (value: string) => {
    if (onChange) {
      onChange(value);
    }
    setLocalValue(value);
  };

  const onSaveClicked = () => {
    if (onSave) {
      onSave(localValue);
      dismiss(localValue, 'confirm');
    }
  };

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={() => dismiss(null, 'cancel')}>Close</IonButton>
          </IonButtons>
          <IonButtons slot="secondary">
            <IonButton color={'secondary'} onClick={onSaveClicked}>
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <TextAreaContainer>
          <RichTextEditor content={value} onChange={onEditorChanged} />
        </TextAreaContainer>
      </IonContent>
    </IonPage>
  );
};

const TextAreaContainer = styled.div`
  padding: 1rem;
`;

export default TextEditorModal;
