import * as React from 'react'
import { LoanMilestoneRecipient } from '../../../types/Pos'
import { IonCheckbox, IonItem, IonItemDivider, IonItemGroup, IonLabel } from '@ionic/react'
import styled from 'styled-components'

type Props = {
  recipient: LoanMilestoneRecipient
}

const RecipientNotificationItem: React.FC<Props> = props => {
  const { recipient } = props

  return (
    <StyledIonItemGroup>
      <StyledIonItemDivider>{recipient.name}</StyledIonItemDivider>

      <IonItem>
        <IonLabel>Email Notification</IonLabel>
        <IonCheckbox
          checked={recipient.email_enabled}
          onIonChange={e => (recipient.email_enabled = e.target.checked)}
        />
      </IonItem>

      <IonItem>
        <IonLabel>SMS Notification</IonLabel>
        <IonCheckbox checked={recipient.sms_enabled} onIonChange={e => (recipient.sms_enabled = e.target.checked)} />
      </IonItem>

      <IonItem>
        <IonLabel>Push Notification</IonLabel>
        <IonCheckbox
          checked={recipient.platform_enabled}
          onIonChange={e => (recipient.platform_enabled = e.target.checked)}
        />
      </IonItem>
    </StyledIonItemGroup>
  )
}

const StyledIonItemGroup = styled(IonItemGroup)`
  margin-bottom: 2.5rem;
`

const StyledIonItemDivider = styled(IonItemDivider)`
  color: var(--color-primary);
  font-size: 24px;
`

export default RecipientNotificationItem
