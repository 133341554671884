import React, { useState } from 'react'
import { IonLoading } from '@ionic/react'
import { Redirect } from 'react-router-dom'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { validateEmail } from 'api/sso'
import { useSession } from 'store/Session'
import { useOnboarding } from 'store/Onboarding'

import Layout from './components/Layout'
import Button from './components/Button'
import FormInput from './components/FormInput'
import styled from 'styled-components'
import Label from '../../components/Label'
import ButtonWrapper from './components/ButtonWrapper'
import Form from './components/Form'
import { useApplication } from '../../store/Application'

const schema = yup.object().shape({
  email: yup.string().email().required(),
})

const LoginLink = styled.span`
  color: var(--color-secondary);
  text-decoration: none;
  margin-left: 5px;
`

const StyledCreateButton = styled(Button)`
  margin-bottom: 1rem;
`

const CheckEmailPage = ({ history }) => {
  const { token } = useSession()
  const { setDetails } = useOnboarding()
  const [loading, setLoading] = useState(false)
  const { hasEncompassIntegration } = useApplication()

  const { register, handleSubmit, errors, setError, trigger, getValues } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = ({ email }) => {
    setLoading(true)

    validateEmail(email)
      .then(({ is_valid, has_password, provider }) => {
        setDetails({ email, provider })

        if (!is_valid) {
          return history.push(`/auth/register/name`)
        }

        if (has_password) {
          return history.push(`/auth/login`)
        }

        return history.push(`/auth/claim`)
      })
      .catch(error => {
        setError('email', {
          type: 'manual',
          message: error.response?.data?.errors?.email?.[0] || error.response?.data?.message,
        })
      })
      .finally(() => setLoading(false))
  }

  if (token) {
    return <Redirect to="/home" />
  }

  const handleClick = async () => {
    const result = await trigger()

    if (result) {
      onSubmit(getValues())
    }
  }

  const Footer = (
    <ButtonWrapper>
      <StyledCreateButton onClick={() => handleClick()}>Get Started</StyledCreateButton>
      <Label center size="sm">
        Already have an account?
        <LoginLink onClick={() => history.push(`/auth/login`)}>Login Here</LoginLink>
      </Label>
    </ButtonWrapper>
  )

  return (
    <Layout title="Hello!" titleLarge footer={Footer} subtitle="Let's create a new account or sign in">
      <IonLoading isOpen={loading} />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          ref={register}
          label={hasEncompassIntegration() ? 'Username' : 'Email'}
          error={errors.email?.message}
          name="email"
          type="email"
          placeholder="Enter your email"
          required
        />
      </Form>
    </Layout>
  )
}

export default CheckEmailPage
