import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IonCard, IonCardContent, IonCardHeader, IonCol, IonGrid, IonRouterLink, IonRow } from '@ionic/react';

import Label from 'components/Label';
import RecentActivity from './RecentActivity';
import RecentConsumers from './RecentConsumers';
import RecentInstallsChart from './RecentInstallsChart';

import { ReactComponent as ProfileIcon } from 'icons/Icon-Profile-2.svg';
import { ReactComponent as Share2Icon } from 'icons/Icon-Share-2.svg';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { useApplication } from '../../../../store/Application';
import { useConsumers } from '../../../../store/Consumers';
import { ShareActionSheet } from 'components/ShareActionSheet';

const StyledIonGrid = styled(IonGrid)``;

const StyledIonCard = styled(IonCard)`
  --background: #fff;
  margin: 0;
`;

const UserDashboard: React.FC = () => {
  const { application } = useApplication();
  const { consumers, fetchConsumers } = useConsumers();

  const [showShare, setShowShare] = useState(false);

  const shareApp = async () => {
    Capacitor.isNativePlatform()
      ? Share.share({ text: application.share_text }).catch(e => console.error(e))
      : setShowShare(true);
  };

  useEffect(() => {
    if (consumers.length) return;

    fetchConsumers();
  }, [consumers, fetchConsumers]);

  return (
    <>
      <StyledIonGrid>
        <IonRow>
          <IonCol size={'6'}>
            <TextAndIconContainer onClick={shareApp} className="home--share_app_button">
              <TextContainer>
                Share
                <br /> Your App
              </TextContainer>
              <IconContainer backgroundColor={'#EBE5F6'} size={48}>
                <Share2Icon />
              </IconContainer>
            </TextAndIconContainer>
          </IonCol>

          <IonCol size={'6'}>
            <IonRouterLink routerLink="/home/switch-view">
              <TextAndIconContainer className="home--switch_view_button">
                <TextContainer>
                  View As
                  <br /> Borrower
                </TextContainer>
                <IconContainer backgroundColor="#D6F4E6" size={48}>
                  <ProfileIcon />
                </IconContainer>
              </TextAndIconContainer>
            </IonRouterLink>
          </IonCol>

          <IonCol size={'12'}>
            <div className="home--recent_activity_widget">
              <RecentActivity />
            </div>
          </IonCol>

          <IonCol size={'12'}>
            <div className="home--recent_users_widget">
              <RecentConsumers />
            </div>
          </IonCol>
          <IonCol size={'12'}>
            <StyledIonCard>
              <IonCardHeader>
                <Label center color="gray-light" size="2xs" weight={500}>
                  Installs in last 30 days
                </Label>
              </IonCardHeader>
              <IonCardContent>
                <RecentInstallsChart />
              </IonCardContent>
            </StyledIonCard>
          </IonCol>
        </IonRow>
      </StyledIonGrid>
      <ShareActionSheet showShare={showShare} handleDismiss={() => setShowShare(false)} />
    </>
  );
};

const TextAndIconContainer = styled.div`
  border-radius: 7.4px;
  padding: 19px 10px 18px 10px;
  background: #fff;
  display: flex;
  box-shadow: 2.13px 2.13px 12.76px rgb(0 0 0 / 7%);
`;

const TextContainer = styled.div`
  display: inline-flex;
  align-items: start;
  height: 100%;
  font-family: Cabin;
  font-weight: 500;
  font-size: 19px;
  line-height: 25px;
  color: #3a455f;
`;
interface IconContainerProps {
  backgroundColor: string;
  size: number;
}
const IconContainer = styled.div<IconContainerProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  border-radius: 9999px;
  margin-left: auto;
  height: ${props => (props.size ? `${props.size}px` : '24px')};
  width: ${props => (props.size ? `${props.size}px` : '24px')};
`;

export default UserDashboard;
