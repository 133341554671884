import styled from 'styled-components'
import { IonCard } from '@ionic/react'

const Card = styled(IonCard)`
  --background: #fff;
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 0.375rem;
  ${props =>
    !props.paddingless &&
    `
        padding: 1rem 1.25rem;
    `}
  ${props =>
    props.onClick &&
    `
        cursor: pointer;
    `}
`

export default Card
